import React, { useState, useEffect, useCallback } from 'react';
import { GamesListVariables, names } from '../../variables/variables';
import GameCard from './molecules/GameCard';
import InactiveEventCard from './molecules/InactiveEventCard';

export default function LibraryList({
  title,
  libraryTitle,
  userGames,
  userEvents,
  libraryGames,
  selectedObject,
  searchOnChange,
  onGameActivateEvent,
  onGameEdit,
  onGameShare,
  onGameShowInfo,
  onGameDelete,
  onEventActivate,
  onEventShowInfo,
  onEventDelete,
  isSuperAdmin,
}) {
  const [sortedUserGames, setSortedUserGames] = useState(false);
  const [sortedLibraryGames, setSortedLibraryGames] = useState(false);
  const [sortedField, setSortedField] = useState('created');
  const [previousSortedField, setPreviousSortedField] = useState(null);

  const handleSortArray = useCallback((a, b) => {
    if (sortedField === 'posts') {
      return (a.questions ? a.questions.length : 0) - (b.questions ? b.questions.length : 0);
    } else {
      if (`${a[sortedField]}`.toLowerCase() < `${b[sortedField]}`.toLowerCase()) {
        return -1;
      }
      if (`${a[sortedField]}`.toLowerCase() > `${b[sortedField]}`.toLowerCase()) {
        return 1;
      }
      return 0;
    }
  }, [sortedField]);

  const handleChangeSortOrderUserGames = (value) => {
    setSortedField(value);
    setSortedUserGames(false);
  };

  const handleChangeSortOrderLibraryGames = (value) => {
    setSortedField(value);
    setSortedLibraryGames(false);
  };

  useEffect(() => {
    if (((userGames && userGames.length > 0) || (userEvents && userEvents.length > 0)) && !sortedUserGames) {
      if (userGames && userGames.length > 0) {
        userGames.sort((a, b) => {
          if (sortedField !== previousSortedField) {
            return handleSortArray(a, b);
          } else {
            return handleSortArray(b, a);
          }
        });
      }
      if (userEvents && userEvents.length > 0) {
        userEvents.sort((a, b) => {
          if (sortedField !== previousSortedField) {
            return handleSortArray(a, b);
          } else {
            return handleSortArray(b, a);
          }
        });
      }
      if (sortedField !== previousSortedField) {
        setPreviousSortedField(sortedField);
      } else {
        setPreviousSortedField(null);
      }
      setSortedUserGames(true);
    }
  }, [userGames, userEvents, sortedUserGames, sortedField, previousSortedField, handleSortArray]);

  useEffect(() => {
    if (libraryGames && libraryGames.length > 0 && !sortedLibraryGames) {
      libraryGames.sort((a, b) => {
        if (sortedField !== previousSortedField) {
          return handleSortArray(a, b);
        } else {
          return handleSortArray(b, a);
        }
      });
      if (sortedField !== previousSortedField) {
        setPreviousSortedField(sortedField);
      } else {
        setPreviousSortedField(null);
      }
      setSortedLibraryGames(true);
    }
  }, [libraryGames, sortedLibraryGames, sortedField, previousSortedField, handleSortArray]);

  return (
    <>
     
        <div className='main-header-container'>
            <div className='main-header-top'>
              <div className='head-container'>
                <h1>{libraryTitle}</h1>
              </div>
              <div className="search-bar-container">
              
              </div>
            </div>
            <div className='main-header-bottom'>
              <p>{names.libraryPageSubtitle}</p>
            </div>
          </div>

<div className="content-container games-list-container">

     
      
      <div style={{display: 'none'}}>
        <ul
          style={{
            listStyleType: 'none',
            display: 'flex',
            backgroundColor: '#F9F9F9',
            padding: '20px',
          }}
        >
          <li style={{ width: '8%' }}>
            <button onClick={() => handleChangeSortOrderLibraryGames('outside')}>
              {GamesListVariables.type}
            </button>
          </li>
          <li style={{ width: '38%' }}>
            <button onClick={() => handleChangeSortOrderLibraryGames('name')}>
              {GamesListVariables.gameName}
            </button>
          </li>
          <li style={{ width: '15%' }}>
            <button onClick={() => handleChangeSortOrderLibraryGames('created')}>
              {GamesListVariables.date}
            </button>
          </li>
          <li style={{ width: '15%' }}>
            <button onClick={() => handleChangeSortOrderLibraryGames('posts')}>
              {GamesListVariables.posts}
            </button>
          </li>
          <li style={{ width: '25%' }}>&nbsp;</li>
        </ul>
      </div>
      <div className="game-cards">
        {libraryGames.map((g, _i) => (
          <GameCard
            key={g.id}
            sourceGame={g}
            isSelected={selectedObject && selectedObject.id === g.id}
            isEditable={isSuperAdmin}
            isDeletable={isSuperAdmin}
            isShareable={false}
            handleShowGameInfo={onGameShowInfo}
            handleActivateGameAsEvent={onGameActivateEvent}
            handleEditExistingGame={onGameEdit}
            handleDeleteGame={onGameDelete}
          />
        ))}
      </div>
    </div>
    </>
  );
}
