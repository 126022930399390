import React from 'react';

export function ContentWrapper(props) {
  return (
    <div
      style={{
        marginLeft: 'calc(250px + 0.5rem)',
        marginRight: '1rem',
        marginTop: '0.8rem',
        borderRadius: '1rem',
        backgroundColor: 'white',
        height: 'fit-content',
        minHeight: '50rem',
      }}
    >
      {props.children}
    </div>
  );
}
