import React, { useState } from 'react';
import useFirebase from '../../../../../hooks/useFirebase';
import Input from '../../../../atoms/Input';
import Button from '../../../../atoms/Button';
import PoplarLogo from '../../../../../static/poplar-logo.svg';
import { Radio } from '../../../../molecules/NewQuestionForm/atoms/Radio';
import { createMember } from '../../../../../utils/API/endpoints';
import { SIGN_IN, OVERVIEW } from '../../../../../constants/routes';
import Loading from '../../../../atoms/Loading';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

export const Register = () => {
  const navigate = useNavigate();
  //const navigate = (path) => { console.log("navigating to: " + path);}
  const firebase = useFirebase();
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    organisation: '',
    className: '',
    isTeacher: false,
  });
  const [loading, setLoading] = useState(false);

  function updateUser(key, value) {
    setUser({
      ...user,
      [key]: value,
    });
  }

  return (
    <div className="register">
      <div className="register__content">
        <div className="register__content__inner">
          <div className="register__content__header">
            <img
              className="register__content__header__logo"
              src={PoplarLogo}
              alt="Poplar logo"
            />
          </div>
          {
            loading ? (
              <p style={{ fontSize: '24px', color: 'white' }}>
                <Loading />
              </p>
            ) : (
              <div className="register__content__login-form">
                <Input
                  name="Fornavn"
                  type="text"
                  labelName={'Fornavn'}
                  styleOverride={{ padding: '4px', color: '#59b9eb' }}
                  onChange={(e) =>
                    updateUser('firstName', e.target.value)
                  }
                  required
                />
                <Input
                  name="Etternavn"
                  type="text"
                  labelName={'Etternavn'}
                  styleOverride={{ padding: '4px', color: '#59b9eb' }}
                  onChange={(e) =>
                    updateUser('lastName', e.target.value)
                  }
                  required
                />
                <Input
                  name="Epost"
                  type="email"
                  labelName={'Epost'}
                  styleOverride={{ padding: '4px', color: '#59b9eb' }}
                  onChange={(e) =>
                    updateUser('email', e.target.value)
                  }
                  required
                />
                <Input
                  name="Passord"
                  labelName={'Passord'}
                  type="password"
                  styleOverride={{ padding: '4px', color: '#59b9eb' }}
                  onChange={(e) =>
                    updateUser('password', e.target.value)
                  }
                  required
                />
                <Input
                  name="Organisasjon"
                  type="text"
                  labelName={'Skole / Organisasjon'}
                  styleOverride={{ padding: '4px', color: '#59b9eb' }}
                  onChange={(e) =>
                    updateUser('organisation', e.target.value)
                  }
                  required
                />
                <Input
                  name="Klasse"
                  type="text"
                  labelName={'Klasse'}
                  styleOverride={{ padding: '4px', color: '#59b9eb' }}
                  onChange={(e) =>
                    updateUser('className', e.target.value)
                  }
                  disabled={!user.isTeacher}
                />
                <Radio
                  name="Lærer"
                  label="Jeg er lærer"
                  checked={user.isTeacher}
                  onChange={(e) => {
                        user.isTeacher = !user.isTeacher;
                        updateUser('isTeacher', user.isTeacher);
                    }
                  }
                  backgroundOverride={'#DDDDDD'}
                />
                <Button
                  text="Registrer"
                  onClick={() => {
                    setLoading(true);
                    createMember(firebase, user).then(() => {
                        // After the new member is created, they should be logged in,
                        // not sent back to the sign in screen!
                        firebase.doSignInWithEmailAndPassword(user.email, user.password)
                        .then(() => {
                          typeof window !== 'undefined' && navigate(OVERVIEW);
                        })
                        .catch((error) => {
                          console.log(`Failed to log in: ${error}`);
                          typeof window !== 'undefined' && navigate(SIGN_IN);
                        });
                    })
                    .catch((error) => {
                      setLoading(false);
                      console.log(`Failed to register a new user: ${error}`);
                      typeof window !== 'undefined' && window.alert(
                        `Det var ikke mulig å opprette deg som bruker. Vennligst ta kontakt med Poplar og gi opp feilkode "${error.code}".`);
                    });
                  }}
                />
                <div className='register_login'>
                  Har du allerede en konto? &nbsp;
                <Link
                to={SIGN_IN}
                  text={'Logg inn'}
                  onClick={() => {
                    typeof window !== 'undefined' && navigate(SIGN_IN);
                  }}
                >Logg inn</Link>
                </div>
                <br/><p>&nbsp;</p><br/>
                
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};
