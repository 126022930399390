import React, { useState, useEffect } from 'react';
import {
    FaChild,
    FaRunning,
    FaUserFriends,
    FaUserCircle,
    FaRegQuestionCircle,
    FaRegLaughBeam,
} from 'react-icons/fa';
import { AdminNav } from './molecules/AdminNav';
import { ContentWrapper } from '../../molecules/ContentWrapper/ContentWrapper';
import useFirebase from '../../../hooks/useFirebase';
import { fetchStatistics } from '../../../utils/API/endpoints';
import { Stat } from './molecules/Stat';
import Loading from "../../atoms/Loading";

export function AdminStats() {
  const firebase = useFirebase();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (firebase === null) {
        return (<p>Laster...</p>);
      }
      try {
        setLoading(true);
        const getStats = await fetchStatistics(firebase);
        setData(getStats.data);
        setLoading(false);
      } catch (_) {
        // Do nothing. This will happen if the user chooses to sign out when on this page.
      }
    };

    getData();
  }, [firebase]);

  return (
    <>
      <ContentWrapper>
        <AdminNav />
        <div style={{ margin: '20px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                fontSize: '1.5em',
                color: '#333',
                padding: '20px',
              }}
            >
              {'Bruksoversikt'}
            </div>
          </div>
        </div>
        {loading || !data ? (
            <div className='admin__wrapper'>
                <Loading />
            </div>
        ) : (
        <>
        <div className="admin__stats">
          <Stat
            title="Nye spill"
            data7d={data.newGames7d}
            data30d={data.newGames30d}
            color="#05445e"
            icon={FaRunning}
          />
          <Stat
            title="Gjennomførte spill"
            data7d={data.newEvents7d}
            data30d={data.newEvents30d}
            color="#1b92a7"
            icon={FaChild}
          />
          <Stat
            title="Nye spørsmål"
            data7d={data.newQuestions7d}
            data30d={data.newQuestions30d}
            color="#75e6da"
            icon={FaRegQuestionCircle}
          />
        </div>
        <div className="admin__stats">
          <Stat
            title="Aktive spillere"
            data7d={data.activePlayers7d}
            data30d={data.activePlayers30d}
            color="#27BAEF"
            icon={FaRegLaughBeam}
          />
          <Stat
            title="Nye medlemmer"
            data7d={data.newMembers7d}
            data30d={data.newMembers30d}
            color="#ef7e27"
            icon={FaUserCircle}
          />
          <Stat
            title="Pålogginger"
            data7d={data.memberLogins7d}
            data30d={data.memberLogins30d}
            color="#a7581b"
            icon={FaUserFriends}
          />
        </div>
        </>
      )}
      </ContentWrapper>
    </>
  );
}
