import React from 'react';
import { Register } from '../components/scenes/Auth/scenes/Register/Register';
import Layout from '../utils/layout';

const RegisterPage = () => (
  <Layout hideNav>
    <Register />
  </Layout>
);

export default RegisterPage;
