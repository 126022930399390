import React from 'react';

export const Button = ({
  text,
  handler,
  styleOverride,
  className,
}) => {
  return (
    <div
      className={'poplar-button' + (className ? ' ' + className : '')}
      onClick={handler}
      onKeyDown={(e) => e.key=="Enter" && handler}
      role="button" tabIndex="0"
      style={styleOverride ? styleOverride : {}}
    >
      <div className="poplar-button-text">{text}</div>
    </div>
  );
};
