import React from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import { TRIAL_DAYS } from '../../variables/variables';
import {
  deleteModalStyles,
  cancelDeleteButtonStyles,
  deleteButtonStyles,
} from '../GamesList/atoms/GamesModalStyles';
import { FaTimesCircle } from 'react-icons/fa';

export function TrialModal({
  isOpen,
  onConfirm,
  onCancel,
  trialStarted,
}) {
  function calculateDaysRemaining() {
    if (trialStarted === undefined) {
      return "Velkommen til Poplar!";
    }
    const difference = new Date().getTime() - trialStarted.getTime();
    const daysRemaining = TRIAL_DAYS - Math.round(difference / 86400000);
    if (daysRemaining < 0) {
      return "Din prøvetid med Poplar har gått ut.";
    }
    if (daysRemaining <= 1) {
      return "Dette er siste dagen du kan prøve Poplar.";
    }
    if (daysRemaining === TRIAL_DAYS) {
      return "Dette er første dagen du prøver Poplar.";
    }
    let daysVerbose;
    switch (daysRemaining) {
    case 2 : daysVerbose = "to"; break;
    case 3 : daysVerbose = "tre"; break;
    case 4 : daysVerbose = "fire"; break;
    case 5 : daysVerbose = "fem"; break;
    case 6 : daysVerbose = "seks"; break;
    case 7 : daysVerbose = "syv"; break;
    case 8 : daysVerbose = "åtte"; break;
    case 9 : daysVerbose = "ni"; break;
    case 10 : daysVerbose = "ti"; break;
    case 11 : daysVerbose = "elleve"; break;
    case 12 : daysVerbose = "tolv"; break;
    case 13 : daysVerbose = "tretten"; break;
    default : daysVerbose = "mange"; break;
    }
    return "Du har tilgang til Poplar i " + daysVerbose + " dager til.";
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      ariaHideApp={false}
      style={deleteModalStyles}
    >
      <h3>{calculateDaysRemaining()}</h3>
      <div>
        <div>
          <p>Du startet prøvetiden {moment(trialStarted).format("Do MMMM YYYY")}.</p>
          <p>
            For å få fast tilgang eller hjelp, ta kontakt med &nbsp;
            <a href="https://www.poplar.no/hovedside/kontakt/" target="_blank" rel="noreferrer">Poplar</a>.
          </p>
        </div>
        <button onClick={onConfirm} style={deleteButtonStyles}>
          OK
        </button>
      </div>
      <button onClick={onCancel} style={{width: '2rem', cursor: 'pointer', ...cancelDeleteButtonStyles}}>
        <FaTimesCircle style={{height: '100%', width: '100%'}}/>
      </button>
    </Modal>
  );
}
