import React, { Component } from 'react';
import { QuestionList } from './molecules/QuestionList';
import { IoChevronDown } from "react-icons/io5";

export class QuestionCategory extends Component {
  state = {
    open: this.props.open,
  };

  handleArrowClick = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  render() {
    return (
      <div
        className="questions-list-container">
        <div className="questions-list-title">
          <div className="questions-list-title-text">
            {this.props.title}
          </div>
{/* 
          <div
            className="questions-list-title-arrow"
            onClick={() => this.handleArrowClick()}
            onKeyDown={() => this.handleArrowClick()}
            role="button" tabIndex="0"
          >
            {
              <IoChevronDown
                style={{ height: '1.0rem', width: '1.0rem' }}
                className={
                  this.state.open
                    ? 'dropLeftButton'
                    : 'dropDownButton'
                }
               />
            }
          </div>*/}
        </div>
        <hr className="qustions-list-divider"></hr>
        <QuestionList
          draggable={this.props.draggable}
          open={true}
          outside={this.props.outside}
          onCardEditClick={this.props.onCardEditClick}
          onCardDeleteClick={this.props.onCardDeleteClick}
          onCardPreviewClick={this.props.onCardPreviewClick}
          questions={this.props.questions}
          withSelector={true}
          setQuestionIsSelected={this.props.setQuestionIsSelected}
        />
      </div>
    );
  }
}

export default QuestionCategory;
