import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { FaSort, FaSortAlphaDown } from 'react-icons/fa';
import { GamesListVariables, names } from '../../variables/variables';
import GameCard from './molecules/GameCard';
import InactiveEventCard from './molecules/InactiveEventCard';

export default function GamesList({
  title,
  libraryTitle,
  userGames,
  userEvents,
  userActiveEvents,
  libraryGames,
  selectedObject,
  searchOnChange,
  onGameActivateEvent,
  onGameEdit,
  onGameShare,
  onGameShowInfo,
  onGameDelete,
  onEditEvent,
  onEventActivate,
  onEventReActivate,
  onEventShowInfo,
  onShowQRcode,
  onEventDelete,
  isSuperAdmin,
  
}) {
  const navigate = useNavigate();
  //const navigate = (path) => { console.log("navigating to: " + path);}
  
  const [sortVisible, setSortVisible] = useState(false);
  const [sortedUserGames, setSortedUserGames] = useState(false);
  const [sortedLibraryGames, setSortedLibraryGames] = useState(false);
  const [sortedField, setSortedField] = useState('created');
  const [previousSortedField, setPreviousSortedField] = useState(null);

  const handleSortArray = useCallback((a, b) => {
    if (sortedField === 'posts') {
      return (a.questions ? a.questions.length : 0) - (b.questions ? b.questions.length : 0);
    } else {
      if (`${a[sortedField]}`.toLowerCase() < `${b[sortedField]}`.toLowerCase()) {
        return -1;
      }
      if (`${a[sortedField]}`.toLowerCase() > `${b[sortedField]}`.toLowerCase()) {
        return 1;
      }
      return 0;
    }
  }, [sortedField]);

  const handleChangeSortOrderUserGames = (value) => {
    setSortedField(value);
    setSortedUserGames(false);
  };

  const handleChangeSortOrderLibraryGames = (value) => {
    setSortedField(value);
    setSortedLibraryGames(false);
  };

  useEffect(() => {
    if (((userGames && userGames.length > 0) || (userEvents && userEvents.length > 0)) && !sortedUserGames) {
      if (userGames && userGames.length > 0) {
        userGames.sort((a, b) => {
          if (sortedField !== previousSortedField) {
            return handleSortArray(a, b);
          } else {
            return handleSortArray(b, a);
          }
        });
      }
      if (userEvents && userEvents.length > 0) {
        userEvents.sort((a, b) => {
          if (sortedField !== previousSortedField) {
            return handleSortArray(a, b);
          } else {
            return handleSortArray(b, a);
          }
        });
      }
      if (sortedField !== previousSortedField) {
        setPreviousSortedField(sortedField);
      } else {
        setPreviousSortedField(null);
      }
      setSortedUserGames(true);
    }
  }, [userGames, userEvents, sortedUserGames, sortedField, previousSortedField, handleSortArray]);

  useEffect(() => {
    if (libraryGames && libraryGames.length > 0 && !sortedLibraryGames) {
      libraryGames.sort((a, b) => {
        if (sortedField !== previousSortedField) {
          return handleSortArray(a, b);
        } else {
          return handleSortArray(b, a);
        }
      });
      if (sortedField !== previousSortedField) {
        setPreviousSortedField(sortedField);
      } else {
        setPreviousSortedField(null);
      }
      setSortedLibraryGames(true);
    }
  }, [libraryGames, sortedLibraryGames, sortedField, previousSortedField, handleSortArray]);

  return (
    <>
     
        <div className='main-header-container'>
            <div className='main-header-top'>
              <div className='head-container'>
                <h1>{title}</h1>
              </div>
              <div className="search-bar-container">
              <div
                className="games-list-search-bar-container"
                style={{ flexBasis: '20%' }}
              >
                <input
                  placeholder={GamesListVariables.gameSearch}
                  className="games-list-search-bar"
                  onChange={(e) => searchOnChange(e.target.value)}
                  style={{
                    padding: '10px',
                    width: '100%',
                    
                    borderRadius: '10px',
                    
                  }}
                />
              </div>
              </div>
            </div>
            <div className='main-header-bottom'>
              <p>{names.mygamesPageSubtitle}</p>
            </div>
          </div>

<div className="content-container games-list-container">
  <div className='sort-container'>
<div className='sort'
onClick={() => setSortVisible(!sortVisible)}
><FaSort /><div>Sortering</div></div>

      <div className='sort-list' style={{display: sortVisible ? 'block' : 'none'}}>
        <ul>
          <li>
            <button onClick={() => handleChangeSortOrderUserGames('outside')}>
              {GamesListVariables.type}
            </button>
          </li>
          <li>
            <button onClick={() => handleChangeSortOrderUserGames('name')}>
              {GamesListVariables.gameName}
            </button>
          </li>
          <li>
            <button onClick={() => handleChangeSortOrderUserGames('created')}>
              {GamesListVariables.date}
            </button>
          </li>
          <li>
            <button onClick={() => handleChangeSortOrderUserGames('posts')}>
              {GamesListVariables.posts}
            </button>
          </li>
        </ul>
      </div>
      </div>


      <div className="game-cards" style={{ marginBottom: '2rem' }}>
      {userActiveEvents.length > 0 ? (
          <div><h2>Aktive spill</h2>
            {userActiveEvents.map((g, _i) => (
              <InactiveEventCard
                key={g.id}
                active={true}
                sourceEvent={g}
                isSelected={selectedObject && selectedObject.id === g.id}
                isDeletable={true}
                onEventActivate={onEventActivate}
                onEventShowInfo={onEventShowInfo}
                onEventDelete={onEventDelete}
                onEditEvent={onEditEvent}
                onShowQRcode={onShowQRcode}
                navigate={navigate}
              />
            ))}
          </div>
        ) : null}

        {userEvents.length > 0 ? (
          <div><h2>Fullførte spill</h2>
            {userEvents.map((g, _i) => (
              <InactiveEventCard
                key={g.id}
                active={false}
                sourceEvent={g}
                isSelected={selectedObject && selectedObject.id === g.id}
                isDeletable={true}
                onEventActivate={onEventActivate}
                onEventReActivate={onEventReActivate}
                onEventShowInfo={onEventShowInfo}
                onEventDelete={onEventDelete}
                onEditEvent={onEditEvent}
                onShowQRcode={onShowQRcode}
                navigate={navigate}
              />
            ))}
          </div>
        ) : null}
        
        {userGames.length > 0 ? (
          <div><h2>Mine spillmaler</h2>
            {userGames.map((g, _i) => (
              <GameCard
                key={g.id}
                sourceGame={g}
                isSelected={selectedObject && selectedObject.id === g.id}
                isEditable={true}
                isDeletable={true}
                isShareable={true}
                handleShowGameInfo={onGameShowInfo}
                handleEditExistingGame={onGameEdit}
                handleActivateGameAsEvent={onGameActivateEvent}
                handleShareGame={onGameShare}
                handleDeleteGame={onGameDelete}
                navigate={navigate}
              />
            ))}
          </div>
        ) : null}
        {userEvents.length === 0 && userGames.length === 0 ? (
          <div style={{ paddingLeft: '1em' }}>
            <p>Ingen treff.</p>
          </div>
        ) : null}
      </div>
      <div>
        
      <Link to="/library" className='more-button' replace={true}>
        <div className='button'>Se flere spill i spillbiblioteket</div>
      </Link>
      </div>
      
    </div>
    </>
  );
}
