import React from "react";

export const QuestionQrPrint = React.forwardRef((question, ref) => {
  return (
    <div className="question-card-print" ref={ref}>
      <h1>Poplar</h1>
      <p>Skann denne koden for å laste inn spørsmålet i appen</p>
      <h3>{question.question.question}</h3>
      <div className="question-card-print image">
        <img alt="QR-kode for spørsmålet"
          src={'https://api.qrserver.com/v1/create-qr-code/?size=300x300&data='+
            encodeURIComponent('Poplar:question/'+question.question.id)}
        />
      </div>
    </div>
  );
});
