import React from 'react';
import { compose } from 'recompose';
import Layout from '../utils/layout';
import Event from '../components/scenes/Event/Event';

import {
  withAuthorization,
  withEmailVerification,
} from '../utils/Session';

const condition = (authUser) => !!authUser;
const EventPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(Event);

export default () => (
  <Layout>
    <EventPage />
  </Layout>
);
