import React from 'react';
import { AuthUserContext } from '../../../utils/Session';

import NavigationAuth from './atoms/NavigationAuth';
import TopMenu from './atoms/TopNavbar';
import SignIn from '../../scenes/Auth/scenes/SignIn/SignIn';

export default function Navigation() {

  
    return (
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser ? (
            <>
            <NavigationAuth authUser={authUser} />
            <TopMenu authUser={authUser} />
            </>
          ) : (
            <>
            <SignIn message={"Du er ikke logget inn..."} />
            </>
          )
        }
      </AuthUserContext.Consumer>
    );
  
}
