/**
 * Functional component of a overflow menu used in OverviewCard
 */
import React from 'react';
//import { useNavigate } from "react-router-dom";
import {
  Menu,
  MenuItem,
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { FaEllipsisV } from "react-icons/fa";
import { GAME_BUILDER } from '../../../../constants/routes';
import { SCORE_PAGE } from '../../../../constants/scoreboard';

const handleResultClick = (props) => props.onResultClick(props.event, SCORE_PAGE);
const handleCompleteClick = (props) => {
  const call = props.firebase.call('endEvent');
  return call({ eventId: props.event.id });
};
const handleEditClick = (props) => {
  //const navigate = useNavigate();
  const navigate = (url) => { console.log("using navigate but not set up: " + url); };

  typeof window !== "undefined" &&
    navigate(`${GAME_BUILDER}?eventId=${props.event.id}&outside=${props.event.outside}&update=true`);
};

export const OverflowMenu = (props) => (
  <Menu
    styles={{ borderRadius: '1rem' }}
    menuButton={
      <button
        style={{
          border: 'none',
          padding: '0.5rem 0.75rem',
          borderRadius: '0.75rem',
          fontSize: 'initial',
          position: 'absolute',
          top: '0.25rem',
          right: '0.25rem',
        }}
       >
        <FaEllipsisV style={{ height: "1.05rem", opacity: "60%" }}/>
      </button>
    }
    offsetX={-130}
   >
    {/*<MenuItem
      disabled={true}
      styles={{ padding: '1.3rem 1.5rem', marginTop: '0.5rem' }}
      onClick={handleEditClick}
    >
      Rediger
    </MenuItem>*/}
    <MenuItem
      styles={{
        padding: '0.1rem 1rem 0.2rem 0.3rem',
        color: '#2DBB54',
      }}
      onClick={() => handleCompleteClick(props)}
     >
      Fullfør
    </MenuItem>
    <MenuItem
      styles={{
        padding: '0.1rem 1rem 0rem 0.3rem',
        color: '#2DBB54',
      }}
      onClick={() => handleResultClick(props)}
     >
      Se Resultater
    </MenuItem>
    <MenuItem
      styles={{
        padding: '0.1rem 1rem 0.2rem 0.3rem',
        color: '#2DBB54',
      }}
      onClick={() => handleEditClick(props)}
     >
      Endre
    </MenuItem>
  </Menu>
);
