import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import useFirebase from '../../../hooks/useFirebase';
import {
  fetchMemberList,
  deleteMember,
  freezeMember,
} from '../../../utils/API/endpoints';
import { AdminNav } from './molecules/AdminNav';
import { ContentWrapper } from '../../molecules/ContentWrapper/ContentWrapper';
import { NewMemberForm } from './molecules/NewMemberForm';
import { AdminCard } from './molecules/AdminCard';
import { Button } from '../../molecules/NewQuestionForm/atoms/Button';
import Loading from '../../atoms/Loading';
import { IoAdd } from "react-icons/io5";

const newMemberModalStyles = {
  content: {
    top: '20%',
    left: '20%',
    right: '20%',
    bottom: '100%',
    minHeight: '500px',
    background: 'rgba(242, 241, 240, 1)',
    color: 'black',
    padding: '0px',
    borderRadius: '1rem',
    border: 'none',
    boxShadow: '0px 4px 10px rgba(103, 107, 133, 0.15)',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(155, 155, 155, 0.75)',
  },
};

export function Admin() {
  const firebase = useFirebase();
  const [admins, setAdmins] = useState(null);
  const [search, setSearch] = useState('');
  const [newMemberModalOpen, setNewMemberModalOpen] = useState(false);
  const [sorted, setSorted] = useState(false);
  const [sortedField, setSortedField] = useState('firstName');
  const [previousSortedField, setPreviousSortedField] = useState(null);
  const [sortedAdmins, setSortedAdmins] = useState(null);

  // 1577836800000 = 2020-01-01 00:00:00 UTC
  const y2020 = new Date(1577836800000);

  const handleFreezeMember = (member) => {
    freezeMember(firebase, {
        uid: member.id,
        isFrozen: !member.isDisabled,
    });
    member.isDisabled = !member.isDisabled;
    setAdmins(admins);
  };

  const handleDeleteMember = (member) => {
    deleteMember(firebase, { uid: member.id });
    setAdmins(admins.filter(item => item.displayName !== member.displayName));
  };

  const handleSortArray = useCallback((a, b) => {
    let val = sortedField;
    if (val === 'superAdmin') {
        if ((a[val] ?? false) === true && (b[val] ?? false) === false) {
          return -1;
        }
        if ((a[val] ?? false) === false && (b[val] ?? false) === true) {
          return 1;
        }
        val = 'firstName';
    }
    if (val === 'loginCount') {
        if ((a[val] ?? 0) < (b[val] ?? 0)) {
          return 1;
        }
        if ((a[val] ?? 0) > (b[val] ?? 0)) {
          return -1;
        }
        val = 'firstName';
    }
    if (val === 'creationTime' || val === 'lastSignInTime') {
        const aDate = !a[val] ? y2020 : new Date(a[val]);
        const bDate = !b[val] ? y2020 : new Date(b[val]);
        if (aDate < bDate) {
          return 1;
        }
        if (aDate > bDate) {
          return -1;
        }
        val = 'firstName';
    }
    if (!a[val] && !b[val]) {
        val = 'firstName';
    }
    if (`${a[val] ?? 'øøø'}`.toLowerCase() < `${b[val] ?? 'øøø'}`.toLowerCase()) {
      return -1;
    }
    if (`${a[val] ?? 'øøø'}`.toLowerCase() > `${b[val] ?? 'øøø'}`.toLowerCase()) {
      return 1;
    }
    return 0;
  }, [sortedField, y2020]);

  const handleChangeSortOrder = (value) => {
    setSortedField(value);
    setSorted(false);
  };

  useEffect(() => {
    const getAdmins = async () => {
      if (firebase === null) {
        return <p>Laster...</p>;
      }
      try {
        const response = await fetchMemberList(firebase);
        setAdmins(response.data.admins);
      } catch (_) {
        // Do nothing. This will happen if the user chooses to sign out when on this page.
      }
    };

    if (admins && admins.length > 0 && !sorted) {
      admins.sort((a, b) => {
        if (sortedField !== previousSortedField) {
          return handleSortArray(a, b);
        } else {
          return handleSortArray(b, a);
        }
      });
      if (sortedField !== previousSortedField) {
        setPreviousSortedField(sortedField);
      } else {
        setPreviousSortedField(null);
      }
      setSortedAdmins(admins);
      setSorted(true);
    }
    getAdmins();
  }, [firebase, admins, sorted, sortedField, previousSortedField, sortedAdmins, handleSortArray]);

  return (
    <>
      <ContentWrapper>
        <AdminNav />
        <div style={{ margin: '20px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                fontSize: '1.5em',
                color: '#333',
                padding: '20px',
              }}
            >
              {'Medlemmer'}
            </div>
            <Button
              styleOverride={{
                height: 'fit-content',
                backgroundColor: '#EB7179',
                borderRadius: '20px',
                boxShadow: '0px 4px 10px rgba(235, 113, 121, 0.5)',
                whiteSpace: 'nowrap',
                fontSize: '0.9rem',
                padding: '0.7rem 0.8rem',
                transitionDuration: '200ms',
                margin: 'auto 0px auto auto',
              }}
              handler={() => setNewMemberModalOpen(true)}
              text={
                <div>
                  Nytt medlem
                  <IoAdd style={{
                      height: '0.8rem',
                      width: '0.8rem',
                      marginLeft: '0.5rem',
                  }}/>
                </div>
              }
            />
            <input
              placeholder="Søk i medlemmer ..."
              className="games-list-search-bar"
              onChange={(e) => setSearch(e.target.value)}
              style={{
                margin: '10px',
                padding: '10px',
                width: '20%',
                background: '#F3F3F3',
                borderRadius: '5px',
                border: '0px solid black',
              }}
            />
          </div>
          {!sortedAdmins ? (
            <div className="admin__wrapper">
              <Loading />
            </div>
          ) : (
            <table
              className="admin__users"
              style={{ backgroundColor: '#F9F9F9', width: '100%' }}
            >
              <thead>
                <tr style={{ backgroundColor: '#F9F9F9' }}>
                  <th style={{ padding: '1em', textAlign: 'left' }}>
                    <button onClick={() => handleChangeSortOrder('firstName')}>
                      Navn
                    </button>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <button onClick={() => handleChangeSortOrder('email')}>
                      Epostadresse
                    </button>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <button onClick={() => handleChangeSortOrder('creationTime')}>
                      Registrert
                    </button>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <button onClick={() => handleChangeSortOrder('loginCount')}>
                      Pålogginger
                    </button>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <button onClick={() => handleChangeSortOrder('lastSignInTime')}>
                      Sist sett
                    </button>
                  </th>
                  <th style={{ textAlign: 'left' }}>
                    <button onClick={() => handleChangeSortOrder('superAdmin')}>
                      SuperAdmin?
                    </button>
                  </th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {sortedAdmins.length > 0 ? (
                  sortedAdmins
                    .filter((value) =>
                      `${value.firstName} ${value.lastName} ${value.email}`
                        .toLowerCase()
                        .includes(search.toLowerCase()),
                    )
                    .map((admin, key) => {
                      return <AdminCard
                        key={key}
                        admin={admin}
                        handleFreezeMember={handleFreezeMember}
                        handleDeleteMember={handleDeleteMember}
                        currentUser={firebase.auth.currentUser}
                      />;
                    })
                ) : (
                  <tr>
                    <td
                      colSpan="5"
                      style={{ padding: '1em', textAlign: 'left' }}
                    >
                      <p>Ingen treff</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </ContentWrapper>
      <Modal
        isOpen={newMemberModalOpen}
        onRequestClose={() => setNewMemberModalOpen(false)}
        contentLabel="Opprett medlem"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        style={newMemberModalStyles}
       >
        <NewMemberForm closeModal={() => setNewMemberModalOpen(false)} />
      </Modal>
    </>
  );
}
