import React, { Component } from 'react';
import SignInForm from '../../molecules/SignInForm';
import PasswordForgetLink from '../../../../molecules/PasswordForget/atoms/PasswordForgetLink';
import PoplarLogo from '../../../../../static/poplar-logo.svg';
import Button from '../../../../atoms/Button';

import { REGISTER } from '../../../../../constants/routes';
import { BrowserAgent } from './atoms/BrowserAgent';
import { Navigate } from 'react-router';
import { withNavigation } from '../../../../../utils/navigation/context';

class SignIn extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const message = this.props.message;
    return (
      <div className="login">
        <div className="login__content">
          <div className="login__content__inner">
            <div className="login__content__header">
              <img
                className="login__content__header__logo"
                src={PoplarLogo}
                alt="Poplar logo"
              />
            </div>
              <BrowserAgent />
              {message && <div className="login__content__message" style={{color: 'white', padding: 50}}>{message}</div>}
            <div className="login__content__login-form">
              <div className="login__content__form">
                <SignInForm />
              </div>
              <div className="login__content__password-forget">
                <PasswordForgetLink />
              </div>
              <div className="login__register__buttons">
                <Button
                  text={'Logg inn med Feide'}
                  onClick={() => {
                    window.open(
                      'https://auth.dataporten.no/oauth/authorization?client_id=ef65f301-46d5-4c38-9d18-377b13dc87c3&response_type=code&redirect_uri=https://europe-west1-poplar-prod-4695a.cloudfunctions.net/token&state=f47282ec-0a8b-450a-b0da-dddb393fbeca',
                      '_self',
                    );
                  }}
                />
                <Button
                  text={'Registrer med epost'}
                  onClick={() => {
                    typeof window !== 'undefined' && this.props.navigate(REGISTER);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNavigation(SignIn);
