import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { onSnapshot, doc, getFirestore } from 'firebase/firestore';
import logo from '../../../../static/poplar-logo-dark.svg';
import {
  ADMIN,
  ADMIN_USERS,
  MY_GAMES,
  MY_QUESTIONS,
  OVERVIEW,
  GAME_BUILDER,
  ACCOUNT,
} from '../../../../constants/routes';
import useFirebase from '../../../../hooks/useFirebase';
import { TRIAL_ENABLED, TRIAL_DAYS } from '../../../variables/variables';
import { TrialModal } from '../../AuthModules/TrialModal';
import { FaBars, FaSignOutAlt, FaUser } from 'react-icons/fa';
import {
    GiIceShield,
} from 'react-icons/gi';

import {
  FaHome,
  FaQuestionCircle,
  FaPuzzlePiece,
  FaFolder,
  FaPlus,
} from 'react-icons/fa';
import SignOutButton from './SignOutButton';
import { NewGameModal } from '../../AuthModules/NewGameModal';

function handleSignOut(firebase) {
  const path = typeof window !== "undefined" ? window.location.pathname : "";
  if (firebase) {
    if (path === GAME_BUILDER &&
      !window.confirm(
        "Er du sikker på at du vil logge ut?\nDette vil slette alle ulagrede endringer!")) {
      return;
    }
    firebase.doSignOut();
  }
}

const activeStyle = {
  boxShadow: 'inset -1px 0px 0px 0px #59b9eb',
  background: '#EFF8FD',
  color: '#59b9eb',
};

const NavigationAuth = ({authUser}) => {
  
  const [mobileMenu, setMobilemenu] = useState(false);  
  const [page, setPage] = useState(null);
  const [superAdmin, setSuperAdmin] = useState(false);
  const [trialStarted, setTrialStarted] = useState(undefined);
  const [trialModal, setTrialModal] = useState(false);
  const [newGameModal, setNewGameModal] = useState(false);

  const firebase = useFirebase();

  function confirmNavigation(e) {
    const path =
      typeof window !== 'undefined' ? window.location.pathname : '';
    const msg =
      'Er du sikker på at du vil gå tilbake?\nDette vil slette alle ulagrede endringer!';
    if (path === GAME_BUILDER && !window.confirm(msg)) {
      e.preventDefault();
    }
  }

  function newGameClick(e) {
    
    console.log("newGameClick");
    setNewGameModal(true);
    /*typeof window !== "undefined" &&
      navigate(`${GAME_BUILDER}?type=inside`);*/
      e.preventDefault();
  }

  
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    let unsubscribe;
    if (authUser) {
      const ref = doc(getFirestore(), 'admins', authUser.uid);
      unsubscribe = onSnapshot(ref, (doc) => {
        setUserData(doc.data());
      });

    } else {
      setUserData(null);
    }

    return unsubscribe;
  }, [authUser]);



  useEffect(() => {
    if (!firebase) return;
    setSuperAdmin(firebase.superAdmin);
    setTrialStarted(firebase.trialStarted);
  }, [firebase]);

  useEffect(() => {
    const path = window && window.location.pathname;
    if (path === OVERVIEW) {
      setPage(1);
    } else if (path === MY_GAMES) {
      setPage(2);
    } else if (path === MY_QUESTIONS) {
      setPage(3);
    } else if (path.includes("library")) {
      setPage(5);
    }
    else if (path.includes(ADMIN)) {
      setPage(4);
    }
  }, [setPage]);

  useEffect(() => {
      if (!firebase) return;
      if (trialStarted !== undefined && TRIAL_ENABLED) {
         // Don't show the reminder too often, i.e. only once per day at most.
         if (localStorage.getItem('onTrialReminder') !== null) {
          const lastShown = new Date(localStorage.getItem('onTrialReminder'));
          // 86 400 000 = milliseconds in one day
          if (new Date().getTime() - lastShown.getTime() > 86400000) {
            localStorage.setItem('onTrialReminder', new Date().toJSON());
            setTrialModal(true);
          }
        } else {
          localStorage.setItem('onTrialReminder', new Date().toJSON());
          setTrialModal(true);
        }
        const difference = new Date().getTime() - trialStarted.getTime();
        const daysRemaining = TRIAL_DAYS - Math.round(difference / 86400000);
        localStorage.setItem('hasTrialPeriodExpired', (daysRemaining < 0));
      } else {
        // If not enabled or the trial period is not applicable then remove the variable.
        localStorage.removeItem('hasTrialPeriodExpired');
      }
  }, [trialModal, firebase, trialStarted]);

  return (
    <>
    <div  className={`navbar ${mobileMenu ? '' : 'navbar-mobile-hide'}`} style={{ zIndex: '1' }}>
      <div className="navbar__inner">
        <div className="navbar__logo">
          <Link to={OVERVIEW}>
            <img src={logo} alt="Poplar logo" />
          </Link>
        </div>
        <div className="navbar__left">
          <div className="navbar__links">
            <Link 
              to={GAME_BUILDER}
              onClick={(e) => newGameClick(e)}
              onKeyDown={(e) => newGameClick(e)}
              role="button" tabIndex="0" 
              style={{marginLeft: '-6px'}}>
              <div className="navbar__new-game-button">
                {React.createElement(FaPlus, {
                  className: 'navbar__links__icon',
                })}
                <span className='navbar__links__text'>Nytt spill</span>
              </div>
            </Link>
            <Link
              to={OVERVIEW}
              style={page === 1 ? activeStyle : null}
              onClick={(e) => confirmNavigation(e)}
              onKeyDown={(e) => confirmNavigation(e)}
              role="button"
              tabIndex="0"
             >
              {page === 1 ?
                React.createElement(FaHome, {
                  className: 'navbar__links__icon',
                })
              :
                React.createElement(FaHome, {
                  className: 'navbar__links__icon',
                  style: {
                    filter: 'brightness(50%)',
                  },
                })
              }
              <span className='navbar__links__text'>Oversikt</span>
            </Link>
            <Link
              to={MY_GAMES}
              style={page === 2 ? activeStyle : null}
              onClick={(e) => confirmNavigation(e)}
              onKeyDown={(e) => confirmNavigation(e)}
              role="button" tabIndex="0"
             >
              {page === 2 ?
                React.createElement(FaPuzzlePiece, {
                  className: 'navbar__links__icon',
                })
              :
                React.createElement(FaPuzzlePiece, {
                  className: 'navbar__links__icon',
                  style: {
                    filter: 'brightness(50%)',
                  },
                })
              }
              <span className='navbar__links__text'>Mine spill</span>
            </Link>
            
            <Link
              to={"/library"}
              style={page === 5 ? activeStyle : null}
              onClick={(e) => confirmNavigation(e)}
              onKeyDown={(e) => confirmNavigation(e)}
              role="button" tabIndex="0"
             >
              {page === 5 ?
                React.createElement(FaFolder, {
                  className: 'navbar__links__icon',
                })
              :
                React.createElement(FaFolder, {
                  className: 'navbar__links__icon',
                  style: {
                    filter: 'brightness(50%)',
                  },
                })
              }
              <span className='navbar__links__text'>Spillbibliotek</span>
            </Link>
            <Link
              to={MY_QUESTIONS}
              style={page === 3 ? activeStyle : null}
              onClick={(e) => confirmNavigation(e)}
              onKeyDown={(e) => confirmNavigation(e)}
              role="button" tabIndex="0"
             >
              {page === 3 ?
                React.createElement(FaQuestionCircle, {
                  className: 'navbar__links__icon',
                })
              :
                React.createElement(FaQuestionCircle, {
                  className: 'navbar__links__icon',
                  style: {
                    filter: 'brightness(90%)',
                  },
                })
              }
              <span className='navbar__links__text'>Spørsmål</span>
            </Link>

          <div className='only-mobile'>


            <div className="">
          <Link
            to={ACCOUNT}
            
            
            role="button" tabIndex="-7"
           >
           { React.createElement(FaUser, {
                  className: 'navbar__links__icon',
                  style: {
                    filter: 'brightness(90%)',
                  },
                })}
            <span className='navbar__links__text'>
              Minside
            </span>
          </Link>

          <Link
            to="/"
            
            onClick={() => handleSignOut(firebase)}
            onKeyDown={() => handleSignOut(firebase)}
            role="button"
            tabIndex="0"
           >
           { React.createElement(FaSignOutAlt, {
                  className: 'navbar__links__icon',
                  style: {
                    filter: 'brightness(90%)',
                  },
                })}
            <span className='navbar__links__text'>
              Logg ut
            </span>
          </Link>
          

          </div>

          <div className='userinfo'>
                
                <div>
                {userData?.firstName}
                &nbsp;{userData?.lastName}
                </div>
                <div className='mail'>
                {authUser.email}
                      &nbsp;</div>
                      <div>
                {userData?.organisation && <>{userData?.organisation}</>}
                </div>
              </div>
          
          </div>

            {superAdmin && (
              <Link
                to={ADMIN_USERS}
                style={page === 4 ? activeStyle : null}
                onClick={(e) => confirmNavigation(e)}
                onKeyDown={(e) => confirmNavigation(e)}
                role="button" tabIndex="0"
               >
              {page === 4 ?
                React.createElement(GiIceShield, {
                  className: 'navbar__links__icon',
                })
              :
                React.createElement(GiIceShield, {
                  className: 'navbar__links__icon',
                  style: {
                    filter: 'brightness(50%)',
                  },
                })
              }
              <span className='navbar__links__text'>Adminpanel</span>
              </Link>
            )}
          </div>
        </div>

        
        <div className='end'>
        <div>
            <a
              href="mailto:post@poplar.no?subject=Feilrapportering i Poplar"
              target="_blank"
              rel="noreferrer"
              style={{
                color: '#59B9EB',
                fontSize: '0.8rem',
                padding: '2px',
                textDecoration: 'none',
              }}
            >
              Rapporter feil
            </a> 
          </div>
          
          <div>
            <a
              href="https://www.poplar.no"
              target="_blank"
              rel="noreferrer"
              style={{
                color: '#59B9EB',
                fontSize: '0.8rem',
                padding: '2px',
                textDecoration: 'none',
              }}
            >
              Poplar AS
            </a> <span
            style={{
              color: '#969BA0',
              fontSize: '0.8rem',
              padding: '2px',
              marginBottom: '10px',
            }}
           >
            &copy;&nbsp;{new Date().getFullYear()}
          </span>
          </div>
          
          <div
            style={{
              color: '#59B9EB',
              fontSize: '0.8rem',
              padding: '2px',
            }}
           >
            <a
              href="https://www.poplar.no/hovedside/personvern/"
              target="_blank"
              rel="noreferrer"
              style={{
                color: '#59B9EB',
                fontSize: '0.8rem',
                padding: '2px',
                textDecoration: 'none',
              }}
             >Personvernerklæring</a>
          </div>
        </div>
      </div>
      <TrialModal
        isOpen={trialModal}
        onCancel={() => setTrialModal(false)}
        onConfirm={() => setTrialModal(false)}
        trialStarted={trialStarted}
       />
       <NewGameModal
        isOpen={newGameModal}
        authUser={authUser}
        onCancel={() => setNewGameModal(false)}
        onConfirm={() => setNewGameModal(false)}
       />
    </div>
    
    <div className={`mobile-navbar`}>
      <div className='logo'><img src={logo} alt="utekirke logo" /></div>
      <div className='bars' onClick={() => setMobilemenu(!mobileMenu)} onKeyDown={() => setMobilemenu(!mobileMenu)} role="button" tabIndex="0"><FaBars /><span className='hide'>Meny</span></div>
    </div>
    </>
  );
};

export default NavigationAuth;

