import React from 'react'

export const Tag = ({text, checked, handler, className}) => {
    return (
        <div className={"poplar-tag-container" + (className ? (" " + className) : "")}>
            <span
                className={"poplar-tag" + (checked ? " checked" : "")}
                checked={checked}
                onClick={_ => handler(text)}
                onKeyDown={_ => handler(text)}
                aria-checked={checked}
                role="checkbox"
                tabIndex="0"
            >{text}</span>
        </div>
    )
}