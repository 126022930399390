import React, { Component } from 'react';
import ScoreboardQuestionCardTeam from './ScoreboardQuestionCardTeam';
import { Button } from '../../NewQuestionForm/atoms/Button';

export class ScoreboardQuestionCard extends Component {
  state = {
    errorMessage: null,
    question: null,
  };

  componentDidMount() {
    const questionId = this.props.question.question.path;
    this.props.firebase
      .path(questionId)
      .get()
      .then((result) => {
        const questionData = {
          ...result.data(),
          id: result.id,
        };
        this.setState({
          question: questionData,
        });
      })
      .catch((err) => {
        if (err.code === 'permission-denied') {
          this.setState({
            errorMessage: 'Fikk ikke tilgang til spørsmål',
          });
        } else {
          this.setState({
            errorMessage: 'Uventet feil ved innlasting',
          });
        }
      });
  }

  render() {
    return (
      <div className="scoreboard-question-card-container">
        {this.state.question ? (
          <>
            <div className="scoreboard-question-card-top">
              <div
                className="scoreboard-question-card-question"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '1px solid #ECECEC',
                }}
               >
                <p
                  style={{
                    color: 'black',
                    fontSize: '1.4rem',
                    marginRight: '1rem',
                    padding: '0 0 0 1rem',
                  }}
                 >
                  {this.props.index + 1}
                </p>
                <p
                  style={{
                    color: 'black',
                    fontSize: '1.25rem',
                    marginRight: '1rem',
                  }}
                 >
                  {' '}
                  {this.state.question.question}
                </p>
              </div>
              <div className="scoreboard-type-points-container">
                <table>
                  <tbody style={{ display: 'flex' }}>
                    {this.state.question.alternatives &&
                      this.state.question.alternatives.map((a, i) => {
                        if (this.state.question.type === 'multiple-answer') {
                          return (
                            <tr key={i}>
                              <td style={{
                                  padding: '0 0 0 1rem',
                                  color: this.state.question.answer.includes(a)
                                      ? 'rgb(38, 170, 111)' : 'rgb(235, 113, 121)',
                                  fontWeight:
                                    this.state.question.answer.includes(a) ? 'bold' : 'initial',
                                }}
                               >
                                {a}
                              </td>
                            </tr>
                          );
                        }
                        return (
                          <tr key={i}>
                            <td
                              style={{
                                padding: '0 0 0 1rem',
                                color: this.state.question.answer === a
                                    ? 'rgb(38, 170, 111)' : 'rgb(235, 113, 121)',
                                fontWeight: this.state.question.answer === a
                                    ? 'bold' : 'initial',
                              }}
                             >
                              {a}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div style={{ display: 'inline-flex' }}></div>
            <div className="scoreboard-question-card-bottom">
              <div className="scoreboard-question-card-teams" style={{ margin: '1rem' }}>
                {this.props.users.map((u, i) => (
                  <ScoreboardQuestionCardTeam
                    key={i}
                    user={u}
                    qid={this.state.question.id}
                   />
                ))}
              </div>
              <div className="scoreboard-question-card-see-more">
                <Button
                  text="Se mer"
                  styleOverride={{
                    border: 'none',
                    borderRadius: '1rem',
                    margin: '1rem',
                  }}
                  handler={() => this.props.onSeeMoreClick(this.state.question)}
                 />
              </div>
            </div>
          </>
        ) : this.state.errorMessage ? (
          <div>{this.state.errorMessage}</div>
        ) : null}
      </div>
    );
  }
}

export default ScoreboardQuestionCard;
