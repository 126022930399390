import React from 'react';

import { useNavigate } from 'react-router-dom';

export const NavigationContext = React.createContext(null);


const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();

  return (
    <NavigationContext.Provider value={navigate}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;




export const withNavigation = (Component) => (props) =>
  (
    <NavigationContext.Consumer>
      {(navigate) => <Component {...props} navigate={navigate} />}
    </NavigationContext.Consumer>
  );

