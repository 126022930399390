import React from 'react';

export const Input = ({
  placeholder,
  maxLength,
  description,
  handler,
  value,
  styleOverride,
  containerStyle,
  className,
  disabled,
  type,
}) => {
  return (
    <div
      className={
        'poplar-input-container' + (className ? ' ' + className : '')
      }
      style={containerStyle ? containerStyle : {}}
    >
      <input
        className="poplar-input"
        placeholder={placeholder}
        value={value ? value : ''}
        disabled={disabled}
        maxLength={maxLength ?? 1024}
        type={type}
        onChange={(e) => handler(e.target.value)}
        style={styleOverride ? styleOverride : {}}
      />
      {description ? (
        <div className="poplar-input-text">{description}</div>
      ) : null}
    </div>
  );
};
