import React, { useEffect } from 'react';
import finalDestinationMarker from '../../../static/final-post.png';

import {
  Circle,
  GoogleMap,
  LoadScript,
  Marker,
} from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const DEFAULT_ZOOM = 17;
const DEFAULT_VIEWPORT = {
  longitude: 16.421906,
  latitude: 65.446827,
  zoom: DEFAULT_ZOOM,
};

// https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
const options = {
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeId: 'hybrid',
  center: {
    lat: DEFAULT_VIEWPORT.latitude,
    lng: DEFAULT_VIEWPORT.longitude,
  },
  clickableIcons: false,
};

const generateMarkers = (questions, onDragEnd) => {
  return questions.map((q, i) => {
    if (window.google && window.google.maps && (q.coords || q.position))
      return (
        <Marker
          key={i}
          position={{
            lat: q.coords ? q.coords.lat : q.position.latitude,
            lng: q.coords ? q.coords.lng : q.position.longitude,
          }}
          className="map-marker"
          draggable={onDragEnd !== undefined ? true : false}
          onDragEnd={(e) => onDragEnd(i, e.latLng)}
          
          label={Object.assign({
            text: String(i + 1),
            className: 'markerText',
          })}
          icon={Object.assign({
            scale: 1.25,
            path: 'M17.474,0.012c0.033,-0 0.067,-0 0.1,-0c0.042,-0 0.082,0.001 0.122,0.003c8.245,0.168 20.813,8.394 9.43,23.272c-6.899,9.017 -9.572,11.648 -9.626,11.7l0,0.001l0,-0l-0,-0l-0.001,-0.001c-0.056,-0.055 -2.731,-2.689 -9.625,-11.701c-11.388,-14.885 1.197,-23.112 9.443,-23.272c0.036,-0.001 0.072,-0.002 0.109,-0.002l0.048,0Z',
            fillColor: q.markerColor ?? "#27BAEF",
            fillOpacity: 1,
            strokeWeight: 0,
            anchor: new window.google.maps.Point(17.5, 35),
            labelOrigin: new window.google.maps.Point(17.5, 13),
          })}
         />
      );
    return null;
  });
};

const generateCirles = (questions, radius) => {
  return questions.map((q, i) => {
    if (window.google && window.google.maps && (q.coords || q.position)) {
        return (
          <Circle
            key={i}
            options={{
              strokeOpacity: 0,
              fillColor: '#6FC9FFAD',
              fillOpacity: 0.55,
            }}
            center={{
              lat: q.coords ? q.coords.lat : q.position.latitude,
              lng: q.coords ? q.coords.lng : q.position.longitude,
            }}
            radius={radius}
            className="map-circle"
           />
        );
    } else {
        return null;
    }
  });
};

const generateFinalDestinationCircle = (
  questions,
  finalDestination,
  radius,
) => {
  if (
    finalDestination &&
    questions.length > 0 &&
    window.google &&
    window.google.maps
  ) {
    return (
      <Circle
        key={'final_destination_circle'}
        options={{
          strokeOpacity: 0,
          fillColor: '#32a866',
          fillOpacity: 0.6,
         }}
        center={{
          lat: finalDestination.latitude,
          lng: finalDestination.longitude,
         }}
        radius={radius}
        className="final-destination-circle"
       />
    );
  } else return null;
};

const generateFinalDestination = (
  questions,
  finalDestination,
  onDragEndFinalDestination,
) => {
  if (
    finalDestination &&
    questions.length > 0 &&
    window.google &&
    window.google.maps
  ) {
    return (
      <Marker
        key={'final_destination'}
        position={{
          lat: finalDestination.latitude,
          lng: finalDestination.longitude,
        }}
        className="final-destination-marker"
        draggable={onDragEndFinalDestination !== undefined ? true : false}
        onDragEnd={(e) => onDragEndFinalDestination(e.latLng)}
        icon={Object.assign({
          url: finalDestinationMarker,
          scaledSize: new window.google.maps.Size(44, 44),
          anchor: new window.google.maps.Point(17, 44),
         })}
       />
    );
  } else return null;
};



const ondragover = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const ondragenter = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

function MapContainer(props) {
  const [map, setMap] = React.useState(null);
  const [hasDropped, setHasDropped] = React.useState(false);
  const [zoom, setZoom] = React.useState(DEFAULT_ZOOM);
  const [center, setCenter] = React.useState({
    lng: DEFAULT_VIEWPORT.longitude,
    lat: DEFAULT_VIEWPORT.latitude,
  });

  useEffect(() => {
    if (props.searchCoordinates) {
      const newCenter = {
        lng: props.searchCoordinates[0],
        lat: props.searchCoordinates[1],
      };
      setCenter(newCenter);
      setZoom(DEFAULT_ZOOM);
    }
  }, [props.searchCoordinates]);

  // Might have to redo this one, but should work for now. Will need to be solved in a better way, but working as a quick fix.
  useEffect(() => {
    if (props.finalDestination) {
      const newCenter = {
        lat: props.finalDestination.latitude,
        lng: props.finalDestination.longitude,
      };
      setCenter(newCenter);
      /*
      const newCenter = new window.google.maps.LatLng(
        props.finalDestination.latitude, props.finalDestination.longitude);
  console.log("Setting goal: " + JSON.stringify(newCenter));
      if (map) {
        const oldCenter = map.getCenter();
  console.log("Old center: " + JSON.stringify(oldCenter));
        if (oldCenter.lat !== newCenter.lat && oldCenter.lng !== newCenter.lng) {
        console.log("Panning to new center");
          map.panTo(center);
        }
      }*/
    }
  }, [props.finalDestination]);

  if (window.google) {
    const input = document.getElementById('pac-input');
    if (input) {
        const searchBox = new window.google.maps.places.SearchBox(input);
        if (map) {
          map.addListener('bounds_changed', () => {
            searchBox.setBounds(map.getBounds());
          });
        }
        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();

          if (places.length === 0) {
            return;
          }

          const bounds = new window.google.maps.LatLngBounds();
          if (places[0] && places[0].geometry.viewport) {
            bounds.union(places[0].geometry.viewport);
          }

          if (map) {
            map.fitBounds(bounds);
          }
        });
    }
  }

  const onLoad = React.useCallback(
    function callback(newMap) {
      if (!props.finalDestination) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (location) => {
              const altCenter = {
                lat: location.coords.latitude,
                lng: location.coords.longitude,
              };
              setCenter(altCenter);
              setZoom(DEFAULT_ZOOM);
            },
            (error) => {
              console.log(error);
              const defCenter = new window.google.maps.LatLng(
                DEFAULT_VIEWPORT.latitude,
                DEFAULT_VIEWPORT.longitude,
              );
              setCenter(defCenter);
              setZoom(DEFAULT_ZOOM);
            },
          );
        } else {
          const defCenter = new window.google.maps.LatLng(
            DEFAULT_VIEWPORT.latitude,
            DEFAULT_VIEWPORT.longitude,
          );
          setCenter(defCenter);
          setZoom(DEFAULT_ZOOM);
        }
      } else {
        const goalCenter = new window.google.maps.LatLng(
          props.finalDestination.latitude,
          props.finalDestination.longitude,
        );
        setCenter(goalCenter);
        setZoom(DEFAULT_ZOOM);
      }
      newMap.setCenter(center);
      newMap.setZoom(zoom);
      setMap(newMap);
      if (props.onMapLoad)
        props.onMapLoad();
    },
    [props, center, zoom],
  );

  const onUnmount = React.useCallback(
    function callback(_map) {
      if (props.onMapUnmount)
        props.onMapUnmount();
      setMap(null);
    },
    [props],
  );

  return (
    <div
      onDrop={(_e, _) => {
        setHasDropped(true);
      }}
     
      onDragOver={(e) => ondragover(e)}
      onDragEnter={(e) => ondragenter(e)}
      className="selected-questions-container"
      role="link"
      tabIndex="0"
    >
      <LoadScript googleMapsApiKey={"AIzaSyBvI4bMw6cakGPqMaIQGVig-sl5_v-kYgk&libraries=places"}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onDragEnd={() => {
            setCenter(map.getCenter().toJSON());
          }}
          onClick={(e) => {
            
            //if (!hasDropped2) {
              props.onDropT(e);
            //  setHasDropped(true);
            //}
          }}
          onUnmount={onUnmount}
          options={options}
          onMouseOver={(e) => {
            if (hasDropped) {
              props.onDrop(e);
              setHasDropped(false);
            }
          }}
          tiltGesturesEnabled={false}
          tilt={0}
         >
          {generateFinalDestination(
            props.questions,
            props.finalDestination,
            props.onDragEndFinalDestination,
           )}
          {!props.generateCustomMarkers ? generateFinalDestinationCircle(
            props.questions,
            props.finalDestination,
            props.gameRadius,
           ) : null}
          {!props.generateCustomMarkers ? generateCirles(props.questions, props.gameRadius) : null}
          {generateMarkers(props.questions, props.onDragEnd)}
          {props.generateCustomMarkers ? props.generateCustomMarkers() : null}
        </GoogleMap>
      </LoadScript>
    </div>
  );
}
export default React.memo(MapContainer);
