import React, { Component } from 'react';
import QuestionCommon from './molecules/QuestionCommon';
import QuestionAnswers from './molecules/QuestionAnswers';
import QuestionSettings from './molecules/QuestionSettings';
import { Button } from './atoms/Button';
import { Radio } from './atoms/Radio';
import firebase from 'firebase/compat/app';
import { createQuestion } from '../../../utils/API/endpoints';
import { isValidString, isValidNumber } from '../../../utils/helpers';
import { FaTimesCircle } from 'react-icons/fa';

const MAX_PAGES = 2;

export class NewQuestionForm extends Component {
  defaultState = {
    //base question
    question: '',
    hint: '',
    points: 5,
    deadlineEnabled: false,
    deadline: 0,
    tags: [],
    category: { grade: 0, subject: '' },
    type: 'simple',
    answer: '',
    mediaUrl: '',
    language: 'bokmål',

    //multiple-choice
    alternatives: [],
    checkedAnswers: [],
    numOptions: 4,
    

    //practical questions
    //number
    answerMin: '',
    answerMax: '',
    marginOfError: null,

    //page management
    loading: false,
    uploading: false,
    error: '',
    uploadError: '',
    uploadState: '',
    uploadProgress: 0,
    page: 0,
    availableTags: [],
    seletedFile: null,
    practicalType: null,

    eventType: null,
    noreset: false,
  };

  state = this.defaultState;

  userDocListenerUnsub = () => {};

  finishButtonTextParser = () => {
    if (this.state.uploading) {
      return 'Laster opp vedlegg...';
    }
    if (this.state.loading) {
      if (this.props.edit) {
        return 'Lagrer spørsmål...';
      }
      return 'Oppretter spørsmål...';
    }
    if (this.props.copyToLibrary) {
      return 'Kopier til bibliotek';
    }
    return 'Lagre';
  };

  finishButtonTextParserNew = () => {
    if (this.state.uploading) {
      return 'Laster opp vedlegg...';
    }
    if (this.state.loadingNew) {
      if (this.props.edit) {
        return 'Lagrer spørsmål...';
      }
      return 'Oppretter spørsmål...';
    }
    return 'Lagre og ny';
  };

  //General handlers
  handleNextPage = () => {
    if (!this.validateQuestionPromt(this.state)) return;

    this.setState((prevState) => ({
      page: (prevState.page + 1) % MAX_PAGES,
      error: '',
    }));
  };

  handlePrevPage = () => {
    this.setState((prevState) => ({
      page: (prevState.page - 1) % MAX_PAGES,
      error: '',
    }));
  };

  handleUpload = (callback, createNewQuestionAfter, noreset = false) => {
    if (
      !this.validateQuestionPromt(this.state) ||
      !this.validateQuestionSettings(this.state)
    )
      return;
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    if (authUser.uid && this.state.selectedFile) {
      this.setState({
        uploading: true,
        uploadError: '',
        uploadState: 'Starter opplasting',
        uploadProgress: 0,
      });

      const uploadTask = this.props.firebase
        .uploadToStorage('questions/' + authUser.uid + '/')
        .put(this.state.selectedFile);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          this.setState({
            uploadProgress:
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          });
          switch (snapshot.state) {
            case 'paused':
              this.setState({
                uploadState: 'Opplasting satt på pause',
              });
              break;
            case 'running':
              this.setState({ uploadState: 'Opplasting pågår' });
              break;
            default:
              console.log(`Unrecognized state during upload: ${snapshot.state}`);
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case 'storage/unauthorized':
              this.setState({ uploadError: 'Ikke autorisert' });
              break;

            case 'storage/canceled':
              this.setState({ uploadError: 'Avbrutt' });
              break;

            default:
              this.setState({
                uploadError: 'Uventet feil oppsto under opplasting',
              });
              break;
          }
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((url) => {
              console.log("lastet opp")
              this.setState({
                mediaUrl: url,
                uploading: false,
                uploadError: '',
                uploadState: 'Opplasting ferdig!',
              });
            })
            .catch((err) => {
              console.log(err);
              this.state({
                uploadError: 'Feil ved henting av bilde-URL',
                uploadState: 'Opplasting ikke fullført',
              });
            })
            .finally(() => {
              callback(createNewQuestionAfter, noreset);
            });
        },
      );
    } else {
      callback(createNewQuestionAfter, noreset);
    }
  };

  handleSubmit = (createNewQuestionAfter = false, noreset = false) => {
    if (
      !this.validateQuestionPromt(this.state) ||
      !this.validateQuestionSettings(this.state)
    )
      return;

    this.setState({
      loading: !createNewQuestionAfter,
      loadingNew: createNewQuestionAfter,
      error: '',
      noreset: noreset,
    });


    //Base for every question
    const payload = {
      question: this.state.question,
      hint: this.state.hint,
      points: this.state.points,
      deadlineEnabled: this.state.deadlineEnabled,
      deadline: this.state.deadline,
      tags: this.state.tags,
      type: this.state.type,
      category: this.state.category,
      mediaUrl: this.state.mediaUrl,
      language: this.state.language,
    };
    if (this.state.type === 'simple') {
      payload.answer = this.state.answer;
    }

    if (
      this.state.type === 'multiple-choice' ||
      this.state.type === 'multiple-answer'
    ) {
      payload.alternatives = this.state.alternatives;
      payload.answer = this.state.answer;
      if (typeof payload.answer === 'object')
        payload.type = 'multiple-answer';
      if (typeof payload.answer !== 'object')
        payload.type = 'multiple-choice';
    }

    if (this.state.type === 'number') {
      payload.answer = this.state.answer;
      payload.marginOfError = this.state.marginOfError ?? 0;
    }

    if (this.state.type === 'practical') {
      payload.practical = this.state.practicalType;
      if (this.state.practicalType === 'number') {
        payload.min = this.state.answerMin;
        payload.max = this.state.answerMax;
      }
    }

    if (this.props.copyToLibrary) {
      this.props.copyToLibrary(this.props.selectedQuestion, payload);
      this.setState({ loading: false, loadingNew: false });
      if (createNewQuestionAfter) {
        this.props.reopenModal();
        this.newQuestion();
      } else {
        this.props.handleModalExit();
      }
    } else if (
      this.props.edit &&
      this.props.selectedQuestion &&
      (JSON.parse(localStorage.getItem('authUser')).uid ===
        this.props.selectedQuestion.owner ||
        this.props.firebase.superAdmin)
    ) {
      const q = this.props.selectedQuestion;
      const id = q.id ?? q.objectID;
      this.props.firebase
        .question(id)
        .update({ ...payload })
        .then((_) => {
          this.setState({ loading: false, loadingNew: false });
          if (createNewQuestionAfter) {
            this.props.reopenModal();
            this.newQuestion();
          } else {
            this.props.handleModalExit();
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            loadingNew: false,
            error:
              'Det har skjedd en feil under redigering av spørsmål',
          });
          console.log(err);
        });
    } else {
      createQuestion(this.props.firebase, payload)
        .then((_) => {
          this.setState({ loading: false, loadingNew: false });
          if (createNewQuestionAfter) {
            this.props.reopenModal();
            this.newQuestion();
          } else {
            this.props.handleModalExit();
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            loadingNew: false,
            error:
              'Det har skjedd en feil under oppretting av spørsmål',
          });
          console.log(err);
        });
    }
  };

  newQuestion = () => {
    console.log("reset form")
    const _type = (this.state.eventType === "live") ? "multiple-choice" : "simple";
    console.log('this.props.eventType', this.props.eventType)
    if(this.state.noreset === true) {
      this.setState({
        question: '',
        hint: '',
        
        answer: '',
        mediaUrl: '',
        preview: null,
        selectedFile: null,

        //multiple-choice
        alternatives: [],
        checkedAnswers: [],
        numOptions: 4,
        loadingNew: false,

        //practical questions
        //number
        answerMin: '',
        answerMax: '',
        marginOfError: null,
      });
    }
    else {
      this.setState({
        question: '',
        hint: '',
        points: 5,
        deadlineEnabled: false,
        deadline: 0,
        tags: [],
        category: { grade: 0, subject: '' },
        type: _type,
        answer: '',
        mediaUrl: '',
        preview: null,
        selectedFile: null,
        language: 'bokmål',

        //multiple-choice
        alternatives: [],
        checkedAnswers: [],
        numOptions: 4,
        loadingNew: false,

        //practical questions
        //number
        answerMin: '',
        answerMax: '',
        marginOfError: null,
      });
    }
  };

  changePage = (pageNumber) => this.setState({ page: pageNumber });

  //Page 1 handlers
  handleQuestion = (input) => this.setState({ question: input });
  handleHint = (input) => this.setState({ hint: input });

  removeImage = (input) => this.setState({ mediaUrl: '', selectedFile: null, });

  handleFileChange = (file) => {
    this.setState({
      selectedFile: file.length > 0 ? file[0] : null,
      preview: URL.createObjectURL(file[0]),
    });
  };

  handleTypeChange = (input) => {
    this.setState({
      type: input,
      answer: '',
      checkedAnswers: [],
      alternatives: [],
    });
  };
  handleTypeChangePractical = (input) => {
    this.setState({
      practicalType: input,
      answer: '',
    });
  };
  handleAddAlternative = (input, number) => {
    const chAns =
      input.length === 0
        ? this.state.checkedAnswers.filter((a) => a !== number)
        : this.state.checkedAnswers;
    const arr = this.state.alternatives;
    arr[number] = input;
    let ans = chAns.map((a) => {
      if (number === a) {
        return input;
      } else return arr[a];
    });
    if (ans.length === 1) ans = ans[0];
    if (ans.length < 1) ans = '';

    this.setState({
      alternatives: arr,
      answer: ans ?? [],
      checkedAnswers: chAns,
    });
  };
  handleCheckMark = (input) => {
    let arr = this.state.checkedAnswers;
    if (arr.includes(input)) {
      arr = arr.filter((a) => a !== input);
    } else {
      if (!this.state.alternatives[input]) return;
      arr.push(input);
    }
    let ans = arr.map((a) => this.state.alternatives[a]);
    if (ans.length === 1) ans = ans[0];
    if (ans.length < 1) ans = '';

    this.setState({
      checkedAnswers: arr,
      answer: ans ?? [],
    });
  };
  handleRemoveAlternative = (input) => {
    const alternatives = this.state.alternatives.filter(
      (a, i) => input !== i,
    );
    const chAns = this.state.checkedAnswers.filter(
      (c) => c < this.state.numOptions - 1 && c !== input,
    );
    let ans = chAns.map((a) => this.state.alternatives[a]);
    if (ans.length === 1) ans = ans[0];
    if (ans.length < 1) ans = '';
    this.setState((prevState) => ({
      alternatives: alternatives,
      numOptions: prevState.numOptions - 1,
      checkedAnswers: chAns,
      answer: ans ?? [],
    }));
  };
  handleAddMoreAlternatives = () => {
    this.setState((prevState) => ({
      numOptions: prevState.numOptions + 1,
    }));
  };
  handleSingleAnswerChange = (input) =>
    this.setState({
      answer: input,
    });
  handleNumberAnswerChange = (input) => {
    this.setState({
      answer: parseFloat(input),
    });
  };
  handleMarginOfErrorChange = (input) => {
    this.setState({
      marginOfError: parseFloat(input),
    });
  };
  handleNumberAnswerMinChange = (input) => {
    this.setState({
      answerMin: parseFloat(input),
    });
  };
  handleNumberAnswerMaxChange = (input) => {
    this.setState({
      answerMax: parseFloat(input),
    });
  };
  handleToggleMinMax = () =>
    this.setState((prevState) => ({
      numberAnswerMinMaxEnabled: !prevState.numberAnswerMinMaxEnabled,
    }));

  //Page 2 handlers
  handlePointsChange = (input) =>
    this.setState({ points: parseInt(input.replace(/\D/g, '')) });
  handleDeadlineSecChange = (input) => {
    if (input.length === 0) {
      input = 0;
    }

    const parsed = parseInt(input);
    const num = Math.min(Math.max(parsed, 0), 59);
    this.setState((prevState) => ({
      deadline:
        prevState.deadline + (num - (prevState.deadline % 60)),
    }));
  };
  handleDeadlineMinChange = (input) => {
    if (input.length === 0) {
      input = 0;
    }

    const parsed = parseInt(input);
    const num = Math.min(Math.max(parsed, 0), 59);
    this.setState((prevState) => ({
      deadline:
        prevState.deadline +
        (num - Math.floor(prevState.deadline / 60)) * 60,
    }));
  };
  handleDeadlineToggle = () =>
    this.setState((prevState) => ({
      deadlineEnabled: !prevState.deadlineEnabled,
    }));
  handleSubjectChange = (input) =>
    this.setState((prevState) => ({
      category: { grade: prevState.category.grade, subject: input },
    }));
  handleGradeChange = (input) =>
    this.setState((prevState) => ({
      category: { grade: input, subject: prevState.category.subject },
    }));
  handleAddTagFirebase = (tag) => {
    if (tag && tag.length > 0) {
      const authUser = JSON.parse(localStorage.getItem('authUser'));

      this.props.firebase
        .admins()
        .doc(authUser.uid)
        .update({
          tags: firebase.firestore.FieldValue.arrayUnion(tag),
        });
      this.handleTagClick(tag);
    }
  };
  handleRemoveTagFirebase = (tag) => {
    if (tag && tag.length > 0) {
      if (window.confirm("Er du sikker på at du vil sletta taggen '"+tag+"'?")) {
          const authUser = JSON.parse(localStorage.getItem('authUser'));
          this.props.firebase
            .admins()
            .doc(authUser.uid)
            .update({
              tags: firebase.firestore.FieldValue.arrayRemove(tag),
            });
          this.handleTagClick(tag);
      }
    }
  };
  handleTagClick = (tag) => {
    this.setState((prevState) => ({
      tags: prevState.tags.includes(tag)
        ? prevState.tags.filter((t) => t !== tag)
        : prevState.tags.concat(tag),
    }));
  };
  handleLanguageChange = (language) => {
    this.setState({
      language,
    });
  };

  //Input validators
  validateQuestionPromt = (state) => {
    if (!isValidString(state.question)) {
      this.setState({
        error: 'Spørsmål er ikke formattert riktig',
      });

      return false;
    }

    if (!isValidString(state.hint, 0)) {
      this.setState({
        error: 'Hint er ikke formattert riktig',
      });

      return false;
    }

    const answerTypes = [
      'simple',
      'multiple-choice',
      'multiple-answer',
      'number',
      'practical',
      'camera',
    ];
    if (!answerTypes.includes(state.type)) {
      this.setState({
        error: 'Ingen gyldig spørsmålstype valgt',
      });

      return false;
    }

    if (
      state.type === 'multiple-choice' ||
      state.type === 'multiple-answer'
    ) {
      for (let i = 0; i < state.numOptions; i++) {
        if (
          !isValidString(state.alternatives[i]) &&
          !isValidNumber(state.alternatives[i])
        ) {
          this.setState({
            error: 'Svar ' + (i + 1) + ' er ikke formattert riktig',
          });

          return false;
        }
      }

      if (state.checkedAnswers.length < 1) {
        this.setState({
          error: 'Ingen svar er markert som riktig!',
        });

        return false;
      }

      if (
        new Set(state.alternatives).size !== state.alternatives.length
      ) {
        this.setState({
          error: 'Noen av alternativene eller svarene er identiske',
        });

        return false;
      }
    }

    if (state.type === 'simple') {
      console.log(state.answer)
      if (
        !isValidString(state.answer) &&
        !isValidNumber(state.answer)
      ) {
        this.setState({
          error: 'Svaret er ikke formattert riktig',
        });

        return false;
      }
    }

    if (state.type === 'number') {
      if (!isValidNumber(state.answer)) {
        this.setState({
          error: 'Svaret er ikke gydlig',
        });
        return false;
      }
    }

    if (state.type === 'practical') {
      if (state.practicalType === 'number') {
        if (!isValidNumber(state.answerMin, null, state.answerMax)) {
          this.setState({
            error: 'Minimum er større enn maksimum',
          });
          return false;
        }
      }
    }

    return true;
  };

  validateQuestionSettings = (state) => {
    if (!isValidNumber(state.points)) {
      this.setState({
        error: 'Poengsummen er ikke ett gyldig nummer',
      });

      return false;
    }

    if (state.category.grade === 0) {
      this.setState({
        error: 'Klasse er ikke valgt',
      });
      return false;
    }

    if (!isValidNumber(state.category.grade, 1, 30)) {
      this.setState({
        error: 'Noe gikk feil ved valg av klasse',
      });
      return false;
    }

    if (!isValidString(state.category.subject)) {
      this.setState({
        error: 'Fag ikke valgt',
      });

      return false;
    }

    if (state.deadlineEnabled) {
      if (!isValidNumber(state.deadline, 1)) {
        this.setState({
          error: 'Tidsfrist ikke valgt',
        });
        return false;
      }
    }

    if (!isValidString(state.language)) {
      this.setState({
        error: 'Målform ikke valgt',
      });
      return false;
    }

    return true;
  };

  pageSwitch = () => {
    switch (this.state.page) {
      case 0:
        return (
          <>
            <div>
              <QuestionAnswers
                type={this.state.type}
                handleTypeChange={this.handleTypeChange}
                practicalType={this.state.practicalType}
                handleTypeChangePractical={this.handleTypeChangePractical}
                answer={this.state.answer}
                handleSingleAnswerChange={this.handleSingleAnswerChange}
                numOptions={this.state.numOptions}
                alternatives={this.state.alternatives}
                handleAddAlternative={this.handleAddAlternative}
                handleRemoveAlternative={this.handleRemoveAlternative}
                handleAddMoreAlternatives={this.handleAddMoreAlternatives}
                checkedAnswers={this.state.checkedAnswers}
                handleCheckMark={this.handleCheckMark}
                numberAnswer={this.state.answer}
                handleNumberAnswerChange={this.handleNumberAnswerChange}
                marginOfError={this.state.marginOfError}
                handleMarginOfErrorChange={this.handleMarginOfErrorChange}
                numberAnswerMin={this.state.answerMin}
                handleNumberAnswerMinChange={this.handleNumberAnswerMinChange}
                numberAnswerMax={this.state.answerMax}
                handleNumberAnswerMaxChange={this.handleNumberAnswerMaxChange}
               />
            </div>
          </>
        );
      default:
        return <div>Invalid page.</div>;
    }
  };

  componentDidUpdate(_, prevState) {
    const error = prevState.error;
    if (error && typeof error === 'string' && error.length > 0) {
      this.setState({ error: '' });
    }
  }

  componentDidMount() {
    const authUser = JSON.parse(localStorage.getItem('authUser'));
console.log('this.props', this.props)
    
    if (this.props.edit && this.props.selectedQuestion) {
      const q = this.props.selectedQuestion;

      this.setState({ ...q });

      if (
        q.type === 'multiple-answer' &&
        typeof q.answer === 'object' &&
        typeof q.alternatives === 'object'
      ) {
        const checked = q.answer.map((c) =>
          q.alternatives.indexOf(c),
        );
        this.setState({
          checkedAnswers: checked,
          numOptions: q.alternatives.length,
        });
      }

      if (
        q.type === 'multiple-choice' &&
        (typeof q.answer === 'string' ||
          typeof q.answer === 'number') &&
        typeof q.alternatives === 'object'
      ) {
        const checked = [q.alternatives.indexOf(q.answer)];
        this.setState({
          checkedAnswers: checked,
          numOptions: q.alternatives.length,
        });
      }
    }
    else {
      if(this.props.type === "live")
        this.setState({eventType: "live", type: "multiple-choice" });
    }

    this.userDocListenerUnsub = this.props.firebase
      .admins()
      .doc(authUser.uid)
      .onSnapshot(
        (doc) => {
          if (!doc.exists) {
            this.setState({
              availableTags: [],
            });
          } else {
            const data = doc.data();
            this.setState({
              availableTags: data.tags ? data.tags : [],
            });
          }
        },
        (err) => {
          console.log(err);
        },
      );
  }

  componentWillUnmount() {
    this.userDocListenerUnsub();
  }

  render() {
    return (
      <div className="new-question-form-container">
        <div
          className="modal-exit-button"
          onClick={(_) => this.props.modalExitButtonHandler()}
          onKeyDown={(e) => e.key=="Escape" && this.props.modalExitButtonHandler()}
          role="button" tabIndex="0"
        >
          <FaTimesCircle style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
        </div>

        <div className="new-question-form-main">
          <div className="new-question-form-left">
            <div
              style={{
                fontSize: '1.4rem',
                marginBottom: '2rem',
                fontWeight: '500',
              }}
             >
              {this.props.copyToLibrary ? 'Kopier spørsmål til bibliotek' : this.props.edit ?  'Rediger spørsmål' : 'Opprett spørsmål'}
            </div>
            <div style={{ fontSize: '1rem', marginBottom: '0.8rem' }}>
              Spørsmålstype *
            </div>
            <fieldset
              onChange={(e) =>
                this.setState({ type: e.target.value })
              }
              style={{
                display: 'flex',
                flexDirection: 'row',
                border: 'none',
                flexWrap: 'wrap',
                padding: '0',
                marginBottom: '10px',
              }}
            >
              <Radio
                name="page-radio"
                value="simple"
                label="Enkel tekst"
                checked={this.state.type === 'simple'}
                disabled={this.state.eventType === "live" ? true : false}
              />
              <Radio
                name="page-radio"
                value="number"
                label="Tall"
                checked={this.state.type === 'number'}
              />
              <Radio
                name="page-radio"
                value="multiple-choice"
                label="Multiple choice"
                checked={this.state.type === 'multiple-choice'}
              />
              <Radio
                name="page-radio"
                value="camera"
                label="Kameraoppgave"
                checked={this.state.type === 'camera'}
                disabled={this.state.eventType === "live" ? true : false}
              />
              <Radio
                name="page-radio"
                value="practical"
                label="Praktisk oppgave"
                checked={this.state.type === 'practical'}
                disabled={this.state.eventType === "live" ? true : false}
              />
            </fieldset>

            <div className="new-question-form-content">
              <QuestionCommon
                question={this.state.question}
                hint={this.state.hint}
                mediaUrl={this.state.mediaUrl}
                handleQuestion={this.handleQuestion}
                handleHint={this.handleHint}
                handleFileChange={this.handleFileChange}
                preview={this.state.preview}
                removeImage={this.removeImage}
               ></QuestionCommon>
              {this.pageSwitch()}
            </div>
          </div>

          <div className="new-question-form-right">
            <QuestionSettings
              points={this.state.points}
              tags={this.state.tags}
              availableTags={this.state.availableTags}
              category={this.state.category}
              deadline={this.state.deadline}
              deadlineEnabled={this.state.deadlineEnabled}
              language={this.state.language}
              handlePointsChange={this.handlePointsChange}
              handleTagsChange={this.handleTagsChange}
              handleDeadlineSecChange={this.handleDeadlineSecChange}
              handleDeadlineMinChange={this.handleDeadlineMinChange}
              handleDeadlineToggle={this.handleDeadlineToggle}
              handleSubjectChange={this.handleSubjectChange}
              handleGradeChange={this.handleGradeChange}
              handleAddTagFirebase={this.handleAddTagFirebase}
              handleRemoveTagFirebase={this.handleRemoveTagFirebase}
              handleTagClick={this.handleTagClick}
              handleLanguageChange={this.handleLanguageChange}
             />
            <div className="new-question-form-complete-buttons">
              {this.state.error ? (
                <div className="error-message question-form-error-message">
                  {this.state.error}
                </div>
              ) : null}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                {!this.props.edit && !this.props.copyToLibrary && (
                  <>
                  <Button
                    styleOverride={{
                      borderRadius: '2rem',
                      width: 'fit-content',
                      padding: '0 2rem',
                      
                      marginBottom: '0.5em',
                      whiteSpace: 'nowrap',
                      border: 'none',
                      height: '45px',
                      background: '#1CC065',
                    }}
                    text={this.finishButtonTextParserNew()}
                    handler={() =>
                      this.handleUpload(this.handleSubmit, true)
                    }
                   />
                   <Button
                    styleOverride={{
                      borderRadius: '2rem',
                      width: 'fit-content',
                      padding: '0 2rem',
                      
                      marginBottom: '0.5em',
                      whiteSpace: 'nowrap',
                      border: 'none',
                      height: '45px',
                      background: '#1CC065',
                    }}
                    text="Lagre og ny (behold valg)"
                    handler={() =>
                      this.handleUpload(this.handleSubmit, true, true)
                    }
                   />
                   </>
                )}
                <Button
                  text={this.finishButtonTextParser()}
                  handler={() => this.handleUpload(this.handleSubmit, false)}
                  className="question-prompt-button complete"
                 />
              </div>

              {this.state.uploading ? (
                <progress
                  className="new-question-form-progress-bar"
                  value={this.state.uploadProgress}
                  max={100}
                 >
                  {this.state.uploadProgress + '%'}
                </progress>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewQuestionForm;
