import React from 'react';

const hidden = {
  position: 'absolute',
  opacity: '0',
  height: '0',
  width: '0',
};

const labelStyle = {
  borderRadius: '2rem',
  width: 'fit-content',
  padding: '10px',
  display: 'flex',
  fontSize: '14px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  marginBottom: '0.8rem',
  marginRight: '1.5rem',
  whiteSpace: 'nowrap',
};

const checkmarkStyle = {
  display: 'flex',
  width: '22px',
  height: '22px',
  borderRadius: '50%',
  margin: 'auto',
  marginRight: '5px',
  border: '2px solid #EB7179',
  boxSizing: 'border-box',
  justifyContent: 'center',
  alignItems: 'center',
};
const checkmarkDotStyle = {
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  background: '#FFF',
};

export const Radio = ({
  name,
  value,
  label,
  defaultChecked = false,
  checked,
  onChange,
  backgroundOverride,
  disabled = false,
}) => {
  let assembledLabelStyle = {
    ...labelStyle,
    background: checked ? '#EB7179' : backgroundOverride ? backgroundOverride : 'rgba(235, 113, 121, 0.15)',
    color: checked ? 'white' : 'black',
    opacity: disabled ? '20%' : '100%',
  };
  const assembledCheckmarkStyle = {
    ...checkmarkStyle,
    border: checked ? '2px solid #FFF' : '2px solid #EB7179',
  };
  return (
    <label
      className={'poplar-radio-container'}
      style={assembledLabelStyle}
    >
      <div
        className={"poplar-radio-checkmark"}
        style={assembledCheckmarkStyle}
      >
        {checked ? (
          <div
            className="poplar-radio-checkmark-dot"
            style={checkmarkDotStyle}
          />
        ) : null}
      </div>
      <input
        type="radio"
        name={name}
        value={value}
        id={'poplar-radio-' + value}
        style={hidden}
        defaultChecked={defaultChecked}
        onChange={onChange}
        onClick={onChange}
      />
      {label}
    </label>
  );
};
