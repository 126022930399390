export const isValidString = (s, min, max) => {
  if (typeof s !== 'string') return false;

  if (typeof min === 'number' && !Number.isNaN(min)) {
    if (s.length < min) return false;
  } else {
    if (s.length < 1) return false;
  }

  if (typeof max === 'number' && !Number.isNaN(max)) {
    if (s.length > max) return false;
  }
  return true;
};

export const isValidNumber = (n, min, max) => {
  if (typeof n !== 'number' || Number.isNaN(n)) return false;

  if (typeof min === 'number' && !Number.isNaN(min)) {
    if (n < min) return false;
  }

  if (typeof max === 'number' && !Number.isNaN(max)) {
    if (n > max) return false;
  }

  return true;
};

export const isViewport = (viewport) => {
  if (!viewport || typeof viewport !== 'object') return false;
  if (!viewport.longitude || typeof viewport.longitude !== 'number')
    return false;
  if (!viewport.latitude || typeof viewport.latitude !== 'number')
    return false;
  if (!viewport.zoom || typeof viewport.zoom !== 'number')
    return false;

  return true;
};
