import React, { Component } from 'react';
import { withFirebase } from '../../../../../../utils/Firebase';

import { OVERVIEW } from '../../../../../../constants/routes';
import { withNavigation } from '../../../../../../utils/navigation/context';
import firebaseConfigProd from '../../../../../../firebaseConfigProd';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

class FeideSignIn extends Component {
  state = {
    error: null,
  };

  componentDidMount() {
    console.log('FeideSignIn');

    const { firebase, navigate } = this.props;
    
    const href = window.location.href;
    const token = href.slice(href.search('token')).split('token=')[1];

    if (firebase) {
      firebase
        .doSiginWithCustomToken(token)
        .then(() => {
          navigate(OVERVIEW);
        })
        .catch((error) => {
          console.error('#FSI1 Error Custom Token SignIn: ', error);
          this.setState({ error });
        });
    } else {
      try {
        const firebaseApp = initializeApp(firebaseConfigProd);
        
        const auth = getAuth(firebaseApp);
        

        signInWithCustomToken(auth, token)
          .then(() => {
            navigate(OVERVIEW);
          })
          .catch((error) => {
            console.error('#FSI2 Error Custom Token SignIn: ', error);
            this.setState({ error });
          });
      } catch (error) {
        console.error('#FSI3 Error Custom Token SignIn: ', error);
        this.setState({ error });
      }
    }
  }

  render() {
    console.log('FeideSignIn Render');

    const { error } = this.state;
    if (error) {
      return (
        <div>
          Det skjedde en feil:
          <a href="https://app.poplar.no">Prøv igjen</a>
          eller
          <a
            href={`mailto:post@poplar.no?subject=${encodeURIComponent(
              error.message,
            )}`}
          >
            send e-post med feilrapport
          </a>
          .
        </div>
      );
    }

    return <div>Du videresendes...</div>;
  }
}

export default withNavigation(withFirebase(FeideSignIn));
