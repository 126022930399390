import React from 'react';

// Check if there is a browser window open.
// If there is a window defined, it will check the userAgent for what type of browser, if it is Safari it will return true.
var isSafari = false;
if (
  typeof window !== 'undefined' &&
  typeof window.navigator !== 'undefined'
) {
  isSafari = /^((?!chrome|android).)*safari/i.test(
    navigator.userAgent,
  );
}

// If the user uses safari, it will display a message on the sign in page.
export function BrowserAgent() {
  if (false && isSafari === true) {
    return (
      <div
        className="browser_agent_safari"
        style={{
          fontSize: '14px',
          color: 'white',
          padding: '0.3rem',
          marginBottom: '1em',
          backgroundColor: '#eb7179',
        }}
       >
        Vi ser at du bruker Safari som nettleser. Den anbefaler vi ikke.<br/>
        Bruk heller Chrome, Firefox eller en annen nettleser for at alle funksjoner skal fungere.
      </div>
    );
  } else {
    return '';
  }
}
