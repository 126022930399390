module.exports = {
  apiKey: 'AIzaSyBvI4bMw6cakGPqMaIQGVig-sl5_v-kYgk',
  authDomain: 'poplar-dev.firebaseapp.com',
  databaseURL: 'https://poplar-dev.firebaseio.com',
  projectId: 'poplar-dev',
  storageBucket: 'poplar-dev.appspot.com',
  messagingSenderId: '221986124315',
  appId: '1:221986124315:web:7b9d340af8ecf01daffebe',
  measurementId: 'G-0P6DNW82PY',
};
