import React, { Component } from 'react';
import OverviewCard from './atoms/OverviewCard';

const generateGrid = (
  events,
  active,
  onClick,
  onJoinEventInfoClick,
  firebase,
) => {
  const e = events.slice();
  const out = [];
  let j = 0;
  while (e.length > 0) {
    j += 1;
    const three = e.splice(0, e.length);
    out.push(
      <div key={j} className={'overview-event-activecards-container '+(active?'':' dark')}>
        {three.map((selectedEvent, i) => (
          <OverviewCard
            key={'overview_card_'+i}
            firebase={firebase}
            onClick={onClick}
            onJoinEventInfoClick={onJoinEventInfoClick}
            selectedEvent={selectedEvent}
           />
        ))}
      </div>,
    );
  }
  return out;
};

export class OverviewCards extends Component {
  render() {
    return (
      <div className='overviewcards'>
        {generateGrid(
          this.props.events,
          this.props.active,
          this.props.onCardClick,
          this.props.onJoinEventInfoClick,
          this.props.firebase,
        )}
      </div>
    );
  }
}

export default OverviewCards;
