import React, { Component, setState } from 'react';
import moment from 'moment';
//import { useNavigate } from "react-router-dom";
import { OverflowMenu } from './OverflowMenu';
import { overviewCardVariables } from '../../../variables/variables';
import { QUESTION_PAGE, SCORE_PAGE } from '../../../../constants/scoreboard';
import { FaClock, FaUsers, FaTree, FaHome, FaQuestionCircle, FaPlayCircle, FaLightbulb, FaFlagCheckered } from 'react-icons/fa';
import { GAME_BUILDER, EVENT } from '../../../../constants/routes';
import { NavigationContext, withNavigation } from '../../../../utils/navigation/context';

/*
const handleResultClick = (props) => props.onResultClick(props.event, SCORE_PAGE);
const handleCompleteClick = (props) => {
  const call = props.firebase.call('endEvent');
  return call({ eventId: props.event.id });
};*/

const clickFinish = (event, firebase) => {
  //setState({ending: true});
  const call = firebase.call('endEvent');
  //setState({ending: false});
  return call({ eventId: event.id });
};


const clickEdit = (event, navigate) => {
  // const navigate = useNavigate();

  typeof window !== "undefined" &&
  navigate(`${GAME_BUILDER}?eventId=${event.id}&outside=${event.outside}&update=true`);
};

const onEventShowInfo = (event, navigate) => {
  console.log("OverviewCard onEventShowInfo");
  console.log("typeof navigate", typeof navigate)

  navigate(`/results?eventId=${event.id}`);
  /*this.setState({
    selectedGame: selectedObject,
    gameId: selectedObject.id,
    isScoreboardModalOpen: true,
  });*/
};

const onEventClick = (sourceEvent, navigate) => {
  console.log("onEventclick");
  console.log("typeof navigate", typeof navigate)

  //const navigate = useNavigate();

  if (localStorage.getItem('hasTrialPeriodExpired') === true) {
    typeof window !== 'undefined' && window.alert(
      `Du kan ikke aktivere spillet fordi prøvetiden din er gått ut. Vennligst ta kontakt med Poplar.`);
  } else {
    typeof window !== 'undefined' &&
    navigate(`${EVENT}?eventId=${sourceEvent.id}`);
  }
};

class OverviewCards extends Component {
  static contextType = NavigationContext;
  
  state = {
    userResults: [],
    finishedUsers: null,
    end: false,
    ending: false,
  };

  eventUsersListenerUnsub = () => {/* Do nothing */};

  componentDidMount() {
    
    // Load the teams / users
    this.eventUsersListenerUnsub = this.props.firebase
      .event(this.props.selectedEvent.id)
      .collection('users')
      .onSnapshot(
        (qs) => {
          let finished = 0;
          for(let i=0;i<qs.docs.length; i++) {
            if(qs.docs[i].data().finished) finished = finished + 1;
          }

          this.setState({
            userResults: qs.docs.map((item) => {
              return { ...item.data(), id: item.id };
            }),
            finishedUsers: finished,
            loading: false,
          });
        },
        (err) => {
          console.log(err);
          this.setState({
            error: 'Feil ved innlasting av deltakere',
            loading: false,
          });
        },
      );
  }

  componentWillUnmount() {
    this.eventUsersListenerUnsub();
  }

  render() {
    
    return (
      <>
      <div className="overview-event-card-container-outer">
      <div className={`overview-event-card-container ${this.state.ending ? 'faded' : ''}`}>
        

        <div className='overview-event-card-content'>
          <div className={`ending-overlay ${this.state.ending ? '' : 'hide'}`}>
            Avslutter...
          </div>
          <div className="top">
            <div
              className="title"
              onClick={() => this.props.onClick(this.props.selectedEvent, SCORE_PAGE)}
              onKeyDown={(e) => e.key=="Enter" && this.props.onClick(this.props.selectedEvent, SCORE_PAGE)}
              role="button" tabIndex="0"
            >
              <h2>{this.props.selectedEvent.name}</h2>
              </div>
            </div>


            <div
            className="overview-event-card-code"
            onClick={() => this.props.onClick(this.props.selectedEvent, SCORE_PAGE)}
            onKeyDown={(e) => e.key=="Enter" && this.props.onClick(this.props.selectedEvent, SCORE_PAGE)}
            role="button" tabIndex="0"
           >
            {overviewCardVariables.gameCode +
              this.props.selectedEvent.eventCode}
          </div>


            <div className="overview-event-card-icons">
              
      <div className={`icon-container ${this.props.selectedEvent.outside ? "outside" : 
          this.props.selectedEvent.type === "live" ? "live" : 
                "inside"}`}>
          <div className='icon'>
            {this.props.selectedEvent.outside ?  
                 <FaTree /> : (this.props.selectedEvent?.type === "live") ? 
                 <FaLightbulb /> : 
                 <FaHome /> }
                </div>
            <div className='text'>
              {this.props.selectedEvent.outside ? 'UTE' : 
                this.props.selectedEvent?.type === "live" ? 'LIVE' : 
                  'INNE'}
            
          </div>
        </div>

        <div className={`icon-container play`}>
          <div className='icon'>
            
                <FaPlayCircle />
                </div>
            <div className='text'>
              Aktivt
            
          </div>
        </div>

          {this.props.selectedEvent?.type !== "live" &&
          <div title="Tidsfrist på spillet" className="overview-event-card-players-container icon-container">    
           <div className='icon'>
            <FaClock /></div>
            <div className="overview-event-card-time-container text">
              {moment.duration(this.props.selectedEvent.timelimit * 1000).asMinutes().toFixed(0) + 'min'}
            </div>
           </div> 
           }


            <div title="Antall spørsmål i spillet"
              className="overview-event-card-questions-container icon-container">
              {/* Antall spørsmål pluss ikon */}
              <div className="overview-event-card-questions-icon icon">
                <FaQuestionCircle />
              </div>
              <div className="overview-event-card-questions-num text">
                {this.props.selectedEvent.questions
                  ? this.props.selectedEvent.questions.length
                  : 0}
              </div>
            </div>


            <div title="Brukere som er registrert i spillet"
              className="overview-event-card-players-container icon-container">
              {/* hvor mange brukere pluss ikon */}
              <div className="overview-event-card-players-icon icon">
                <FaUsers />
              </div>
              <div className="overview-event-card-players-num text">
              {/*show number of finished usersthis.state.finishedUsers > 0 && <><span className='finished'>{this.state.finishedUsers}</span> / </>*/} 
              {this.state.userResults.length}
              </div>
            </div>

            {this.state.finishedUsers > 0 && <>
            <div title="Brukere som har fullført spillet"
              className="overview-event-card-players-container icon-container">
                {/* ferdige brukere */}
                <div className="overview-event-card-players-icon icon">
                  <FaFlagCheckered />
                </div>
                <div className="overview-event-card-players-num text">
                <span className=''>{this.state.finishedUsers}</span>
                </div>
              </div>
            </>} 



          </div>



            <div className='actions'>

            {this.props.selectedEvent?.type === "live" && this.props.selectedEvent?.state != "finished" && <>
          <div className='button' 
              onClick={(e) => onEventClick(this.props.selectedEvent, this.props.navigate)}
              onKeyDown={(e) => e.key==="Enter" && onEventClick(this.props.selectedEvent, this.props.navigate)}
              role="button"
              tabIndex="0" >Spill</div>
</>}


            <div className='button qr' onClick={(_) => this.props.onJoinEventInfoClick(this.props.selectedEvent)}
                onKeyDown={(e) => e.key=="Enter" && this.props.onJoinEventInfoClick(this.props.selectedEvent)}
                role="button"
                tabIndex="0">Vis QR-kode</div>
              <div className='button' role="button" onClick={() => clickEdit(this.props.selectedEvent, this.props.navigate)}>Endre</div>


              {(this.props.selectedEvent.type !== "live" || (this.props.selectedEvent.type === "live" && this.props.selectedEvent.state === "finished")) && <>
          <div className='button' 
              onClick={(e) => onEventShowInfo(this.props.selectedEvent, this.props.navigate)}
              onKeyDown={(e) => e.key==="Enter" && onEventShowInfo(this.props.selectedEvent, this.props.navigate)}
              role="button"
              tabIndex="0" >Resultater</div></>}
              
              <div className='button' role="button" onClick={() => this.setState({end: true})}>Avslutt</div>
              <div className={`confirm-overlay ${this.state.end ? '' : 'hide'}`}>
                <div className='text'>Er du sikker på at du vil avslutte dette spillet? </div>
                <div className='buttons'>
                  <div className='button' role="button" onClick={() => {clickFinish(this.props.selectedEvent, this.props.firebase); this.setState({end: false})}}>Bekreft</div>
                  <div className='button' role="button" onClick={() => this.setState({end: false})}>Avbryt</div>
                </div>
              </div>
            </div>


             
           

        
        </div>
      </div>
      </div>

      </>
    );
  }
}

export default withNavigation(OverviewCards);
