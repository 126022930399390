import React from 'react';
import { questionCategories } from '../../../variables/variables';
import { Tag } from '../../NewQuestionForm/atoms/Tag';

export const parseAnswer = (question) => {
  const formatErr = 'Feil ved formattering av svar';
  const AnswerText = ({ text, color }) => {
    if (typeof text === 'string') {
      const elements = text.split(/([;|])/).map((part, index) => {
        if (part === ";") {
          return <div key={index} className="preview-and-separator">og</div>;
        }
        if (part === "|") {
          return <div key={index} className="preview-or-separator">eller</div>;
        }
        return <p key={index} style={{ margin: '0 .5rem', color }}>{part}</p>;
      });
      return <>{elements}</>;
    } else {
      return <p style={{ margin: '0 .5rem', color }}>{text}</p>;
    }
  };
  

  if (question.answer) {
    let output = [(
        <p style={{ margin: '0 .5em 0 0', color: '#EB7179' }} key={'qans_'+question.id}>
            Svar:
        </p>
    )];

    if (
      typeof question.answer === 'string' ||
      typeof question.answer === 'number'
    ) {
      if (question.alternatives) {
        if (Array.isArray(question.alternatives)) {
          let alternativesAreValid = true;
          for (let i = 0; i < question.alternatives.length; i++) {
            const validAlternative =
              typeof question.alternatives[i] === 'string' ||
              typeof question.alternatives[i] === 'number';
            if (!validAlternative) {
              alternativesAreValid = false;
              break;
            }
          }

          if (!alternativesAreValid) {
            output.push(
              <AnswerText
                key={'qans_'+question.id+'_err'}
                color={'#000000'}
                text={formatErr}
               />,
            );
          } else {
            for (let i = 0; i < question.alternatives.length; i++) {
              const isCorrect =
                question.answer === question.alternatives[i]; //loose equality in case some string conversion has happened etc.
              output.push(
                <AnswerText
                  key={'qans_'+question.id+'_alt_'+i}
                  color={isCorrect ? '#1CC065' : '#EB7179'}
                  text={question.alternatives[i]}
                 />,
              );
            }
          }
        } else if (
          typeof question.alternatives === 'string' ||
          typeof question.alternatives === 'number'
        ) {
          const isCorrect = question.alternatives === question.answer; // loose equality in case some string conversion has happened etc.
          if (isCorrect) {
            output.push(
              <AnswerText color={'#1CC065'} text={question.answer} />,
            );
          } else {
            output.push(
              <AnswerText
                key={'qans_'+question.id+'_err'}
                color={'#000000'}
                text={'Feil: Riktig svar ikke mulig å velge fra alternativ'}
               />,
            );
          }
        } else {
          output.push(
            <AnswerText
              key={'qans_'+question.id+'_err'}
              color={'#000000'}
              text={formatErr}
             />,
          );
        }
      } else {
        output.push(
          <AnswerText
            key={'qans_'+question.id+'_ans'}
            color={'#1CC065'}
            text={question.answer}
           />,
        );
      }
    }

    if (Array.isArray(question.answer)) {
      let answersAreValid = true;
      for (let i = 0; i < question.answer.length; i++) {
        const validAnswer =
          typeof question.answer[i] === 'string' ||
          typeof question.answer[i] === 'number';
        if (!validAnswer) {
          answersAreValid = false;
          break;
        }
      }
      if (!answersAreValid) {
        output.push(
          <p style={{ margin: '0 1rem', color: '#000000' }}>
            {formatErr}
          </p>,
        );
      } else if (question.alternatives) {
        if (Array.isArray(question.alternatives)) {
          let alternativesAreValid = true;
          for (let i = 0; i < question.alternatives.length; i++) {
            const validAlternative =
              typeof question.alternatives[i] === 'string' ||
              typeof question.alternatives[i] === 'number';
            if (!validAlternative) {
              alternativesAreValid = false;
              break;
            }
          }

          if (!alternativesAreValid) {
            output.push(
              <AnswerText
                key={'qans_'+question.id+'_err'}
                color={'#000000'}
                text={formatErr}
               />,
            );
          } else {
            for (let i = 0; i < question.alternatives.length; i++) {
              const isCorrect = question.answer.some(
                (ans) => ans === question.alternatives[i],
              );
              output.push(
                <AnswerText
                  key={'qans_'+question.id+'_alt_'+i}
                  color={isCorrect ? '#1CC065' : '#EB7179'}
                  text={question.alternatives[i]}
                 />,
              );
            }
          }
        } else if (
          typeof question.alternatives === 'string' ||
          typeof question.alternatives === 'number'
        ) {
          if (
            question.answer.some(
              (ans) => ans === question.alternatives,
            )
          ) {
            for (let i = 0; i < question.answer.length; i++) {
              const isCorrect = (question.answer[i] === question.alternatives);
              output.push(
                <AnswerText
                  key={'qans_'+question.id+'_alt_'+i}
                  color={isCorrect ? '#1CC065' : '#EB7179'}
                  text={question.answer[i]}
                 />,
              );
            }
          } else {
            output.push(
              <AnswerText
                key={'qans_'+question.id+'_err'}
                color={'#000000'}
                text={
                  'Feil: Kun ett alternativ og ingen svar som tilhører'
                }
               />,
            );
          }
        } else {
          output.push(
            <AnswerText
              key={'qans_'+question.id+'_err'}
              color={'#000000'}
              text={formatErr}
             />,
          );
        }
      } else {
        for (let i = 0; i < question.answer.length; i++) {
          output.push(
            <AnswerText
              key={'qans_'+question.id+'_alt_'+i}
              color={'#1CC065'}
              text={question.answer[i]}
             />,
          );
        }
      }
    }
    return output;
  }
  return null;
};

export const qTypeParser = (type) => {
  for(let i = 0; i<questionCategories.length; i++)
    if(questionCategories[i].value == type) return questionCategories[i].short;
  switch (type) {
    case 'simple':
      return 'Enkelt';
    case 'multiple-choice':
      return 'Flervalg';
    case 'number':
      return 'Tall';
    case 'practical':
      return 'Praktisk';
    case 'multiple-answer':
      return 'Flersvar';
    case 'camera':
      return 'Kameraoppgave';
    default:
      return 'Ugyldig type';
  }
};

export const qTagParser = (tags) => {
  if (tags) {
    const tagText = tags.map((t, i) => (
      <Tag key={'tag_key_' + i} text={t} checked={false} handler={() => { /* Do nothing */ }} />
    ));
    return tagText.length > 0 ? (
      tagText
    ) : (
      <span className="no-tags" style={{ fontStyle: 'italic', padding: '3px' }}></span>
    );
  } else return null;
};
