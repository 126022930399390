import React from 'react';
import Input from '../../../atoms/Input';
import Button from '../../../atoms/Button';

const PasswordChangeForm = ({
    isInvalid,
    error,
    passwordOne,
    passwordTwo,
    onSubmit,
    onChange,
    className,
}) => {
    return (
    <div className='settingsbox'>
      <h3>Endre passord</h3>
        <form className={className} onSubmit={e => onSubmit(e)}>
            <Input
                name="passwordOne"
                value={passwordOne}
                onChange={e => onChange(e)}
                type="password"
                labelName="Nytt passord"
                required
            />
            <Input
                name="passwordTwo"
                value={passwordTwo}
                onChange={e => onChange(e)}
                type="password"
                labelName="Bekreft nytt passord"
                required
            />

            <Button
                disabled={isInvalid}
                type="submit"
                text="Endre passord"
            />

            {error && <p>{error.message}</p>}
        </form>
      </div>
    );
};

export default PasswordChangeForm;
