import React from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { doc, getDoc, getFirestore } from 'firebase/firestore';


const fetchQuestions = async (event) => {
    const questionPaths = event.questions;
    const questions = [];

    const firestore = getFirestore();

    for (const path of questionPaths) {
        const segments = path.question._key.path.segments;
        const docRef = doc(firestore, ...segments.slice(-2));
        
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log(docSnap)
            questions.push({
                ...docSnap.data(),
                id: docSnap.id,
            });
        }
    }

    return questions;
};

const downloadImagesAsZip = async (imageList, event) => {
    const zip = new JSZip();
    const imageFolder = zip.folder("images");
  
    for (let i = 0; i < imageList.length; i++) {
      const img = imageList[i];
      const response = await fetch(img.original);
      const blob = await response.blob();
      imageFolder.file(`${img.question}_${img.teamName}_${i}.jpg`, blob);
    }

    const truncatedEventName = (event.name || '').slice(0, 10);
    const safeEventName = truncatedEventName.replace(/[^a-zA-Z0-9]/g, '_');
  
    zip.generateAsync({ type: "blob" }).then(content => {
      saveAs(content, safeEventName + "_images.zip");
    });
};

const handleDownload = async (event, users) => {
    const allQuestions = await fetchQuestions(event);
    const photoQuestions = allQuestions ? allQuestions.filter(q => q.type === 'camera') : [];

    const imageList = [];

    for (const user of users) {
        if (Array.isArray(user.answers)) {
            for (const question of photoQuestions) {
                const foundAnswer = user.answers.find(a => a.questionId === question.id);
                if (foundAnswer && foundAnswer.answer) {
                    //console.log(question.question, foundAnswer.answer, user.teamName)
                    // Ensure that the description and question are each limited to 10 characters.
                    const truncatedTeamName = (user.teamName || '').slice(0, 10);
                    const truncatedQuestion = (question.question || '').slice(0, 10);

                    // Replace forbidden characters.
                    const safeTeamName = truncatedTeamName.replace(/[^a-zA-Z0-9]/g, '_');
                    const safeQuestion = truncatedQuestion.replace(/[^a-zA-Z0-9]/g, '_');
                    imageList.push({
                        original: foundAnswer.answer,
                        teamName: safeTeamName,
                        question: safeQuestion,
                    });
                }
            }
        }
    }

    downloadImagesAsZip(imageList, event);
};

const DownloadAllButton = ({event, users}) => {
    return (
        <div>
            <button style={{cursor: 'pointer'}} onClick={() => handleDownload(event, users)}>
                Last ned alle bilder
            </button>
        </div>
    );
};

export default DownloadAllButton;
