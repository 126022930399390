import React, { Component } from "react";
import Select from "react-select";
import { Input } from "../atoms/Input";
import { NumberInput } from "../atoms/NumberInput";
import TagManager from "./TagManager";
import { categoryOptions, gradeOptions } from "../../../variables/variables";
import { IoCheckmark } from "react-icons/io5";

const CircleCheckbox = ({ text, selected, handler }) => {
  const languageButtonStyle = {
    height: "1.2rem",
    width: "1.2rem",
    borderStyle: "none",
    backgroundColor: "#FFF",
    border: "2px solid #161B1E",
    borderRadius: "99rem",
    marginLeft: ".5rem",
    outline: "none",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  };

  const languageButtonSelectedStyle = {
    ...languageButtonStyle,
    backgroundColor: "#161B1E",
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-end",
        margin: "0 0 5px 0",
      }}
    >
      <div>{text}</div>
      <button
        style={selected ? languageButtonSelectedStyle : languageButtonStyle}
        onClick={() => handler()}
       >
        <IoCheckmark style={{width: '0.5rem', height: '0.5rem', WebkitFilter: 'invert(1)'}} />
      </button>
    </div>
  );
};

const subjectParser = (subject) =>
  subject !== '' ? { label: subject, value: subject } : '';

  const gradeParser = (grade) => {
    let r = { label: '', value: 0 };
    gradeOptions.forEach((option) => {
      if(grade === option.value) r = { label: option.label, value: option.value };
    })
    return r;
  }
/*
  grade !== 0
    ? grade === 30
      ? { label: 'Annet', value: grade }
      : { label: grade + '. Klasse', value: grade }
    : '';
*/

export class QuestionSettings extends Component {
  render() {
    return (
      <>
        <div
          style={{
            fontSize: "1.4rem",
            marginBottom: "2rem",
            fontWeight: "500",
          }}
        >
          Poeng, tidsfrist, og kategorier
        </div>
        <div style={{ marginBottom: "3.75em" }}>
          <div className="question-settings-points-container">
            <span
              className="question-settings-entry-header"
              style={{ width: '50%', whiteSpace: 'nowrap' }}
            >
              Antall poeng
            </span>
            <Input
              value={this.props.points}
              placeholder="Poeng"
              handler={this.props.handlePointsChange}
              className="question-settings-points"
              styleOverride={{ border: 'none', padding: '1rem' }}
            />
          </div>

          <div className="question-settings-time-container">
            <div className="question-settings-time-headers">
              <CircleCheckbox
                text={'Tidsfrist'}
                selected={this.props.deadlineEnabled}
                handler={this.props.handleDeadlineToggle}
              />
            </div>
            <div className="question-settings-time-inputs">
              <NumberInput
                value={Math.floor(this.props.deadline / 60)}
                placeholder={"Min"}
                handler={this.props.handleDeadlineMinChange}
                disabled={!this.props.deadlineEnabled}
                className="question-settings-time-input"
                styleOverride={{ border: "none", padding: "1rem" }}
              />
              <NumberInput
                value={this.props.deadline % 60}
                placeholder={"Sek"}
                handler={this.props.handleDeadlineSecChange}
                disabled={!this.props.deadlineEnabled}
                className="question-settings-time-input"
                styleOverride={{ border: "none", padding: "1rem" }}
              />
            </div>
          </div>
        </div>
        <div style={{ marginBottom: "3.75em" }}>
          <div className="question-settings-class-subject-container">
            <div className="question-settings-class-container">
              <span className="question-settings-entry-header">Klassetrinn *</span>
              <Select
                className="class-picker"
                placeholder="Velg klasse"
                value={gradeParser(this.props.category.grade)}
                options={gradeOptions}
                onChange={(e) => this.props.handleGradeChange(e.value)}
              />
            </div>

            <div className="question-settings-subject-container">
              <span className="question-settings-entry-header">
                Velg fag *
              </span>
              <Select
                className="subject-picker"
                placeholder="Velg fag"
                value={subjectParser(this.props.category.subject)}
                options={categoryOptions}
                onChange={(e) => this.props.handleSubjectChange(e.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <TagManager
            tags={this.props.tags}
            availableTags={this.props.availableTags}
            language={this.props.language}
            handleAddTagFirebase={this.props.handleAddTagFirebase}
            handleRemoveTagFirebase={this.props.handleRemoveTagFirebase}
            handleTagClick={this.props.handleTagClick}
            handleLanguageChange={this.props.handleLanguageChange}
          />
        </div>
      </>
    );
  }
}

export default QuestionSettings;
