import React from 'react';

export const Checkbox = ({
  handler,
  checked,
  styleOverride,
  className,
  disabled,
}) => {
  return (
    <div
      className={
        'poplar-checkbox-container' +
        (className ? ' ' + className : '')
      }
    >
      <input
        className="poplar-checkbox"
        type="checkbox"
        checked={checked ? checked : false}
        disabled={disabled}
        onChange={(_) => handler()}
        style={styleOverride ? styleOverride : {}}
      />
    </div>
  );
};
