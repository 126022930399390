import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { withFirebase } from '../../../../utils/Firebase';
import { OVERVIEW } from '../../../../constants/routes';
import Input from '../../../atoms/Input';
import Button from '../../../atoms/Button';
import { fixAdminClaimsIfNecessary } from '../../../../utils/API/endpoints';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const SignInForm = ({ firebase }) => {
  const [state, setState] = useState({ ...INITIAL_STATE });
  const [initFirebase, setInitFirebase] = useState(false); // eslint-disable-line no-unused-vars
  const navigate = useNavigate();
  //const navigate = (path) => { console.log("navigating to: " + path);}

  const firebaseInit = () => {
    if (firebase && !this._initFirebase) {
      setInitFirebase(true);
    }
  };

  React.useEffect(() => {
    firebaseInit();
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    const { email, password } = state;
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        fixAdminClaimsIfNecessary(firebase);
        if(typeof window !== 'undefined') {
          //console.log("Loginfrompath: ",window.location.pathname);
          if(window.location.pathname === '/signin' || window.location.pathname === '/') {
            navigate(OVERVIEW, { replace: true });
          }
        }
      })
      .catch((error) => {
        if (process.env.REACT_APP_ENV === 'dev') {
          console.log(error.toString());
        }
        setState({ ...state, error });
      });
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { email, password, error } = state;
  const isInvalid = password === '' || email === '';

  return (
    <>
      <form onSubmit={onSubmit}>
        <Input
          name="email"
          value={email}
          onChange={onChange}
          type="text"
          labelName="Epostadresse"
          styleOverride={{ padding: '4px', color: '#59b9eb' }}
          required
        />

        <Input
          name="password"
          value={password}
          onChange={onChange}
          type="password"
          labelName="Passord"
          className="input--no-margin"
          styleOverride={{ padding: '4px', color: '#59b9eb' }}
          required
        />

        <Button disabled={isInvalid} type="submit" text="Logg inn" />

        {error && (
          <p style={{ textAlign: 'center', color: '#AAAAAA' }}>
            {error.code === 'auth/user-disabled'
              ? 'Din konto er deaktivert. Vennligst ta kontakt med Poplar.'
              : 'Feil brukernavn og / eller passord.'}
          </p>
        )}
      </form>
    </>
  );
};

export default withFirebase(SignInForm);
