import React from 'react';
import Layout from '../utils/layout';

export default () => (
  <Layout>
    <main className="main">
      <div className="container">
        <h1>Denne siden finnes ikke</h1>
        <p>
          Hvis du mener det har oppstått en feil, send en e-post til{' '}
          <a
            href={`mailto:post@poplar.no?subject=Feilmelding på Poplar: ${encodeURIComponent(
              window.location.href,
            )}`}
          >
            post@poplar.no
          </a>
          .
          <br />
          Husk å inkludere hvilken side du prøvde å gå til og adressen
          som nå vises i nettleseren.
        </p>
      </div>
    </main>
  </Layout>
);
