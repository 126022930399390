import React from 'react';
import ColoredMarker from '../atoms/ColoredMarker';
import { qTypeParser, qTagParser } from '../atoms/QuestionAnswer';
import { FaMapMarkerAlt, FaEdit, FaEye, FaTrashAlt } from 'react-icons/fa';

/**
 * This question card is shown when creating a new game.
 */
const funcWrapEdit = (func, question) => () => {
  func(question);
};

const funcWrapDelete = (func, index, outside) => () => {
  func(index, outside);
};

export const SmallQuestionCard = ({
  question,
  outside,
  index,
  onCardEditClick,
  draggable,
  onCardDeleteClick,
  onCardPreviewClick,
  withSelector = false,
  setQuestionIsSelected,
}) => {

  

  //let qid2 = localStorage.getItem('qid2');
  
  /*
  React.useEffect(() => {
    if(localStorage.getItem('qid2') === null){ 
    var messages = document.querySelectorAll(".drag-click");
    for (var i = 0; i < messages.length; i++) {
      messages[i].classList.remove('selected');
    }
  }
  }, [question]);
*/

  const dragStart = (e) => {
    const target = e.target;

    let img = new Image();
    img.src = FaMapMarkerAlt;

    if(e.dataTransfer)
      e.dataTransfer.setDragImage(img, 12, 30);
    localStorage.setItem('qid', JSON.stringify(question));
    target.style.opacity = 0.3;
  };

  //quickfix for touch devices
  const qStart = (e) => {
    const target = e.target;

    // reset all visible
    var messages = document.querySelectorAll(".drag-click");
    for (var i = 0; i < messages.length; i++) {
      messages[i].classList.remove('selected');
    }
    
    let img = new Image();
    img.src = FaMapMarkerAlt;


    if(localStorage.getItem('qid2') === JSON.stringify(question)){ 
      localStorage.setItem('qid2', null);
      setQuestionIsSelected(false);
      // deselect
    }
    else {
      // select
      if(e.dataTransfer)
        e.dataTransfer.setDragImage(img, 12, 30);
      localStorage.setItem('qid2', JSON.stringify(question));
      //if(target.tagName === 'svg') target.parentElement.classList.add('selected');
      //else 
        target.classList.add('selected');
        setQuestionIsSelected(true);
    }
  };

  const dragEnd = (e) => {
    const target = e.target;
    target.style.opacity = 1;
  };

  const dragOver = (e) => {
    e.preventDefault();
  };
  
  return (
    <div
      className={
        'question-card-container' + (draggable ? ' draggable' : '')
      }
      id={'question-container-' + index}
      onDragStart={dragStart}
      onDragEnd={dragEnd}
      draggable={draggable}
      onDragOver={dragOver}
      role={'link'}
      tabIndex="0"
     >
      <div className="question-card-content">
        <div className="top">

{withSelector && <>
        <div
                className={'poplar-button drag-click'}
                onClick={qStart}
                
                role="button"
                tabIndex="0"
                style={{
                  backgroundColor: 'white',
                  opacity: '50%',
                }}
               >
               
              </div>
              </>}


          <div className="question-text" style={{ flex: 4 }}>{question.question}</div>
          {false && onCardDeleteClick ? (<div className="question-index">{index + 1}</div>) : null}
          <div className="points-marker-container" style={{ paddingRight: '2rem' }}>
            {question.markerColor ? (<ColoredMarker color={question.markerColor} />) : null}
          </div>
          <div
            className="button-container"
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              position: 'absolute',
              top: '0',
              right: '0',
              width: '30px',
            }}
           >
            {onCardPreviewClick ? (
              <div
                className={'poplar-button'}
                onClick={funcWrapEdit(onCardPreviewClick, question)}
                onKeyDown={funcWrapEdit(onCardPreviewClick, question)}
                role="button"
                tabIndex="0"
                style={{
                  backgroundColor: 'white',
                  opacity: '50%',
                }}
               >
                <FaEye id="se" style={{
                    height: '1.0rem',
                    width: '1.0rem',
                    cursor: 'pointer',
                    color: 'black',
                }}/>
              </div>
            ) : null}
            {onCardEditClick ? (
              <div
                className={'poplar-button'}
                onClick={funcWrapEdit(onCardEditClick, question)}
                onKeyDown={(e) => e.key=="Enter" && funcWrapEdit(onCardEditClick, question)}
                role="button"
                tabIndex="0"
                style={{
                  backgroundColor: 'white',
                  opacity: '50%',
                }}
               >
                <FaEdit id="edit" style={{
                    height: '1.0rem',
                    width: '1.0rem',
                    cursor: 'pointer',
                    color: 'black',
                }}/>
              </div>
            ) : null}
            {onCardDeleteClick ? (
              <div
                className={'poplar-button question-card-delete'}
                onClick={funcWrapDelete(onCardDeleteClick, index, outside)}
                onKeyDown={(e) => e.key=="Enter" && funcWrapDelete(onCardDeleteClick, index, outside)}
                role="button"
                tabIndex="0"
                style={{
                  backgroundColor: 'white',
                  opacity: '50%',
                }}
               >
                <FaTrashAlt style={{
                    height: '1.0rem',
                    width: '1.0rem',
                    cursor: 'pointer',
                    color: 'black',
                }}/>
              </div>
            ) : null}
          </div>
        </div>
        <div className="bottom">
          <div style={{ display: 'flex' }}>
            <div className="question-tags">{qTagParser(question.tags)}</div>
            <div className="type-points-container">
              <div className="question-type" style={{padding: '3px'}}>{qTypeParser(question.type)}</div>
            </div>
          </div>
          <div className="question-points" style={{padding: '3px'}}>{question.points + ' poeng'}</div>
        </div>
        <FaMapMarkerAlt id="drag-marker" style={{ display: 'none' }}/>
      </div>
    </div>
  );
};
