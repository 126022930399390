import React, { Component } from 'react';
import { QuestionList } from './molecules/QuestionList';

export class QuestionSearchResult extends Component {
  state = {
    open: this.props.open,
  };

  handleArrowClick = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  render() {
    return (
      <div
        className="questions-list-container"
        style={{ margin: '0.5rem 0rem' }}
      >
        <QuestionList
          draggable={this.props.draggable}
          open={this.state.open}
          outside={this.props.outside}
          onCardEditClick={this.props.onCardEditClick}
          onCardDeleteClick={this.props.onCardDeleteClick}
          onCardPreviewClick={this.props.onCardPreviewClick}
          questions={this.props.questions}
        />
      </div>
    );
  }
}

export default QuestionSearchResult;
