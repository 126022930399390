/**
 * Functional component of a overflow menu used in OverviewCard
 */
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { QuestionQrPrint } from "../atoms/QuestionQrPrint";
import { FaEllipsisV } from "react-icons/fa";

export default function KebabMenu({
  selectedItem,
  previewSelectedItem,
  activateSelectedItem,
  editSelectedItem,
  deleteSelectedItem,
  canDeleteItem,
  isSuperAdmin,
  addItemToLibrary,
  isPrintEnabled,
}) {
  const componentRef = useRef(null);
  const handlePrintQrCode = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Menu
        style={{ borderRadius: "1rem" }}
        menuButton={
          <button
            style={{
              border: "none",
              padding: "0.5rem 0.75rem",
              borderRadius: "0.75rem",
              position: "absolute",
              top: "0.25rem",
              right: "0.25rem",
              margin: '0.25rem 0.5rem',
            }}
           >
            <FaEllipsisV style={{ height: "1.05rem", opacity: "60%" }}/>
          </button>
        }
        offsetX={-130}
       >
        {activateSelectedItem && (
          <MenuItem
            style={{ color: "#2DBB54" }}
            onClick={() => activateSelectedItem(selectedItem)}
           >
            Aktiver
          </MenuItem>
        )}

        {editSelectedItem && (
          <MenuItem
            style={{ color: "#2DBB54" }}
            onClick={() => editSelectedItem(selectedItem)}
          >
            {isPrintEnabled ? "Rediger" : "Kopier"}
          </MenuItem>
        )}

        {isPrintEnabled && <MenuItem onClick={handlePrintQrCode}>Skriv ut QR</MenuItem>}

        {previewSelectedItem &&
          <MenuItem onClick={() => previewSelectedItem(selectedItem)}>Detaljer</MenuItem>
        }

        {addItemToLibrary && isSuperAdmin && selectedItem.owner !== 'library' && (
          <MenuItem onClick={() => addItemToLibrary(selectedItem)}>
            Kopier til bibliotek
          </MenuItem>
        )}

        {deleteSelectedItem && (canDeleteItem || isSuperAdmin) && (
          <MenuItem
            style={{ color: "rgb(228, 42, 60)" }}
            onClick={() => deleteSelectedItem(selectedItem)}
           >
            Slett
          </MenuItem>
        )}
      </Menu>
      <QuestionQrPrint ref={componentRef} question={selectedItem} />
    </>
  );
}
