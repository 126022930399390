import React, { Component } from 'react';
import { Input } from '../atoms/Input';
import { FaUpload } from 'react-icons/fa';

export class QuestionCommon extends Component {
  render() {
    return (
      <>
        <div style={{ fontSize: '1rem', marginBottom: '0.8rem' }}>
          Ditt spørsmål *
        </div>
        <div className="question-prompt-question-row">
          <textarea
            className="question-prompt-textarea"
            maxLength={256}
            value={this.props.question}
            placeholder="Skriv inn ditt spørsmål her..."
            onChange={(e) =>
              this.props.handleQuestion(e.target.value)
            }
          />
        </div>

        <div className="question-prompt-hint-row">
          <Input
            maxLength={128}
            value={this.props.hint}
            placeholder="Hint:"
            description=""
            handler={this.props.handleHint}
            className="question-prompt-input"
            styleOverride={{ border: 'none', padding: '1rem' }}
          />
          <input
            type="file"
            className="file-input-hidden"
            id="question-image-upload"
            accept="image/*"
            onChange={(e) =>
              this.props.handleFileChange(e.target.files)
            }
          />
          <div style={{ color: 'rgba(103, 107, 133, 0.45)', paddingTop: '1rem' }}>
            <label
              style={{}}
              className="question-prompt-upload-button"
              htmlFor="question-image-upload"
            >
              Last opp bilde{' '}
              <FaUpload style={{ marginLeft: '1.0rem', height: '1.0rem', width: '1.0rem' }}/>
            </label>
              <span style={{ color: 'rgba(103, 107, 133, 0.45)', fontSize: '0.8rem' }}>
                Anbefalt størrelse er 500x500 piksler
              </span>
            <div
              style={{
                marginTop: '.2em',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
            </div>
          </div>
        </div>
        <div
          style={{
            marginBottom: '1em',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          {this.props.mediaUrl.length > 0 ? (
            <>
            <img
              src={this.props.mediaUrl}
              style={{
                width: 'auto',
                maxWidth: '45%',
                height: 'auto',
                maxHeight: '100px',
                borderRadius: '.3em',
                margin: ' auto 1em auto 0',
              }}
              alt="Opplastet bilde"
            />
            <div><div onClick={this.props.removeImage} className="btn" style={{ padding: '5px 10px'}}>Fjern bilde</div></div>
            </>
          ) : null}
          {this.props.preview ? (
            <img
              src={this.props.preview}
              style={{
                width: 'auto',
                maxWidth: '45%',
                height: 'auto',
                maxHeight: '100px',
                borderRadius: '.3em',
                margin: ' auto 0',
              }}
              alt="Forhåndsvisning"
            />
          ) : null}
        </div>
      </>
    );
  }
}

export default QuestionCommon;
