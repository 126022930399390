import React, { useEffect, useState } from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import { getLibSearchKey, getSearchKey } from '../API/endpoints';

const withAuthentication = (Component) => {
  const WithAuthentication = (props) => {
    const [authUser, setAuthUser] = useState(null);
    //console.log("w auth init fb and listener")
    let _initFirebase = false;
    let listener = null;

    const firebaseInit = () => {
      //console.log("test init firebase", typeof props.firebase, props.firebase, Boolean(props.firebase))
      if (props.firebase && !_initFirebase) {
        //console.log("firebase and not initiated", authUser)
        _initFirebase = true;
        listener = props.firebase.onAuthUserListener(
          (authUser) => {
            //console.log("w atuh authUser", authUser, localStorage.getItem('authUser'))
            localStorage.setItem(
              'authUser',
              JSON.stringify(authUser),
            );
            setAuthUser(authUser);

            const algoliaKey = localStorage.getItem('algoliaKey');
            if (typeof algoliaKey !== 'string') {
              getSearchKey(props.firebase)
                .then((result) => {
                  localStorage.setItem('algoliaKey', result.data.key);
                })
                .catch((err) => {
                  console.log(err);
                  localStorage.removeItem('algoliaKey');
                });
            }

            const algoliaKeyLib =
              localStorage.getItem('algoliaKeyLib');
            if (typeof algoliaKeyLib !== 'string') {
              getLibSearchKey(props.firebase)
                .then((result) => {
                  localStorage.setItem(
                    'algoliaKeyLib',
                    result.data.key,
                  );
                })
                .catch((err) => {
                  console.log(err);
                  localStorage.removeItem('algoliaKeyLib');
                });
            }
          },
          () => {
            localStorage.removeItem('authUser');
            localStorage.removeItem('algoliaKey');
            localStorage.removeItem('algoliaKeyLib');
            setAuthUser(null);
          },
        );
      }
    };

    useEffect(() => {
      setAuthUser(JSON.parse(localStorage.getItem('authUser')));
      firebaseInit();
      

      // console.log("useeffect authUser", listener, authUser);
      // if(listener && authUser === null) {
      //   console.log("firebase but no auth", props.firebase.auth);
      //   if(window.location.pathname !== '/'){
      //     //window.location.href = '/';
      //   }
      //   }

      return () => {
        listener && listener();
      };
    }, [props.firebase]);

    // useEffect(() => {
    //   console.log("first authUser", authUser);
    //   if(_initFirebase && authUser === null) {
    //     console.log("firebase but no auth", props.firebase.auth);
    //     if(window.location.pathname !== '/')
    //       window.location.href = '/';

    //   }
    // }, [props.firebase?.auth]);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} />
      </AuthUserContext.Provider>
    );
  };

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
