import React from 'react';
import ReactImageGallery from 'react-image-gallery';
import { questionMoreInfoVariables } from '../../../variables/variables';
import { FaTimesCircle } from 'react-icons/fa';

const qTypeParser = (type) => {
  switch (type) {
    case 'simple':
      return 'Enkelt';
    case 'multiple-choice':
      return 'Flervalg';
    case 'number':
      return 'Tall';
    case 'multiple-answer':
      return 'Flersvar';
    case 'practical':
      return 'Praktisk';
    case 'camera':
      return 'Kameraoppgave';
    default:
      return 'Ugyldig type';
  }
};

const getAnswer = (user, question) => {
  if (!user.answers) {
    if (question.type === 'camera') {
      return FaTimesCircle;
    } else {
      return 'Ikke svart';
    }
  }
  const ans = user.answers.find((a) => a.questionId === question.id);

  if (ans) {
    if (ans.answer instanceof Array) {
      return ans.answer.join(", ");
    } else {
      return ans.answer;
    }
  } else {
    if (question.type === 'camera') {
      return FaTimesCircle;
    } else {
      return 'Ikke svart';
    }
  }
};

const formatAnswers = (type, answer, alternatives, question) => {
  if (type === 'simple') {
    return (
      <div className="question-see-more-simple-answer">
        {'Riktig svar: ' + answer}
      </div>
    );
  }

  if (type === 'multiple-choice') {
    return (
      <div className="question-see-more-multiple-choice-answer">
        <table>
          <thead>
            <tr>
              <th style={{
                fontSize: '1.4rem',
                fontWeight: '400',
                padding: '1rem 0.3rem',
                borderBottom: 'none',
              }}>Valg</th>
            </tr>
          </thead>
          <tbody>
            {alternatives.map((a, i) => {
              return (
                <tr key={i}>
                  <td
                    className={
                      'multiple-choice-row' +
                      (answer.includes(a) ? ' correct' : ' wrong')
                    }
                  >
                    {a}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  if (type === 'multiple-answer') {
    return (
      <div className="question-see-more-multiple-answer-answer">
        <table>
          <caption></caption>
          <thead>
            <tr>
              <th colSpan="2" style={{
                fontSize: '1.4rem',
                fontWeight: '400',
                padding: '1rem 0.3rem',
                borderBottom: 'none',
              }}>Valg</th>
            </tr>
          </thead>
          <tbody>
            {alternatives.map((a, i) => {
              return (
                <tr key={i}>
                  <td
                    className={
                      'multiple-choice-row' +
                      (answer.includes(a) ? ' correct' : ' wrong')
                    }
                  >
                    {a}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  if (type === 'number') {
    return (
      <div className="question-see-more-number-answer">
        <div className="question-see-more-number-answer">
          {'Riktig svar: ' + answer}
        </div>
      </div>
    );
  }

  if (type === 'practical') {
    return <div className="question-see-more-practical-answer"></div>;
  }
};

const formatResponses = (question, users) => {
  var imageList = [];
  return question.type === 'camera' ? (
    (imageList.push(
      users.map((u, _i) => {
        return {
          original: getAnswer(u, question),
          description: u.teamName,
          thumbnail: getAnswer(u, question),
          originalWidth: 680,
        };
      }),
    ),
    (
      <div>
        <h3>Bildegalleri</h3>
        <ReactImageGallery items={imageList[0]} />
      </div>
    ))
  ) : (
    <table>
      <thead>
        <tr>
          <th colSpan="2" style={{
            fontSize: '1.4rem',
            fontWeight: '400',
            padding: '1rem 0.3rem',
            borderBottom: 'none',
          }}>Responser</th>
        </tr>
        <tr>
          <th style={{ borderBottom: '0.2rem solid #DDDDDD' }}>{questionMoreInfoVariables.teamPlayer}</th>
          <th style={{ borderBottom: '0.2rem solid #DDDDDD' }}>Svar</th>
        </tr>
      </thead>
      <tbody>
        {users.map((u, i) => {
          return (
            <tr key={i}>
              <td>{u.teamName}</td>
              <td>{getAnswer(u, question)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default function QuestionMoreInfo({ question, users, onCloseWindow }) {
  return question ? (
    <div
      className="question-see-more-container"
      style={{ backgroundColor: '#FFF' }}
    >
      <div
        className="modal-exit-button"
        onClick={() => onCloseWindow()}
        onKeyDown={(e) => e.key=="Escape" && onCloseWindow()}
        role="button" tabIndex="0"
        style={{top: '0.0rem', right: '0.0rem'}}
      >
        <FaTimesCircle style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
      </div>
      <div className="question-see-more-content">
        <div className="question-see-more-question-info">
          <div className="question-see-more-question">
            {question.question}
          </div>
          <div className="question-see-more-subtext">
            <div className="question-see-more-subject">
              {question.category ? question.category.subject : ''}
            </div>
            
            <div className="question-see-more-divider">|</div>
            <div className="question-see-more-qtype">
              {qTypeParser(question.type)}
            </div>
            <div className="question-see-more-divider">|</div>
            <div className="question-see-more-points">
              {question.points + ' poeng'}
            </div>
          </div>
          {question.mediaUrl && <div className="media"><img src={question.mediaUrl} alt="media" /></div>}
          <div className="question-see-more-correct-answer">
            {formatAnswers(
              question.type,
              question.answer,
              question.alternatives,
              question,
            )}
          </div>
        </div>
        <div className="question-see-more-user-answers">
          {formatResponses(question, users)}
        </div>
      </div>
    </div>
  ) : null;
}
