import React from 'react';
import Modal from 'react-modal';
import {
  deleteModalStyles,
  cancelDeleteButtonStyles,
  deleteButtonStyles,
} from '../GamesList/atoms/GamesModalStyles';
import { FaTimesCircle } from 'react-icons/fa';

export function DeleteModal({
  isOpen,
  title,
  text,
  buttonTitle,
  onConfirm,
  onCancel,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      ariaHideApp={false}
      style={deleteModalStyles}
    >
      <h3>{title}</h3>
      <p>{text}</p>
      <div>
        <button onClick={onConfirm} style={deleteButtonStyles}>
          {buttonTitle}
        </button>
      </div>
      <button onClick={onCancel} style={cancelDeleteButtonStyles} className="modal-exit-button">
        <FaTimesCircle style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
      </button>
    </Modal>
  );
}

export default DeleteModal;
