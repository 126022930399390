import React, { useState, useEffect } from 'react';
import { onSnapshot, doc, getDoc, getDocs, updateDoc, collection, getFirestore, Timestamp } from 'firebase/firestore';
import Loading from '../../atoms/Loading';
import { MY_GAMES } from '../../../constants/routes';
//import { useNavigate } from 'react-router-dom';
import { FaArrowRight, FaPlay, FaStop, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router';


const SCORE_MULTIPLICATOR = 100;

    
// update ranking
const calculateRanking = (event, eventUsers, question, winners = null) => {
    let ranking = [];
    let qid = null;

    // use winners as answers (has not been written to db yet) or get from event
    let _winners = winners !== null ? winners : event.winners;
    // check if a ga
    if(_winners && _winners.length) {
        // load users into array
        let _users = eventUsers.map(e => {
            let u = {};
            u.id = e.id;
            u.teamName = e.teamName;
            return u;
        });
        
        if(question) qid = question.id;

        // update totalscore for each user
        _users.forEach(u => {
            let x = 0;
            let y = 0;
            _winners.forEach((w) => {
                if(w.user === u.id) {
                    x += w.totalscore;
                    if(qid && w.question === qid) y += w.totalscore;
                }
            });
            u.totalscore = x;
            u.questionscore = y;
        });

        // sort by totalscore
        _users.sort((a,b) => { return a.totalscore < b.totalscore ? 1 : a.totalscore > b.totalscore ? -1 : 0 })

        // set ranking based on position
        let z = 1;
        ranking = _users.map(u => {
            u.position = z; 
            z++; 
            return u;
        });
    }

    // return the rankings
    return ranking;

}


export default function Event() {
console.log("Event")
    const navigate = useNavigate();
    //const navigate = (path) => { console.log("navigating to: " + path);}

    /* SET VARIABLES AND STAT HOOKS */

    // getting eventID from url
    let params = new URLSearchParams(
        document.location.search.substring(1),
    );
    const eventId = params.get('eventId');

    // loading state for beginning, do not display until initial load
    const [loading, setLoading] = useState(true);

    // errormassage
    const [error, setError] = useState('');

    const [eventState, setEventState] = useState('pregame');
    // pregame pre active post finished

    const [timeleft, setTimeleft] = useState(null);

    // when should the question end (if set in question)
    const [endTime, setEndTime] = useState(null);

    // the current event (contains question array which contains db-refs to real questions)
    const [event, setEvent] = useState(null);
    // subcollection Users under current event (user contains array of own answers)
    const [eventUsers, setEventUsers] = useState([]);

    // the question loaded from database "questions/*"
    const [question, setQuestion] = useState(null);

    // keeping track of which question is currenly displayed
    const [questionNumber, setQuestionNumber] = useState(null);

    // number of valid qustions (that does not have state "ended")
    const [validQuestions, setValidQuestions] = useState(null);
   
    // timer/interval number - to control clearInterval(*)
    const [interval, setIntervalId] = useState(null);
    




    /* DATABASE FUNCTIONS */




    // load a question (full question data from database path)
    const loadQuestion = async(questionPath) => {
  
        const ref = doc(getFirestore(), questionPath);
        const docSnap = await getDoc(ref);
        //console.log(docSnap.data())
        return ({id: docSnap.id, ...docSnap.data()});
    }

    // controller to set which question is active and update event in DB
    const setActiveQuestion = async(questionPath) => {
        
        console.log("setActiveQuestion");
        let data = null;
        // set no active question
        if(questionPath === null) {
            data = {
                state: "pregame",
                activeQuestion: null
            }
            
        }
        else if(typeof(questionPath) === "string" && questionPath.length > 8) {
            // update activequestion if given string
            const qid = questionPath;
            data = {
                state: "pre",
                activeQuestion: qid
            }
        }
        
        if(data !== null) {
            // update if data to update
            const ref = doc(getFirestore(), 'events', eventId);
            await updateDoc(ref,data);
            //setEventState(_eventState);
            return true;
        }
        else {
            console.log("Error setting active question")
            return false;
        }
    }


    // controller to start question and update event in DB
    const startQuestion = async() => {
        let _error = false;
        let _question = question;
        const questionPath = "questions/"+_question.id;

        console.log("startquestion")
        

        let questions = event.questions;
        let data = null;
        let _endTime = null;
        let newQuestions = questions.map((q) => {
            if(q.question.path === questionPath) {
                console.log("path ok")
                if(q.state == "ended") {
                    _error = true;
                    setError("Question already used");
                }
                else {
                    q.state = "started"; // started - ended
                    q.startTime = Timestamp.now();
                    if(question.deadlineEnabled && question.deadline > 0) {
                        _endTime = Timestamp.fromMillis(Timestamp.now().toMillis() + (question.deadline * 1000));
                        q.endTime = _endTime;
                        setEndTime(_endTime.toMillis());
                    }
                    else {
                        // TODO: deadline for event?
                        //_endTime = Timestamp.fromMillis(Timestamp.now().toMillis() + (defaultDeadline * 1000));
                        
                    }
                    
                }
                return q;
            }
            else return q;
        })

        //console.log("todb: ",newQuestions);

        if(typeof(questionPath) === "string" && questionPath.length > 8 && !_error) {
            
            data = {
                state: "active",
                questions: newQuestions
            }
        }
        
        if(data !== null && !_error) {
            // update if data to update
            const ref = doc(getFirestore(), 'events', eventId);
            //console.log(data)
            await updateDoc(ref,data);
            setEventState("active");
            return true;
        }
        else {
            console.log("Error starting question")
            return false;
        }
    }

    // update event in DB that event is finished
    const updateEventSetFinished = async() => {

        let score = [];

        const ranking = calculateRanking(event, eventUsers, question);

        const data = {
            ranking: ranking,
            score: score,
            state: "finished",
            active: false,
            activeQuestion: null
        }
        const ref = doc(getFirestore(), 'events', eventId);
        await updateDoc(ref,data);
        return true;
    }

  
    




    // controller to stop question and update event in DB
    const stopQuestion = async(early = false) => {
        let _error = false;
        const now = Timestamp.now().toMillis();

        // construct question path to match paths
        const questionPath = "questions/"+question.id;
        
        // the question metadata from event
        let questions = event.questions;

        // current winners
        let winners = event.winners ?? [];

        // new winners
        let _winners = [];

        // fetching fresh version of users with answers, 
        // to get all answers in cases where user answer 
        // is later than latest eventUsers snapshop
        let _eventUsers = [];

        const ref = collection(getFirestore(), 'events', eventId, 'users');
        const docs = await getDocs(ref);
                    
        docs.forEach((doc) => {
            let _user = {id: doc.id, ...doc.data() };
            _eventUsers.push(_user);
        })
    
        
        // loop questions to find current question-meta based on path
        // q is the meta-question that will be updated in db on the event
        let newQuestions = questions.map((q) => {
            if(q.question.path === questionPath) {

                // check if question has been processed already
                if(q.state == "ended") {
                    // already updated
                    _error = true;
                }

                // set endtime if it has not been set for the timer
                if(early) q.endTime = Timestamp.now();

                // set ended state on the question to prevend double processing
                q.state = "ended";
                
                
                // the question metadata
                let eventQuestion = event.questions.find(element => {
                    if(element.question.path == questionPath) return element;
                })
                const startTime = eventQuestion.startTime.toMillis();
                
                // loop the users in the event
                let _users = _eventUsers.map(u => {return u})
                for(let i = 0; i < _users.length; i++) {
                    
                    const _answers = _users[i]?.answers;
                    if(_answers) {
                        // if user have answers -> find currenct answer
                        const _answer = _answers.find(element => {
                            if(element.questionId == question.id) return element;
                        });
                        if(_answer && _answer.points > 0) {
                            // if we find answer and it is currenct -> check it
                            _users[i].answer = _answer;
                            const _submitted = Date.parse(_answer.time);
                            console.log('starttime vs submitted vs now', startTime, _submitted, now);

                            // must be submitted after question started and not in the future
                            // TODO: check if this is to strickt / is all connected to db timestamp? timezones?
                            //if(_submitted > startTime && _submitted < now) {
                            if(true) {
                                // users is among winners, construkt winner-object for db
                                let _winner = {};
                                _winner.user = _users[i].id;
                                _winner.question = question.id;
                                _winner.points = _answer.points;
                                _winner.timeToAnswer = _submitted - startTime;
                                // add object to new winner array
                                _winners.push(_winner);
                                
                            }
                            
                        }
                        
                    }
                }
  
                // sort all the winners
                _winners.sort((a,b) => { return a.timeToAnswer > b.timeToAnswer ? 1 : a.timeToAnswer < b.timeToAnswer ? -1 : 0 })

                // find max points in question
                const points = question.points;
                // divide score on how many winners
                const fragment = points / _winners.length;

                // how much shoud time count vs correct answer? 70%?
                // TODO: integrate in event parameters?
                const TIME_SCORE_FACTOR = 0.7;

                // give score and position for each user
                for(let i=0;i<_winners.length;i++) {
                    _winners[i].timescore = (_winners.length - i)*fragment*TIME_SCORE_FACTOR;
                    _winners[i].totalscore = _winners[i].timescore + _winners[i].points;
                    _winners[i].position = i+1;
                }

                return q;
            }
            else return q;
        })
        
        // add winners to current winners
        const newWinners= winners.concat(_winners);

        const ranking = calculateRanking(event, eventUsers, question, newWinners);
         
        // object for update in db
        const data = {
            ranking: ranking,
            state: "post",
            questions: newQuestions,
            winners: newWinners,
        }

        console.log('data', data)

        // update if data to update and no errors
        if( data !== null && !_error) {
            // update event in db
            const ref = doc(getFirestore(), 'events', eventId);
            await updateDoc(ref,data);

            // set new state 
            // (not needed as the event listener will update the event and set the state)
            setEventState("post");
            return true;
        }
        else {
            console.log("Error stopping question")
            return false;
        }
    }






    
    /* OTHER FUNCTIONS */


    // check how many valid questions are left
    const updateValidQuestionCount = (event) => {

        const q = event.questions;
        let _validquestions = 0;
        for(let i=0;i<q.length;i++) {
            if(q[i].state != "ended") {
                if(event.activeQuestion && event.activeQuestion === q[i].question.path) setQuestionNumber(i + 1);
                _validquestions = _validquestions+1;
            }
        }
        setValidQuestions(_validquestions);

    }

    




    /* EVENT FUNCTIONS */

    // click start, skip or next question
    const clickNextQuestion = async() => {
        // reset timer
        clearInterval(interval);
        setTimeleft(null);

        if(eventState === "active") {
            console.log("was active - stopping")
            await stopQuestion(true);
        }
        

        // to fint question number x (default first question)
        let x = 0;
        // possible next question
        let trynext = false;
        let match = null;
        // q = all the questions
        let q = event.questions;


        // if a question is active, find next question
        if(question) {
            for(let i=0;i<q.length;i++) {
                // check if question could be selected if match was found on last
                if(trynext && x < q.length) {
                    if(q[x].state === "started" || q[x].state === "ended") {
                        // question has been used 
                        // try next question
                        x = x+1;
                        
                    }
                    else {
                        //question ok
                        match = x;
                        trynext = false;
                    }
                }
                // find matching question
                else if(q[i].question.path === "questions/"+question.id) {
                    x = i+1; // select next question in array
                    trynext = true;
                }
            }
        }
        //console.log(x)
        if(!question || x >= q.length || match === null) {
            trynext = true;
            for(let i=0;i<q.length;i++) {
                // try to find a match if no match is found
                if(match === null && q[i].state != "ended") {
                    // found a vaild question
                    match = i;
                }
            }
        }
        //console.log("x and match ",x,match)

        //console.log(match)
        if(match !== null && match < q.length) {
            setActiveQuestion(q[match].question.path);
            setQuestionNumber(match + 1);
        }
        else {
            setActiveQuestion(null);
            //setEventState("finished");
        }
    }

    // click start when question is loaded
    const clickStart = async() => {
        if(question && eventState === "pre") {
            startQuestion();
        }
        else {
            console.log("Clickstart: No active question")
        }
    }

    // click stopp question
    const clickStop = async() => {
        // console.log("is",interval)
        if(question && eventState === "active") {
            console.log("stopping question")
            // reset timer
            console.log("setting post")
            setTimeleft(null);
            clearInterval(interval);
            stopQuestion(true);
        }
        else {
            console.log("Clickstop: No active question")
        }
    }

    // click finish event
    const clickFinish = async() => {

        if(question && eventState === "active") {
            setTimeleft(null);
            clearInterval(interval);
            stopQuestion(true);
        }

        await updateEventSetFinished();

        setEventState("finished");
    }

    // click close event -> go back to my games
    const clickClose = async() => {
        
        typeof window !== "undefined" &&
            navigate(`${MY_GAMES}`);

    }

    // click close event -> go back to my games
    const clickResults = async() => {
        
        typeof window !== "undefined" &&
            navigate(`/results?eventId=${eventId}`);

    }







    /* HOOKS, UPDATES WHEN SOMETHING UPDATES IN STATE OR DB */

    // listen to the event - load event object and set initial states
    useEffect(() => {
        let unsubscribe;

        if (eventId) {
            // TODO: try -> catch if no access or error
            const ref = doc(getFirestore(), 'events', eventId);
            unsubscribe = onSnapshot(ref, (doc) => {
                //console.log(1)
                setEvent({id: doc.id, ...doc.data()});

                if(doc.data().state)
                    setEventState(doc.data().state);
                else 
                    setEventState("pregame");
                if(doc.data().activeQuestion) {
                    //console.log("docdata.actveQ",doc.data().activeQuestion)
                    loadQuestion(doc.data().activeQuestion)
                        .then((q) => setQuestion(q))

                    const _questions = doc.data().questions;
                    for(let i=0; i<_questions.length;i++) {
                        // if page has been refreshed while in game or not closed properly
                        if(loading && doc.data().activeQuestion == _questions[i].question.path)
                            //setActiveQuestion(_questions[i].question.path);
                            if(_questions[i].question.state == "started" && _questions[i].question.endTime)
                                setEndTime(_questions[i].question.endTime.toMillis());
                    }
                }
                else {
                    setQuestion(null);
                    //setActiveQuestion(null);
                }

                updateValidQuestionCount({id: doc.id, ...doc.data()});

                setLoading(false);
            });
        
        } else {
            setError('No event');
        }
    
        return unsubscribe;
      }, [eventId]);

      //listen to event users - update if new users or users change (like answer a question)
      useEffect(() => {
        let unsubscribe;
        if(!eventId) { setError('No event id'); }
        else {
                // TODO: try -> catch if no access or error
                const ref = collection(getFirestore(), 'events', eventId, 'users');
                unsubscribe = onSnapshot(ref, (querySnapshot) => {
                    let _eventUsers = [];
                      
                    querySnapshot.forEach((doc) => {
                        let _user = {id: doc.id, ...doc.data() };
                        _eventUsers.push(_user);
                    })

                    setEventUsers(_eventUsers);
                });
        }
    
        return unsubscribe;
      }, [eventId]);
 




      // timer controller (ends question if time is up)
      useEffect(() => {
        const now = new Date().getTime();
        
        if(event) updateValidQuestionCount(event);
        if(eventState == "active" && question && question.deadlineEnabled && endTime) {
            if(endTime > now) {
                
                const _interval = setInterval(() => {

                    const _timeLeft = ((endTime - new Date().getTime())/1000).toFixed(1);

                    if(_timeLeft <= 0) {

                        setTimeleft(0);
                        stopQuestion();
                        setEventState("post");
                        clearInterval(_interval);
                    }
                    else if(_timeLeft >= 0)
                        setTimeleft(_timeLeft);
                    
                }, 100);
                
                setIntervalId(_interval);
                
            }
            else {
                setTimeleft(0);
                setEndTime(null);
                setEventState("post");
                clearInterval(interval);
            }
        }
        
      }, [eventState]);
   




    // Calculate ranking

    let ranking = [];
    // if game data is initialized
    if(event && eventUsers.length) {
        
        if(event.winners && event.winners.length) {
            ranking = calculateRanking(event, eventUsers, question);
            
        }
    }


    /* RENDER */

    // dev mode shows all variables in a simple setup for debugging
    let dev = false;
    if(dev)
        return (
        <>
        <main className="main">
            <div className='event container'>
            
                <h1>{event.name}</h1>
                <h2>Spørsmål:</h2>

                qid: {question && question.id}
                <br />
                q: {question && question.question}

                <br />
                {question && question.mediaUrl && <>
                <img src={question.mediaUrl} />
                </>}
                <br />
                EndTime: {timeleft}
                <br />
                State: {eventState}
                <br />
                Controller: <div onClick={() => setActiveQuestion(null)}>Ingen</div>
                {event.questions.map((q,key) => 
                            <div key={key}>{q.question.path} - 
                                <span onClick={() => setActiveQuestion(q.question.path)}>
                                    {key+1}
                                </span>   -
                                <span onClick={() => startQuestion(q.question.path)}>
                                    start
                                </span>   - {q.state} - {question && "questions/"+question.id == q.question.path && <>X</>}
                            </div>
                            
                        )}
                <br />
                <div onClick={() => clickNextQuestion()}>
                    next
                </div> 
                <div onClick={() => clickStart()}>
                    start
                </div> 
                <div onClick={() => clickStop()}>
                    stopp
                </div> 
               
                
                <h2>Users: ({eventUsers.length})</h2>
                <br />
                {eventUsers && eventUsers.map(user => <div key={user.id}>
                    <h3>- {user && user.teamName}</h3>
                    {user.answers && user.answers.map(answer =>  
                        <div key={user.id + answer.questionId}>
                            -- Answer: {answer.answer}
                        </div>)}
                </div>)}


            </div>
            
        </main>
        
        </>
        );
    else {
        return(<>
        <div className="eventpage">
            <div className='inner'>
                <div className="close-container"><div className="close-button" onClick={() => clickClose()}>Lukk</div></div>
            {loading && <>
                <Loading />
            </> || <>
                
                    <EventPage event={event} 
                        eventState={eventState}
                        eventUsers={eventUsers} 
                        question={question} 
                        clickNextQuestion={clickNextQuestion} 
                        clickStart={clickStart}
                        clickStop={clickStop}
                        clickFinish={clickFinish}
                        clickClose={clickClose}
                        clickResults={clickResults}
                        validQuestions={validQuestions} 
                        timeleft={timeleft}
                        questionNumber={questionNumber}
                        ranking={ranking}
                        />


                    <EventUsers eventState={eventState} eventUsers={eventUsers} question={question} ranking={ranking} />
              
                <div className="stats">
                    
                    <div className="code">Spillere: {eventUsers.length}</div>
                    <div className="code">Spillkode: <span>{event.eventCode}</span></div>
                    <div className="code">Spørsmål: {questionNumber && <>{questionNumber} / </>}{event.questions.length}</div>


                </div>
            </>}
            </div>
        </div>
        </>);
    }
}


const EventUsers = ({eventState, eventUsers, question, ranking}) => {
    if(eventState === "finished") return <></>;
    else return(<>

        <div className="eventusers">
            <h3>Lag:</h3>
            <div className="eventusers-list">
                {eventUsers && eventUsers.map(user => (
                    <div className="eventuser" key={user.id}>
                        <EventUser user={user} eventState={eventState} question={question} ranking={ranking} showscore={true} />
                    </div>
                ))}
            </div>
        </div>
    
    
    </>);
}


const EventPage = ({event, 
            eventState,
            question, 
            clickNextQuestion, 
            clickStart,
            clickStop,
            clickFinish,
            clickClose,
            clickResults,
            validQuestions,
            timeleft,
            questionNumber,
            ranking,

            }) => {


    
    return(<>
        <div className="eventheader">
            
            <div className="title"><h1>{event.name}</h1></div>
            
        </div>


{/*<div className="button secondary" onClick={() => clickNextQuestion()}>Hopp til neste oppgave</div>}*/}
        <div className='eventactions-container'>
        <div className="left-actions">
            {validQuestions>1 && (eventState === "pre") && 
                <div>&nbsp;</div> }
        </div>
        <div className="eventactions">
            
            {validQuestions>=1 && (eventState === "pregame") && 
                <div className="button primary" onClick={() => clickNextQuestion()}><FaPlay /> Start spillet</div>}
            
            {validQuestions >= 1 && eventState === "pre" && <>
                <div className="button primary" onClick={() => clickStart()}><FaPlay /> Aktiver spørsmål</div>
            </>}
            {eventState === "active" && <>
                <div className="button primary" onClick={() => clickStop()}><FaStop /> Avslutt spørsmål</div>
            </>}
            
            
            
            {validQuestions>=1 && (eventState === "post") && 
                <div className="button primary" onClick={() => clickNextQuestion()}>Gå til neste spørsmål</div>}
            {validQuestions < 1 && eventState !== "finished" && 
                <div className="button primary" onClick={() => clickFinish()}>Avslutt spillet</div>}
            {eventState === "finished" && <>
                <div className="button primary" onClick={() => clickClose()}>Lukk</div>
                <div className="button primary" onClick={() => clickResults()}>Vis resultater</div>
                </>}
        </div>
        <div className="right-actions">
            {validQuestions>1 && (eventState === "pre") && 
                <div className="button secondary" onClick={() => clickNextQuestion()}>Hopp over spørsmål <FaArrowRight /></div>}
        </div>
        </div>



        <div className="eventmain">

            {eventState === "pregame" && <>
                <div className="eventmessage">{event.startMessage}</div>
                <div className="starters">
                    <div className="code">Spillkode: <span>{event.eventCode}</span></div>
                    <div className="qr">
                        <img src={encodeURI(
                                'https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=Poplar:' +
                                event.eventCode,
                            )} alt="QR-kode" />
                        </div>
                 </div>
                {validQuestions < 1 && <div className="eventtext">Ingen spørsmål gjenstår.</div>}
            </>}
            {eventState === "finished" && <>
                <div className="eventmessage">{event.finishMessage}</div>
                <div className={`ranking finished`}>
                    {ranking && ranking.map((user) => (
                        <div key={user.id} className={`pos-${user.position} ${user.position > 10 ? "rest" : ""} ${"user"}`}>
                            <div className="position">{user.position}</div> <div className="name">{user.teamName}</div> <div className='score'>{(user.totalscore*SCORE_MULTIPLICATOR).toFixed(0)}</div>
                        </div>
                    ))}
                </div>
            </>}
            {eventState === "pre" && <>
                <div className="eventmessage">Gjør deg klar for neste spørsmål!</div>
                <div className="questionnumber">
                    Spørsmål:<br />
                    <span>{questionNumber} / {event.questions.length}</span>
                </div>
            </>}
            {eventState === "post" && <>

            <div className="eventmessage">Godt jobbet!</div>

            <div className='showanswer'>
                <div className="question">{question?.question}</div>
                <div className="answer">
                {question?.alternatives && 
                question.alternatives.map((a, i) => {
                    const isAnswer =
                    question.type === 'multiple-answer'
                        ? question.answer.includes(a)
                        : question.answer === a;
                    return (
                    <div key={i}>
                        <div className={`multipleanswer ${isAnswer ? 'correct' : 'incorrect'}`} >
                        {a}
                        </div>
                    </div>
                    
                    );
                    
                }) || <>
                <div className="singleanswer">{question?.answer}</div></>}
                </div>
            </div>

                <div className="eventmessage">Topplisten:</div>
                <div className={`ranking`}>
                    {ranking && ranking.map((user) => (
                        <div key={user.id} className={`pos-${user.position} ${user.totalscore > 0 && user.position <= 10 ? "user" : "hide"}`}>
                            <div className="position">{user.position}</div> <div className="name">{user.teamName}</div> <div className='score'>{(user.totalscore * SCORE_MULTIPLICATOR).toFixed(0)}</div>
                        </div>
                    ))}
                </div>
            </>}
            
            {eventState === "active" && <>
                <div className="eventquestion">
                    <div className="questioninner">
                    <h2>{question && question.question}</h2>
                    {question && question.mediaUrl && <>
                        <img src={question.mediaUrl} />
                    </>}
                    </div>
                    {timeleft !== null && <><div className="timeleft">{timeleft}</div></>}
                </div>
            </>}


        </div>



        

        
        
        
        <div className="eventbottom"></div>
    </>)
}

const EventUser = ({user, eventState, question, ranking, showscore = false}) => {
    let answer = null;
    let active = (eventState === "active" || eventState === "post") ? true : false;
    let post = (eventState === "post") ? true : false;
    let correct = null;

    if(active && question && user.answers) {
        answer = user.answers.find(a => a.questionId === question.id)
        
    }
    if(post && answer) {

        correct = answer.points > 0 ? true : false;
    }
    
    return(<>
        <div className={`team ${answer ? "answer" : ""} ${answer && correct === true ? "correct" : ""} ${answer && correct === false ? "incorrect" : ""}`}>{user.teamName ? user.teamName : <><FaUser />...</>}</div>
        {false &&<div className="participants">{user.participants && user.participants.map(person =><>
        -{person}<br />
        </>)}</div>}
    </>)
}