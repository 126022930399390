import React from 'react';

export const NumberInput = ({
  placeholder,
  description,
  handler,
  value,
  styleOverride,
  className,
  disabled,
}) => {
  return (
    <div
      className={
        'poplar-number-input-container' +
        (className ? ' ' + className : '')
      }
    >
      <input
        className="poplar-number-input"
        type="number"
        placeholder={placeholder}
        disabled={disabled}
        value={value ? value : ''}
        onChange={(e) => handler(e.target.value)}
        style={styleOverride ? styleOverride : {}}
      />
      {/*<img style={{width: '1rem'}} src={timeWatch} alt=''></img>*/}
      <div className="poplar-number-input-text">{description}</div>
    </div>
  );
};
