import React from 'react';

const hidden = {
  position: 'absolute',
  opacity: '0',
  height: '0',
  width: '0',
};

const labelStyle = {
  borderRadius: '2rem',
  width: 'fit-content',
  display: 'flex',
  fontSize: '14px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  marginRight: '1.5rem',
  whiteSpace: 'nowrap',
};

const checkmarkStyle = {
  display: 'flex',
  width: '22px',
  height: '22px',
  borderRadius: '50%',
  margin: 'auto',
  marginRight: '5px',
  border: '2px solid #000',
  boxSizing: 'border-box',
  justifyContent: 'center',
  alignItems: 'center',
};
const checkmarkDotStyle = {
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  background: '#EB7179',
};

export const LanguageRadio = ({
  name,
  value,
  label,
  defaultChecked = false,
  checked,
}) => {
  return (
    <label
      className="poplar-radio-container"
      style={{ ...labelStyle }}
    >
      <div
        className="poplar-radio-checkmark"
        style={{
          ...checkmarkStyle,
          border: checked ? '2px solid #EB7179' : '2px solid #000',
        }}
      >
        {checked ? (
          <div
            className="poplar-radio-checkmark-dot"
            style={checkmarkDotStyle}
          />
        ) : null}
      </div>
      <input
        type="radio"
        name={name}
        value={value}
        id={'poplar-radio-' + value}
        style={hidden}
        defaultChecked={defaultChecked}
      />
      {label}
    </label>
  );
};
