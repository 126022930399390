import React, { Component } from "react";

//Generates a color based on name length
const generateColorFromName = (name) => {
  const array = [
    "#59B9EB",
    "#6575EB",
    "#EB9271",
    "#EBC442",
    "#6CCFA3",
    "#EB7179",
    "#676B85",
  ];
  return array[name.length % 6];
};

const formatTime = (time) => {
  const totalTime = time / 1000;
  let sec_num = parseInt(totalTime, 10);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return (hours !== "00" ? hours + ":" : "") + minutes + ":" + seconds;
};

const formatDate = (date) => {
  if (date === undefined) {
    return 'Startet ikke';
  }
  let value;
  if (typeof date === 'number')
    value = new Date(date);
  else
    value = date.toDate();
  return value.getFullYear() + "-" +
    (value.getMonth() < 9 ? "0" : "") + (value.getMonth() + 1) + "-" +
    (value.getDate() < 10 ? "0" : "") + value.getDate() + " " +
    (value.getHours() < 10 ? "0" : "") + value.getHours() + ":" +
    (value.getMinutes() < 10 ? "0" : "") + value.getMinutes();
//  return value.toISOString().replace(/T/, " ").replace(/\..+/, "");
};

const generateRow = (user, event, index) => {
  const displayName = (user.teamName ? user.teamName : "");
  const usedTime = user.endTime ? formatTime(user.timeUsed) : "Ikke fullført";
  const distance = Number.parseFloat(user.distance).toFixed(0);

  return (

      <tr
        key={'user_'+index+'_'+user.id}
        style={{
          textAlignLast: "left",
          boxShadow: "0px 4px 10px rgba(103, 107, 133, 0.15)",
          borderRadius: "1rem",
          color: "#676B85",
        }}
      >
        <td style={{ width: "2%" }}>
          <div
            style={{
              backgroundColor: generateColorFromName(displayName),
              width: "2rem",
              height: "2rem",
              borderRadius: "0.5rem",
              color: "white",
              textAlignLast: "center",
              paddingTop: "0.45rem",
              margin: "0.2rem",
            }}
          >
            {index + 1}
          </div>
        </td>
        {user.participants[0] !== displayName ? (
        <td style={{ width: "20%", textAlign: 'left' }}>{displayName}<br/><span style={{fontSize: '0.75rem', textAlign: 'left'}}>{user.participants.join(", ")}</span></td>
        ) : (
        <td style={{ width: "20%", textAlign: 'left' }}>{displayName}</td>
        )}
        <td style={{paddingLeft: '1rem'}}>{user.points}</td>
        {event.hintsAvailable > 0 ? (
        <td style={{paddingLeft: '1rem'}}>{event.hintsAvailable - user.hintsAvailable ?? 0}</td>
        ) : null}
        {event.timelimit > 0 ? (
          <td style={{paddingLeft: '1rem'}}>{user.startTime ? usedTime : "Ikke startet"}</td>
        ) : null}
        {event.outside ? (
        <td style={{paddingLeft: '1rem'}}>{isNaN(distance) ? "0" : distance} m</td>
        ) : null}
        <td style={{paddingLeft: '1rem'}}>{user.finished ? "Ja" : "Nei"}</td>
        <td>{formatDate(user.startTime)}</td>
      </tr>
     
  );
};

const generateRows = (users, event, _now) => {
  const sorted = users.sort(sortUsers);
  
  return sorted.map((u, i) => generateRow(u, event, i));
};

const sortUsers = (a, b) => {
  // Got to check if actually "timeUsed" works as supposed to in API.

  return (
    b.points - a.points || a.timeUsed - b.timeUsed || a.distance - b.distance
  );
};

const distanceSum = (users) => {
  let sum = 0;
  users.forEach((u) => sum = sum + (u.distance ? u.distance : 0));
  return Math.floor(sum);
};

const finishedSum = (users) => {
  let sum = 0;
  users.forEach((u) => sum = sum + (u.finished ? 1 : 0));
  return sum;
};

const hintsSum = (users, hintsAvailable) => {
  let sum = 0;
  users.forEach((u) => sum = sum + (hintsAvailable - u.hintsAvailable ?? 0));
  return sum;
};

const pointsSum = (users) => {
  let sum = 0;
  users.forEach((u) => sum = sum + (u.points ? u.points : 0));
  return sum;
};

const startSum = (users) => {
  let sum = 0;
  users.forEach((u) => sum = sum + (u.startTime ? 1 : 0));
  return sum;
};

const timeSum = (users) => {
  let sum = 0;
  users.forEach((u) => sum = sum + (u.timeUsed ? u.timeUsed : 0));
  return sum;
};

export class ScoreboardTable extends Component {
  interval = 0;

  constructor(props) {
    super(props);
    this.state = {
      now: Date.now(),
    };
  }

  componentDidMount() {
    this.setState({ now: Date.now() });
    this.interval = setInterval(() => this.setState({ now: Date.now() }), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { users, event } = this.props;
    return (
      <div className="scoreboard-table-container">
        {this.props.users ? (
          <>
            <table style={{ width: "100%", padding: "1rem" }}>
              <thead style={{ textAlign: "left" }}>
                <tr>
                  <th
                    style={{
                      width: "2%",
                      backgroundColor: "#FFF",
                      color: "black",
                    }}
                  >
                    &nbsp;
                  </th>
                  <th
                    style={{
                      width: "20%",
                      backgroundColor: "#FFF",
                      color: "black",
                    }}
                  >
                    Visningsnavn
                  </th>
                  <th style={{ backgroundColor: "#FFF", color: "black" }}>
                    Poeng
                  </th>
                  {event.hintsAvailable > 0 ? (
                  <th style={{ backgroundColor: "#FFF", color: "black" }}>
                    Brukte hint
                  </th>
                  ) : null}
                  {event.timelimit > 0 ? (
                    <th style={{ backgroundColor: "#FFF", color: "black" }}>
                      Tid brukt
                    </th>
                  ) : null}
                  {event.outside ? (
                  <th style={{ backgroundColor: "#FFF", color: "black" }}>
                    Distanse
                  </th>
                  ) : null}
                  <th style={{ backgroundColor: "#FFF", color: "black" }}>
                    Fullf&oslash;rte
                  </th>
                  <th style={{ backgroundColor: "#FFF", color: "black" }}>
                    Startet
                  </th>
                </tr>
              </thead>
              <tbody>{generateRows(users, event, this.state.now)}</tbody>
              <tfoot>
                <tr>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th style={{ backgroundColor: "#FFF", color: "black", textAlign: "left", paddingLeft: '1rem' }}>
                    {pointsSum(users)}
                  </th>
                  {event.hintsAvailable > 0 ? (
                  <th style={{ backgroundColor: "#FFF", color: "black", textAlign: "left", paddingLeft: '1rem' }}>
                    {hintsSum(users, event.hintsAvailable)}
                  </th>
                  ) : null}
                  {event.timelimit > 0 ? (
                    <th style={{ backgroundColor: "#FFF", color: "black", textAlign: "left", paddingLeft: '1rem' }}>
                      {formatTime(timeSum(users))}
                    </th>
                  ) : null}
                  {event.outside ? (
                  <th style={{ backgroundColor: "#FFF", color: "black", textAlign: "left", paddingLeft: '1rem' }}>
                    {distanceSum(users)} m
                  </th>
                  ) : null}
                  <th style={{ backgroundColor: "#FFF", color: "black", textAlign: "left", paddingLeft: '1rem' }}>
                    {finishedSum(users)}
                  </th>
                  <th style={{ backgroundColor: "#FFF", color: "black", textAlign: "left", paddingLeft: '1rem' }}>
                    {startSum(users)}
                  </th>
                </tr>
              </tfoot>
            </table>
          </>
        ) : null}
      </div>
    );
  }
}

export default ScoreboardTable;
