import React from "react";
import { questionCategories } from "../../../variables/variables";
import KebabMenu from './KebabMenu';


const questionTypeTitle = (type) => {
  for(let i = 0; i<questionCategories.length; i++)
    if(questionCategories[i].value == type) return questionCategories[i].label;
}

/**
 * This question card is used in the «Mine Spørsmål»-lists and in the
 * administrative function for adding questions to the library.
 */
export const QuestionCard = ({
  selectedQuestion,
  selectedQuestionIndex,
  openQuestionPreview,
  closeQuestionPreview,
  editQuestion,
  deleteQuestion,
  hideFromList,
  addQuestionToLibrary,
  isEditable,
  isDeletable,
  isSuperAdmin,
  isAllQuestionsListMode,
}) => {
  return (
<>
  <div className="questioncard">
    <div className="questioncard-inner-container">
      <div 
        className="questioncard-content"
        onClick={() => openQuestionPreview(selectedQuestion)}
        onKeyDown={(e) => e.key=="Enter" && openQuestionPreview(selectedQuestion)}
        role="button"
        tabIndex="0"
      >
        <div className="questioncard-content-inner-container">
          {selectedQuestionIndex > 0 ? (
            <>
              <div className="questioncard-index">
                <p>
                  {selectedQuestionIndex}
                </p>
              </div>
            </>
          ) : null}
          <div className="questioncard-question">
            <p>
              {selectedQuestion.question}
            </p>
          </div>
          <div className="questioncard-tags-container">
            <div className="questioncard-tags">
              <div className="questioncard-tag"></div>
              {questionTypeTitle(selectedQuestion.type)}
              
            </div>
          </div>
        </div>
      </div>
      <div className="questioncard-actions">
            {isAllQuestionsListMode ? (
              <>
                <div
                  style={{
                    margin: '0',
                    padding: '0 0.5rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => hideFromList(selectedQuestion)}
                  onKeyDown={(e) => e.key=="Enter" && hideFromList(selectedQuestion)}
                  role="button" tabIndex="0"
                >
                  Avvis
                </div>
                <p style={{ margin: '0' }}>|</p>
                <div
                  style={{
                    margin: '0',
                    padding: '0 0.5rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => addQuestionToLibrary(selectedQuestion)}
                  onKeyDown={(e) => e.key=="Enter" && addQuestionToLibrary(selectedQuestion)}
                  role="button" tabIndex="0"
                >
                  Legg til bibliotek
                </div>
              </>
            ) : (
              <>
                <KebabMenu
                    selectedItem={selectedQuestion}
                    previewSelectedItem={openQuestionPreview}
                    editSelectedItem={editQuestion}
                    deleteSelectedItem={deleteQuestion}
                    canDeleteItem={isDeletable}
                    isSuperAdmin={isSuperAdmin}
                    addItemToLibrary={addQuestionToLibrary}
                    isPrintEnabled={true}
                />
              </>
            )}
      </div>
    </div>
  </div>
</>
  );
}

export default QuestionCard;
