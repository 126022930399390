export const names = {
  serviceName: 'Poplar',
  libraryTitle: 'Spillbibliotek',
  libraryQuestions: 'Mine spill',
  questionTitle: 'Spørsmål',
  questionName: 'Spørsmål',
  questionPageSubtitle: 'Her finner du spørsmål du har opprettet og vårt bibliotek av Poplar-spørsmål. Du kan ta utgangspunkt i et Poplar-spørsmål og gjøre det til ditt eget ved å velge "Rediger". Bruk filteret eller søkefunksjonen for å lete etter bestemte spørsmål. For å bruke spørsmål i et spill kan du opprette et nytt spill.',
  mygamesPageSubtitle: 'Her finner du spillene du har opprettet, spilt eller arkivert',
  libraryPageSubtitle: 'Her finner du samlinger med spørsmål som du selv kan plassere ut i ditt nærområde som et nytt spill.',
};
export const questionCategories = [
  { label: 'Tekst', value: 'simple', short: 'Tekst' },
  { label: 'Tall', value: 'number', short: 'Tall' },
  { label: 'Flervalg', value: 'multiple-choice', short: 'Flervalg' },
  { label: 'Kameraoppgave', value: 'camera', short: 'Kamera' },
  { label: 'Praktisk oppgave', value: 'practical', short: 'Praktisk' },
  { label: 'Flervalg', value: 'multiple-answer', short: 'Flervalg', visible: false },
];

export const categoryOptions = [
  { label: 'Annet', value: 'Annet' },
  { label: 'Norsk', value: 'Norsk' },
  { label: 'Matematikk', value: 'Matematikk' },
  { label: 'Naturfag', value: 'Naturfag' },
  { label: 'Engelsk', value: 'Engelsk' },
  { label: 'Samfunnsfag', value: 'Samfunnsfag' },
  { label: 'Kunst og håndverk', value: 'Kunst og håndverk' },
  { label: 'Musikk', value: 'Musikk' },
  { label: 'Kroppsøving', value: 'Kroppsøving' },
  { label: 'KRLE', value: 'KRLE' },
  { label: 'Geografi', value: 'Geografi' },
];


export const gradeOptions = [
  { label: 'Annet', value: 30 },
  { label: 'Førskole', value: 16 },
  { label: 'Småtrinnet 1.-4. klasse', value: 11 },
  { label: 'Mellomtrinnet 5.-7. klasse', value: 12 },
  { label: 'Undomsskolen 8.-10. klasse', value: 13 },
  { label: 'Videregående/høyskole', value: 14 },
  { label: 'Voksen', value: 15 },
  { label: 'For store og små', value: 17 },
  { label: '1. klasse', value: 1 },
  { label: '2. klasse', value: 2 },
  { label: '3. klasse', value: 3 },
  { label: '4. klasse', value: 4 },
  { label: '5. klasse', value: 5 },
  { label: '6. klasse', value: 6 },
  { label: '7. klasse', value: 7 },
  { label: '8. klasse', value: 8 },
  { label: '9. klasse', value: 9 },
  { label: '10. klasse', value: 10 },
];
/*
export const gradeOptions = [
  { label: 'Annet', value: 30 },
  { label: '1. Klasse', value: 1 },
  { label: '2. Klasse', value: 2 },
  { label: '3. Klasse', value: 3 },
  { label: '4. Klasse', value: 4 },
  { label: '5. Klasse', value: 5 },
  { label: '6. Klasse', value: 6 },
  { label: '7. Klasse', value: 7 },
  { label: '8. Klasse', value: 8 },
  { label: '9. Klasse', value: 9 },
  { label: '10. Klasse', value: 10 },
];*/

// Names below are index dependent
// TODO: Fix index dependent issue
export const questionGroups = {
  name: 'Klassetrinn',
  groups: { gradeOptions },
};

// Names below are index dependent
// TODO: Fix index dependent issue
export const subjectGroups = {
  name: 'Fag',
  subjects: { categoryOptions },
};

// TODO: languagesupport
export const languages = {
  name: 'Målform',
  language: ['Bokmål', 'Nynorsk'],
};
/*
export const languages = {
  name: 'Målform',
  language: ['Bokmål', 'Nynorsk', 'Engelsk'],
};*/

export const filterName = {
  name: 'Filter',
};

export const modalStrings = {
  activateQuestion: 'Aktiver Spill',
  showQuestion: 'Vis spill',
  startMessage: 'Velkommen til spillet!',
  finishMessage: 'Hurra! Dere er ferdig med spillet. Godt jobbet!',
  errorMessage: 'Feil ved oppretting av spill',
  howToPlay: 'Slik spiller du:',
  useCode: 'Be dine spilldeltakere benytte koden:',
  useQr: 'Be dine spilldeltakere scanne denne QR koden:',
};

export const gameSettings = {
  settings: 'Spillinstillinger',
  welcomeMessage: 'Velkomstmelding',
  timelimit: 'Tidsfrist på spillet (minutter)',
  noTimelimit: 'Ingen tidsfrist',
  showAnswer: 'Vis riktige svar etter svart spørsmål',
  showPoints: 'Vis poengsum på hvert spørsmål',
  useTimelimit: 'Bruk tidsfrister fra hvert spørsmål',
  endMessage: 'Avslutningsmelding',
  introHowTo: 'Slik spiller du:',
  howToPlay: 'Be dine spilldeltakere skrive inn:',
  useQr: 'Be dine spilldeltakere scanne:',
  gameCode: 'SPILL-KODE',
  qrCode: 'QR-KODE',
  showQuestionNumbers: 'Vis nummer på spørsmål i kartet',
};

export const buttonStrings = {
  activateGame: 'Aktiver spill',
  seeActiveGames: 'Se aktive spill',
  newGame: 'Nytt spill',
  gameLibrary: 'Spillbibliotek',
  deleteGame: 'Slett spill',
};

export const createGameVariables = {
  gameType: 'Spilltype',
  gameName: 'Spillnavn',
};

export const gameOverviewVariables = {
  useGame: 'Bruk spill',
};

export const GamesListVariables = {
  gameSearch: 'Søk i spill...',
  type: 'Type',
  gameName: 'Spillnavn',
  date: 'Dato',
  posts: 'Poster',
};

export const inactiveEventCardVariables = {
  useInNewGame: 'Bruk i nytt spill',
};

export const navigationAuthVariables = {
  newGame: 'Nytt spill',
  myGames: 'Mine spill',
};

export const overviewCardVariables = {
  gameCode: 'Spillkode: ',
};

export const scoreboardVariables = {
  gameResults: 'Resultater fra spill',
};

export const questionMoreInfoVariables = {
  teamPlayer: 'Lag / Spiller',
};

export const overviewVariables = {
  activeGames: 'Aktive spill',
  activeGamesDescription: 'Dine aktive og pågående spill',
  gamesLibrary: 'Spillbibliotek',
  startMessage: 'Velkommen til spillet!',
  finishMessage: 'Hurra! Dere er ferdig med spillet. Godt jobbet!',
  error: 'Feil ved oppretting av spill',
};

export const questionsVariables = {
  useCoords: 'Vil du bruke koordinater fra spillet?',
  error: 'Feil ved oppretting av spill',
  title: 'Spill-spørsmål',
  ownerTitle: 'Poplar spørsmål',
  copyTitle: 'Bruke koordinater?',
  copyConfirm: 'Ja',
  copyReject: 'Nei',
};

export const TRIAL_ENABLED = true;
export const TRIAL_DAYS = 14;
