import React from 'react';
import { Input } from "./Input";

export const TextField = ({
  title,
  value,
  handler,
  placeholder,
  type,
  disabled,
}) => {
  return (
        <div style={{padding: "10px"}}>
            <div style={{fontSize: '1rem', marginBottom: '0.8rem'}}>
                {title}
            </div>
            <Input
                type={type}
                value={value}
                placeholder={placeholder}
                handler={handler}
                className="member-textfield"
                styleOverride={{border: 'none', padding: '1rem', maxWidth: "350px"}}
                disabled={disabled ?? false}
             />
        </div>
    );
};
