import React from 'react';
import {
  ADMIN,
  ADMIN_USERS,
  ADMIN_STATS,
  ADMIN_LIBRARY,
} from '../../../../constants/routes';
import { Link, NavLink } from 'react-router-dom';


export const AdminNav = () => (
  <ul className="admin__wrapper">
    <li>
      <Link
        className={`admin__wrapper__link ${location.pathname === ADMIN ? "admin__wrapper__link__active" : ""}`}
        to={ADMIN}
      >
        Pålogginger
      </Link>
    </li>
    <li>
      <Link
        className={`admin__wrapper__link ${location.pathname === ADMIN_USERS ? "admin__wrapper__link__active" : ""}`}
        to={ADMIN_USERS}
      >
        Medlemmer
      </Link>
    </li>
    <li>
      <Link
        className={`admin__wrapper__link ${location.pathname === ADMIN_LIBRARY ? "admin__wrapper__link__active" : ""}`}
        to={ADMIN_LIBRARY}
      >
        Bibliotek
      </Link>
    </li>
    <li>
      <Link
        className={`admin__wrapper__link ${location.pathname === ADMIN_STATS ? "admin__wrapper__link__active" : ""}`}
        to={ADMIN_STATS}
      >
        Statistikk
      </Link>
    </li>
  </ul>
);
