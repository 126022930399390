import React from 'react';
import * as PropTypes from 'prop-types';
import MapContainer from '../MapContainer/MapContainer';
import InsideQuestionSelection from './InsideQuestionSelection';

/**
 * Functional component for googleMaps. Todo: Issue with loading google.window
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function GameBuilderRightComponent(props) {
  
  return (
    <>
      {props.outside ? (
        <>
          <div className="map-container">
            <div className="map-location-search-form">
              {props.mapLoaded ? (
                <input
                  id="pac-input"
                  className="map-location-search-input"
                  type="text"
                  placeholder="Søk etter steder, adresser i kartet"
                 />
              ) : (
                <div>Laster...</div>
              )}
            </div>
          </div>
          <MapContainer
            questions={props.questions}
            onDragEnd={props.onDragEnd}
            onDrop={props.onDrop}
            onDropT={props.onDropT}
            gameRadius={props.gameRadius}
            finalDestination={props.finalDestination}
            onDragEndFinalDestination={
              props.onDragEndFinalDestination
            }
            searchCoordinates={props.searchCoordinates}
            onMapLoad={props.onMapLoad}
            onMapUnmount={props.onMapUnmount}
           /> 
        </>
      ) : (
        <InsideQuestionSelection
          onDrop={props.onDrop}
          onDropT={props.onDropT}
          title="Valgte spørsmål"
          questions={props.questions1}
          onCardEditClick={props.onCardEditClick}
          onCardPreviewClick={props.onCardPreviewClick}
          onCardDeleteClick={props.onCardDeleteClick}
          questionIsSelected={props?.questionIsSelected}
         />
      )}
    </>
  );
};

GameBuilderRightComponent.propTypes = {
  outside: PropTypes.bool,
  questions: PropTypes.any,
  onDragEnd: PropTypes.func,
  onDrop: PropTypes.func,
  onDropT: PropTypes.func,
  gameRadius: PropTypes.number,
  finalDestination: PropTypes.any,
  onDragEndFinalDestination: PropTypes.func,
  searchCoordinates: PropTypes.any,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  questions1: PropTypes.any,
  onCardDeleteClick: PropTypes.func,
};
