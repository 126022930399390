import React from 'react';
import { parseAnswer, qTypeParser, qTagParser } from '../atoms/QuestionAnswer';
import { FaTimesCircle } from 'react-icons/fa';

export const QuestionPreview = ({
  selectedQuestion,
  closeQuestionPreview,
}) => {

  return (
  <div style={{
    minHeight: '250px',
    maxHeight: '90vh',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  }}>
    <div style={{
        height: '100%',
        display: 'flex',
        flexBasis: '100%',
        flexDirection: 'column',
    }}>
        <div
            role="button"
            tabIndex="0"
            className="modal-exit-button"
            onClick={() => closeQuestionPreview()}
            onKeyPress={() => closeQuestionPreview()}
            style={{zIndex: '100'}}
         >
          <FaTimesCircle style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
        </div>
        <div className="top"
            style={{
                padding: '1rem',
                height: '100%',
                minHeight: '226px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                position: 'relative',
            }}
         >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    textAlign: 'center',
                }}
             >
                <h3 className="question-text">{selectedQuestion.question}</h3>
                {(selectedQuestion.mediaUrl ?? '') !== '' ? (
                    <img
                        style={{
                            margin: '0 auto',
                            maxWidth: '100%',
                            maxHeight: '70vh',
                            objectFit: 'contain',
                        }}
                        src={selectedQuestion.mediaUrl} alt=""></img>
                ) : null}
                <div
                    style={{
                        padding: '0.5rem 0.1rem',
                        display: 'flex',
                        marginTop: 'auto',
                    }}
                 > {parseAnswer(selectedQuestion)} </div>
            </div>
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#eb7179',
            color: '#fff',
            borderRadius: '0 0 0.9rem 0.9rem',
            padding: '0.2rem 0.5rem',
            fontSize: '12px',
        }}>
          <div style={{ display: 'flex' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginRight: '10px',
                    maxWidth: '80%',
                    whiteSpace: 'nowrap',
                }}
             >{qTagParser(selectedQuestion.tags)}</div>
          </div>
          <div className="question-points" style={{padding: '3px', whiteSpace: 'nowrap'}}>
            {qTypeParser(selectedQuestion.type)},
            &nbsp;
            {selectedQuestion.category.subject}
            &nbsp;
            {selectedQuestion.category.grade > 10 ? 'Annet' : selectedQuestion.category.grade + '. klasse'},
            &nbsp;
            {selectedQuestion.points + ' poeng'}
          </div>
        </div>
    </div>
  </div>);
};

export default QuestionPreview;
