import React, { Component, useEffect, useState } from 'react';
import MapContainer from '../MapContainer/MapContainer';
//import { useNavigate } from "react-router-dom";
import { gameOverviewVariables } from '../../variables/variables';
import { parseAnswer, qTypeParser } from '../QuestionCategory/atoms/QuestionAnswer';
import { cancelDeleteButtonStyles } from '../GamesList/atoms/GamesModalStyles';
import banner from '../../../static/banner-poplar-folk.jpg';
import { FaQuestionCircle, FaTimesCircle } from 'react-icons/fa';
import { GAME_BUILDER } from '../../../constants/routes';

const QuestionRow = ({ questionPath, num, firebase }) => {
  const [question, setQuestion] = useState(undefined);
  const [error, setError] = useState(undefined);

  
  useEffect(() => {
    firebase
      .path(questionPath)
      .get()
      .then((result) => {
        const q = {
          ...result.data(),
          id: result.id,
        };
        setQuestion(q);
      })
      .catch((err) => {
        if (err.code === 'permission-denied') {
          setError('Fikk ikke tilgang til spørsmål');
        } else {
          setError('Uventet feil ved innlasting');
        }
      });
  }, [firebase, questionPath]);

  return (
    <div className="game-overview-question-container">
      <div className="game-overview-question-content">
        {error ? (
          <div><i>{error}</i></div>
        ) : question ? (
          <>
            <div className="game-overview-question-left">
              <div className="game-overview-question-number">
                {num}
              </div>
              <div className="game-overview-question-text">
                {question.question}
                <div className="game-overview-question-answer">
                  {parseAnswer(question)}
                </div>
              </div>
            </div>
            <div className="game-overview-question-right">
              <div className="game-overview-question-type-container">
                <div className="game-overview-question-type-icon" />
                <div className="game-overview-question-type">
                  {qTypeParser(question.type)}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>Laster...</div>
        )}
      </div>
    </div>
  );
};

const invalidState = (game) => {
  return (!game || !game.questions || !Array.isArray(game.questions));
};

export default class GameOverview extends Component {
  numberOfQuestions = (game) => {
    if (invalidState(game))
      return '0';

    return game.questions.length.toString();
  };

  questionArray = (game) => {
    if (invalidState(game))
      return [];

    return game.questions;
  };

  render() {
    //const navigate = useNavigate();
    //const navigave = (url) => {};

    return !invalidState(this.props.game) ? (
      <div className="game-overview-container">
        <div className="game-overview-content">
          {this.props.game.outside ? (
            <div className="game-overview-banner questions-container" style={{
              display: 'flex',
              paddingLeft: '0rem',
              height: '20rem',
              width: '100%',
              minWidth: '100%',
              position: 'inherit',
              overflow: 'inherit',
              minHeight: '20rem',
            }}>
              <div className={'questions-right'}>
                <MapContainer
                  questions={this.questionArray(this.props.game)}
                  gameRadius={this.props.game.radius}
                  finalDestination={this.props.game.finalDestination}
                  onDragEnd={undefined}
                  onDragEndFinalDestination={undefined}
                  onDrop={() => { /* Do nothing */ }}
                 />
              </div>
            </div>
          ) : (
            <div className="game-overview-banner">
              <img src={banner} alt="Poplar"></img>
            </div>
          )}
          <div className="game-overview-info-container">
            <div className="game-overview-name">
              {this.props.game.name}
            </div>
            <div className="game-overview-info">
              <div className="game-overview-time"></div>
              <div className="game-overview-numq">
                <FaQuestionCircle style={{
                  height: '1.1rem',
                  width: '0.8rem',
                  marginRight: '0.2rem',
                }}/>
                <div>{this.numberOfQuestions(this.props.game)}</div>
              </div>
              <div className="game-overview-outside">
                {this.props.game.outside ? 'UTE' : 'INNE'}
              </div>
            </div>
          </div>
          <div className="game-overview-questions">
            {this.questionArray(this.props.game).map((q, i) => {
              return (
                <QuestionRow
                  key={i + q.question.id}
                  questionPath={q.question.path}
                  num={i + 1}
                  firebase={this.props.firebase}
                />
              );
            })}
          </div>
          <div className="game-overview-footer">
            <div className="game-overview-button-container">
              <button
                className="game-overview-use-game-button"
                onClick={() =>
                  typeof window !== 'undefined' &&
                    navigate(`${GAME_BUILDER}?gameId=${this.props.game.id}&outside=${this.props.game.outside}`)
                }
              >
                {gameOverviewVariables.useGame}
              </button>
            </div>
          </div>
        </div>
        <button className={'modal-exit-button'} onClick={this.props.onRequestClose} style={cancelDeleteButtonStyles}>
          <FaTimesCircle style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
        </button>
      </div>
    ) : null;
  }
}
