import React from "react";
import { withFirebase } from "../../../../utils/Firebase";
import { GAME_BUILDER } from '../../../../constants/routes';

function handleSignOut(firebase) {
  const path = typeof window !== "undefined" ? window.location.pathname : "";
  if (firebase) {
    if (path === GAME_BUILDER &&
      !window.confirm(
        "Er du sikker på at du vil logge ut?\nDette vil slette alle ulagrede endringer!")) {
      return;
    }
    firebase.doSignOut();
  }
}

const SignOutButton = ({ firebase }) => (
  <div
    className="signout-btn"
    onClick={() => handleSignOut(firebase)}
    onKeyDown={(e) => e.key=="Enter" && handleSignOut(firebase)}
    role="button"
    tabIndex="0"
  >
    Logg ut
  </div>
);

export default withFirebase(SignOutButton);
