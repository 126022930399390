import React, { Component } from "react";
import moment from 'moment';
import { Marker } from '@react-google-maps/api';
import MapContainer from '../../MapContainer/MapContainer';

export class ScoreboardMap extends Component {
  generateMarkers = () => {
    
    return this.props.users.map((user, index) => {
      if (user.position && window.google && window.google.maps)
          return (
            <Marker
              key={'user_'+index}
              position={{ lat: user.position.latitude, lng: user.position.longitude }}
              className="map-marker"
              draggable={false}
              title={user.lastSeen
                ? user.teamName + ' var her for ' + moment(user.lastSeen.toDate()).fromNow()
                : user.teamName
              }
              label={Object.assign({
                text: user.teamName,
                className: 'markerText',
              })}
             />
          );
      return null;
    });
  };

  render() {
    return (
      <div className={'questions-right'}>
        <MapContainer
          questions={this.props.questions}
          onDragEnd={undefined}
          onDragEndFinalDestination={undefined}
          onDrop={() => { /* Do nothing */ }}
          onDropT={() => { /* Do nothing */ }}
          gameRadius={this.props.event.radius}
          finalDestination={this.props.event.finalDestination}
          generateCustomMarkers={this.generateMarkers}
         />
      </div>
    );
  }
}

export default ScoreboardMap;
