import React, {Component} from 'react';
import PasswordForgetForm from '../../../../molecules/PasswordForget/PasswordForget';

class PasswordForget extends Component {
    render() {
        return (
            <div style={{margin: '2rem 4rem', padding: '2rem 4rem'}}>
                <h1>Gl&oslash;mt passord</h1>
                <PasswordForgetForm/>
            </div>
        );
    }
}

export default PasswordForget;
