import React, { useState } from "react";
import Modal from 'react-modal';
import { MemberAccount } from "../../../molecules/AuthModules/MemberAccount";
import { DeleteModal } from "../../../molecules/DeleteModal/DeleteModal";
import Loading from '../../../atoms/Loading';
import moment from 'moment';
import { cancelDeleteButtonStyles } from '../../../molecules/GamesList/atoms/GamesModalStyles';
import {
  IoTrash,
  IoPencil,
  IoCloseCircle,
  IoSnow,
  IoSunny,
  IoCheckmarkDoneCircle,
} from "react-icons/io5";

const editMemberModalStyles = {
  content: {
    top: '20%',
    left: '30%',
    right: '30%',
    bottom: '100%',
    minHeight: '32rem',
    background: 'rgba(242, 241, 240, 1)',
    color: 'black',
    padding: '0.0rem',
    borderRadius: '1.0rem',
    border: 'none',
    boxShadow: '0px 4px 10px rgba(103, 107, 133, 0.15)',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(155, 155, 155, 0.75)',
  },
};

export function AdminCard(props) {
  const [loading, setLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  return loading ? ( <Loading /> ) : (
    <tr className="admin__user">
      <td style={{ fontWeight: 800, paddingLeft: '1em' }}>
        {props.admin.firstName} {props.admin.lastName}
      </td>
      <td className="admin__user__field">{props.admin.email}</td>
      <td className="admin__user__field">{
        props.admin.creationTime ? moment(new Date(props.admin.creationTime)).format("Do MMM YYYY") : 'Ukjent'}
      </td>
      <td className="admin__user__field" style={{ paddingLeft: '2em' }}>{props.admin.loginCount ?? '0'}</td>
      <td className="admin__user__field">{
        props.admin.lastSignInTime ? moment(new Date(props.admin.lastSignInTime)).fromNow() : 'Aldri'}
      </td>
      <td className="admin__user__field" style={{ paddingLeft: '2em' }}>{props.admin.superAdmin ? 'Ja' : 'Nei'}</td>
      <td className="admin__user__field" style={{ paddingRight: '1em' }}>
        <button
          style={{
            float: "right",
            outline: "none",
            borderStyle: "none",
            backgroundColor: "#F9F9F9",
            marginLeft: '1rem',
          }}
          onClick={() => setDeleteOpen(true)}
         >
          <IoTrash title="Slett" style={{height: '1.4rem', width: '1.4rem', cursor: 'pointer'}}/>
        </button>
        <DeleteModal
          isOpen={deleteOpen}
          title="Er du sikker?"
          buttonTitle="Slett bruker"
          onConfirm={() => {
            setLoading(true);
            props.handleDeleteMember(props.admin);
            setLoading(false);
            setDeleteOpen(false);
          }}
          onCancel={() => setDeleteOpen(false)}
        />
        <button
          style={{
            float: "right",
            outline: "none",
            borderStyle: "none",
            backgroundColor: "#F9F9F9",
          }}
          onClick={() => {
            setLoading(true);
            props.handleFreezeMember(props.admin);
            setLoading(false);
          }}
         >
          {props.admin.isDisabled ? (
          <IoSunny title="Aktiver tilgang" style={{height: '1.4rem', width: '1.4rem', cursor: 'pointer'}}/>
          ) : (
          <IoSnow title="Frys tilgang" style={{height: '1.4rem', width: '1.4rem', cursor: 'pointer'}}/>
          )}
        </button>
        <button
          style={{
            float: "right",
            outline: "none",
            borderStyle: "none",
            backgroundColor: "#F9F9F9",
          }}
          onClick={() => setEditOpen(true)}
         >
          <IoPencil title="Rediger" style={{height: '1.4rem', width: '1.4rem', cursor: 'pointer'}}/>
        </button>
        {props.admin.purchased ? (
          <IoCheckmarkDoneCircle title="Godkjent medlem" style={{height: '1.4rem', width: '1.4rem', cursor: 'help'}}/>
        ) : null}
        <Modal
          isOpen={editOpen}
          onRequestClose={() => setEditOpen(false)}
          ariaHideApp={false}
          style={editMemberModalStyles}
         >
          <MemberAccount
            authUser={props.currentUser}
            closeModal={() => setEditOpen(false)}
            userUid={props.admin.id}
           />
          <button onClick={() => setEditOpen(false)} style={cancelDeleteButtonStyles} className="modal-exit-button">
            <IoCloseCircle style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
          </button>
        </Modal>
      </td>
    </tr>
  );
}
