import React, { Component } from 'react';
import Modal from 'react-modal';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { withFirebase } from '../../../utils/Firebase';
import { copyGame } from '../../../utils/API/endpoints';
import { Link } from "react-router-dom";
// useNavigate
import GameOverview from '../../molecules/GameOverview/GameOverview';
import OverviewCards from '../../molecules/OverviewCards/OverviewCards';
import Scoreboard from '../../molecules/Scoreboard/Scoreboard';
import { GAME_BUILDER, MY_GAMES, MY_QUESTIONS } from '../../../constants/routes';
import { SCORE_PAGE } from '../../../constants/scoreboard';
import {
  modalStrings,
  names,
  overviewVariables,
} from '../../variables/variables';
import {
  customStylesModal,
  gameOverviewStyle,
  cancelDeleteButtonStyles,
  qrCodeModalStyles,
} from '../../molecules/GamesList/atoms/GamesModalStyles';
import gutta from '../../../static/banner-poplar-gutter.jpg';
import ipadgame from '../../../static/ipadgame.png';
import ipadute from '../../../static/ipadute.png';
//import poplar from '../../../static/poplar.png';
import { FaTimesCircle } from 'react-icons/fa';
import { withNavigation } from '../../../utils/navigation/context';
/*
const stringLimiter = (input, limit) => {
  if (typeof input !== 'string') return;
  if (input.length > limit) {
    return input.slice(0, limit) + '...';
  } else {
    return input;
  }
};*/

class Overview extends Component {
  state = {
    isLoading: true,
    errorMessage: '',
    isScoreboardVisible: false,
    displayScoreboardPage: SCORE_PAGE,
    isJoinEventInfoModalVisible: false,
    isGameOverviewModalVisible: false,
    selectedLibraryGame: null,
    selectedEvent: null,
    selectedEventCodeword: null,

    activeEvents: [],
    libraryGames: [],

    activeEventsLoading: true,

    events: [],

    searchedGames: [],
    isSearching: false,
    onTrial: false,
  };

  _initFirebase = false;

  activeEventsListenerUnsub = () => { /* Do nothing for now. */ };
  libraryGamesListenerUnsub = () => { /* Do nothing for now. */ };

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      //Fetch the active events
      const authUser = JSON.parse(localStorage.getItem('authUser'));
      this.activeEventsListenerUnsub = this.props.firebase
        .events(true, authUser.uid)
        .onSnapshot(
          (qs) => {
            var events = qs.docs.map((item) => {
              return { ...item.data(), id: item.id };
            });
            events.sort(function (left, right) {
              var leftHas = left.hasOwnProperty('created');
              var rightHas = right.hasOwnProperty('created');
              if (leftHas && rightHas) {
                return right.created.seconds
                  .toString()
                  .localeCompare(left.created.seconds.toString());
              }
              return leftHas ? -1 : rightHas ? 1 : 0;
            });
            this.setState({
              activeEvents: events,
              activeEventsLoading: false,
            });
          },
          (err) => {
            console.log(err);
            this.setState({
              errorMessage: "Firebase error! Cant't fetch active events",
            });
          },
        );

      this.libraryGamesListenerUnsub = this.props.firebase
        .gamesLibrary()
        .onSnapshot(
          (qs) => {
            this.setState({
              libraryGames: qs.docs.map((item) => {
                return { ...item.data(), id: item.id };
              }),
              isLoading: false,
            });
          },
          (err) => {
            this.setState({ errorMessage: err });
          },
        );
    }
  };

  componentDidMount() {
    this.firebaseInit();
    // const navigate = useNavigate();
    //const navigate = (url) => { console.log("trying to navigate to, but not set up: " + url);};


    // Someone has shared a game ID with the user and they've clicked the link.
    // Then copy the game and the questions to this user.
    const sharedGameId = localStorage.getItem('sharedGameId');
    if (sharedGameId) {
      // Clear the local storage so the game won't be copied more than once.
      localStorage.removeItem('sharedGameId');
      // Create a copy of the game for this user.
      copyGame(this.props.firebase, {gameId: sharedGameId})
      .then((_) => {
        window.alert(`Du har nå en kopi av spillet som var delt med deg.`);
        typeof window !== 'undefined' && this.props.navigate(`${MY_GAMES}`);
      })
      .catch((err) => {
        window.alert(`Kunne ikke kopiere spillet som var delt med deg.`);
        console.log(err);
      });
    }
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.activeEventsListenerUnsub();
    this.libraryGamesListenerUnsub();
  }

  handleSearchSimple = (text) => {
    if (typeof text !== 'string' || text.length < 1) {
      this.setState({
        isSearching: false,
      });
      return;
    }

    let filteredGames = [];
    this.state.libraryGames.forEach((game, i) => {
      //Here is the search logic. To lower case and remove spaces before and after text
      if (
        `${game.name}`
          .toLowerCase()
          .trim()
          .includes(text.toLowerCase().trim())
      ) {
        filteredGames.push(game);
      }
    });

    this.setState({
      searchedGames: filteredGames,
      isSearching: true,
    });
  };

  closeScoreboardModal = () => {
    this.setState({
      isScoreboardVisible: false,
    });
  };

  closeGameOverviewModal = () => {
    this.setState({
      isGameOverviewModalVisible: false,
    });
  };

  onOverviewCardClick = (event, page) => {
    this.setState({
      selectedEvent: event,
      isScoreboardVisible: true,
      displayScoreboardPage: page ?? SCORE_PAGE,
    });
  };

  onLibraryCardClick = (event) => {
    this.setState({
      selectedEvent: event,
      isGameOverviewModalVisible: true,
    });
  };

  onJoinEventInfoClick = (event) => {
    this.setState({
      isJoinEventInfoModalVisible: true,
      selectedEventCodeword: event.eventCode,
    });
  };

  closeJoinEventInfoModal = () => {
    this.setState({
      isJoinEventInfoModalVisible: false,
    });
  };

  render() {
    if (!this._initFirebase || this.state.activeEventsLoading) {
      return <div>Laster inn...</div>;
    }
    return (
      <>
      {this.state.activeEvents && <>
      <main className="main">
        <div className="overview container">
          <div className="main-header-container">
            <div className="main-header-top">
            <div className="head-container">
              <h1 className="active-events-title">
            {overviewVariables.activeGames}
          </h1>
            </div>
            </div>
            <div className="main-header-bottom">
              <p>
                {overviewVariables.activeGamesDescription}
              </p>
            </div>

          </div>
          
          
          <div className="overview-separator" />
          <OverviewCards
            events={this.state.activeEvents}
            active={true}
            onCardClick={this.onOverviewCardClick}
            onJoinEventInfoClick={this.onJoinEventInfoClick}
            firebase={this.props.firebase}
          />
          

          <Modal
            isOpen={this.state.isGameOverviewModalVisible}
            onAfterOpen={() => { /* Do nothing */ }}
            onAfterClose={() => this.setState({ selectedEvent: null })}
            onRequestClose={this.closeGameOverviewModal}
            contentLabel="Bruk på nytt"
            style={gameOverviewStyle}
            ariaHideApp={false}
          >
            <GameOverview
              game={this.state.selectedEvent}
              firebase={this.props.firebase}
              onRequestClose={this.closeGameOverviewModal}
            />
          </Modal>
          <Modal
            isOpen={this.state.isScoreboardVisible}
            onAfterOpen={() => { /* Do nothing */ }}
            onAfterClose={() => this.setState({ selectedEvent: null })}
            onRequestClose={this.closeScoreboardModal}
            contentLabel="Poengtabell"
            style={customStylesModal}
            ariaHideApp={false}
          >
            <Scoreboard
              event={this.state.selectedEvent}
              firebase={this.props.firebase}
              close={this.closeScoreboardModal}
              page={this.state.displayScoreboardPage}
            />
          </Modal>
          <Modal
            isOpen={this.state.isJoinEventInfoModalVisible}
            onAfterOpen={() => {}}
            onAfterClose={() => this.setState({ selectedEvent: null })}
            onRequestClose={this.closeJoinEventInfoModal}
            contentLabel="QrCode"
            style={qrCodeModalStyles}
            ariaHideApp={false}
          >
            <div ref={(el) => (this.componentRef = el)}>
              <img
              className='hide-mobile'
                style={{
                  width: '100%',
                  height: '10rem',
                  objectFit: 'cover',
                }}
                src={gutta}
                alt="Poplar-brukere"
              />
              <div className="qr-code-container">
                <p style={{ fontSize: '2rem', margin: '0.7rem' }}>
                  {modalStrings.howToPlay}
                </p>
                <p
                  className="hideFromPrint"
                  style={{ fontSize: '1.25rem', margin: '0.7rem' }}
                 >
                  {modalStrings.useCode}
                </p>
                <p
                  className="showInPrint"
                  style={{ fontSize: '1.25rem', margin: '0.7rem' }}
                 >
                  Skriv inn denne koden i appen:
                </p>
                <p
                  style={{
                    fontSize: '1.5rem',
                    color: '#EB7179',
                    margin: '0',
                  }}
                 >
                  &laquo;{this.state.selectedEventCodeword}&raquo;
                </p>
                <p style={{ fontSize: '1.25rem', margin: '2rem' }}>
                  Eller...
                </p>
                <p
                  className="hideFromPrint"
                  style={{ fontSize: '1.25rem' }}
                 >
                  {modalStrings.useQr}
                </p>
                <p
                  className="showInPrint"
                  style={{ fontSize: '1.25rem' }}
                 >
                  Skann denne QR koden med appen:
                </p>
                <img
                  src={encodeURI(
                    'https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=Poplar:' +
                      this.state.selectedEventCodeword,
                  )}
                  alt="QR-kode"
                 />
                <br />
                <ReactToPrint content={() => this.componentRef}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <button
                        className="hideFromPrint"
                        style={{
                          borderStyle: 'none',
                          backgroundColor: '#676B85',
                          margin: '2rem',
                          padding: '1rem 4rem',
                          borderRadius: '10rem',
                          color: 'white',
                        }}
                        onClick={handlePrint}
                       >
                        Print
                      </button>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
              <button className={'hideFromPrint modal-exit-button'} onClick={this.closeJoinEventInfoModal} style={cancelDeleteButtonStyles}>
                <FaTimesCircle className={'hideFromPrint'} style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
              </button>
            </div>
          </Modal>
        </div>
        </main>
        </>}

        <div className="main">
          <div className="container">
            <div className="main-header-container">
              <div className="main-header-top">
              <div className="head-container">
                <h1 className="active-events-title">
                  Velkommen til Poplar
                </h1>
              </div>
            </div>
            <div className='main-header-bottom'>
              <p>Her er en oversikt over hvordan du bruker Poplar</p>
              </div>

            </div>
            <div className='content-container promo-container'>




            {/* <Link to={MY_GAMES} className='promo-card'>
            <div className=''>
              <div className='image'>
                    <img src={gutta} alt="Poplar-brukere" />
                  </div>
                <div className='inner with-image'>
                  
                  <div className='head'>
                    <h2>Hvordan lage et spill</h2>
                  </div>
                  <div className='content'>
                    <p>Denne enkle veiledningen viser hvordan du enkelt lager et spill med Poplar.</p>
                    <div className='button'>Vis veiledning</div>
                  </div>
                </div>
              </div></Link>
*/}
              <Link to={MY_GAMES} className='promo-card'>
              <div>
              <div className='image'>
                    <img src={ipadgame} alt="Poplar-brukere" />
                  </div>
                <div className='inner with-image'>
                  <div className='head'>
                    <h2>{names.libraryQuestions}</h2>
                  </div>
                  <div className='content'>
                    <p>{names.mygamesPageSubtitle}</p>
                    <div className='button'>Mine spill</div>
                  </div>
                </div>
              </div>
              </Link>

              <Link to="/library" className='promo-card'>
              <div className=''>
              <div className='image'>
                    <img src={ipadute} alt="Poplar-brukere" />
                  </div>
                <div className='inner with-image'>
                  <div className='head'>
                    <h2>{names.libraryTitle}</h2>
                  </div>
                  <div className='content'>
                    <p>{names.libraryPageSubtitle}</p>
                    <div className='button'>Spillbibliotek</div>
                  </div>
                </div>
              </div>
              </Link>

              <Link to={MY_QUESTIONS} className='promo-card'>
              <div className=''>
                <div className='image'>
                    <img src={gutta} alt="Poplar-brukere" />
                  </div>
                <div className='inner with-image'>
                  <div className='head'>
                    <h2>{names.questionName}</h2>
                  </div>
                  <div className='content'>
                    <p>{names.questionPageSubtitle}</p>
                    <div className='button'>Spørsmål</div>
                  </div>
                </div>
              </div>
              </Link>
              

              
            <Link to={GAME_BUILDER} className='promo-card'>
            <div className=''>
          
                <div className='inner'>
                  
                  <div className='head'>
                    <h2>Lag ditt eget spill</h2>
                  </div>
                  <div className='content'>
                    <p>Kom i gang med å lage ditt eget spill. Du kan velge spørsmål fra forhåndslagede Poplar-spørsmål eller lage egne spørsmål.</p>
                    <div className='button'>Lag nytt spill</div>
                  </div>
                </div>
              </div></Link>
              
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withNavigation(withFirebase(Overview));
