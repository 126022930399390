import React from 'react';
import { compose } from 'recompose';
import Layout from '../utils/layout';
import MyGames from '../components/scenes/MyGames/MyGames';

import {
  withAuthorization,
  withEmailVerification,
} from '../utils/Session';

const condition = (authUser) => !!authUser;
const MyGamesPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(MyGames);

export default () => (
  <Layout>
    <MyGamesPage />
  </Layout>
);
