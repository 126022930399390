import React, { Component } from 'react';
import Modal from 'react-modal';

import { GAME_BUILDER } from '../../../constants/routes';
import { withFirebase } from '../../../utils/Firebase';
import {
  deleteGame,
  shareGame,
  deleteEvent,
} from '../../../utils/API/endpoints';
import GameOverview from '../../molecules/GameOverview/GameOverview';
import GamesList from '../../molecules/GamesList/GamesList';
import CreateEvent from '../../molecules/CreateEvent/CreateEvent';
import DeleteModal from '../../molecules/DeleteModal/DeleteModal';
import Scoreboard from '../../molecules/Scoreboard/Scoreboard';
import ShareGame from '../../molecules/ShareGame/ShareGame';
import {
  names,
  modalStrings,
  buttonStrings,
} from '../../variables/variables';
import {
  deleteModalStyles,
  customStylesModal,
  gameOverviewStyle,
} from '../../molecules/GamesList/atoms/GamesModalStyles';
import LibraryList from '../../molecules/GamesList/LibraryList';
import { withNavigation } from '../../../utils/navigation/context';

class LibraryPage extends Component {
  state = {
    //page management
    errorMessage: '',
    games: [],
    inactiveEvents: [],
    unusedGames: [],
    libraryGames: [],
    selectedGame: undefined,
    isActivateGameModalOpen: false,
    isScoreboardModalOpen: false,
    isSearching: false,
    searchTerm: '',

    isShowGameModalOpen: false,
    isDeleteEventModalOpen: false,
    eventToDelete: null,
    isDeleteGameModalOpen: false,
    gameToDelete: null,
    isShareGameModalOpen: false,

    gameId: null,
    eventId: null,
    urlGame: null,
  };
  _initFirebase = false;
  gamesListenerUnsub = () => { /* Do nothing right now. */ };
  libraryGamesListenerUnsub = () => { /* Do nothing right now. */ };
  inactiveEventsListenerUnsub = () => { /* Do nothing right now. */ };

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      const authUser = JSON.parse(localStorage.getItem('authUser'));
      this.gamesListenerUnsub = this.props.firebase
        .games(authUser.uid)
        .onSnapshot(
          (qs) => {
            var gameList = qs.docs.map((item) => {
              return {
                ...item.data(),
                id: item.id,
              };
            });
            gameList.sort(function (left, right) {
              var leftHas = left.hasOwnProperty('created');
              var rightHas = right.hasOwnProperty('created');
              if (leftHas && rightHas) {
                return right.created.seconds
                  .toString()
                  .localeCompare(left.created.seconds.toString());
              }
              return leftHas ? -1 : rightHas ? 1 : 0;
            });
            this.setState({
              games: gameList,
              loading: false,
              selectedGame: undefined,
              gameId: gameList[0]?.id || '',
            });
          },
          (err) => {
            // Kunne ikke laste inn dine spill.
            this.setState({ errorMessage: err });
          },
        );

      this.libraryGamesListenerUnsub = this.props.firebase
        .gamesLibrary()
        .onSnapshot(
          (qs) => {
            this.setState({
              libraryGames: qs.docs.map((item) => {
                return { ...item.data(), id: item.id };
              }),
              loading: false,
            });
          },
          (err) => {
            // Kunne ikke laste inn spill fra Poplar-biblioteket.
            this.setState({ errorMessage: err });
          },
        );

      this.inactiveEventsListenerUnsub = this.props.firebase
        .events(false, authUser.uid)
        .onSnapshot(
          (qs) => {
            this.setState({
              inactiveEvents: qs.docs.map((item) => {
                return { ...item.data(), id: item.id };
              }),
              inactiveEventsLoading: false,
            });
          },
          (err) => {
            // Kunne ikke laste inn dine avslutta spill.
            this.setState({ errorMessage: err });
          },
        );
      this.getUrlGame();
    }
  };

  async getUrlGame() {
    let params = new URLSearchParams(
      document.location.search.substring(1),
    );
    let gID = params.get('gameId');
    if (gID != null) {
      await this.setState({
        gameId: gID,
        selectedGame: undefined,
        isActivateGameModalOpen: true,
      });
      this.getGame(gID);
    }
    let eID = params.get('eventId');
    if (eID != null) {
      await this.setState({
        eventId: eID,
        selectedGame: undefined,
        isActivateGameModalOpen: true,
      });
    }
  };

  getGame = (gameId) => {
    this.props.firebase
      .game(gameId)
      .get()
      .then((game) => {
        if (game === null && game.data() === null) return;
        const gameData = game.data();
        this.setState({
          urlGame: gameData,
        });
      });
  };

  componentDidMount() {
    this.firebaseInit();
  }

  onShowGameInfo = (selectedObject) => {
    this.setState({
      selectedGame: selectedObject,
      gameId: selectedObject.id,
      isShowGameModalOpen: true,
    });
  };

  onCloseGameInfo = () => {
    this.setState({
      isShowGameModalOpen: false,
      selectedGame: null,
      gameId: null,
    });
  };

  onEditGame = (game) => {
    typeof window !== "undefined" &&
      this.props.navigate(`${GAME_BUILDER}?gameId=${game.id}&outside=${game.outside}&update=true`);
  };

  onShareGame = (game) => {
    this.setState({
      isShareGameModalOpen: true,
      selectedGame: game,
    });
  };

  onSendEmail = (game, email) => {
    shareGame(this.props.firebase, {
        gameUri: `https://app.poplar.no/?share=true&gameId=${game.id}`,
        gameName: game.name,
        gameVenue: (game.outside ? "utendørs" : "innendørs"),
        gameQuestions: `${game.questions.length}`,
        senderName: this.props.firebase.auth.currentUser.displayName ?? 'En venn',
        recipientEmail: email,
    });
    this.setState({
      isShareGameModalOpen: false,
      selectedGame: null,
    });
  };

  onDeleteGame = (game) => {
    this.setState({
      isDeleteGameModalOpen: true,
      gameToDelete: game,
    });
  };

  onEventShowInfo = (selectedObject) => {
    this.setState({
      selectedGame: selectedObject,
      gameId: selectedObject.id,
      isScoreboardModalOpen: true,
    });
  };

  onEventActivate = (selectedObject) => {
    //console.log(selectedObject);
    this.setState({
      selectedGame: selectedObject,
      gameId: selectedObject.id,
      isActivateGameModalOpen: true,
    });
  };

  onDeleteEvent = (event) => {
    this.setState({
      isDeleteEventModalOpen: true,
      eventToDelete: event,
    });
  };

  componentDidUpdate(_, prevState) {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.gamesListenerUnsub();
  }

  searchOnChange = (input) => {
    if (input && typeof input === 'string' && input.length > 0) {
      this.setState({
        isSearching: true,
        searchTerm: input.trim(),
      });
    } else {
      this.setState({
        isSearching: false,
        searchTerm: '',
      });
    }
  };

  filterGames = (games) => {
    //Only display unused games
    let unusedGames = games.filter(
      (game) =>
        !this.state.inactiveEvents
          .map((event) => event.source)
          .includes(game.id),
    );

    if (!this.state.isSearching) return unusedGames;
    const lowerFilter = (input, filter) =>
      input.toLowerCase().indexOf(filter.toLowerCase()) > -1;
    return unusedGames.filter((s) => {
      return lowerFilter(s.name, this.state.searchTerm);
    });
  };

  filterEvents = (events) => {
    if (!this.state.isSearching) return events;
    const lowerFilter = (input, filter) =>
      input.toLowerCase().indexOf(filter.toLowerCase()) > -1;
    return events.filter((s) => {
      return lowerFilter(s.name, this.state.searchTerm);
    });
  };

  closeScoreboardModal = () => {
    this.setState({
      isScoreboardModalOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      isActivateGameModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isActivateGameModalOpen: false,
    });
  };

  render() {
    if (!this._initFirebase || this.state.loading)
      return <div>Laster inn...</div>;
    return (
      <main className="main">
        <div className='games-container container'>
          
              
      
            <LibraryList
              title={names.libraryQuestions}
              libraryTitle={names.libraryTitle}
              userGames={this.filterGames(this.state.games)}
              userEvents={this.filterEvents(this.state.inactiveEvents)}
              libraryGames={this.state.libraryGames}
              selectedObject={this.state.selectedGame}
              searchOnChange={this.searchOnChange}
              onGameShowInfo={this.onShowGameInfo}
              onGameEdit={this.onEditGame}
              onGameShare={this.onShareGame}
              onGameDelete={this.onDeleteGame}
              onGameActivateEvent={this.onEventActivate}
              onEventShowInfo={this.onEventShowInfo}
              onEventActivate={this.onEventActivate}
              onEventDelete={this.onDeleteEvent}
              isSuperAdmin={this.props.firebase.superAdmin}
             />
          

          {/* Game creator part 2, options and activate game */}
      
          <Modal
            isOpen={this.state.isActivateGameModalOpen}
            onRequestClose={this.closeModal}
            contentLabel={modalStrings.activateQuestion}
            //style={customStyles}
            ariaHideApp={false}
           >
            <CreateEvent
              selectedObject={this.state.selectedGame}
              gameId={this.state.gameId}
              eventId={this.state.eventId}
              firebase={this.props.firebase}
              handleRequestClose={this.closeModal}
             />
          </Modal>

          <div
            style={{display: this.state.isActivateGameModalOpen ? 'block':'none', position: 'fixed', backgroundColor: 'white'}}
           >
            <CreateEvent
              selectedObject={this.state.selectedGame}
              gameId={this.state.gameId}
              eventId={this.state.eventId}
              firebase={this.props.firebase}
              handleRequestClose={this.closeModal}
             />
          </div>


          <Modal
            isOpen={this.state.isScoreboardModalOpen}
            onRequestClose={this.closeScoreboardModal}
            contentLabel={modalStrings.showQuestion}
            ariaHideApp={false}
            style={customStylesModal}
           >
            <Scoreboard
              event={this.state.selectedGame}
              firebase={this.props.firebase}
              close={() => this.closeScoreboardModal()}
             />
          </Modal>

          {/*Delete game modal*/}
          <DeleteModal
            isOpen={this.state.isDeleteGameModalOpen}
            title="Er du sikker?"
            buttonTitle={buttonStrings.deleteGame}
            onConfirm={() => {
              deleteGame(this.props.firebase, {
                'uid': this.state.gameToDelete.id,
                'gameId': this.state.gameToDelete.id,
              });
              this.setState({
                isDeleteGameModalOpen: false,
                gameToDelete: null,
              });
            }}
            onCancel={() => this.setState({
              isDeleteGameModalOpen: false,
              gameToDelete: null,
            })}
           />

          {/*Delete event modal*/}
          <DeleteModal
            isOpen={this.state.isDeleteEventModalOpen}
            title="Er du sikker?"
            buttonTitle={buttonStrings.deleteGame}
            onConfirm={() => {
              deleteEvent(this.props.firebase, {
                'uid': this.state.eventToDelete.id,
                'eventId': this.state.eventToDelete.id,
              });
              this.setState({
                isDeleteEventModalOpen: false,
                eventToDelete: null,
              });
            }}
            onCancel={() => this.setState({
              isDeleteEventModalOpen: false,
              eventToDelete: null,
            })}
           />

          { /* Show library game information. */ }
          <Modal
            isOpen={this.state.isShowGameModalOpen}
            onRequestClose={this.onCloseGameInfo}
            contentLabel="Opplysninger"
            style={gameOverviewStyle}
            ariaHideApp={false}
           >
            <GameOverview
              game={this.state.selectedGame}
              firebase={this.props.firebase}
              onRequestClose={this.onCloseGameInfo}
             />
          </Modal>

          { /* Share a game. */ }
          <Modal
            isOpen={this.state.isShareGameModalOpen}
            onRequestClose={() => this.setState({
              isShareGameModalOpen: false,
              selectedGame: null,
            })}
            ariaHideApp={false}
            style={deleteModalStyles}
           >
            <ShareGame
              onConfirm={this.onSendEmail}
              onCancel={() => this.setState({
                isShareGameModalOpen: false,
                selectedGame: null,
              })}
              game={this.state.selectedGame}
             />
          </Modal>
        </div>
      </main>
    );
  }
}

export default withNavigation(withFirebase(LibraryPage));
