import React, { Component } from 'react';
import Modal from 'react-modal';
import { withFirebase } from '../../../utils/Firebase';
import { Button } from '../../molecules/NewQuestionForm/atoms/Button';
import NewQuestionForm from '../../molecules/NewQuestionForm/NewQuestionForm';
import QuestionPreview from '../../molecules/QuestionCategory/molecules/QuestionPreview';
import QuestionCard from '../../molecules/QuestionCategory/molecules/QuestionCard';
import { DeleteModal } from '../../molecules/DeleteModal/DeleteModal';
import { IoCheckmark, IoAdd } from "react-icons/io5";
import {
  filterName,
  languages,
  names,
  questionGroups,
  subjectGroups,
  categoryOptions,
} from '../../variables/variables';
import {
  customStyles,
  languageButtonSelectedStyle,
  languageButtonStyle,
  tagButtonSelectedStyle,
  tagButtonStyle,
  filterClassButtonSelectedStyle,
  filterClassButtonStyle,
  previewStyle,
} from "./MyQuestionsModalStyles";
import firebase from 'firebase/compat/app';

class MyQuestions extends Component {



  state = {
    //Questions
    userQuestionList: [],
    libraryQuestionList: [],

    // Modal dialogue boxes
    isPreviewQuestionModalOpen: false,
    isNewQuestionModalOpen: false,
    isEditQuestionModalOpen: false,
    isDeleteQuestionModalOpen: false,

    //page management
    errorMessage: '',
    canEditQuestion: false,
    selectedQuestion: null,
    questionToDelete: null,

    isSearching: false,
    searchTerm: '',

    language: [false, false],
    class: [false],
    subject: [false],
    tags: [false],
    filtering: false,
    filteredQuestions: [],
    filteredLibrary: [],
    filteringClasses: false,
    filteringSubjects: false,
    filteringTags: false,
    filteringLanguage: false,

    availableTags: [],

    mobileFilterOpen: false,
  };
  _initFirebase = false;
  userDocListenerUnsub = () => { /* Do nothing */ };
  questionListenerUnsub = () => { /* Do nothing */ };
  libraryListenerUnsub = () => { /* Do nothing */ };
  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      const authUser = JSON.parse(localStorage.getItem('authUser'));

      this.userDocListenerUnsub = this.props.firebase
        .admins()
        .doc(authUser.uid)
        .onSnapshot(
          (doc) => {
            if (!doc.exists) {
              this.setState({
                availableTags: [],
              });
            } else {
              const data = doc.data();
              this.setState({
                availableTags: data.tags ? data.tags : [],
              });
            }
          },
          (err) => {
            console.log(err);
          },
        );
      this.questionListenerUnsub = this.props.firebase
        .questions(authUser.uid)
        .onSnapshot(
          (qs) => {
            var questionList = qs.docs.map((item) => {
              return {
                ...item.data(),
                id: item.id,
              };
            });
            questionList.sort(function (left, right) {
              const leftHas = left.hasOwnProperty('created');
              const rightHas = right.hasOwnProperty('created');
              if (leftHas && rightHas) {
                return right.created.seconds
                  .toString()
                  .localeCompare(left.created.seconds.toString());
              }
              return leftHas ? -1 : rightHas ? 1 : 0;
            });
            this.setState({
              userQuestionList: questionList,
              loading: false,
            });
          },
          (_err) => {
            this.setState({
              errorMessage: 'Firebase error fetching questions',
            });
          },
        );
      this.libraryListenerUnsub = this.props.firebase
        .library()
        .onSnapshot(
          (qs) => {
            this.setState({
              libraryQuestionList: qs.docs.map((item) => {
                return { ...item.data(), id: item.id };
              }),
              loading: false,
            });
          },
          (_err) => {
            this.setState({
              errorMessage: 'Firebase error fetching library',
            });
          },
        );
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    if (this.userDocListenerUnsub !== undefined) {
      this.userDocListenerUnsub();
    }
    if (this.questionListenerUnsub !== undefined) {
      this.questionListenerUnsub();
    }
    if (this.libraryListenerUnsub !== undefined) {
      this.libraryListenerUnsub();
    }
  }

  componentDidUpdate(_, prevState) {
    this.firebaseInit();
    if (this.state.errorMessage.length > 0) {
      console.log("Error: " + this.state.errorMessage);
      this.setState({
        errorMessage: '',
      });
    }
  }

  closeModal = () => {
    this.setState({
      isNewQuestionModalOpen: false,
      isEditQuestionModalOpen: false,
      isPreviewQuestionModalOpen: false,
      isDeleteQuestionModalOpen: false,
      canEditQuestion: false,
      selectedQuestion: null,
      questionToDelete: null,
    });
  };

  openNewQuestionModal = () => {
    this.closeModal();
    this.setState({
      isNewQuestionModalOpen: true,
    });
  };

  openQuestionPreviewModal = (question) => {
    this.closeModal();
    this.setState({
      isPreviewQuestionModalOpen: true,
      canEditQuestion: false,
      selectedQuestion: question,
    });
  };

  openEditQuestionModal = (question) => {
    this.closeModal();
    this.setState({
      isEditQuestionModalOpen: true,
      canEditQuestion: true,
      selectedQuestion: question,
    });
  };

  openDeleteQuestionModal = (question) => {
    this.closeModal();
    this.setState({
      isDeleteQuestionModalOpen: true,
      questionToDelete: question,
    });
  };

  addQuestionToLibrary = (question) => {
    const original = question.owner;
    this.props.firebase.question(question.id)
    .update({
        owner: 'library',
        originalOwner: original,
    })
    .catch((err) => {
        console.log(err);
    });
  }

  searchOnChange = (input) => {
    if (input && typeof input === 'string' && input.length > 0) {
      this.setState({
        isSearching: true,
        searchTerm: input.trim(),
      });
    } else {
      this.setState({
        isSearching: false,
        searchTerm: '',
      });
    }
  };

  applySearchFilter = (questions) => {
    if (!this.state.isSearching) return questions;
    const lowerFilter = (input) => {
      if (!input) return false;
      if (typeof input === 'string')
        return (
          input
            .toLowerCase()
            .indexOf(this.state.searchTerm.toLowerCase()) > -1
        );
      if (Array.isArray(input)) {
        for (let i in input) {
          const result =
            i
              .toLowerCase()
              .indexOf(this.state.searchTerm.toLowerCase()) > -1;
          if (result) return true;
        }
      }
    };
    return questions.filter((s) => {
      return lowerFilter(s.question) || lowerFilter(s.answer);
    });
  };

  isFiltering = () => {
    this.setState({ filteringSubjects: false });
    this.setState({ filteringClasses: false });
    this.setState({ filteringTags: false });
    this.setState({ filteringLanguage: false });
    let checkIfFiltering = false;
    //Check if any buttons clicked
    for (let i = 0; i < this.state.language.length; i++) {
      if (this.state.language[i]) {
        this.setState({ filteringLanguage: true });
        checkIfFiltering = true;
      }
    }
    for (let i = 0; i < this.state.subject.length; i++) {
      if (this.state.subject[i]) {
        this.setState({ filteringSubjects: true });
        checkIfFiltering = true;
      }
    }
    for (let i = 0; i < this.state.class.length; i++) {
      if (this.state.class[i]) {
        this.setState({ filteringClasses: true });
        checkIfFiltering = true;
      }
    }
    for (let i = 0; i < this.state.tags.length; i++) {
      if (this.state.tags[i]) {
        this.setState({ filteringTags: true });
        checkIfFiltering = true;
      }
    }
    return checkIfFiltering;
  };

  activateFilter = async () => {
    const filterAct = this.isFiltering();
    await this.setState({
      filtering: filterAct,
    });
    if (filterAct) {
      const filterLib = this.filterQuestions(this.state.libraryQuestionList);
      const filterOwn = this.filterQuestions(this.state.userQuestionList);
      await this.setState({
        filteredLibrary: filterLib,
        filteredQuestions: filterOwn,
      });
    }
  };

  removeTagHandler = async (index) => {
    const tag = this.state.availableTags[index];
    if (tag && tag.length > 0) {
      if (window.confirm("Er du sikker på at du vil sletta taggen '"+tag+"'?")) {
          const authUser = JSON.parse(localStorage.getItem('authUser'));
          await this.props.firebase
            .admins()
            .doc(authUser.uid)
            .update({
              tags: firebase.firestore.FieldValue.arrayRemove(tag),
          });
          // Remove the tag from the list of available tags...
          const temp = this.state.availableTags.filter(data => data !== tag);
          // ...then update the list and...
          await this.setState({
            availableTags: temp,
          });
          // ...finally toggle it off.
          this.toggleTag(index);
      }
    }
  };

  toggleLanguage = async (index) => {
    const languageCopy = this.state.language.slice();
    languageCopy[index] = !languageCopy[index];
    await this.setState({
      language: languageCopy,
    });
    await this.activateFilter();
  };

  toggleClass = async (index) => {
    const classCopy = this.state.class.slice();
    classCopy[index] = !classCopy[index];
    await this.setState({
      class: classCopy,
    });
    await this.activateFilter();
  };

  toggleSubject = async (index) => {
    //console.log("toggle " + index + " to " + !this.state.subject[index]);
    const subjectCopy = this.state.subject.slice();
    subjectCopy[index] = !subjectCopy[index];
    await this.setState({
      subject: subjectCopy,
    });
    await this.activateFilter();
  };

  toggleTag = async (index) => {
    const tagsCopy = this.state.tags.slice();
    tagsCopy[index] = !tagsCopy[index];
    await this.setState({
      tags: tagsCopy,
    });
    await this.activateFilter();
  };

  tagKeyHandler = async (event, index) => {
    switch (event.keyCode) {
    case 8: // Backspace
    case 46: // Delete
      if (this.state.tags[index] === true) {
        await this.removeTagHandler(index);
      }
      break;
    case 13: // Enter
    case 32: // Spacebar
      await this.toggleTag(index);
      break;
    default:
      // Do nothing.
      break;
    }
  };

  filterQuestions = (questions) => {
    let questionSubset = [];

    if (this.state.filteringClasses) {
      let classes = this.state.class;
      for (let i = 0; i < questions.length; i++) {
        if (classes[0] && questions[i].category.grade === 30) {
          questionSubset.push(questions[i]);
        }
        for (let j = 1; j < classes.length; j++) {
          if (classes[j] && questions[i].category.grade === j) {
            questionSubset.push(questions[i]);
          }
        }
      }
    }

    //Filter on subjects next
    if (this.state.filteringSubjects) {
      questionSubset = this.filterQuestionsSubject(
        questionSubset.length > 0 ? questionSubset : questions
      );
    }

    if (this.state.filteringLanguage) {
      questionSubset = this.filterLanguage(
        questionSubset.length > 0 ? questionSubset : questions
      );
    }

    if (this.state.filteringTags) {
      questionSubset = questionSubset.length > 0 ? questionSubset : questions;
      const tags = this.state.availableTags.filter(
        (_, i) => this.state.tags[i]
      );
      questionSubset = questionSubset.filter((fq) => {
        for (let i = 0; i < fq.tags.length; i++) {
          if (tags.includes(fq.tags[i])) {
            return true;
          }
        }
        return false;
      });
    }
    //console.log(`Questions in filter subset: ${questionSubset.length}`);
    return questionSubset;
  };

  filterQuestionsSubject = (questions) => {
    const subjects = this.state.subject;
    let filteredQuestions = [];
    for (const q of questions) {
      for (const s in categoryOptions) {
        if (subjects[s] === true && q.category.subject === categoryOptions[s].value) {
          filteredQuestions.push(q);
        }
      }
    }
    this.setState({ update: true });
    return filteredQuestions;
  };

  filterLanguage = (questions) => {
    const language = this.state.language;
    let filteredQuestions = [];
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].language === 'bokmål') {
        if (language[0]) filteredQuestions.push(questions[i]);
      } else if (questions[i].language === 'nynorsk') {
        if (language[1]) filteredQuestions.push(questions[i]);
      }
    }
    this.setState({ update: true });
    return filteredQuestions;
  };

  generateTags = (availableTags) => {
    if (Array.isArray(availableTags)) {
      return availableTags.map((availableTagName, i) => {
        return (
          <button
            key={i}
            style={
              this.state.tags[i]
                ? tagButtonSelectedStyle
                : tagButtonStyle
            }
            onClick={() => this.toggleTag(i)}
            onKeyDown={(e) => this.tagKeyHandler(e,i)}
            tabIndex="0"
          >
            {availableTagName}
          </button>
        );
      });
    } else return null;
  };

  render() {
    if (!this._initFirebase || this.state.loading)
      return <div>Laster inn...</div>;
    return (
      <main className="main">
        <div className='my-questions container'>
          <div className='main-header-container'>
            <div className='main-header-top'>
              <div className='head-container'>
                <h1>{names.questionName}</h1>
              </div>
              <div className="search-bar-container">
                <input
                  placeholder="Søk i spørsmål..."
                  className="my-questions-search-bar"
                  onChange={(e) => this.searchOnChange(e.target.value)}
                  style={{
                    padding: '10px',
                    width: '100%',
                    
                    borderRadius: '10px',
                    
                  }}
                />
              </div>
            </div>
            <div className='main-header-bottom'>
              <p>{names.questionPageSubtitle}</p>
            </div>
          </div>
          <div className='content-container'>
            <div className='my-questions-container'>
              <div className='mobile-filter-button' onClick={() => this.setState({ mobileFilterOpen: !this.state.mobileFilterOpen })}>Vis/skjul filter</div>
              <div className={`filter-container ${this.state.mobileFilterOpen ? '' : 'mobile-hide-filter'}`}>
                <h2>
                  {filterName.name}
                </h2>

                <p style={{ margin: '0.2rem 0px 0px 0px' }}>
                  {languages.name}
                </p>
                <div style={{ display: 'flex' }}>
                  {languages.language.map((lng, idx) => (
                    <div
                      id={lng + idx}
                      key={lng + idx}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0 2rem 0 0',
                      }}
                     >
                      <button
                        style={
                          this.state.language[idx]
                            ? languageButtonSelectedStyle
                            : languageButtonStyle
                        }
                        onClick={() => this.toggleLanguage(idx)}
                        onKeyDown={() => this.toggleLanguage(idx)}
                        tabIndex="0"
                       >
                        <IoCheckmark style={{width: '1.15rem', height: '1.15rem', margin: '0.27rem -0.1rem', WebkitFilter: 'invert(1)'}} />
                      </button>
                      <p>{lng}</p>
                    </div>
                  ))}
                </div>
                <p
                  style={{
                    fontSize: '1.3rem',
                    margin: '0.6rem 0px 0.3rem 0px',
                  }}
                >
                  {questionGroups.name}
                </p>
                {questionGroups.groups.gradeOptions.map(
                  (groupName, idx) => (
                    <button
                      key={'group_key_' + idx}
                      style={
                        this.state.class[idx]
                          ? filterClassButtonSelectedStyle
                          : filterClassButtonStyle
                      }
                      onClick={() => this.toggleClass(idx)}
                      onKeyDown={() => this.toggleClass(idx)}
                      tabIndex="0"
                     >
                      {groupName.label}
                    </button>
                  ),
                )}
                <p
                  style={{
                    fontSize: '1.3rem',
                    margin: '0.6rem 0px 0.3rem 0px',
                  }}
                 >
                  {subjectGroups.name}
                </p>
                {subjectGroups.subjects.categoryOptions.map(
                  (subject, idx) => (
                    <div
                      id={subject + idx}
                      key={subject + idx}
                      style={{
                        display: 'flex',
                        width: '20rem',
                        alignItems: 'center',
                      }}
                     >
                      <p
                        style={{
                          width: '16.3rem',
                          margin: '10px 0px',
                        }}
                       >
                        {subject.label}
                      </p>
                      <button
                        style={
                          this.state.subject[idx]
                            ? languageButtonSelectedStyle
                            : languageButtonStyle
                        }
                        onClick={() => this.toggleSubject(idx)}
                        onKeyDown={() => this.toggleSubject(idx)}
                        tabIndex="0"
                       >
                        <IoCheckmark style={{width: '1.15rem', height: '1.15rem', margin: '0.27rem -0.1rem', WebkitFilter: 'invert(1)'}} />
                      </button>
                    </div>
                  ),
                )}
                <p
                  style={{
                    fontSize: '1.3rem',
                    margin: '0.6rem 0px 0.3rem 0px',
                  }}
                 >
                  Tags
                </p>
                {this.generateTags(this.state.availableTags)}
                {this.state.filteringTags ? (
                  <p
                    style={{
                      fontSize: '0.6rem',
                      margin: '0.6rem 0px 0.3rem 0px',
                    }}
                 >
                  &#8505; Tags kan slettes med å trykke på <i>Backspace</i>- eller <i>Delete</i>-tasten når de er valgt.
                </p>) : null}
              </div>
              <div className='questions'>
              <div>
                    <p style={{ fontSize: '1.4rem' }}>
                      Mine spørsmål
                    </p>
                    </div>
                    <div style={{ display: 'flex' }}>
                    
                    <Button
                      styleOverride={{
                        height: 'fit-content',
                        backgroundColor: '#EB7179',
                        borderRadius: '20px',
                        boxShadow:
                          '0px 4px 10px rgba(235, 113, 121, 0.5)',
                        whiteSpace: 'nowrap',
                        fontSize: '0.9rem',
                        padding: '0.7rem 0.8rem',
                        transitionDuration: '200ms',
                        margin: 'auto 0px auto auto',
                      }}
                      handler={this.openNewQuestionModal}
                      text={
                        <div>
                          Nytt spørsmål
                          <IoAdd style={{height: '0.8rem', width: '0.8rem', marginLeft: '0.5rem'}}/>
                        </div>
                      }
                     />
                  </div>
                <div className='box'>
                  
                  <div className='list'>
                    {!this.state.filtering &&
                      this.applySearchFilter(
                        this.state.userQuestionList,
                      ).map((q, i) => {
                        return (
                          <QuestionCard
                             key={'qcard_key_' + q.id}
                             selectedQuestion={q}
                             selectedQuestionIndex={i + 1}
                             openQuestionPreview={this.openQuestionPreviewModal}
                             closeQuestionPreview={this.closeModal}
                             editQuestion={this.openEditQuestionModal}
                             deleteQuestion={this.openDeleteQuestionModal}
                             isEditable={true}
                             isDeletable={true}
                             isSuperAdmin={this.props.firebase.superAdmin === true}
                             isAllQuestionsListMode={false}
                             addQuestionToLibrary={this.addQuestionToLibrary}
                           />
                        );
                      })}
                  </div>
                  <div className='filterlist'>
                    {this.state.filtering &&
                      this.applySearchFilter(
                        this.state.filteredQuestions,
                      ).map((q, i) => {
                        return (
                          <QuestionCard
                             key={'qcard_key_' + q.id}
                             selectedQuestion={q}
                             selectedQuestionIndex={i + 1}
                             openQuestionPreview={this.openQuestionPreviewModal}
                             closeQuestionPreview={this.closeModal}
                             editQuestion={this.openEditQuestionModal}
                             deleteQuestion={this.openDeleteQuestionModal}
                             isEditable={true}
                             isDeletable={true}
                             isSuperAdmin={this.props.firebase.superAdmin === true}
                             isAllQuestionsListMode={false}
                             addQuestionToLibrary={this.addQuestionToLibrary}
                           />
                        );
                      })}
                  </div>
                </div>
                <div>
                    <p style={{ fontSize: '1.4rem' }}>
                      Poplar-spørsmål
                    </p>
                    </div>
                <div className='box'>
                  
                  <div className='list'>
                    {!this.state.filtering &&
                      this.applySearchFilter(this.state.libraryQuestionList).map(
                        (q, i) => {
                          return (
                          <QuestionCard
                             key={'qcard_lib_key_' + q.id}
                             selectedQuestion={q}
                             selectedQuestionIndex={i + 1}
                             openQuestionPreview={this.openQuestionPreviewModal}
                             closeQuestionPreview={this.closeModal}
                             editQuestion={this.openEditQuestionModal}
                             deleteQuestion={this.openDeleteQuestionModal}
                             isEditable={true}
                             isDeletable={this.props.firebase.superAdmin === true}
                             isSuperAdmin={this.props.firebase.superAdmin === true}
                             isAllQuestionsListMode={false}
                           />
                          );
                        },
                      )}
                  </div>
                  <div className='filterlist'>
                    {this.state.filtering &&
                      this.applySearchFilter(
                        this.state.filteredLibrary,
                      ).map((q, i) => {
                        return (
                          <QuestionCard
                             key={'qcard_lib_key_' + q.id}
                             selectedQuestion={q}
                             selectedQuestionIndex={i + 1}
                             openQuestionPreview={this.openQuestionPreviewModal}
                             closeQuestionPreview={this.closeModal}
                             editQuestion={this.openEditQuestionModal}
                             deleteQuestion={this.openDeleteQuestionModal}
                             isEditable={true}
                             isDeletable={this.props.firebase.superAdmin === true}
                             isSuperAdmin={this.props.firebase.superAdmin === true}
                             isAllQuestionsListMode={false}
                           />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: '2rem' }}></div>
        <Modal
          isOpen={this.state.isNewQuestionModalOpen}
          onAfterOpen={() => { /* Do nothing */ }}
          onRequestClose={this.closeModal}
          contentLabel="Nytt spørsmål"
          style={customStyles}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
         >
          {this.state.isNewQuestionModalOpen ? (
            <NewQuestionForm
              modalExitButtonHandler={this.closeModal}
              edit={this.state.canEditQuestion}
              selectedQuestion={this.state.selectedQuestion}
              firebase={this.props.firebase}
              handleModalExit={() => this.closeModal()}
              reopenModal={() => this.openNewQuestionModal}
             />
          ) : null}
        </Modal>
        <Modal
          isOpen={this.state.isPreviewQuestionModalOpen}
          onAfterOpen={() => { /* Do nothing */ }}
          onRequestClose={this.closeModal}
          contentLabel="Spørsmål"
          style={previewStyle}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
         >
          {this.state.isPreviewQuestionModalOpen && this.state.selectedQuestion ? (
              <QuestionPreview
                 key={'preview_key_' + this.state.selectedQuestion.id}
                 selectedQuestion={this.state.selectedQuestion}
                 closeQuestionPreview={this.closeModal}
               />
          ) : null}
        </Modal>
        {/*Edit question modal*/}
        <Modal
          isOpen={this.state.isEditQuestionModalOpen}
          onAfterOpen={() => { /* Do nothing */ }}
          onRequestClose={this.closeModal}
          contentLabel="Nytt spørsmål"
          style={customStyles}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
        >
          {this.state.isEditQuestionModalOpen ? (
            <NewQuestionForm
              modalExitButtonHandler={this.closeModal}
              edit={this.state.canEditQuestion}
              selectedQuestion={this.state.selectedQuestion}
              firebase={this.props.firebase}
              handleModalExit={() => this.closeModal()}
              reopenModal={() => this.openNewQuestionModal}
            />
          ) : null}
        </Modal>
        <DeleteModal
          isOpen={this.state.isDeleteQuestionModalOpen}
          title="Er du sikker?"
          buttonTitle="Slett spørsmål"
          onConfirm={() => {
            this.props.firebase
              .question(this.state.questionToDelete.id)
              .delete();
            this.setState({
              isPreviewQuestionModalOpen: false,
              isDeleteQuestionModalOpen: false,
              eventToDelete: null,
            });
          }}
          onCancel={() => {
            this.setState({
              isPreviewQuestionModalOpen: false,
              isDeleteQuestionModalOpen: false,
              eventToDelete: null,
            });
          }}
        />
      </main>
    );
  }
}

export default withFirebase(MyQuestions);
