export const LANDING = '/';
export const SIGN_IN = '/signin';
export const REGISTER = '/register';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_STATS = '/admin/stats';
export const ADMIN_LIBRARY = '/admin/library';
//export const ADMIN_DETAILS = '/admin/:id';

export const FEIDE = '/feide';

export const OVERVIEW = '/overview';
export const GAME_BUILDER = '/game-builder';
export const MY_GAMES = '/my-games';
export const EVENT = '/event';
export const MY_QUESTIONS = '/my-questions';
