import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import { SIGN_IN } from '../../constants/routes';

const withAuthorization = (condition) => (Component) => {
  const WithAuthorization = (props) => {
    const navigate = useNavigate();
    //const navigate = (path) => { console.log("navigating to: " + path);}

    useEffect(() => {
      const firebaseInit = async () => {
        //console.log('withAuthorization', props.firebase, condition);
        if (props.firebase) {
          const authUser = await props.firebase.onAuthUserListener();
          //console.log('authUser', authUser)
          if (!condition(authUser)) {
            //console.log('no condition', authUser);
            navigate(SIGN_IN);
          }
        }
      };

      firebaseInit();

      return () => {
        //props.firebase && props.firebase.onAuthUserListener().off();
      };
    }, [props.firebase, condition, navigate]);

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (condition(authUser) ? <Component {...props} /> : null)}
      </AuthUserContext.Consumer>
    );
  };

  return withFirebase(WithAuthorization);
};

export default withAuthorization;
