import React from 'react';
import Layout from '../utils/layout';
import SignIn from '../components/scenes/Auth/scenes/SignIn/SignIn';

// Check if someone has shared a game ID with us. If so, store it until
// the user has logged, and then trigger the copy. See 'Overview' for
// the copy logic.
// Done this way because Gatsby doesn't preserve search parameters.
if (typeof window !== 'undefined') {
  const params = new URLSearchParams(document.location.search);
  if (params.has('share') && params.has('gameId')) {
    localStorage.setItem('sharedGameId', params.get('gameId'));
  }
}

export default () => (
  <Layout hideNav>
    <SignIn />
  </Layout>
);
