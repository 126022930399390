import React, { Component } from 'react';
import { Input } from '../atoms/Input';
import { Button } from '../atoms/Button';
import { LanguageRadio } from '../atoms/LanguageRadio';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 3px;
`;

const generateTags = (tags, availableTags, clickHandler, removeHandler) => {
  let checkedTags = {};
  availableTags.forEach((t) => {
    checkedTags[t] = false;
  });
  tags.forEach((t) => {
    checkedTags[t] = true;
  });

  const keyHandler = (event, target) => {
    switch (event.keyCode) {
    case 8: // Backspace
    case 46: // Delete
      removeHandler(target);
      break;
    case 13: // Enter
    case 32: // Spacebar
      clickHandler(target);
      break;
    default:
      // Do nothing.
      break;
    }
  };

  return Object.keys(checkedTags).map((t, i) => {
    return (
      <div className="tag-manager-tag-container" key={i}>
        <span
          className={
            'tag-manager-tag' + (checkedTags[t] ? ' checked' : '')
          }
          checked={checkedTags[t]}
          onClick={(_) => clickHandler(t)}
          onKeyDown={(e) => keyHandler(e, t)}
          aria-checked={checkedTags[t]}
          role="checkbox"
          tabIndex="0"
        >
          {t}
        </span>
      </div>
    );
  });
};

export default class TagManager extends Component {
  state = {
    newTag: '',
  };

  handleNewTagInput = (input) => {
    this.setState({
      newTag: input,
    });
  };

  render() {
    return (
      <div className="tag-manager-container">
        <Container className="tag-manager">
          <div className="tag-manager-top">
            <div className="tag-manager-new-tag-container">
              <span className="tag-manager-new-tag-label">
                Emneknagger
              </span>
              <div className="tag-manager-new-tag">
                <Input
                  placeholder="Tagnavn"
                  handler={this.handleNewTagInput}
                  value={this.state.newTag}
                  className="tag-manager-input"
                  styleOverride={{
                    border: 'none',
                    padding: '.4rem',
                    minWidth: '6rem',
                  }}
                />
                <Button
                  text="+"
                  handler={() =>
                    this.props.handleAddTagFirebase(this.state.newTag)
                  }
                  className="tag-manager-button"
                  styleOverride={{
                    background: 'rgba(22, 27, 30, 0.5)',
                  }}
                />
              </div>
            </div>

            <div className="tag-manager-language-selector">
              <span className="tag-manager-language-selector-label">
                Velg målform
              </span>
              <fieldset
                onChange={(e) =>
                  this.props.handleLanguageChange(e.target.value)
                }
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  border: 'none',
                  padding: '0',
                  height: '35px',
                }}
              >
                <LanguageRadio
                  name="page-radio"
                  value="bokmål"
                  label="Bokmål"
                  checked={this.props.language === 'bokmål'}
                />
                <LanguageRadio
                  name="page-radio"
                  value="nynorsk"
                  label="Nynorsk"
                  checked={this.props.language === 'nynorsk'}
                />
                
              </fieldset>
            </div>
          </div>

          <div className="tag-manager-tags-container">
            {generateTags(
              this.props.tags,
              this.props.availableTags,
              this.props.handleTagClick,
              this.props.handleRemoveTagFirebase,
            )}
          </div>
        </Container>
      </div>
    );
  }
}
