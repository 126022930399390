import React, {useState} from 'react';

import moment from 'moment';
import { inactiveEventCardVariables } from '../../../variables/variables';
import {
    FaTree,
    FaHome,
    FaLightbulb,
    FaPlayCircle,
    FaQuestionCircle,
    FaClock, //FaSyncAlt
} from 'react-icons/fa';
import { GAME_BUILDER, EVENT } from '../../../../constants/routes';
import getFirebase from '../../../../utils/Firebase';
import { useNavigate } from 'react-router';

const gNameParser = (name) => {
  if (name) {
    return name;
  } else {
    return 'Feil: Data ikke funnet';
  }
};

const gCreatedParser = (e) => {
  if (typeof e.created != 'undefined') {
    const today = e.created.toDate();
    return (
      String(today.getDate()).padStart(2, '0') +
      '.' +
      String(today.getMonth() + 1).padStart(2, '0') +
      '.' +
      today.getFullYear()
    );
  }
  return '-';
};


export default function InactiveEventCard({
  sourceEvent,
  isSelected,
  isDeletable,
  onEventActivate,
  onEventReActivate,
  onEventShowInfo,
  onEditEvent,
  onEventDelete,
  onShowQRcode,
}) {
  const navigate = useNavigate();
  //const navigate = (path) => { console.log("navigating to: " + path);}
   
  const handleCreateNewEvent = () => {
    if (localStorage.getItem('hasTrialPeriodExpired') === true) {
      typeof window !== 'undefined' && window.alert(
        `Du kan ikke aktivere spillet fordi prøvetiden din er gått ut. Vennligst ta kontakt med Poplar.`);
    } else {
      typeof window !== 'undefined' &&
        navigate(`${GAME_BUILDER}?eventId=${sourceEvent.id}&outside=${sourceEvent.outside}`);
    }
  };

  const onEventClick = (sourceEvent) => {
    if (localStorage.getItem('hasTrialPeriodExpired') === true) {
      typeof window !== 'undefined' && window.alert(
        `Du kan ikke aktivere spillet fordi prøvetiden din er gått ut. Vennligst ta kontakt med Poplar.`);
    } else {
      typeof window !== 'undefined' &&
        navigate(`${EVENT}?eventId=${sourceEvent.id}`);
    }
  };

  const [end, setEnd] = useState(false);
  const [ending, setEnding] = useState(false);
  
const clickFinish = (event) => {
  setEnding(true);
  const firebase = getFirebase();
  const call = firebase.call('endEvent');
  //setState({ending: false});
  return call({ eventId: event.id });
  setEnding(false);
};

  return (
    <div className={'game-card-container game-card-list ' + (isSelected ? ' selected' : '') + (ending ? ' faded' : '')}>
      
      <div className='top'>
      <div className="game-card-title"
       onClick={() => {console.log("onEventShowInfo", typeof navigate);onEventShowInfo(sourceEvent, navigate)}}
       onKeyDown={(e) => e.key==="Enter" && onEventShowInfo(sourceEvent, navigate)}
       role="button"
       tabIndex="0"
        >
          {(sourceEvent.name)}
        </div>

        
        <div className='top-right'>
        <div className="game-card-code">
            Spillkode: { sourceEvent.eventCode}
          </div>
        <div className='date'>
          
           {gCreatedParser(sourceEvent)}
        </div>

        </div>
        </div>

      <div className='bottom'>
        
        <div className='card-icons'>
           <div className={`icon-container ${sourceEvent.outside ? 'outside' : (sourceEvent?.type === "live") ? 'live' : 'inside'}`} >
             <div className='icon'>
               {sourceEvent.outside ?  
                 <FaTree /> : (sourceEvent?.type === "live") ? 
                 <FaLightbulb /> : 
                 <FaHome /> }
             </div>
             <div className='text'>{sourceEvent.outside ? 'UTE' : (sourceEvent?.type === "live") ? 'LIVE' : 'INNE'}</div>
           </div>
           
           {sourceEvent.active && <>
           <div className={`icon-container play`}>
             <div className='icon'>
               
                   <FaPlayCircle />
                   </div>
               <div className='text'>
                 Aktivt
               
             </div>
           </div>
           </>}

           {(sourceEvent.type !== "live" &&  sourceEvent.type !== "allemoten") && <>
              <div className=" icon-container">
                <div className='icon'>
                <FaClock /></div>
                <div className="overview-event-card-time-container text">
                  {moment.duration(sourceEvent.timelimit * 1000).asMinutes() + 'min'}
                </div>
              </div>
            </>}

           <div
             className=" icon-container">
             {/* Antall spørsmål pluss ikon */}
             <div className="overview-event-card-questions-icon icon">
               <FaQuestionCircle />
             </div>
             <div className="overview-event-card-questions-num text">
               {sourceEvent.questions
                 ? sourceEvent.questions.length
                 : 0}
             </div>
           </div>



       </div> 



        <div className='actions'>
          
        {(sourceEvent.type !== "live" || (sourceEvent.type === "live" && sourceEvent.state === "finished")) && <>
          <div className='button' 
              onClick={(e) => onEventShowInfo(sourceEvent, navigate)}
              onKeyDown={(e) => e.key==="Enter" && onEventShowInfo(sourceEvent, navigate)}
              role="button"
              tabIndex="0" >Resultater</div></>}

{sourceEvent.type === "live" && sourceEvent.state != "finished" && <>
          <div className='button' 
              onClick={(e) => onEventClick(sourceEvent)}
              onKeyDown={(e) => e.key==="Enter" && onEventClick(sourceEvent)}
              role="button"
              tabIndex="0" >Spill</div>
</>}
            
            
            {!sourceEvent.active && <>

              <div className='button' 
                onClick={() => onEventReActivate(sourceEvent)}
                onKeyDown={(e) => e.key==="Enter" && onEventReActivate(sourceEvent)}
                role="button" title="Bruk på nytt"
                tabIndex="0">Bruk på nytt</div>
              
             
{/* 
              <div className='button' 
                onClick={() => handleCreateNewEvent(sourceEvent)}
                onKeyDown={(e) => e.key==="Enter" && handleCreateNewEvent(sourceEvent)}
                role="button"
                tabIndex="0">Endre1</div> */}

              { isDeletable && (
              <div className='button' 
                onClick={() => onEventDelete(sourceEvent)}
                onKeyDown={(e) => e.key==="Enter" && onEventDelete(sourceEvent)}
                role="button"
                tabIndex="0">Slett resultater</div>
              )}

            </>}
            {sourceEvent.active && <>
              <div className='button qr' 
                onClick={() => onShowQRcode(sourceEvent)}
                onKeyDown={(e) => e.key==="Enter" && onShowQRcode(sourceEvent)}
                role="button"
                tabIndex="0" >Vis QR-kode</div>

            <div className='button' 
              onClick={(e) => onEditEvent(sourceEvent, navigate)}
              onKeyDown={(e) => e.key==="Enter" && onEditEvent(sourceEvent, navigate)}
              role="button" 
              tabIndex="0"  >Endre</div>
              
              
              <div className='button' 
              onClick={() => setEnd(true)} 
              onKeyDown={(e) => e.key==="Enter" && setEnd(true)}
              role="button" 
              tabIndex="0" >Avslutt</div>
              <div className={`confirm-overlay ${end ? '' : 'hide'}`}>
                <div className='text'><strong>{(sourceEvent.name)}</strong><br />Er du sikker på at du vil avslutte dette spillet? </div>
                <div className='buttons'>
                  <div className='button' role="button" tabIndex="0" 
                    onClick={() => {clickFinish(sourceEvent); setEnd(false)}} >
                      Bekreft</div>
                  <div className='button' role="button" onClick={() => setEnd(false)}>Avbryt</div>
                </div>
              </div>

            </>}
          </div>
        
       
        </div>
    </div>
  );
}
