import React from 'react';
import { Input } from '../NewQuestionForm/atoms/Input';
import { Button } from '../NewQuestionForm/atoms/Button';
import { createGameVariables } from '../../variables/variables';
import { FaHome, FaLightbulb, FaPencilAlt, FaTree } from 'react-icons/fa';
import { useNavigate } from 'react-router';


const updateValue = () => {
  var slider = document.getElementById('create-game-range-slider');
  var output = document.getElementById('create-game-range-display');
  output.innerHTML = slider.value;

  slider.oninput = function () {
    output.innerHTML = this.value;
  };
};

const hidden = {
  position: 'absolute',
  opacity: '0',
  height: '0',
  width: '0',
};

const labelStyle = {
  width: 'fit-content',
  display: 'flex',
  fontSize: '14px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  color: 'white',
};

const checkmarkStyle = {
  display: 'flex',
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  margin: 'auto',
  marginRight: '5px',
  border: '1px solid #FFF',
  background: '#FFF',
  boxSizing: 'border-box',
  justifyContent: 'center',
  alignItems: 'center',
};

const checkmarkDotStyle = {
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  background: '#EB7179',
};



function getButtonLabel(isSaving, isUpdating) {
  if (isUpdating && isSaving)
    return "Oppdaterer...";
  else if (isUpdating && !isSaving)
    return "Oppdater";
  else if (!isUpdating && isSaving)
    return "Oppretter...";
  else if (!isUpdating && !isSaving)
    return "Lagre og opprett";
}

export default function CreateGame({
  type,
  outside,
  outsideHandler,
  error,
  name,
  sliderValue,
  nameHandler,
  loading,
  sliderHandler,
  buttonHandler,
  isUpdatingGame,
  isUpdatingEvent,
}) {
  const navigate = useNavigate();
  //const navigate = (path) => { console.log("navigating to: " + path);}
  const [changeGame, setChangeGame] = React.useState(false);
  

  const IORadio = ({
    name,
    value,
    label,
    defaultChecked = false,
    checked,
    onClickHandler,
    style,
  }) => {
    return (
      <div
        className="io-radio-container"
        onClick={() => {onClickHandler(value);setChangeGame(false)}}
        
        style={{ ...labelStyle, ...style }}
        role="button"
        tabIndex="0"
      >
        <div
          className="io-radio-checkmark"
          style={{ ...checkmarkStyle }}
        >
          {checked ? (
            <div
              className="io-radio-checkmark-dot"
              style={checkmarkDotStyle}
            />
          ) : null}
        </div>
        <input
          type="radio"
          name={name}
          value={value}
          style={hidden}
          defaultChecked={defaultChecked}
        />
        {label}
      </div>
    );
  };


  return (
    <>
    <div className="create-game-container">
      <div className="create-game-name-container">
        <div style={{ position: 'relative', width: '100%' }}>
          <Input
            placeholder={createGameVariables.gameName}
            value={name}
            handler={nameHandler}
           />
            
        </div>
      </div>
      <div className='game-type-container'>
        {/*<label style={{ color: 'white', fontSize: '1.2em' }}>
          {createGameVariables.gameType}
        </label>*/}
        <div className='game-type-inner'>
          
            <div className={`icon-container  ${outside ? 'outside' : type === "live" ? 'live' : 'inside'}`} 
            onClick={() => setChangeGame(!changeGame)}
            
            role="button"
            tabIndex="0">
             <div className='icon'>
               {outside ?  
                 <FaTree /> : type === "live" ? 
                 <FaLightbulb /> : 
                 <FaHome /> }
             </div>
             <div className='text'>{outside ? 'UTE' : type === "live" ? 'LIVE' : 'INNE'}-SPILL</div>
           </div>
           {type !== "live" && <>
           <div className={`icon-container outline link`}
            onClick={() => setChangeGame(!changeGame)}
            
            role="button"
            tabIndex="0"
            >
             <div className='icon'>
                 <FaPencilAlt /> 
             </div>
             <div className='text'>Endre</div>
           </div>

           <div className='change-game-container' style={{display: changeGame ? 'block' : 'none'}}>
            <div className='text'>Her kan du endre spilltypen.</div>
            <div className='buttons'>
              <IORadio
                name="io-radio"
                value={true}
                label="Ute"
                checked={outside}
                onClickHandler={outsideHandler}
                style={{ marginRight: '0.75rem' }}
              />
              <IORadio
                name="io-radio"
                value={false}
                label="Inne"
                checked={!outside && type !== "live"}
                onClickHandler={outsideHandler}
              />
              
            </div>
            <div className={`x`}
            onClick={() => setChangeGame(!changeGame)}
            
            role="button"
            tabIndex="0"
            >x</div>
          </div>
          </>}
          
        </div>
      </div>

      <div
        className="slider-container"
        style={outside ? { display: 'block' } : { display: 'none' }}
      >
        <div className="slider-title">{'Radius'}: <span id="create-game-range-display">{sliderValue ?? 10}</span>
          {' meter'}</div>
        <input
          type="range"
          min="5"
          max="50"
          defaultValue={sliderValue ?? 10}
          className="slider"
          onMouseUp={(e) => sliderHandler(e.target.value)}
          onInput={(_) => updateValue()}
          id="create-game-range-slider"
        />
        <div>
          
        </div>
      </div>
      <div className="create-game-button-container">
        <Button
          className="green"
          text={getButtonLabel(loading, (isUpdatingGame || isUpdatingEvent))}
          handler={(_) => buttonHandler(navigate)}
          styleOverride={{
            margin: '0rem',
            borderRadius: '2.0rem',
            padding: '0.70rem',
          }}
        />
      </div>
    </div>
    {error && error.length > 0 ? (
      <div className="create-game-container error-message create-game-error-text">{error}</div>
    ) : null}
    </>
  );
}
