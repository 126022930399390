import React, { Component } from 'react';
import Modal from 'react-modal';
import ScoreboardQuestionList from './molecules/ScoreboardQuestionList';
import ScoreboardTable from './molecules/ScoreboardTable';
import ScoreboardPhotos from './atoms/ScoreboardPhotos';
import ScoreboardMap from './molecules/ScoreboardMap';
import QuestionMoreInfo from './molecules/QuestionMoreInfo';
import { scoreboardVariables } from '../../variables/variables';
import { cancelDeleteButtonStyles } from '../GamesList/atoms/GamesModalStyles';
import Loading from '../../atoms/Loading';
import { FaTimesCircle } from 'react-icons/fa';
import { QUESTION_PAGE, PHOTO_PAGE, MAP_PAGE, SCORE_PAGE } from '../../../constants/scoreboard';

const questionInformationModalStyle = {
  content: {
    top: '2%',
    left: '2%',
    right: '2%',
    bottom: '2%',
    background: 'rgb(235, 235, 235)',
    padding: '0px',
    color: 'black',
    border: 'none',
    margin: '5rem 10rem',
    boxShadow: '0px 10px 30px rgba(103, 107, 133, 0.15)',
  },
};

export class Scoreboard extends Component {
  state = {
    errorMessage: '',
    isLoading: true,
    isQuestionInformationModalVisible: false,
    visiblePage: SCORE_PAGE,
    selectedQuestion: null,
    //Users
    userResults: [],
  };

  eventUsersListenerUnsub = () => {};

  componentDidMount() {
    //Initial page is the scoreboard itself
    this.setPage(this.props.page ?? SCORE_PAGE);

    let users = [];

    console.log("scoreboard didmount eventid",this.props.event.id);

    //Load the teams / users
    this.eventUsersListenerUnsub = this.props.firebase
      .event(this.props.event.id)
      .collection('users')
      .onSnapshot(
        (qs) => {
          users = [];
          if(qs) qs.forEach(item => {
            // only add user if complete user
            // testing: add as long as teamName is defined 
            //if(item.data().startTime !== undefined && item.data().teamName !== undefined && item.data().timeUsed !== undefined)
            if(item.data().teamName !== undefined)
              users.push({...item.data(), id: item.id});
          })
          // update state with new users
          this.setState({
            userResults: users,
            isLoading: false,
          });
          
        },
        (err) => {
          console.log(err);
          this.setState({
            errorMessage: 'Feil ved innlasting av brukere',
            isLoading: false,
          });
        },
      );
  }

  componentWillUnmount() {
    this.eventUsersListenerUnsub();
  }

  setPage = (n) => {
    this.setState({
      visiblePage: n,
    });
  };

  closeQuestionInfo = () => {
    this.setState({
      isQuestionInformationModalVisible: false,
      selectedQuestion: null,
    });
  };

  openQuestionInfo = (question) => {
    this.setState({
      isQuestionInformationModalVisible: true,
      selectedQuestion: question,
    });
  };

  render() {
    
    return (
      <div className="scoreboard-container">
        <div className='scoreboard-header'>
          <div className='header-container'>
            <button className={'modal-exit-button'} onClick={this.props.close} style={cancelDeleteButtonStyles}>
              <FaTimesCircle style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
            </button>
            <div className='text'>
              <h2>
                {this.props.event.name}
              </h2>
              <p>
                {scoreboardVariables.gameResults}
              </p>
            </div>
            <div className='pres'>
              <div className='pres-inner'>
                O
              </div>
              <button className='pres-button'>
                {'Presenter resultater >'}
              </button>
            </div>
          </div>
        </div>
        <div className='scoreboard-menu'>
          <button className={`button ${this.state.visiblePage === QUESTION_PAGE ? 'button-active': ''}`}
            onClick={() => this.setPage(QUESTION_PAGE)} >
            Spørsmål
          </button>
          <button className={`button ${this.state.visiblePage === PHOTO_PAGE ? 'button-active': ''}`}
            onClick={() => this.setPage(PHOTO_PAGE)} >
            Bilder
          </button>
          {this.props.event.outside ? (
          <button className={`button ${this.state.visiblePage === MAP_PAGE ? 'button-active': ''}`}
            onClick={() => this.setPage(MAP_PAGE)} >
            Kart
          </button>
          ) : null }
          <button className={`button ${this.state.visiblePage === SCORE_PAGE ? 'button-active': ''}`}
            onClick={() => this.setPage(SCORE_PAGE)} >
            Resultater
          </button>
        </div>
        {this.state.isLoading ? (
          <Loading />
        ) : (<>
        <div className="scoreboard-top"></div>
        {this.state.visiblePage === QUESTION_PAGE && (
          <div className="scoreboard-content">
            <div className="scoreboard-left">
              <ScoreboardQuestionList
                firebase={this.props.firebase}
                title={this.props.event.name}
                key={this.props.event.name}
                questions={this.props.event.questions}
                users={this.state.userResults}
                onSeeMoreClick={this.openQuestionInfo}
              />
            </div>
          </div>
        )}
        {this.state.visiblePage === PHOTO_PAGE && (
          <div className="scoreboard-content" style={{ display: 'block' }}>
            {/*Todo: has to have selectedQuestion to show images*/}
            {this.props.event.questions.map((q, i) => (
              <ScoreboardPhotos
                key={'image_'+i}
                users={this.state.userResults}
                question={q}
                firebase={this.props.firebase}
               />
            ))}

            {/*<h2>{this.props.event.eventCode}</h2>*/}
          </div>
        )}
        {this.props.event.outside && this.state.visiblePage === MAP_PAGE && (
          <div className="scoreboard-content questions-container" style={{
            display: 'block',
            position: 'absolute',
            top: '10rem',
            left: '0rem',
            paddingLeft: '0rem',
          }}>
            <ScoreboardMap
              key={'map_'+this.props.event.id}
              users={this.state.userResults}
              event={this.props.event}
              questions={this.props.event.questions}
             />
          </div>
        )}
        {this.state.visiblePage === SCORE_PAGE && (
          <div className="scoreboard-content">
              <div className="scoreboard-right">
                <ScoreboardTable
                  key={'results_'+this.props.event.id}
                  users={this.state.userResults}
                  event={this.props.event}
                  questions={this.props.event.questions}
                 />
              </div>
          </div>
        )}
        </>)}

        <Modal
          isOpen={this.state.isQuestionInformationModalVisible}
          onRequestClose={this.closeQuestionInfo}
          contentLabel="Spørsmålsinformasjon"
          style={questionInformationModalStyle}
          ariaHideApp={false}
         >
          <QuestionMoreInfo
            question={this.state.selectedQuestion}
            users={this.state.userResults}
            onCloseWindow={this.closeQuestionInfo}
           />
        </Modal>
      </div>
    );
  }
}

export default Scoreboard;
