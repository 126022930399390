/**
 * This data form is currently not used because it triggered endless recursion by
 * the NavigationAuth. Consider using it instead as a dialog for letting the user
 * edit their own information (also add "Change Password" here?)
 */
import React, { useEffect, useState, useCallback } from 'react';
import useFirebase from '../../../hooks/useFirebase';
import { Button } from '../NewQuestionForm/atoms/Button';
import { TextField } from '../NewQuestionForm/atoms/TextField';
import Loading from '../../atoms/Loading';
import {
  fetchMember,
  updateMember,
} from '../../../utils/API/endpoints';
import { IoCheckmark } from "react-icons/io5";

const CircleCheckbox = ({ text, selected, handler }) => {
  const circleCheckboxSelectedStyle = {
    height: '2rem',
    minHeight: '2rem',
    width: '2rem',
    minWidth: '2rem',
    borderStyle: 'none',
    backgroundColor: '#161B1E',
    border: '2px solid #161B1E',
    borderRadius: '99rem',
    marginRight: '1rem',
    outline: 'none',
  };

  const circleCheckboxUnselectedStyle = {
    height: '2rem',
    minHeight: '2rem',
    width: '2rem',
    minWidth: '2rem',
    borderStyle: 'none',
    backgroundColor: '#FFF',
    border: '2px solid #161B1E',
    borderRadius: '99rem',
    marginRight: '1rem',
    outline: 'none',
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: '0rem 2rem 0rem 0rem' }}>
      <button
        style={ selected ? circleCheckboxSelectedStyle : circleCheckboxUnselectedStyle }
        onClick={() => handler(!selected)}>
        <IoCheckmark style={{width: '1.15rem', margin: '0.27rem -0.1rem', WebkitFilter: 'invert(1)'}} />
      </button>
      <p>{text}</p>
    </div>
  );
};

export function MemberAccount({ authUser, closeModal, userUid }) {
  const firebase = useFirebase();
  const [error, setError] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [member, setMember] = useState(null);

  const loadMemberData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchMember(firebase, userUid ?? authUser.uid);
      const data = response['data']['admin'];
      setMember(data);
    } catch (err) {
        console.log(`Error reading user ${userUid ?? authUser.uid}\n===>${err}`);
        setMember({
            id: '',
            firstName: '',
            lastName: '',
            email: '',
            organisation: '',
            isTeacher: false,
            className: null,
        });
    }
    setLoading(false);
  }, [firebase, authUser, userUid]);

  useEffect(() => {
    if (!firebase) return;
    if (!member) loadMemberData();
  }, [firebase, member, authUser, loadMemberData]);

  function validate() {
/* Can't use this since not all fields are visible or editable.
    for (const val of Object.keys(member)) {
      if (member[val] === '') {
        setValidationError('\nVennligst fyll ut alle felt.');
        return false;
      }
    }
*/
    setValidationError(null);
    if (member['firstName'] === undefined || member['firstName'] === '') {
        setValidationError('\nVennligst fyll ut fornavn.');
        return false;
    }
    if (member['lastName'] === undefined || member['lastName'] === '') {
        setValidationError('\nVennligst fyll ut etternavn.');
        return false;
    }
    if (member['organisation'] === undefined || member['organisation'] === '') {
        setValidationError('\nVennligst fyll ut organisasjon.');
        return false;
    }
    if (member['isTeacher'] === true && (member['className'] === undefined || member['className'] === '')) {
        setValidationError('\nVennligst fyll ut klassenavn.');
        return false;
    }
    return true;
  }

  return (
    <div className='settingsbox'>
      <h3>Opplysinger</h3>
      {loading || !member ? (
        <Loading />
      ) : (
        <>
            <div style={{ display: 'flex' }}>
                <TextField
                    title="Fornavn"
                    value={member.firstName ?? ''}
                    handler={(value) => setMember({
                        ...member,
                        firstName: value,
                    })}
                    placeholder="Fyll inn fornavn"
                    disabled={loading}
                />
                <TextField
                    title="Etternavn"
                    value={member.lastName ?? ''}
                    handler={(value) => setMember({
                        ...member,
                        lastName: value,
                    })}
                    placeholder="Fyll inn etternavn"
                    disabled={loading}
                />
            </div>
            <div style={{ display: 'flex' }}>
                <TextField
                    title="Epostadresse"
                    value={member.email ?? ''}
                    handler={(value) => setMember({
                        ...member,
                        email: value,
                    })}
                    placeholder="Fyll inn epost"
                    disabled={loading}
                />
                <CircleCheckbox
                  text={userUid ? 'Vedkommende er lærer' : 'Jeg er lærer'}
                  selected={member.isTeacher ?? false}
                  handler={(value) => setMember({
                      ...member,
                      isTeacher: value,
                  })}
                  disabled={loading}
                />
            </div>
            <div style={{ display: 'flex' }}>
                <TextField
                    title="Organisasjon"
                    value={member.organisation ?? ''}
                    handler={(value) => setMember({
                        ...member,
                        organisation: value,
                    })}
                    placeholder="Fyll inn organisasjon"
                    disabled={loading}
                />
                <TextField
                    title="Klassenavn"
                    value={member.className ?? ''}
                    handler={(value) => setMember({
                        ...member,
                        className: value,
                    })}
                    placeholder="Klassenavn"
                    disabled={!member.isTeacher || loading}
                />
            </div>
            <div style={{ display: 'flex' }}>
              {error && (
                <span style={{ color: 'red' }}>Feil ved oppdatering av data.</span>
              )}
              {validationError && (
                <span style={{ color: 'red' }}>{validationError}</span>
              )}
            </div>
            <Button
                text={loading ? 'Lagrer data...' : 'Lagre'}
                handler={async () => {
                  if (loading) {
                    return;
                  }
                  if (validate()) {
                    setLoading(true);
                    await updateMember(firebase, member)
                      .then(() => {
                        setValidationError("Dine opplysinger er oppdatert!");
                        if (closeModal !== undefined) {
                          closeModal();
                        }
                      })
                      .catch((e) => {
                        setValidationError(e);
                        setError(true);
                      });
                    setLoading(false);
                  }
                }}
                styleOverride={{
                  borderRadius: '2rem',
                  width: 'fit-content',
                  padding: '0 2rem',
                  marginRight: '1rem',
                  whiteSpace: 'nowrap',
                  border: 'none',
                  height: '45px',
                  background: '#1CC065',
                  float: 'right',
                }}
             />
        </>
      )}
    </div>
  );
}
