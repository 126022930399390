import React, { Component } from 'react';
import Select from 'react-select';
import {
  MultipleChoice,
  NumberAnswer,
  NumberAnswerMinMax,
  SingleAnswer,
} from './QuestionTypes';

/*
const options = [
  { label: 'Enkel tekst', value: 'simple' },
  { label: 'Tall', value: 'number' },
  { label: 'Multiple choice', value: 'multiple-choice' },
  { label: 'Praktisk oppgave', value: 'practical' },
  { label: 'Kameraoppgave', value: 'camera' },
];
*/
const optionsPractical = [
  { label: 'Tall', value: 'number' },
  { label: 'Tekst', value: 'text' },
];

const qTypeParserPractical = (type) => {
  switch (type) {
    case 'number':
      return { label: 'Tall', value: 'number' };
    case 'text':
      return { label: 'Tekst', value: 'text' };
    default:
      return '';
  }
};

export class QuestionAnswers extends Component {
  typeSwitch = () => {
    switch (this.props.type) {
      case 'simple':
        return (
          <SingleAnswer
            answer={this.props.answer}
            handleSingleAnswerChange={
              this.props.handleSingleAnswerChange
            }
           />
        );
      case 'multiple-choice':
        return (
          <MultipleChoice
            amount={this.props.numOptions}
            alternatives={this.props.alternatives}
            handleAddAlternative={this.props.handleAddAlternative}
            handleCheckMark={this.props.handleCheckMark}
            checkedAnswers={this.props.checkedAnswers}
            handleRemoveAlternative={
              this.props.handleRemoveAlternative
            }
            handleAddMoreAlternatives={
              this.props.handleAddMoreAlternatives
            }
           />
        );
      case 'multiple-answer':
        return (
          <MultipleChoice
            amount={this.props.numOptions}
            alternatives={this.props.alternatives}
            handleAddAlternative={this.props.handleAddAlternative}
            handleCheckMark={this.props.handleCheckMark}
            checkedAnswers={this.props.checkedAnswers}
            handleRemoveAlternative={
              this.props.handleRemoveAlternative
            }
            handleAddMoreAlternatives={
              this.props.handleAddMoreAlternatives
            }
           />
        );
      case 'number':
        return (
          <NumberAnswer
            numberAnswer={this.props.numberAnswer}
            handleNumberAnswerChange={this.props.handleNumberAnswerChange}
            marginOfError={this.props.marginOfError}
            handleMarginOfErrorChange={this.props.handleMarginOfErrorChange}
           />
        );
      case 'camera':
          return (
            <><p>Deltakere svarer ved å ta et bilde med sin enhet.</p></>
          );
      case 'practical':
        return (
          <div className="question-form-practical">
            <Select
              className="question-form-answer-type"
              options={optionsPractical}
              value={qTypeParserPractical(this.props.practicalType)}
              onChange={(e) =>
                this.props.handleTypeChangePractical(e.value)
              }
            />
            <div className="question-form-set-answer-container">
              {this.practicalTypeSwitch()}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  practicalTypeSwitch = () => {
    switch (this.props.practicalType) {
      case 'number':
        return (
          <NumberAnswerMinMax
            numberAnswer={this.props.numberAnswer}
            numberAnswerMin={this.props.numberAnswerMin}
            numberAnswerMax={this.props.numberAnswerMax}
            numberAnswerMinMaxEnabled={
              this.props.numberAnswerMinMaxEnabled
            }
            handleNumberAnswerChange={
              this.props.handleNumberAnswerChange
            }
            handleNumberAnswerMinChange={
              this.props.handleNumberAnswerMinChange
            }
            handleNumberAnswerMaxChange={
              this.props.handleNumberAnswerMaxChange
            }
            handleToggleMinMax={this.props.handleToggleMinMax}
           />
        );
      case 'text':
      case 'date': // Not implemented but defined
      case 'picture': // Not implemented but defined
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <div className="question-set-answer">
          {this.props.type === 'camera' ? null : (
            <div style={{ fontSize: '1rem', marginBottom: '0.8rem' }}>
              Svar *{' '}
            </div>
          )}
          <div className="question-form-set-answer-container">
            {this.typeSwitch()}
          </div>
        </div>
      </>
    );
  }
}

export default QuestionAnswers;
