import React, { useState } from "react";
import moment from 'moment';
import useFirebase from '../../../../hooks/useFirebase';
import { verifyMember } from '../../../../utils/API/endpoints';
import { DeleteModal } from "../../../molecules/DeleteModal/DeleteModal";
import { TRIAL_DAYS } from '../../../variables/variables';
import {
  IoThumbsUp,
  IoStopwatch,
} from "react-icons/io5";

export function MemberCard(props) {
  const [verifyOpen, setVerifyOpen] = useState(false);
  const firebase = useFirebase();

  function licenseInfo(admin) {
    if (admin.purchased) {
        return "Godkjent";
    }
    if (!admin.trialStarted) {
        return "Mangler startdato";
    }
    // Return values from Firebase are mixed mess...
    const isTdStr = (typeof admin.trialStarted === 'string');
    const isTdObj = (typeof admin.trialStarted === 'object') && admin.trialStarted._seconds === undefined;
    const isTdTms = (typeof admin.trialStarted === 'object') && admin.trialStarted._seconds !== undefined;
    const tryDate = isTdStr ? new Date(admin.trialStarted) : isTdObj ? admin.trialStarted : isTdTms ? new Date(admin.trialStarted._seconds * 1000) : admin.trialStarted.toDate();
    const endDate = new Date(tryDate.getTime() + TRIAL_DAYS * 86400000);
    const nowDate = new Date();
    const delta = new moment.duration(endDate - nowDate);
    if (nowDate > endDate) {
        const overtime = -1 * (Math.round(delta.asDays()) - TRIAL_DAYS);
        return overtime + (overtime > 1 ? " dager" : " dag") + " på overtid";
    }
    const left = Math.round(delta.asDays())
    return left + (left === 1 ? " dag" : " dager") + " gjenstår";
  }

  return (
    <tr className="admin__user">
      <td style={{ fontWeight: 800, paddingLeft: '1em' }}>
        {props.admin.firstName} {props.admin.lastName}
      </td>
      <td className="admin__user__field">{props.admin.email}</td>
      <td className="admin__user__field">{props.admin.organisation}</td>
      <td className="admin__user__field" style={{ paddingLeft: '1em' }}>{props.admin.isTeacher ? 'Ja' : 'Nei'}</td>
      <td className="admin__user__field" style={{ paddingLeft: '1em' }}>{props.admin.className}</td>
      <td className="admin__user__field">{licenseInfo(props.admin)}</td>
      <td className="admin__user__field" style={{ paddingRight: '1em' }}>
        {!props.admin.purchased ? (
        <>
        <button
          style={{
            float: "right",
            outline: "none",
            borderStyle: "none",
            backgroundColor: "#F9F9F9",
            paddingLeft: "1rem",
          }}
          onClick={() => setVerifyOpen(true)}
         >
          <IoThumbsUp title="Godkjenn medlem" style={{height: '1.4rem', width: '1.4rem', cursor: 'pointer'}}/>
        </button>
        <button
          style={{
            float: "right",
            outline: "none",
            borderStyle: "none",
            backgroundColor: "#F9F9F9",
            paddingLeft: "1rem",
          }}
          onClick={() => {
            props.handleExtendTrialPeriod(props.admin);
          }}
         >
          <IoStopwatch title="Forleng prøvetiden" style={{height: '1.4rem', width: '1.4rem', cursor: 'pointer'}}/>
        </button>
        </>
        ) : null }
        <DeleteModal
          isOpen={verifyOpen}
          title={`Er du sikker på at du vil godkjenne ${props.admin.firstName}?`}
          buttonTitle="Godkjenn medlem"
          onConfirm={() => {
            verifyMember(firebase, {
              id: props.admin.id,
            }).then(() => setVerifyOpen(false));
          }}
          onCancel={() => setVerifyOpen(false)}
        />
      </td>
    </tr>
  );
}
