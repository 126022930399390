module.exports = {
  apiKey: 'AIzaSyCxvq-7HmyZAFuISM1r8dmlS1U0q2WInrw',
  authDomain: 'poplar-prod-4695a.firebaseapp.com',
  databaseURL: 'https://poplar-prod-4695a.firebaseio.com',
  projectId: 'poplar-prod-4695a',
  storageBucket: 'poplar-prod-4695a.appspot.com',
  messagingSenderId: '1075483765325',
  appId: '1:1075483765325:web:e07b9b7e2c9a1d13014a04',
  measurementId: 'G-H9D07ZY4LG',
};
