import React from 'react';
import { useEffect, useState } from 'react';
import { SmallQuestionCard } from './SmallQuestionCard';

export const QuestionList = ({
  open,
  outside,
  questions,
  onCardEditClick,
  draggable,
  onCardDeleteClick,
  onCardPreviewClick,
  onDropT,
  withSelector = true,
  questionIsSelected = false,
  setQuestionIsSelected,
}) => {

//console.log(outside);
  //const [qSelected, setQSelected] = React.useState(false);
  //let question = JSON.parse(localStorage.getItem('qid2'));
  /*React.useEffect(() => {
    if(question !== null) setQSelected(true);
    else setQSelected(false);
  }, [question]);*/

  return (
    <div
      className="question-cards-container"
    >
      {open
        ? questions.map((d, i) => (
            <SmallQuestionCard
              key={i}
              question={d}
              outside={outside}
              onCardEditClick={onCardEditClick}
              onCardDeleteClick={onCardDeleteClick}
              index={i}
              draggable={draggable}
              onCardPreviewClick={onCardPreviewClick}
              withSelector={withSelector}
              setQuestionIsSelected={setQuestionIsSelected}
            />
          ))
        : null}
      
      {questionIsSelected && 
        <div className='add-question-button' style={{display: outside !== false ? 'none' : 'block'}} role="button" onClick={onDropT} tabIndex="0">
            Legg til valgt spørsmål
        </div>
      }
    
    </div>
  );
};
