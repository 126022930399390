import React, { Component } from 'react';
import { AuthUserContext } from '../../../utils/Session';
import { MemberAccount } from '../../molecules/AuthModules/MemberAccount'
import PasswordChange from '../../molecules/PasswordChange/PasswordChange';

class Account extends Component {
  render() {
    return (
      <div className="settings container">
        <AuthUserContext.Consumer>
          {(authUser) => (
            <main className='main'>
              <div className='container'>
              <h1>Min side</h1>
              <MemberAccount authUser={authUser} />
              <PasswordChange className="settings__password-change" />
              </div>
            </main>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

export default Account;
