export const copyGame = (firebase, body) => {
  const call = firebase.call('copyGame');
  return call(body);
};

export const createEvent = (firebase, body) => {
  const call = firebase.call('createEvent');
  return call(body);
};

export const createAdmin = (firebase, body) => {
  const call = firebase.call('createAdmin');
  return call(body);
};

export const createGame = (firebase, body) => {
  const call = firebase.call('createGame');
  return call(body);
};

export const createQuestion = (firebase, body) => {
  const call = firebase.call('createQuestion');
  return call(body);
};

export const createMember = (firebase, body) => {
  const call = firebase.call('createMember');
  return call(body);
};

export const deleteMember = (firebase, body) => {
  const call = firebase.call('deleteMember');
  return call(body);
};

export const deleteEvent = (firebase, uid) => {
  const call = firebase.call('deleteEvent');
  return call(uid);
};

export const deleteGame = (firebase, uid) => {
  const call = firebase.call('deleteGame');
  return call(uid);
};

export const disownGame = (firebase, id) => {
  const call = firebase.call('disownGame');
  return call({ gameId: id });
};

export const endEvent = (firebase, eventId) => {
  const call = firebase.call('endEvent');
  return call({ eventId: eventId });
};

export const extendTrialPeriod = (firebase, body) => {
  const call = firebase.call('extendTrialPeriod');
  return call(body);
};

export const fetchMember = (firebase, uid) => {
  const call = firebase.call('fetchMember');
  return call({ uid: uid });
};

export const fetchMemberList = (firebase) => {
  const call = firebase.call('fetchMemberList');
  return call();
};

export const fetchStatistics = (firebase) => {
  const call = firebase.call('fetchStatistics');
  return call();
};

// functions/src/admins/enhancedSignIn.ts
export const fixAdminClaimsIfNecessary = (firebase) => {
  const call = firebase.call('enhancedSignIn');
  return call();
};

export const freezeMember = (firebase, body) => {
  const call = firebase.call('freezeMember');
  return call(body);
};

export const getLibSearchKey = (firebase) => {
  const call = firebase.call('getLibSearchKey');
  return call();
};

export const getSearchKey = (firebase) => {
  const call = firebase.call('getSearchKey');
  return call();
};

export const shareGame = (firebase, body) => {
  const call = firebase.call('shareGame');
  return call(body);
};

export const updateEvent = (firebase, body) => {
  const call = firebase.call('updateEvent');
  return call(body);
};

export const updateGame = (firebase, body) => {
  const call = firebase.call('updateGame');
  return call(body);
};

export const updateMember = (firebase, body) => {
  const call = firebase.call('updateMember');
  return call(body);
};

export const verifyMember = (firebase, body) => {
  const call = firebase.call('verifyMember');
  return call(body);
};
