import React, { Component } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import algoliasearch from 'algoliasearch/lite';
import { withFirebase } from '../../../utils/Firebase';
import { createGame, updateGame, updateEvent } from '../../../utils/API/endpoints';
import { isValidNumber, isValidString } from '../../../utils/helpers';

import { MY_GAMES, OVERVIEW } from '../../../constants/routes';
import QuestionCategory from '../../molecules/QuestionCategory/QuestionCategory';
import QuestionSearchResult from '../../molecules/QuestionCategory/QuestionSearchResult';
import QuestionPreview from '../../molecules/QuestionCategory/molecules/QuestionPreview';
import NewQuestionForm from '../../molecules/NewQuestionForm/NewQuestionForm';
import CreateGame from '../../molecules/CreateGame/CreateGame';
import GameBuilderRightComponent from '../../molecules/GameBuilder/GameBuilderRightComponent';
import CopyCoordinatesModal from '../../molecules/GameBuilder/CopyCoordinatesModal';
import Loading from '../../atoms/Loading';
import { Button } from '../../molecules/NewQuestionForm/atoms/Button';
import { customStyles, previewStyle } from '../../molecules/GameBuilder/GameBuilderStyles';
import {
  questionsVariables,
  gradeOptions,
  categoryOptions,
} from '../../variables/variables';
import { IoArrowBack, IoCheckmark, IoAdd, IoClose, IoChevronDown } from "react-icons/io5";
import questionArrowOutside from '../../../static/question-arrow-tip-outdoor.png';
import questionArrowInside from '../../../static/question-arrow-tip-indoor.png';
import { FaEye, FaEyeSlash, FaInfoCircle } from 'react-icons/fa';
import { withNavigation } from '../../../utils/navigation/context';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const subjectParser = (subject) =>
  subject !== '' ? { label: subject, value: subject } : { label: 'Alle', value: 'Alle' };
/*
const gradeParser = (grade) =>
  grade !== 0
    ? grade === 30
      ? { label: 'Annet', value: grade }
      : { label: grade + '. Klasse', value: grade }
    : { label: 'Alle', value: 0 };
    */
const gradeParser = (grade) => {
  let r = { label: 'Alle', value: 0 };
  gradeOptions.forEach((option) => {
    if(grade === option.value) r = { label: option.label, value: option.value };
  })
  return r;
}

const subjectFilter = (list, subject) => {
  if (subject === 'Alle') return list;
  return list.filter((e) => e.category.subject === subject);
};

const gradeFilter = (list, grade) => {
  
  if (grade === 0) return list;

  const specials = [11,12,13];
  if(specials.includes(grade)) {
    let valid = [];
    if(grade === 11) valid = [1,2,3,4,11];
    if(grade === 12) valid = [5,6,7,12];
    if(grade === 13) valid = [8,9,10,13];
    
    return list.filter((e) =>  valid.includes(e.category.grade));
  }
  
  return list.filter((e) => e.category.grade === grade);
};

const tagFilter = (list, tags) => {
  if (tags.length === 0) return list;
  return list.filter((e) => e.tags.some((t) => tags.includes(t)));
};

const typeFilter = (list, type) => {
  if (type === "live") {
    const types = ["multiple-choice", "number"];
    return list.filter((e) => types.includes(e.type));
  } else 
  return list;
};

const languageFilter = (list, bokmal, sidemal) => {
  if (bokmal) {
    return list.filter((e) => (e.language ?? 'bokmål') === 'bokmål');
  } else if (sidemal) {
    return list.filter((e) => (e.language ?? 'bokmål') === 'nynorsk');
  }
  return list;
};

const filterQuestions = (list, state, useTags, type = null) => {
  let grade = state.grade;
  let subject = state.subject;
  let tags = state.selectedTags ?? [];
  let bokmal = state.bokmal ?? false;
  let sidemal = state.sidemal ?? false;


  if(type == "live") list = typeFilter(list, type);
  if (grade !== 0) list = gradeFilter(list, grade);
  if (subject !== 'Alle') list = subjectFilter(list, subject);
  if (tags !== null && tags !== undefined && useTags)
    list = tagFilter(list, tags);
  if (bokmal !== sidemal)
    list = languageFilter(list, bokmal, sidemal);
  return list;
};

const CircleCheckbox = ({ text, selected, handler }) => {
  const languageButtonSelectedStyle = {
    height: '2rem',
    minHeight: '2rem',
    width: '2rem',
    minWidth: '2rem',
    borderStyle: 'none',
    backgroundColor: '#161B1E',
    border: '2px solid #161B1E',
    borderRadius: '99rem',
    marginRight: '1rem',
    outline: 'none',
  };

  const languageButtonStyle = {
    height: '2rem',
    minHeight: '2rem',
    width: '2rem',
    minWidth: '2rem',
    borderStyle: 'none',
    backgroundColor: '#FFF',
    border: '2px solid #161B1E',
    borderRadius: '99rem',
    marginRight: '1rem',
    outline: 'none',
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '0 2rem 0 0',
      }}
     >
      <button
        style={
          selected ? languageButtonSelectedStyle : languageButtonStyle
        }
        onClick={() => handler()}
       >
        <IoCheckmark style={{width: '1.15rem', height: '1.15rem', margin: '0.27rem -0.1rem', WebkitFilter: 'invert(1)'}} />
      </button>
      <p>{text}</p>
    </div>
  );
};

class GameBuilder extends Component {
  state = {
    //page management
    isLoading: true,
    library: [],
    error: '',
    createGameLoading: false,
    mapSearchTerm: '',
    searching: false,
    searchingLib: false,
    searchBarText: '',
    searchBarTextLib: '',
    mapLoaded: false,
    filtersOpen: false,
    searchOpen: false,
    qtab: 2,

    // Modal dialog boxes
    isShowQuestionModalVisible: false,
    isCopyGameModalVisible: false,
    isQuestionPreviewModalVisible: false,
    isShowHiddenModalVisible: false,

    // Filters
    grade: 0,
    subject: 'Alle',
    allTags: [],
    selectedTags: [],
    bokmal: true,
    sidemal: true,

    //Questions
    questions: [],
    questionsAddedOutdoors: [],
    questionsAddedIndoors: [],
    algoliaQuestions: [],
    algoliaQuestionsLib: [],

    // Filtering
    unusedUserQuestions: [],
    unusedLibraryQuestions: [],

    // Flag for marking if content is being loaded
    isLoadingContent: false,

    // Game settings
    isEditingGame: false,
    isUpdatingGame: false,
    gameId: null,
    gameQuestionIds: [],

    // Event settings
    isEditingEvent: false,
    isUpdatingEvent: false,
    eventId: null,
    eventQuestionIds: [],

    // Common settings
    gameName: '',
    gameRadius: 10,
    gameGoal: null,
    gameOutdoors: true,
    type: "outside",

    questionIsSelected: false,
  };

  _initFirebase = false;
  questionListenerUnsub = () => {};
  libraryListenerUnsub = () => {};
  userDocListenerUnsub = () => {};

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      // Check if we need to load an event or a game.
      const params = new URLSearchParams(
        document.location.search.substring(1),
      );
      if (params.get('eventId') || params.get('gameId')) {
        this.setState({
          isLoadingContent: true,
        });
      }
      else if(params.get('type')) {
        if(params.get('type') === "inside") this.setState({ gameOutdoors: false, type: "inside" })
        if(params.get('type') === "live") this.setState({ gameOutdoors: false, type: "live" })
        if(params.get('type') === "allemoten") this.setState({ gameOutdoors: false, type: "allemoten" })
      }

      // Load questions.
      const authUser = JSON.parse(localStorage.getItem('authUser'));
      this.questionListenerUnsub = this.props.firebase
        .questions(authUser.uid)
        .onSnapshot(
          (qs) => {
            var questionList = qs.docs.map((item) => {
              return {
                ...item.data(),
                id: item.id,
              };
            });
            questionList.sort(function (left, right) {
              var leftHas = left.hasOwnProperty('created');
              var rightHas = right.hasOwnProperty('created');
              if (leftHas && rightHas) {
                return right.created.seconds
                  .toString()
                  .localeCompare(left.created.seconds.toString());
              }
              return leftHas ? -1 : rightHas ? 1 : 0;
            });
            this.setState({
              questions: questionList,
              unusedUserQuestions: questionList,
              isLoading: false,
            });
          },
          (err) => {
            this.setState({
              error: 'Firebase error fetching questions',
            });
          },
        );
      this.libraryListenerUnsub = this.props.firebase
        .library()
        .onSnapshot(
          (qs) => {
            this.setState({
              library: qs.docs.map((item) => {
                return { ...item.data(), id: item.id };
              }),
              unusedLibraryQuestions: qs.docs.map((item) => {
                return { ...item.data(), id: item.id };
              }),
              isLoading: false,
            });
          },
          (err) => {
            this.setState({
              error: 'Firebase error fetching library',
            });
          },
        );
      this.userDocListenerUnsub = this.props.firebase
        .admins()
        .doc(authUser.uid)
        .onSnapshot(
          (doc) => {
            if (!doc.exists) {
              this.setState({
                allTags: [],
              });
            } else {
              const data = doc.data();
              if (data.tags) {
                this.setState({
                  allTags: data.tags.map((t) => {
                    return { value: t, label: t };
                  }),
                });
              }
            }
          },
          (err) => {
            this.setState({
              error: 'Firebase error fetching user information',
            });
          },
        );
      // Load the event or game.
      if (params.get('eventId'))
        this.copyEventDetails();
      else if (params.get('gameId'))
        this.copyGameDetails();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  // Copying or editing an event (instance)
  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  async copyEventDetails() {
    console.log("copyEventDetails");
    let params = new URLSearchParams(
      document.location.search.substring(1),
    );
    let objectId = params.get('eventId');
    let objectOutside = params.get('outside') === 'true';
    let objectUpdate = params.get('update') === 'true';
    if (!objectId) {
      window.alert(`Du har ikke valgt et spill du vil oppdatere. Avbryter.`);
      return;
    }
    if (objectId != null) {
      await this.setState({
        eventId: objectId,
        isEditingEvent: true,
        isUpdatingEvent: true,
        gameOutdoors: objectOutside,
        isCopyGameModalVisible: false,
      });
      if (objectOutside) {
        this.copyOutdoorGameOrEvent(true);
      } else {
        await this.props.firebase.event(objectId)
        .get()
        .then((event) => {
          if (!event.data()) {
            window.alert(`Spillet du valgte å ${objectUpdate ? 'oppdatere' : 'kopiere'} finnes ikke eller har ingen data. Avbryter.`);
            return;
          }
          // Read all the questions used in the game we're editing / copying.
          // NB. This will only give us their ID's so in the next step we'll
          //     need to find the actual questions.
          const gameQuestionIds = event.data().questions.map((q) => q.question.id) ?? null;
          // Generate a list of all available questions...
          const allQuestions = this.state.unusedUserQuestions.concat(
            this.state.unusedLibraryQuestions,
          );
          // ...then find each question object for each question ID in the game.
          const questionObj = gameQuestionIds.map((q) => {
            return allQuestions.find((aq) => aq.id === q);
          });
          // Filter out any null values. These are questions that have been deleted.
          const filteredQuestionObj = questionObj.filter(n => n);
          if (filteredQuestionObj.length !== questionObj.length) {
            window.alert(`Kunne ikke finne ${questionObj.length - filteredQuestionObj.length} av ${questionObj.length} spørsmål i originalen fordi de er sletta eller ugyldige.`);
          }
          this.setState((prevState) => ({
            gameQuestionIds: gameQuestionIds,
            gameName: event.data().name,
            type: event.data().type ?? '',
            questionsAddedIndoors: prevState.questionsAddedIndoors.concat(filteredQuestionObj),
            isCopyGameModalVisible: false,
            isLoadingContent: false,
          }));
        })
        .catch((err) => {
          console.log(err);
          window.alert(`Spillet du valgte å ${objectUpdate ? 'oppdatere' : 'kopiere'} finnes ikke eller har ingen data. Avbryter.`);
          this.setState({
            isCopyGameModalVisible: false,
            isLoadingContent: false,
          });
        });
      }
    }
  };

  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  // Copying or editing a game (template)
  // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
  async copyGameDetails() {
    console.log("copyGameDetails");
    let params = new URLSearchParams(
      document.location.search.substring(1),
    );
    let objectId = params.get('gameId');
    let objectOutside = params.get('outside') === 'true';
    let objectUpdate = params.get('update') === 'true';
    if (!objectId) {
      window.alert(`Du har ikke valgt et spill du vil ${objectUpdate ? 'oppdatere' : 'kopiere'}. Avbryter.`);
      return;
    }
    console.log("copyGameDetails-1");
    if (objectOutside) {
      console.log("copyGameDetails-outside");
      //if(library) do not copy coordinates
      await this.setState({
        gameId: objectId,
        isEditingGame: true,
        isUpdatingGame: objectUpdate,
        gameOutdoors: objectOutside,
        isCopyGameModalVisible: objectOutside && !objectUpdate,
      });
      if (objectUpdate) {
        console.log("copyGameDetails-objectupdate");
        this.copyOutdoorGameOrEvent(true);
      }
    } else {
      console.log("copyGameDetails-notoutside");
      await this.props.firebase.game(objectId)
      .get()
      .then((game) => {
        console.log("copyGameDetails-game");
        if (!game.data()) {
          window.alert(`Spillet du valgte å ${objectUpdate ? 'oppdatere' : 'kopiere'} finnes ikke eller har ingen data. Avbryter.`);
          return;
        }
        // Read all the questions used in the game we're editing / copying.
        // NB. This will only give us their ID's so in the next step we'll
        //     need to find the actual questions.
        const gameQuestionIds = game.data().questions
          .map((q) => q.question.id) ?? null;
        // Generate a list of all available questions...
        const allQuestions = this.state.unusedUserQuestions.concat(
          this.state.unusedLibraryQuestions,
        );
        // ...then find each question object for each question ID in the game.
        const questionObj = gameQuestionIds.map((q) => {
          return allQuestions.find((aq) => aq.id === q);
        });
        // Filter out any null values. These are questions that have been deleted.
        const filteredQuestionObj = questionObj.filter(n => n);
        if (filteredQuestionObj.length !== questionObj.length) {
            window.alert(`Kunne ikke finne ${questionObj.length - filteredQuestionObj.length} av ${questionObj.length} spørsmål i originalen fordi de er sletta eller ugyldige.`);
        }

        this.setState((prevState) => ({
          gameId: objectId,
          isEditingGame: true,
          isUpdatingGame: objectUpdate,
          gameOutdoors: false,
          gameQuestionIds: gameQuestionIds,
          gameName: game.data().name,
          type: game.data().type ?? '',
          questionsAddedIndoors: prevState.questionsAddedIndoors.concat(filteredQuestionObj),
          isCopyGameModalVisible: false,
          isLoadingContent: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        window.alert(`Spillet du valgte å ${objectUpdate ? 'oppdatere' : 'kopiere'} finnes ikke eller har ingen data. Avbryter.`);
        this.setState({
          isCopyGameModalVisible: false,
          isLoadingContent: false,
        });
      });
    }
  };

  copyQuestionsFromEvent = (eventId) => {
    console.log("copyQuestionsFromEvent");
    this.props.firebase
      .event(eventId)
      .get()
      .then((event) => {
        if (event === null || event.data() === null) {
          return;
        }
        const eventQuestionIds = event.data().questions.map((q) => q.question.id) ?? null;
        this.setState({
          eventQuestionIds: eventQuestionIds,
        });
      });
  };

  copyOutdoorGameOrEvent = (copyCoordinates = false) => {
    console.log("copyOutdoorGameOrEvent");
    if (!this.state.gameId && !this.state.eventId) {
      this.setState({
        isCopyGameModalVisible: false,
        isLoadingContent: false,
      });
      return;
    }
    const docRef = this.state.gameId
      ? this.props.firebase.game(this.state.gameId)
      : this.props.firebase.event(this.state.eventId);

    docRef.get().then((game) => {
      if (!game.data()) {
        window.alert(`Kunne ikke laste innholdet i det valgte spillet.`);
        this.setState({
          isCopyGameModalVisible: false,
          isLoadingContent: false,
        });
        return;
      }
      const questionData = game.data();
      const gameQuestionIds =
        questionData.questions.map((q) => q.question.id) ?? null;

      if (copyCoordinates && questionData.outside && gameQuestionIds !== null) {
        const allQuestions = this.state.unusedUserQuestions.concat(
          this.state.unusedLibraryQuestions,
        );

        const questionObj = questionData.questions.map((q) => {
          const question = allQuestions.find(
            (aq) => aq.id === q.question.id,
          );
          if (question) {
              question.coords = {
                lat: q.position.latitude,
                lng: q.position.longitude,
              };
              question.markerColor = getRandomColor();
          }
          return question;
        });
        // Filter out any null values.
        const filteredQuestionObj = questionObj.filter(n => n);
        if (filteredQuestionObj.length !== questionObj.length) {
            console.log(`Failed to get permission to use ${questionObj.length - filteredQuestionObj.length} out of the ${questionObj.length} questions in the original.`);
            window.alert(`Kunne ikke bruke ${questionObj.length - filteredQuestionObj.length} av ${questionObj.length} spørsmål fra originalen fordi de er ugyldige.`);
        }

        this.setState((prevState) => ({
          questionsAddedOutdoors:
            prevState.questionsAddedOutdoors.concat(filteredQuestionObj),
          gameGoal: questionData.finalDestination,
          gameQuestionIds: gameQuestionIds,
          isCopyGameModalVisible: false,
          gameName: questionData.name,
          gameRadius: questionData.radius,
          isLoadingContent: false,
        }));
      } else {
        this.setState({
          gameName: questionData.name,
          gameRadius: questionData.radius,
          gameGoal: questionData.finalDestination,
          gameQuestionIds: gameQuestionIds,
          isCopyGameModalVisible: false,
          isLoadingContent: false,
        });
      }
    })
    .catch((err) => {
      console.log(err);
      window.alert(`Spillet er sletta eller har ingen data. Avbryter.`);
      this.setState({
        isCopyGameModalVisible: false,
        isLoadingContent: false,
      });
    });
  };

  questionsFilter = () => {
    let toRemove = this.state.questionsAddedOutdoors.concat(
      this.state.questionsAddedIndoors,
    );
    let q = this.state.questions;
    let l = this.state.library;
    //filter out the active questions

    const newQuestions = q.filter(
      (ar) => !toRemove.find((rm) => rm.question === ar.question),
    );

    const newLibrary = l.filter(
      (ar) => !toRemove.find((rm) => rm.question === ar.question),
    );

    this.setState({
      unusedUserQuestions: newQuestions,
      unusedLibraryQuestions: newLibrary,
    });
  };

  statefulQuestionsFilter = (toRemove, theQuestions) => {
    return theQuestions.filter(
      (ar) => !toRemove.find((rm) => rm.question === ar.question),
    );
  };

  statefulLibraryFilter = (toRemove, library) => {
    return library.filter(
      (ar) => !toRemove.find((rm) => rm.question === ar.question),
    );
  };

  componentDidUpdate(_, prevState) {
    this.firebaseInit();
    if (
      this.state.eventId !== prevState.eventId &&
      this.state.eventId !== null
    ) {
      this.copyQuestionsFromEvent(this.state.eventId);
    }
    if (this.state.error.length > 0) {
      if (
        this.state.gameName !== prevState.gameName ||
        this.state.gameRadius !== prevState.gameRadius ||
        this.state.grade !== prevState.grade ||
        this.state.subject !== prevState.subject ||
        this.state.selectedTags !== prevState.selectedTags
      ) {
        this.setState({
          error: '',
        });
      }
    }

    if (
      this.state.questionsAddedIndoors.length !==
        prevState.questionsAddedIndoors.length ||
      this.state.questionsAddedOutdoors.length !==
        prevState.questionsAddedOutdoors.length
    ) {
      this.questionsFilter();
    }
  }

  componentWillUnmount() {
    this.questionListenerUnsub();
    this.libraryListenerUnsub();
    this.userDocListenerUnsub();
  }

  //Handlers
  modalExitButtonHandler = () => {
    this.setState({
      isShowQuestionModalVisible: false,
      edit: false,
      selectedQuestion: [],
    });
  };

  ondrop = (e) => {
    // Create the goal along with the first question dragged onto the map.
    if (this.state.gameOutdoors && this.state.questionsAddedOutdoors.length === 0) {
      const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.setState({
        gameGoal: {
          latitude: position.lat - 0.0002,
          longitude: position.lng - 0.0002,
        },
      });
    }

    const question = JSON.parse(localStorage.getItem('qid'));

    if (this.state.gameOutdoors) {
      const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      question.coords = position;
      question.markerColor = getRandomColor();
      this.setState((prevState) => ({
        questionsAddedOutdoors:
          prevState.questionsAddedOutdoors.concat(question),
      }));
    } else {
      this.setState((prevState) => ({
        questionsAddedIndoors:
          prevState.questionsAddedIndoors.concat(question),
      }));
    }
  };


  ondropT = (e) => {
    // quick click-based option to make it touch-friendly

    // Create the goal along with the first question dragged onto the map.
    if (this.state.gameOutdoors && this.state.questionsAddedOutdoors.length === 0) {
      const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.setState({
        gameGoal: {
          latitude: position.lat - 0.0002,
          longitude: position.lng - 0.0002,
        },
      });
    }

    const question = JSON.parse(localStorage.getItem('qid2'));
    if(question) {
    if (this.state.gameOutdoors) {
      const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      question.coords = position;
      question.markerColor = getRandomColor();
      this.setState((prevState) => ({
        questionsAddedOutdoors:
          prevState.questionsAddedOutdoors.concat(question),
      }));
    } else {
      this.setState((prevState) => ({
        questionsAddedIndoors:
          prevState.questionsAddedIndoors.concat(question),
      }));
    }
    localStorage.setItem('qid2', null);
    var messages = document.querySelectorAll(".drag-click");
    for (var i = 0; i < messages.length; i++) {
      messages[i].classList.remove('selected');
    }
    }
    else {
      return true;
    }
  };

  onDragEnd = (index, position) => {
    const lng = position.lng();
    const lat = position.lat();
    const aqu = this.state.questionsAddedOutdoors;
    aqu[index].coords = { lng, lat };
    this.setState({
      questionsAddedOutdoors: aqu,
    });
  };

  onDragEndFinalDestination = (position) => {
    const lng = position.lng();
    const lat = position.lat();
    this.setState({
      gameGoal: {
        latitude: lat,
        longitude: lng,
      },
    });
  };


  handleCloseHiddenModal = () => {
    this.setState({
      isShowHiddenModalVisible: false,
    });
  };



  handleOpenQuestionModal = () => {
    this.setState({
      isShowQuestionModalVisible: true,
    });
  };

  handleCloseQuestionModal = () => {
    this.setState({
      isShowQuestionModalVisible: false,
      edit: false,
      selectedQuestion: [],
    });
  };

  handleCloseQuestionPreview = () => {
    this.setState({
      isShowQuestionModalVisible: false,
      isQuestionPreviewModalVisible: false,
      edit: false,
      selectedQuestion: [],
    });
  };

  

  handleCloseCopyGameModal = () => {
    this.setState({
      isCopyGameModalVisible: false,
    });
  };

  openNewQuestionModal = () => {
    this.handleCloseQuestionModal();
    this.handleOpenQuestionModal();
  };

  insideOutsideNavClick = (input) => {
    if (typeof input === 'boolean' && this.state.gameOutdoors !== input) {
      if (this.state.questionsAddedIndoors.concat(this.state.questionsAddedOutdoors).length > 0) {
        if (this.state.gameOutdoors && window.confirm(
            'Om du bytter til innendørs vil plasseringen til alle spørsmål bli fjernet. Vil du fortsette likevel?',
        )) {
          this.setState((prevState) => ({
            gameOutdoors: false,
            questionsAddedIndoors: prevState.questionsAddedOutdoors,
            questionsAddedOutdoors: [],
          }));
        } else if (!this.state.gameOutdoors && window.confirm(
          'Om du bytter til utendørs kan noen eller alle spørsmål bli fjernet. Vil du fortsette likevel?',
        )) {
          this.setState((prevState) => ({
            gameOutdoors: true,
            questionsAddedIndoors: [],
            questionsAddedOutdoors: prevState.questionsAddedIndoors,
          }));
        }
      } else {
        this.setState({ gameOutdoors: input });
      }
    }
  };

  /**
   * Create a new game or update an existing game or event.
   */
  createGameButtonHandler = (navigate) => {
    // First validate the variables in the interface.
    if (!this.createGameValidator(this.state)) {
      return;
    }
    // Everything is set, let the interface know we're working.
    this.setState({ createGameLoading: true });
    // Select the correct question array, depending on whether or not the game
    // is outdoors, and if so, also add their selected location to the final
    // map that will be stored in Firebase.
    const activeQuestions = this.state.gameOutdoors
      ? this.state.questionsAddedOutdoors
      : this.state.questionsAddedIndoors;
    const activeQuestionMap = activeQuestions.map((q) => {
      const id = q.id ?? q.objectID;
      const qObj = {
        questionId: id,
      };
      if (this.state.gameOutdoors) {
        const coords = {
          latitude: q.coords.lat,
          longitude: q.coords.lng,
        };
        qObj.position = coords;
        //console.log(q, qObj)
        qObj.hidden = Boolean(q.hidden);
      }
      //console.log(qObj);
      return qObj;
    });
    // Build the JSON payload to send to Firebase.
    const payload = {
      name: this.state.gameName,
      radius: this.state.gameRadius,
      outside: this.state.gameOutdoors,
      type: this.state.type,
      questions: activeQuestionMap,
      finalDestination: this.state.gameGoal,
    };
    //console.log(payload)

    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
    // Case 1: Updating an existing event (instance)
    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
    if (this.state.eventId) {
      payload['id'] = this.state.eventId;
      updateEvent(this.props.firebase, payload)
      .then(() => {
        this.setState({
          questionsAddedOutdoors: [],
          questionsAddedIndoors: [],
          gameName: '',
          error: '',
          createGameLoading: false,
        });
        typeof window !== 'undefined' && navigate(`${MY_GAMES}?eventId=${this.state.eventId}`);
      })
      .catch((err) => {
        this.setState({
          error: questionsVariables.error,
          createGameLoading: false,
        });
        console.log(err);
      });
      return;
    }

    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
    // Case 2: Updating an existing game template
    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
    if (this.state.gameId && this.state.isUpdatingGame) {
      payload['id'] = this.state.gameId;
      updateGame(this.props.firebase, payload)
      .then(() => {
        this.setState({
          questionsAddedOutdoors: [],
          questionsAddedIndoors: [],
          gameName: '',
          error: '',
          createGameLoading: false,
        });
        typeof window !== 'undefined' && navigate(`${MY_GAMES}?gameId=${this.state.gameId}`);
      })
      .catch((err) => {
        this.setState({
          error: questionsVariables.error,
          createGameLoading: false,
        });
        console.log(err);
      });
      return;
    }

    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
    // Case 3: Copying an existing game template
    // Disabled: The user will need to do their own
    //           housework deleting the old game.
    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
    //if (this.state.gameId && this.state.isEditingGame) {
    //  disownGame(this.props.firebase, this.state.gameId);
    //}

    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
    // Case 4: Creating a new game template
    // -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
    createGame(this.props.firebase, payload)
    .then((result) => {
      this.setState({
        questionsAddedOutdoors: [],
        questionsAddedIndoors: [],
        gameName: '',
        error: '',
        createGameLoading: false,
      });
      typeof window !== 'undefined' && navigate(`${MY_GAMES}?gameId=${result.data.gameId}`);
    })
    .catch((err) => {
      this.setState({
        error: questionsVariables.error,
        createGameLoading: false,
      });
      console.log(err);
    });
    return;
  };

  setQuestionIsSelected = (value) => {
    console.log('value', value)
    this.setState({questionIsSelected: value});
  }


  gameNameHandler = (input) => this.setState({ gameName: input });
  gameRangeHandler = (input) =>
    this.setState({ gameRadius: parseInt(input) });

  onQuestionCardEditClick = (question) => {
    this.setState({
      isShowQuestionModalVisible: true,
      isQuestionPreviewModalVisible: false,
      edit: true,
      selectedQuestion: question,
    });
  };

  onQuestionCardPreviewClick = (question) => {
    this.setState({
      isShowQuestionModalVisible: true,
      isQuestionPreviewModalVisible: true,
      edit: false,
      selectedQuestion: question,
    });
  };

  onCardDeleteClick = (index, outside) => {
    let activeQuestions = outside
      ? this.state.questionsAddedOutdoors.filter(
          (_, i) => i !== index,
        )
      : this.state.questionsAddedIndoors.filter(
          (_, i) => i !== index,
        );

    if (outside) {
      this.setState((prevState) => ({
        questionsAddedOutdoors: activeQuestions,
        gameGoal:
          activeQuestions.length === 0
            ? null
            : prevState.gameGoal,
        unusedUserQuestions: this.statefulQuestionsFilter(
          activeQuestions,
          prevState.questions,
        ),
        unusedLibraryQuestions: this.statefulLibraryFilter(
          activeQuestions,
          prevState.library,
        ),
      }));
    } else {
      this.setState((prevState) => ({
        questionsAddedIndoors: activeQuestions,
        unusedUserQuestions: this.statefulQuestionsFilter(
          activeQuestions,
          prevState.questions,
        ),
        unusedLibraryQuestions: this.statefulLibraryFilter(
          activeQuestions,
          prevState.library,
        ),
      }));
    }
  };


  

  onCardToggleVisibility = (index, outside) => {
    const toggleVisibility = (questions) =>
      questions.map((question, i) =>
        i === index ? { ...question, hidden: !question.hidden } : question
      );
  
    if (outside) {
      this.setState((prevState) => {
        const updatedQuestions = toggleVisibility(prevState.questionsAddedOutdoors);
  
        return {
          questionsAddedOutdoors: updatedQuestions,
          // ... (rest of the state updates)
        };
      });
    } else {
      this.setState((prevState) => {
        const updatedQuestions = toggleVisibility(prevState.questionsAddedIndoors);
  
        return {
          questionsAddedIndoors: updatedQuestions,
          // ... (rest of the state updates)
        };
      });
    }
  };

  handleSearch = (query) => {
    if (typeof query !== 'string' || query.length < 1) {
      return;
    }

    const API_KEY = localStorage.getItem('algoliaKey');
    const algoliaIndex = localStorage.getItem('algoliaIndex');
    const client = algoliasearch('WUYLEVU2QQ', API_KEY);
    const index = client.initIndex(algoliaIndex);

    index.search(query).then((result) => {
      this.setState({
        algoliaQuestions: result.hits,
        searching: true,
      });
    });
  };

  handleSearchLib = (query) => {
    if (typeof query !== 'string' || query.length < 1) {
      return;
    }

    const API_KEY = localStorage.getItem('algoliaKeyLib');
    const algoliaIndex = localStorage.getItem('algoliaIndex');
    const client = algoliasearch('WUYLEVU2QQ', API_KEY);
    const index = client.initIndex(algoliaIndex);

    index.search(query).then((result) => {
      this.setState({
        algoliaQuestionsLib: result.hits,
        searchingLib: true,
      });
    });
  };

  //Validators
  createGameValidator = (state) => {
    if (state.gameOutdoors) {
      if (state.questionsAddedOutdoors.length === 0) {
        this.setState({
          error: 'Kan ikke lagre: Du har ikke lagt til noen oppgaver på kartet.',
        });
        return false;
      }
    } else {
      if (state.questionsAddedIndoors.length === 0) {
        this.setState({
          error: 'Kan ikke lagre: Du har ikke lagt til noen spørsmål.',
        });
        return false;
      }
    }

    if (!isValidString(state.gameName) && !isValidNumber(state.gameName)) {
      this.setState({
        error: 'Kan ikke lagre: Du har ikke gitt spillet ett gyldig navn.',
      });
      return false;
    }

    if (state.gameOutdoors &&
        (!isValidNumber(state.gameRadius) || this.state.gameRadius < 5 || this.state.gameRadius > 50)
    ) {
      this.setState({
        error: 'Kan ikke lagre: Radiusen du har valgt er ikke gyldig.',
      });
      return false;
    }

    return true;
  };

  
  

  render() {

  let gameQuestionCount = this.state.unusedUserQuestions
  .concat(this.state.unusedLibraryQuestions)
  .filter((q) =>
    this.state.eventQuestionIds.some(
      (id) => id === q.id,
    ),
  )
  .filter((q) =>
    this.state.gameQuestionIds.some(
      (id) => id === q.id,
    ),
  )
  .length;

    if (!this._initFirebase || this.state.isLoading)
      return <div>Laster inn...</div>;
    return (
      <>
        <div className="questions-container-outer">
        <div className="questions-container">
          <div className="questions-left">

          <div className='questions-left-top'>
            <button
              onClick={() => {
                if (
                  typeof window !== 'undefined' && window.confirm(
                    'Er du sikker på at du vil gå tilbake?\nDette vil slette alle ulagrede endringer!',
                  )
                ) {
                  typeof window !== 'undefined' && this.props.navigate(MY_GAMES);
                }
              }}
              className="back-button"
             >
              <IoArrowBack />
              Tilbake
            </button>
            </div>
          
            <div className='questions-left-bottom'>
          <div className='questions-left-bottom-top'>

            








            {this.state.questionsAddedOutdoors.length > 0 && (
              <div className='active-questions'>
                <h3>
                  Aktive spørsmål
                </h3>
                <div className='list'>
                  {this.state.questionsAddedOutdoors.map((q, i) => {
                    return (
                      <div className='item' key={i} >
                        <div className='number-container'>
                        <p key={i} className='number'>
                          {i + 1}
                        </p>
                        </div>
                        <p className='title'>
                          {q.question}
                          {q.hidden ? (<>
                            <br />
                              <span onClick={() => {this.setState({
                                  isShowHiddenModalVisible: true,
                                })}}>
                                  Spørsmålet er skjult <FaInfoCircle />
                              </span>
                              </>) : null}

                        </p>
                        <div className='hide-button'
                          role="button"
                          title="Synlig i kartet"
                          tabIndex={0}
                          onClick={() =>
                            this.onCardToggleVisibility(i, true)
                          }
                          onKeyDown={(e) => e.key=="Enter" && this.handleClick}
                          >
                          {q.hidden ? <FaEyeSlash /> : <FaEye />}
                        </div>
                        <div className='button'
                          role="button"
                          tabIndex={0}
                          title="Slett spørsmål"
                          onClick={() =>
                            this.onCardDeleteClick(i, true)
                          }
                          onKeyDown={(e) => e.key=="Enter" && this.handleClick}
                          >
                          <IoClose />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}


<Button
          className="questions-list-button"
          handler={this.handleOpenQuestionModal}
          text={
            <div>
              Nytt spørsmål{' '}
              <IoAdd style={{height: '0.8rem', width: '0.8rem', marginLeft: '0.5rem'}}/>
            </div>
          }
          />



</div>{/* questions left body top */}



<div className='questions-left-bottom-bottom'>

      <div className='tabs-header'>
        <div 
          className={`item ${this.state.qtab === 1 ? 'active' : ''}`} 
          onClick={() => this.setState({qtab: 1})}
          onKeyDown={(e) => e.key=="Enter" && this.setState({qtab: 1})}
          tabIndex="0"
          role="button"
          >
          Spill-spørsmål
          {gameQuestionCount > 0 &&
            <div className='icon'>{gameQuestionCount}</div>}
          </div>
        
        <div 
          className={`item ${this.state.qtab === 2 ? 'active' : ''}`} 
          onClick={() => this.setState({qtab: 2})}
          onKeyDown={(e) => e.key=="Enter" && this.setState({qtab: 2})}
          tabIndex="0"
          role="button"
          >Mine spørsmål
          </div>
        
        <div 
          className={`item ${this.state.qtab === 3 ? 'active' : ''}`} 
          onClick={() => this.setState({qtab: 3})}
          onKeyDown={(e) => e.key=="Enter" && this.setState({qtab: 3})}
          tabIndex="0"
          role="button"
          >Poplar-spørsmål</div>

        <div 
          className={`item ${this.state.qtab === 4 ? 'active' : ''}`} 
          onClick={() => this.setState({qtab: 4})}
          onKeyDown={(e) => e.key=="Enter" && this.setState({qtab: 4})}
          tabIndex="0"
          role="button"
          >Søk i spørsmål</div>

      </div>










      <div className='filters'>
        <div className='inner'>
              <div className='headers'>
              <div className={`filters-header ${this.state.filtersOpen ? 'open' : ''}`}>
                <div className='filters-text' 
                  onClick={() => this.setState({filtersOpen: !this.state.filtersOpen})}>
                    Filter
                    {
                          <IoChevronDown
                            style={{ height: '1.0rem', width: '1.0rem' }}
                            className={
                              this.state.filtersOpen
                                ? 'dropLeftButton'
                                : 'dropDownButton'
                            }
                          />
                        }
                    </div>
              </div>
              </div>
             
           
            

            <div className='filters-body' style={{display: this.state.filtersOpen ? 'block' : 'none'}}>



            


            <p>Målgruppe</p>
            <div style={{width: '100%', borderRadius: '0.5rem'}}>
              <Select
                style={{width: '100%'}}
                className="class-picker"
                placeholder="Velg klasse"
                value={gradeParser(this.state.grade)}
                options={[{ label: 'Alle', value: 0 }, ...gradeOptions]}
                onChange={(e) =>
                  this.setState({
                    grade: e.value,
                  })
                }
               />
            </div>
            <p>Fag</p>
            <div style={{width: '100%', borderRadius: '0.5rem'}}
             >
              <Select
                style={{width: '100%'}}
                className="subject-picker"
                placeholder="Velg fag"
                value={subjectParser(this.state.subject)}
                options={[{ label: 'Alle', value: 'Alle' }, ...categoryOptions]}
                onChange={(e) =>
                  this.setState({
                    subject: e.value,
                  })
                }
               />
            </div>

            <p>Tags</p>
            <div style={{width: '100%', borderRadius: '0.5rem'}}>
              <Select
                className="tag-picker"
                placeholder="Velg tag"
                isMulti={true}
                isClearable={true}
                options={this.state.allTags}
                onChange={(e) => {
                  if (e !== null) {
                    let tags = e.map((v) => v.value);
                    this.setState({
                      selectedTags: tags,
                    });
                  } else {
                    this.setState({
                      selectedTags: [],
                    });
                  }
                }}
               />
            </div>
            <div
              style={{
                paddingTop: '1rem',
                display: 'flex',
                flexDirection: 'row',
              }}
             >
              <CircleCheckbox
                text={'Bokmål'}
                selected={this.state.bokmal}
                handler={() =>
                  this.setState({ bokmal: !this.state.bokmal })
                }
               />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingRight: '1rem',
                }}
               >
                <CircleCheckbox
                  text={'Nynorsk'}
                  selected={this.state.sidemal}
                  handler={() =>
                    this.setState({ sidemal: !this.state.sidemal })
                  }
                 />
              </div>
              
            </div>
            {this.state.type === "live" && <div style={{paddingTop: "1em"}}>Live-spill: Viser bare flervalg- og nummer-spørsmål.</div>}

</div>{/* filters body */}

</div>{/* inner */}

</div>{/* filters */}




<div className={`tab-body ${this.state.filtersOpen ? 'filters-open' : ''}`} style={{display: this.state.qtab === 4 ? 'block' : 'none'}}>


  <div className='search-bar'>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.handleSearchLib(this.state.searchBarTextLib);
                this.handleSearch(this.state.searchBarTextLib);
              }}
             >
              <input
                className="algolia-search-bar"
                type="text"
                placeholder="Søk spørsmål..."
                value={this.state.searchBarTextLib}
                onChange={(e) => {
                  if (e.target.value.length < 1) {
                    this.setState({
                      searchBarTextLib: e.target.value,
                      searchingLib: false,
                    });
                  } else {
                    this.setState({
                      searchBarTextLib: e.target.value,
                    });
                  }
                }}
               />
              <input
                className="algolia-search-button"
                type="submit"
                value="Søk"
               />
            </form>

            {this.state.searching && this.state.searchBarTextLib.length > 1 && !filterQuestions(
                this.state.searchingLib
                  ? this.state.algoliaQuestionsLib.concat(
                      this.state.algoliaQuestions ?? [],
                    )
                  : [],
                this.state,
                false,
              ).length && <>Ingen resultater</>}

            <QuestionSearchResult
              open={true}
              onCardPreviewClick={this.onQuestionCardPreviewClick}
              questions={filterQuestions(
                this.state.searchingLib
                  ? this.state.algoliaQuestionsLib.concat(
                      this.state.algoliaQuestions ?? [],
                    )
                  : [],
                this.state,
                false,
                this.state.type,
              )}
              title=""
              draggable
              setQuestionIsSelected={this.setQuestionIsSelected}
             />
</div>  
              </div>

<div className={`tab-body ${this.state.qtab === 1 ? 'active' : ''} ${this.state.filtersOpen ? 'filters-open' : ''}`}>

    


            {this.state.isEditingEvent && this.state.eventQuestionIds ? (
              <QuestionCategory
                open={true}
                onCardPreviewClick={this.onQuestionCardPreviewClick}
                questions={this.state.unusedUserQuestions
                  .concat(this.state.unusedLibraryQuestions)
                  .filter((q) =>
                    this.state.eventQuestionIds.some(
                      (id) => id === q.id,
                    ),
                  )}
                title="Event spørsmål"
                draggable
                setQuestionIsSelected={this.setQuestionIsSelected}
               />
            ) : null}

            {this.state.isEditingGame && this.state.gameQuestionIds ? (
              <QuestionCategory
                open={true}
                onCardPreviewClick={this.onQuestionCardPreviewClick}
                questions={this.state.unusedUserQuestions
                  .concat(this.state.unusedLibraryQuestions)
                  .filter((q) =>
                    this.state.gameQuestionIds.some(
                      (id) => id === q.id,
                    ),
                  )}
                title={questionsVariables.title}
                draggable
                setQuestionIsSelected={this.setQuestionIsSelected}
               />
            ) : null}


            </div>
            <div className={`tab-body ${this.state.qtab === 2 ? 'active' : ''} ${this.state.filtersOpen ? 'filters-open' : ''}`}>

                

<QuestionCategory
                  open={true}
                  onCardEditClick={this.onQuestionCardEditClick}
                  onCardPreviewClick={this.onQuestionCardPreviewClick}
                  button={true}
                  buttonHandler={this.handleOpenQuestionModal}
                  questions={filterQuestions(
                    this.state.unusedUserQuestions,
                    this.state,
                    true,
                    this.state.type,
                  )}
                  title="Mine spørsmål"
                  draggable
                  setQuestionIsSelected={this.setQuestionIsSelected}
                />

            </div>

            <div className={`tab-body ${this.state.qtab === 3 ? 'active' : ''} ${this.state.filtersOpen ? 'filters-open' : ''}`}>

 <QuestionCategory
                  open={true}
                  onCardPreviewClick={this.onQuestionCardPreviewClick}
                  onCardEditClick={this.onQuestionCardEditClick}
                  questions={filterQuestions(
                    this.state.unusedLibraryQuestions,
                    this.state,
                    true,
                    this.state.type,
                  )}
                  title="Poplar-spørsmål"
                  draggable
              setQuestionIsSelected={this.setQuestionIsSelected}

                />

{this.props.firebase.superAdmin ? (
              <div>
                <QuestionCategory
                  open={true}
                  onCardEditClick={this.onQuestionCardEditClick}
                  onCardPreviewClick={this.onQuestionCardPreviewClick}
                  button={true}
                  buttonHandler={this.handleOpenQuestionModal}
                  questions={filterQuestions(
                    this.state.searchingLib
                      ? this.state.algoliaQuestionsLib
                      : this.state.library,
                    this.state,
                    true,
                    this.state.type,
                  )}
                  title={questionsVariables.ownerTitle}
                  draggable
              setQuestionIsSelected={this.setQuestionIsSelected}
                  
                />
              </div>
            ) : null}


            </div>

            
          </div>
          </div>
          </div>
          <div className="questions-right">
            {!(
              this.state.questionsAddedOutdoors.length > 0 ||
              this.state.questionsAddedIndoors.length > 0
            ) && (
              <img
                src={
                  this.state.gameOutdoors
                    ? questionArrowOutside
                    : questionArrowInside
                }
                alt=""
                style={{
                  height: '15%',
                  position: 'absolute',
                  top: '38rem',
                  left: '-0.3rem',
                  zIndex: '1',
                }}
               />
            )}
           

            <CreateGame
              type={this.state.type}
              outside={this.state.gameOutdoors}
              outsideHandler={this.insideOutsideNavClick}
              error={this.state.error}
              name={this.state.gameName}
              loading={this.state.createGameLoading}
              sliderValue={this.state.gameRadius}
              nameHandler={this.gameNameHandler}
              sliderHandler={this.gameRangeHandler}
              buttonHandler={this.createGameButtonHandler}
              isUpdatingGame={this.state.isUpdatingGame}
              isUpdatingEvent={this.state.isUpdatingEvent}
            />
            
            {this.state.isLoadingContent ? (<Loading/>) : (
            <GameBuilderRightComponent
              outside={this.state.gameOutdoors}
              questions={this.state.questionsAddedOutdoors}
              onDragEnd={this.onDragEnd}
              onDrop={this.ondrop}
              onDropT={this.ondropT}
              finalDestination={this.state.gameGoal}
              onDragEndFinalDestination={
                this.onDragEndFinalDestination
              }
              gameRadius={this.state.gameRadius}
              searchCoordinates={this.state.searchCoordinates}
              questions1={this.state.questionsAddedIndoors}
              //onCardEditClick={this.onQuestionCardEditClick}
              onCardPreviewClick={this.onQuestionCardPreviewClick}
              onCardDeleteClick={this.onCardDeleteClick}
              onMapLoad={() => this.setState({ mapLoaded: true })}
              onMapUnmount={() => this.setState({ mapLoaded: false })}
              mapLoaded={this.state.mapLoaded}
              questionIsSelected={this.state.questionIsSelected}
            />
            )}
          </div>
          <CopyCoordinatesModal
            isOpen={this.state.isCopyGameModalVisible}
            onConfirm={this.copyOutdoorGameOrEvent}
            onCancel={this.handleCloseCopyGameModal}
           />
          <Modal
            isOpen={this.state.isShowQuestionModalVisible}
            onAfterOpen={() => {}}
            onRequestClose={this.handleCloseQuestionModal}
            contentLabel="Spørsmål"
            style={
              this.state.isQuestionPreviewModalVisible
                ? previewStyle
                : customStyles
            }
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
           >
            {this.state.isShowQuestionModalVisible && !this.state.isQuestionPreviewModalVisible ? (
              <NewQuestionForm
                modalExitButtonHandler={this.modalExitButtonHandler}
                edit={this.state.edit}
                selectedQuestion={this.state.selectedQuestion}
                firebase={this.props.firebase}
                handleModalExit={() => this.handleCloseQuestionModal()}
                reopenModal={() => this.openNewQuestionModal}
                type={this.state.type}
               />
            ) : this.state.isQuestionPreviewModalVisible && this.state.selectedQuestion ? (
              <QuestionPreview
                 key={'preview_key_' + this.state.selectedQuestion.id}
                 selectedQuestion={this.state.selectedQuestion}
                 closeQuestionPreview={this.handleCloseQuestionPreview}
               />
            ) : null}
          </Modal>
          <Modal
          isOpen={this.state.isShowHiddenModalVisible}
          onAfterOpen={() => {}}
          onRequestClose={this.handleCloseHiddenModal}
          contentLabel="Skjult spørsmål"
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          style={{
              content: {
                  width: '300px',
                  height: '300px',
                  margin: 'auto'
              }
          }}
      >
          <button onClick={this.handleCloseHiddenModal} style={{ float: 'right', background: 'none', border: 'none', fontSize: 'large', cursor: 'pointer' }}>&times;</button>
          <div className='infotext'>
              <h2>Skjult spørsmål</h2>
              Spørsmålet vil være skjult på kartet på enheten, 
              men vil kunne besvares som vanlig 
              dersom enheten er innenfor spørsmålets rekkevidde.<br /><br />
              <button onClick={this.handleCloseHiddenModal} style={{ padding: '5px 20px',fontSize: 'large', cursor: 'pointer' }}>Lukk</button>
          </div>
      </Modal>

        </div>
        </div>
      </>
    );
  }
}

export default withNavigation(withFirebase(GameBuilder));

