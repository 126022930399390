import React from 'react';
import { compose } from 'recompose';
import Layout from '../utils/layout';
import MyQuestions from '../components/scenes/MyQuestions/MyQuestions';

import {
  withAuthorization,
  withEmailVerification,
} from '../utils/Session';

const condition = (authUser) => !!authUser;
const MyQuestionsPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(MyQuestions);

export default () => (
  <Layout>
    <MyQuestionsPage />
  </Layout>
);
