import React, { Component } from 'react';
import { Checkbox } from '../NewQuestionForm/atoms/Checkbox';
import { Button } from '../NewQuestionForm/atoms/Button';
import Loading from '../../atoms/Loading';
import banner from '../../../static/banner-poplar-gutter.jpg';
import { MY_GAMES } from '../../../constants/routes';
import {
  gameSettings,
  modalStrings,
  buttonStrings,
} from '../../variables/variables';
import { withFirebase } from '../../../utils/Firebase';
import { createEvent, updateEvent } from '../../../utils/API/endpoints';
import { isValidNumber, isValidString } from '../../../utils/helpers';
import { cancelDeleteButtonStyles } from '../GamesList/atoms/GamesModalStyles';
import { FaClock, FaTimesCircle } from 'react-icons/fa';
import { Input } from '../NewQuestionForm/atoms/Input';

import { NavigationContext, withNavigation } from '../../../utils/navigation/context';



export class CreateEvent extends Component {
  static contextType = NavigationContext;
  state = {
    //event params
    startMessage: modalStrings.startMessage,
    finishMessage: modalStrings.finishMessage,
    errorMessage: '',
    timeLimit: 1800,
    timeLimitMin: '30',
    isNoTimeLimit: false,
    isAnswerRevealed: true,
    isScoreRevealed: false,
    isQuestionTimeUsed: true,
    isAllowingQrQuestions: false,
    showQuestionNumbers: false,
    hintsAvailable: 0,
    event: null,
    game: null,
    isUpdatingFirebase: false,
    name: null,
    reactivate: false,
  };
  _initFirebase = false;

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      console.log("init")
      // If we're asked to update an existing event, load its data.
      if (this.props.eventId) {
        console.log("init event")
        this.props.firebase.event(this.props.eventId).get()
        .then((event) => {
          if (event === undefined || event.data() === undefined) {
            window.alert(`Kunne ikke laste det valgte spillet.`);
            return;
          }
          const eventData = event.data();
          this.setState({
            startMessage: eventData.startMessage,
            finishMessage: eventData.finishMessage,
            timeLimit: eventData.timelimit > 0 ? eventData.timelimit : 0,
            isNoTimeLimit: (eventData.timelimit === 0),
            isAnswerRevealed: eventData.showCorrect ?? false,
            isScoreRevealed: eventData.showScore ?? false,
            isQuestionTimeUsed: eventData.useTimeFromQuestions ?? false,
            isAllowingQrQuestions: eventData.qrQuestions ?? false,
            showQuestionNumbers: eventData.showQuestionNumbers ?? false,
            hintsAvailable: eventData.hintsAvailable > 0 ? eventData.hintsAvailable : 0,
            event: eventData,
            reactivate: this.props.reactivate,
          });
        });
      }
      else if (this.props.gameId) {
        console.log("init game " + this.props.gameId)
        this.props.firebase.game(this.props.gameId).get()
        .then((game) => {
          if (game === undefined || game.data() === undefined) {
            window.alert(`Kunne ikke laste det valgte spillet.`);
            return;
          }
          const gameData = game.data();
          this.setState({
            game: gameData,
          });
        });
      }
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate(_, prevState) {
    this.firebaseInit();
    if (this.state.errorMessage.length > 0) {
      if (
        this.state.startMessage !== prevState.startMessage ||
        this.state.finishMessage !== prevState.finishMessage ||
        this.state.timeLimit !== prevState.timeLimit
      ) {
        this.setState({
          errorMessage: '',
        });
      }
    }
  }
  //Handlers
  handleToggleTimeLimit = () =>
    this.setState((prevState) => ({
      isNoTimeLimit: !prevState.isNoTimeLimit,
      timeLimit: !prevState.isNoTimeLimit
        ? 0
        : prevState.timeLimitMin * 60,
    }));
  handleToggleRevealAnswer = () =>
    this.setState((prevState) => ({
      isAnswerRevealed: !prevState.isAnswerRevealed,
    }));
  handleToggleRevealScore = () =>
    this.setState((prevState) => ({
      isScoreRevealed: !prevState.isScoreRevealed,
    }));
  handleToggleUseQuestionTime = () =>
    this.setState((prevState) => ({
      isQuestionTimeUsed: !prevState.isQuestionTimeUsed,
    }));
  handleToggleAllowQrQuestions = () =>
    this.setState((prevState) => ({
      isAllowingQrQuestions: !prevState.isAllowingQrQuestions,
    }));
  handleToggleShowQuestionNumbers = () =>
    this.setState((prevState) => ({
      showQuestionNumbers: !prevState.showQuestionNumbers,
    }));
  handleSetStartMessage = (input) => this.setState({ startMessage: input });
  handleSetFinishMessage = (input) => this.setState({ finishMessage: input });
  handleTimeLimitMinChange = (input) => {
    let num = Math.min(Math.max(parseInt(input), 0), 6000);
    let min = num;
    if (!Number.isSafeInteger(num)) {
      num = 0;
      min = '';
    }
    this.setState((prevState) => ({
      timeLimitMin: min,
      timeLimit:
        prevState.timeLimit +
        (num - Math.floor(prevState.timeLimit / 60)) * 60,
    }));
  };
  handleHintCountChange = (input) => {
    let num = Math.min(Math.max(parseInt(input), 0), 60);
    if (!Number.isSafeInteger(num)) {
      num = 0;
    }
    this.setState({ hintsAvailable: num });
  };
  handleCreateNewEvent = () => {
    console.log("newgame");
    if (!this.createEventValidator(this.state)) {
      return;
    }
    if (localStorage.getItem('hasTrialPeriodExpired') === 'true') {
      typeof window !== 'undefined' && window.alert(
        `Du kan ikke aktivere spillet fordi prøvetiden din er gått ut. Vennligst ta kontakt med Poplar.`);
      return;
    }

    const payload = {
      'timelimit': this.state.isNoTimeLimit ? 0 : this.state.timeLimit,
      'startMessage': this.state.startMessage,
      'finishMessage': this.state.finishMessage,
      'showCorrect': this.state.isAnswerRevealed,
      'showScore': this.state.isScoreRevealed,
      'showQuestionNumbers': this.state.showQuestionNumbers,
      'hintsAvailable': this.state.hintsAvailable,
      'useTimeFromQuestions': this.state.isQuestionTimeUsed,
      'qrQuestions': this.state.isAllowingQrQuestions,
    };

    // Are we creating a new event from an existing event or from a game?
    if (this.props.selectedObject) {
      if (this.props.selectedObject.source) {
        payload['eventId'] = this.props.selectedObject.id;
      }
      else {
        payload['gameId'] = this.props.selectedObject.source;
        
      }
    } else {
      if (this.props.eventId) {
        payload['eventId'] = this.props.eventId;
      }
      else {
        payload['gameId'] = this.props.gameId;
      }
    }

    console.log("payload", payload);
    
    this.setState({ isUpdatingFirebase: true });
    createEvent(this.props.firebase, payload)
    .then(() => {
      typeof window !== 'undefined' && this.props.navigate(MY_GAMES);
      this.props.handleRequestClose(this.props.navigate);
    })
    .catch((err) => {
      this.setState({
        errorMessage: modalStrings.errorMessage,
        isUpdatingFirebase: false,
      });
      console.log(err);
    });
  };
  handleUpdateExistingEvent = () => {
    console.log("updating");
    if (!this.createEventValidator(this.state)) {
      return;
    }
    if (localStorage.getItem('hasTrialPeriodExpired') === 'true') {
      typeof window !== 'undefined' && window.alert(
        `Du kan ikke oppdatere spillet fordi prøvetiden din er gått ut. Vennligst ta kontakt med Poplar.`);
      return;
    }



    const payload = {
      'eventId': this.props.eventId,
      'timelimit': this.state.isNoTimeLimit ? 0 : this.state.timeLimit,
      'startMessage': this.state.startMessage,
      'finishMessage': this.state.finishMessage,
      'showCorrect': this.state.isAnswerRevealed,
      'showScore': this.state.isScoreRevealed,
      'showQuestionNumbers': this.state.showQuestionNumbers,
      'hintsAvailable': this.state.hintsAvailable,
      'useTimeFromQuestions': this.state.isQuestionTimeUsed,
      'qrQuestions': this.state.isAllowingQrQuestions,
    };

    this.setState({ isUpdatingFirebase: true });
    updateEvent(this.props.firebase, payload)
    .then(() => {
      typeof window !== 'undefined' && this.props.navigate(MY_GAMES);
      this.props.handleRequestClose();
    })
    .catch((err) => {
      this.setState({
        errorMessage: modalStrings.errorMessage,
        isUpdatingFirebase: false,
      });
      console.log(err);
    });
  };

  //Validators
  createEventValidator = (state) => {
    if (
      !isValidString(state.startMessage) &&
      !isValidNumber(state.startMessage)
    ) {
      this.setState({
        errorMessage: 'Velkomstmelding ikke gyldig',
      });
      return false;
    }

    if (
      !isValidString(state.finishMessage) &&
      !isValidNumber(state.finishMessage)
    ) {
      this.setState({
        errorMessage: 'Avslutningsmelding ikke gyldig',
      });
      return false;
    }

    if (!isValidNumber(state.timeLimit)) {
      this.setState({
        errorMessage: 'Tidsfrist ikke gyldig',
      });
      return false;
    }

    return true;
  };

  render() {
    
    return this.state.isUpdatingFirebase ? ( <Loading /> ) : (
      <div className="create-event-container">
        <div className="create-event-inputs">
          <div className="create-event-left">
            <label className="create-event-title">
              Spillinnstillinger
            </label>
            <h2>{this.state.event?.name} {this.state.game?.name}</h2>
            <div className="create-event-message-container">
              <label className="create-event-section-label">
                {gameSettings.welcomeMessage}
              </label>
              <textarea
                className="create-event-input"
                placeholder="Velkomstmelding..."
                onChange={(e) =>
                  this.handleSetStartMessage(e.target.value)
                }
                value={this.state.startMessage}
                style={{
                  resize: 'none',
                  borderRadius: '10px',
                  borderWidth: '0px',
                  padding: '15px',
                  width: '100%',
                }}
               />
            </div>
           
            {this.state.event?.type === "live" || this.state.game?.type === "live" && <>
            <label className="create-event-section-label">
                    Innstillinger for Live-spill
                  </label>
                  <div className="create-event-checkboxes">
                  Det gis inntil 70% ekstra poeng for hurtig svar. Ekstrapoeng fordeles etter hvilken rekkefølge besvarelsene kommer inn. Feil svar gir 0 poeng.
                  Poengsum i Live-spill er antall poeng per oppgave ganget med 100 (pluss ekstrapoeng).
                {/*
                <label className="create-event-checkbox-label">
                  <Checkbox
                    className="create-event-checkbox"
                    checked={true}
                    disabled={true}
                    handler={this.handleToggleTimeLimit}
              />
                  
                </label>
                <label className="create-event-checkbox-label">
                  <Input
                    className="create-event-checkbox"
                    handler={this.handleToggleTimeLimit}
                    value="70"
                    style={{width: "30px"}}
                   />
                  Prosentvis ekstrapoeng i forhold til spørsmålets poeng
                </label>*/}
                </div>

            </>||<>
            <div className="create-event-inputs" style={{flexDirection: 'row'}}>
                <div className="create-game-time-container">
                  <label className="create-event-section-label">
                    {gameSettings.timelimit}
                  </label>
                  <div className="create-game-time-inputs">
                    <div className="create-game-time-input-container">
                      <FaClock className="create-game-time-icon" style={{
                        height: '1.0rem',
                        width: '1.0rem',
                        color: '#7a7e94',
                        marginTop: '0.2rem',
                      }}/>
                      <input
                        className="create-event-time-input"
                        onChange={(e) =>
                          this.handleTimeLimitMinChange(
                            e.target.value,
                          )
                        }
                        value={this.state.timeLimitMin}
                        placeholder="Min"
                        disabled={this.state.isNoTimeLimit}
                       />
                    </div>
                  </div>
                </div>
                <div className="create-game-time-container" style={{marginLeft: '2rem'}}>
                  <label className="create-event-section-label" htmlFor="hintsAvailable">
                    Antall hint
                  </label>
                  <div className="create-game-time-inputs">
                    <div className="create-game-time-input-container">
                      <input
                        id="hintsAvailable"
                        className="create-event-time-input"
                        onChange={(e) =>
                          this.handleHintCountChange(
                            e.target.value,
                          )
                        }
                        value={this.state.hintsAvailable}
                        placeholder="Antall"
                       />
                    </div>
                  </div>
                </div>
            </div>

            <div className="create-event-checkbox-container">
              <label className="create-event-section-label">
                {gameSettings.settings}
              </label>
              <div className="create-event-checkboxes">
                <label className="create-event-checkbox-label">
                  <Checkbox
                    className="create-event-checkbox"
                    checked={this.state.isNoTimeLimit}
                    handler={this.handleToggleTimeLimit}
                   />
                  {gameSettings.noTimelimit}
                </label>
                <label className="create-event-checkbox-label">
                  <Checkbox
                    className="create-event-checkbox"
                    checked={this.state.isQuestionTimeUsed}
                    handler={this.handleToggleUseQuestionTime}
                    disabled={this.state.isNoTimeLimit}
                   />
                  {gameSettings.useTimelimit}
                </label>
                <label className="create-event-checkbox-label">
                  <Checkbox
                    className="create-event-checkbox"
                    checked={this.state.isAnswerRevealed}
                    handler={this.handleToggleRevealAnswer}
                   />
                  {gameSettings.showAnswer}
                </label>
                <label className="create-event-checkbox-label">
                  <Checkbox
                    className="create-event-checkbox"
                    checked={this.state.isScoreRevealed}
                    handler={this.handleToggleRevealScore}
                   />
                  {gameSettings.showPoints}
                </label>
                <label className="create-event-checkbox-label">
                  <Checkbox
                    className="create-event-checkbox"
                    checked={this.state.showQuestionNumbers}
                    handler={this.handleToggleShowQuestionNumbers}
                   />
                  {gameSettings.showQuestionNumbers}
                </label>
                <label className="create-event-checkbox-label" htmlFor="isAllowingQrQuestions">
                  <Checkbox
                    id="isAllowingQrQuestions"
                    className="create-event-checkbox"
                    checked={this.state.isAllowingQrQuestions}
                    handler={this.handleToggleAllowQrQuestions}
                   />
                  Tillat lesing av andre spørsmål fra QR-kode
                </label>
              </div>
            </div>
            </>}

            <div className="create-event-message-container">
              <label className="create-event-section-label">
                {gameSettings.endMessage}
              </label>
              <textarea
                className="create-event-input"
                placeholder="Avslutningsmelding..."
                onChange={(e) =>
                  this.handleSetFinishMessage(e.target.value)
                }
                value={this.state.finishMessage}
                style={{
                  resize: 'none',
                  borderRadius: '10px',
                  borderWidth: '0px',
                  padding: '15px',
                  width: '100%',
                }}
               />
            </div>
            {this.state.errorMessage.length > 0 ? (
            <div className="error-message create-event-error-text">
              {this.state.errorMessage}
            </div>) : (<span/>)}
            <Button
              className="create-event-button"
              text={
                this.state.isLoading
                  ? (this.props.eventId ? 'Oppdaterer...' : 'Aktiverer...')
                  : buttonStrings.activateGame
              } 
              handler={(_) => (this.props.eventId && this.props.reactivate === false) ? this.handleUpdateExistingEvent() : this.handleCreateNewEvent()}
             />
          </div>

          <div className="create-event-right">
            <div className="create-event-banner-container">
              <img
                className="create-event-banner"
                src={banner}
                alt="Poplar"
               />
            </div>

            {this.state.event && this.props.reactivate !== true && <div className="create-event-how-to-play">
              <div className="create-event-how-to-play-title">
                <span>{gameSettings.introHowTo}</span>
              </div>

              <div className="create-event-how-to-play-instructions">
                <span className="create-event-how-to-play-1">
                  {gameSettings.howToPlay}
                </span>

                {/* eventcode is not generated before event is made??? */}
                <span
                  className="create-event-how-to-play-2"
                  style={{ color: '#EB7179' }}
                 >
                  {this.state.event ? this.state.event.eventCode : gameSettings.gameCode}
                </span>
                <span className="create-event-how-to-play-3">
                  Eller...
                </span>
                <span className="create-event-how-to-play-4">
                  {gameSettings.useQr}
                </span>
                <span
                  className="create-event-how-to-play-5"
                  style={{ color: '#EB7179' }}
                 >
                  {this.state.event ? (
                  <img
                    src={encodeURI(
                    'https://api.qrserver.com/v1/create-qr-code/?size=128x128&data=Poplar:' +
                      this.state.event.eventCode)}
                    alt="QR-kode" />) : gameSettings.qrCode}
                </span>
                <span className="create-event-how-to-play-6">
                  &nbsp;
                </span>
              </div>
            </div>}
          </div>
        </div>
        <button className={'modal-exit-button'} onClick={this.props.handleRequestClose} style={cancelDeleteButtonStyles}>
          <FaTimesCircle style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
        </button>
      </div>
    );
  }
}

export default withNavigation(withFirebase(CreateEvent));
