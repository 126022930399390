import React from 'react';
import { compose } from 'recompose';
import Layout from '../utils/layout';
import Overview from '../components/scenes/Overview/Overview';

import {
  withAuthorization,
  withEmailVerification,
} from '../utils/Session';

const condition = (authUser) => !!authUser;
const OverviewPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(Overview);

export default () => (
  <Layout>
    <OverviewPage />
  </Layout>
);
