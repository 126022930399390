import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { TRIAL_DAYS } from '../../variables/variables';
import {
  deleteModalStyles,
  cancelDeleteButtonStyles,
  deleteButtonStyles,
} from '../GamesList/atoms/GamesModalStyles';
import { FaHome, FaLightbulb, FaTimesCircle, FaTree } from 'react-icons/fa';
import { GAME_BUILDER } from '../../../constants/routes';

export function NewGameModal({
  isOpen,
  authUser,
  onConfirm,
  onCancel,
}) {

    let liveon = true;

  return (
    <Modal
      isOpen={isOpen}

      onRequestClose={onCancel}
      ariaHideApp={false}
      style={deleteModalStyles}
        className="new-game-modal"
    >
      <h3>Nytt spill</h3>
      <div>
        <div>
          <p>Hvilket type spill ønsker du å lage?</p>

          <div className='new-card-icons'>

            <div className="outside">
            <Link to={`${GAME_BUILDER}?type=outside`} ><FaTree /> Utespill</Link>
            </div>
            <div className="inside">
            <Link to={`${GAME_BUILDER}?type=inside`} ><FaHome /> Innespill</Link>
            </div>
            {liveon &&
            <div className="live">
            <Link to={`${GAME_BUILDER}?type=live`} ><FaLightbulb /> Live-spill<sup>BETA</sup></Link>
            </div>}
            
          </div>

          <div className='desc'>

            <h3>Spilltyper:</h3>
                <p><strong>Utespill:</strong> GPS-baserte poster som løses individuelt eller som lag, der spørsmålet aktiveres når deltakeren kommer frem til riktig sted. Krever at spillerne bruker en enhet med støtte for GPS (husk at ikke alle nettbrett har ekte GPS). </p>
                <p><strong>Innespill:</strong> Spørsmål i liste, der deltakerne eller lagene løser oppgavene på hver sin enhet. Kan også suppleres med ekstra spørsmål ved å plassere ut QR-koder. Krever at spillerne har en enhet med Poplar-appen installert, men GPS er ikke et krav. </p>
                {liveon && <p><strong>Live-spill<sup>BETA</sup>:</strong> Alle deltakerne svarer på spørsmålene samtidig, styrt fra en sentral enhet. Den sentrale enheten kan vises på skjerm, og viser også rangering mellom hvert spørsmål. Mulighet for ekstrapoeng etter hvor raskt en svarer. Krever at enheten er koblet til internett under hele økten. Dette er en helt ny spill-modus på Poplar.</p>}
            </div>
          
        </div>
        
      </div>
      <button onClick={onCancel} style={{width: '2rem', cursor: 'pointer', ...cancelDeleteButtonStyles}}>
        <FaTimesCircle style={{height: '100%', width: '100%'}}/>
      </button>
    </Modal>
  );
}
