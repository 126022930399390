import React from 'react';
import Layout from '../utils/layout';
import { Admin } from '../components/scenes/Admin/Admin';
import { compose } from 'recompose';
import {
  withAuthorization,
  withEmailVerification,
} from '../utils/Session';

const condition = (authUser) => !!authUser;
const AdminPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(Admin);

export default () => (
  <Layout>
    <AdminPage />
  </Layout>
);
