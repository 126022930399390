import React from 'react';
import { compose } from 'recompose';
import { AdminStats } from '../../components/scenes/Admin/Stats';
import Layout from '../../utils/layout';
import {
  withAuthorization,
  withEmailVerification,
} from '../../utils/Session';

const condition = (authUser) => !!authUser;
const AdminPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(AdminStats);

export default () => (
  <Layout>
    <AdminPage />
  </Layout>
);
