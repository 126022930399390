import React, { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import { FaTimesCircle } from 'react-icons/fa';

const ScoreboardPhotos = (props) => {
  const [fQuestion, setFQuestion] = useState(null);

  //Format question to proper format. Has to be in a useEffect to work
  useEffect(() => {
    
    const qid = props.question.question.path;
    props.firebase
      .path(qid)
      .get()
      .then((result) => {
        const question = {
          ...result.data(),
          id: result.id,
        };
        setFQuestion(question);
      })
      .catch((err) => {
        if (err.code === 'permission-denied') {
        } else {
        }
      });
  }, [props.firebase, props.question.question.path]);

const getAnswer = (user, question) => {
  if (!user.answers) {
    if (question.type === 'camera') {
      return FaTimesCircle;
    } else {
      return 'Ikke svart';
    }
  }
  const ans = user.answers.find((a) => a.questionId === question.id);

  if (ans) {
    if (ans.answer instanceof Array) {
      return ans.answer.join(", ");
    } else {
      return ans.answer;
    }
  } else {
    if (question.type === 'camera') {
      return FaTimesCircle;
    } else {
      return 'Ikke svart';
    }
  }
};

const getAnswerUrl = (user, question) => {
  if (!user.answers) {
    return null;
  }
  const ans = user.answers.find((a) => a.questionId === question.id);

  if (ans) {
    if (ans.answer instanceof Array) {
      return ans.answer.join(", ");
    } else {
      return ans.answer;
    }
  } else {
    return null;
  }
};

  const formatResponses = (question, users) => {
    let imageList = [];
    let _list = [];
    if(question.type === 'camera') {
      
      users.map((u, i) => {
          const url = getAnswerUrl(u, question);
          
          if(url)
            _list.push( {
              original: url,
              description: u.teamName,
              thumbnail: url,
              originalWidth: 680,
            });
          
        });
      if(_list.length)
          imageList.push(_list);
      _list = [];
    }
    return (
      
        
      <>
        <div
          style={{
            borderBottom: '1px solid #DDD',
            marginBottom: '3rem',
            padding: '4rem',
          }}
        >
          <p style={{ fontSize: '1.4rem' }}>
            {fQuestion && fQuestion.question}
          </p>
          
          {imageList.length && 
          <ReactImageGallery items={imageList[0]} />
        || <div className="d">Ingen bilder lastet opp enda.</div>}
        </div>
        </> 
    );
  };

  if(fQuestion && fQuestion?.type == "camera")
  return (
    <div>{fQuestion && formatResponses(fQuestion, props.users)}</div>
  );
  else return (<></>);
};

export default ScoreboardPhotos;
