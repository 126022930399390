import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import { v4 as uuidv4 } from 'uuid';
import devConfig from '../../firebaseConfigDev';
//import devConfig from '../../../firebaseConfigProd';
import prodConfig from '../../firebaseConfigProd';


class Firebase {
  constructor() {

    const ENV = process.env.REACT_APP_ENV.trim();
    console.log("ENV: " + ENV)
    if (ENV === 'dev') {
      app.initializeApp(devConfig);
      localStorage.setItem('algoliaIndex', 'dev_QUESTIONS');
      localStorage.setItem('algoliaEventIndex', 'dev_GAMES');
    } else if (ENV === 'prod') {

      app.initializeApp(prodConfig);
      localStorage.setItem('algoliaIndex', 'prod_QUESTIONS');
      localStorage.setItem('algoliaEventIndex', 'prod_GAMES');
    } else {
      throw new Error('Please specify ENV in environment');
    }

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.functions = app.app().functions('europe-west1');
    this.superAdmin = undefined;
    this.trialStarted = undefined;
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSiginWithCustomToken = (authToken) =>
    this.auth.signInWithCustomToken(authToken);

  doSignOut = () => {
    this.auth.signOut();
    localStorage.removeItem('authUser');
    localStorage.removeItem('algoliaKey');
    localStorage.removeItem('algoliaKeyLib');
    if(window) {
      window.location.href = '/';
    }
  }

  doPasswordReset = (email) =>
    this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: window.location.href,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        authUser
          .getIdTokenResult()
          .then((tokenRes) => tokenRes['claims'])
          .then((claim) => {
            this.superAdmin = Boolean(claim['superAdmin']) ?? false;
            if (!Boolean(claim['admin'])) {
              this.auth.signOut();
            }
          });
        this.db
          .collection('admins')
          .doc(authUser.uid)
          .get()
          .then((snapshot) => {
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
            };
            if (snapshot.data()) {
              if (snapshot.data()['purchased'] !== true && snapshot.data()['trialStarted'] !== undefined) {
                this.trialStarted = snapshot.data()['trialStarted'].toDate();
              }
            }
            //console.log("next next", typeof next)
            if (typeof next === 'function') {
              next(authUser);
            }
          });
      } else {
        console.log("not logged in")
        
        //fallback();
      }
    });

  // *** User API ***

  admins = () => this.db.collection('admins');
  call = (endpoint) => this.functions.httpsCallable(endpoint);
  event = (id) => this.db.collection('events').doc(id);
  superAdmin = () => this.superAdmin;
  events = (active, uid) =>
    this.db
      .collection('events')
      .where('active', '==', active)
      .where('owner', '==', uid);
  nextEvents = (amount, from, active, uid) =>
    this.db
      .collection('events')
      .where('active', '==', active)
      .where('owner', '==', uid)
      .limit(amount)
      .startAfter(from);
  prevEvents = (amount, from, active, uid) =>
    this.db
      .collection('events')
      .where('active', '==', active)
      .where('owner', '==', uid)
      .endAt(from)
      .limitToLast(amount);
  question = (id) => this.db.collection('questions').doc(id);
  questions = (uid) =>
    this.db.collection('questions').where('owner', '==', uid);
  library = () =>
    this.db.collection('questions').where('owner', '==', 'library');
  userQuestions = () =>
    this.db.collection('questions').where('owner', '!=', 'library');
  gamesLibrary = () =>
    this.db.collection('games').where('owner', '==', 'library');
  games = (uid) =>
    this.db.collection('games').where('owner', '==', uid);
  game = (id) => this.db.collection('games').doc(id);
  path = (path) => this.db.doc(path);
  uploadToStorage = (path) => this.storage.ref(path + uuidv4());
}

let fb;

function getFirebase(fbapp, auth, database) {
  if (!fb) {
    fb = new Firebase(fbapp, auth, database);
  }

  return fb;
}

export default getFirebase;
