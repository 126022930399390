import React from 'react';
import { compose } from 'recompose';
import Layout from '../utils/layout';
import Results from '../components/scenes/Results/Results';

import {
  withAuthorization,
  withEmailVerification,
} from '../utils/Session';

const condition = (authUser) => !!authUser;
const ResultsPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(Results);

export default () => (
  <Layout>
    <ResultsPage />
  </Layout>
);
