import React, { useEffect, useRef, useState } from 'react';
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { TeamPage } from "./TeamPage";
import moment from "moment";



export const EventTeam = ({user, distance, started, startTime, time, ranking, showscore = false, questions, QRquestions, participants, points, openAll, eventId}) => {

    const [open, setOpen] = useState(false);

    const prevUserId = useRef(null); // Ref to store the previous user.id

    useEffect(() => {
      // If the previous user ID doesn't match the new one, set open to false
      if (prevUserId.current !== null && prevUserId.current !== user.id) {
        setOpen(false);
      }

      // Update the previous user ID for the next effect run
      prevUserId.current = user.id;
    }, [user.id]); // Only re-run the effect if user.id changes

    let totalPoints = 0;

    if (user.points !== undefined && user.points !== null && !isNaN(user.points)) {
    totalPoints += user.points;
    }

    if (user.extraPoints !== undefined && user.extraPoints !== null && !isNaN(user.extraPoints)) {
    totalPoints += parseInt(user.extraPoints);
    }


    return (
    <div key={user.id} className={`pos-${user.ranking} ${user.ranking > 10 ? "rest" : ""} ${"user"}`}>
        <div className='top'>
            <div className="position">{user.ranking}</div> 
            <div className="name" onClick={() => setOpen(!open)} style={{cursor: "pointer"}}>
                {participants && user.participants && user.participants.length > 1 && user.participants[0] !== user.teamName ? (<>{user.teamName}<span>{user.participants.join(", ")}</span></> ) : (user.teamName)}
            </div> 
            <div className='team-open' onClick={() => setOpen(!open)}>{open ? <FaCaretUp />: <FaCaretDown />}</div>

            {points && <div className='score'>{(totalPoints).toFixed(0)}</div>}

            </div>
            <div className='bottom'>
                
                <div className='distance'>{distance && <>Avstand: {user.distance && user.distance + "m"}</>}</div>
                <div className='startTime'>{started && <>Startet: {user.startTime && formatDate(user.startTime)}</>}</div>
                <div className='time'>{time && <>Brukt tid: {user.startTime && user.timeUsed && secondsToTime(user.timeUsed)}</>}</div>
            </div>
            {open && <TeamPage questions={questions} QRquestions={QRquestions} user={user} eventId={eventId} />}

    </div>
    )

    // if(question && user.answers) {
    //     answer = user.answers.find(a => a.questionId === question.id)
    // }
 
    
    // return(<>
    //     <div className={`team ${answer ? "answer" : ""} ${answer && correct === true ? "correct" : ""} ${answer && correct === false ? "incorrect" : ""}`}>{user.teamName ? user.teamName : <><FaUser />...</>}</div>
    //     {false &&<div className="participants">{user.participants && user.participants.map(person =><>
    //     -{person}<br />
    //     </>)}</div>}
    // </>)
}



const formatDate = (date) => {
    if (date === undefined) {
      return 'Startet ikke';
    }

    // Check if the timestamp exists and has a toDate() method
    if (date && typeof date.toDate === 'function') {
        try {
        // Convert Firebase Timestamp to JavaScript Date object
        const dateObject = date.toDate();

        // Use moment to format the date
        return moment(dateObject).format('DD.MM.YYYY HH:mm');
        } catch (error) {
        console.error("Error converting timestamp:", error);
        return "";
        }
    } else {
        return "Startet ikke";
    }

  };
  

  const secondsToTime = (mseconds) => {
    // Check for undefined or null values
    if (mseconds === undefined || mseconds === null) {
      return '';
    }
    
    // Check if the mseconds is a number
    if (typeof mseconds !== 'number') {
      return "";
    }
    
    try {
      const seconds = mseconds / 1000;
      // Calculate hours, minutes, and remaining seconds
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60);
  
      console.log(hours, minutes, remainingSeconds)
      // Pad minutes and seconds with zeros if needed
      const paddedHours = hours.toString().padStart(2, '0');
      const paddedMinutes = minutes.toString().padStart(2, '0');
      const paddedSeconds = remainingSeconds.toString().padStart(2, '0');
  
      if(hours > 0) return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
      else return `${paddedMinutes}:${paddedSeconds}`;
    } catch (error) {
      console.error("Error converting milliseconds to time:", error);
      return "Invalid milliseconds";
    }
  };
  
  