import React, {useState} from "react";
import { Button } from "../../../molecules/NewQuestionForm/atoms/Button";
import { TextField } from "../../../molecules/NewQuestionForm/atoms/TextField";
import useFirebase from "../../../../hooks/useFirebase";
import { createAdmin } from '../../../../utils/API/endpoints';
import { FaTimesCircle } from 'react-icons/fa';

export function NewMemberForm({closeModal}) {
    const firebase = useFirebase();
    const [error, setError] = useState(null);
    const [validationError, setValidationError] = useState("");
    const [loading, setLoading] = useState(false);
    const [member, setMember] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        superAdmin: false,
    })

    function validate() {
        for (const val of Object.keys(member)) {
            if (member[val] === "") {
                setValidationError("\nVennligst fyll ut alle felt");
                return false;
            }
        }
        return true;
    }

    return (
        <div style={{ padding: "20px" }}>
            <div
                className="modal-exit-button"
                onClick={(_) => closeModal()}
                onKeyDown={(e) => e.key=="Escape" && closeModal()}
                role="button"
                tabIndex="0"
            >
              <FaTimesCircle style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
            </div>
            <h3>Opprett nytt medlem</h3>
            <div style={{ display: 'flex' }}>
                <TextField
                    title="Fornavn *"
                    value={member.firstName}
                    handler={(value) => setMember({
                        ...member,
                        firstName: value,
                    })}
                    placeholder="Fyll inn fornavn"
                />
                <TextField
                    title="Etternavn *"
                    value={member.lastName}
                    handler={(value) => setMember({
                        ...member,
                        lastName: value,
                    })}
                    placeholder="Fyll inn etternavn"
                />
            </div>
            <div style={{ display: 'flex' }}>
                <TextField
                    title="Epostadresse *"
                    value={member.email}
                    handler={(value) => setMember({
                        ...member,
                        email: value,
                    })}
                    placeholder="Fyll inn epost"
                />
                <TextField
                    title="Midlertidlig passord *"
                    value={member.password}
                    handler={(value) => setMember({
                        ...member,
                        password: value,
                    })}
                    type={"password"}
                    placeholder="Fyll inn passord"
                />
            </div>
            <div style={{ display: 'flex' }}>
                <TextField
                    title="Organisasjon *"
                    value={member.organisation}
                    handler={(value) => setMember({
                        ...member,
                        organisation: value,
                    })}
                    placeholder="Fyll inn organisasjon"
                />
                <TextField
                    title="Klassenavn (om lærer)"
                    value={member.className}
                    handler={(value) => setMember({
                        ...member,
                        className: value,
                    })}
                    placeholder="Fyll inn klassenavn (om lærer)"
                />
            </div>
            {error && <span style={{color: "red"}}>
                Feil ved lagring av nytt medlem. Vennligst prøv igjen senere.
                <br/>
                (Kode: {error})
            </span>}
            {validationError && <span style={{color: "red"}}>{validationError}</span>}
            <Button
                text={loading ? "Oppretter nytt medlem..." : "Opprett"}
                handler={() => {
                    if (validate()) {
                        setLoading(true);
                        createAdmin(firebase, member)
                        .then((_) => {
                            closeModal();
                        })
                        .catch((e) => {
                            setLoading(false);
                            setError(e.code);
                            console.log('Feil ved registrering: ' + e);
                        })
                    }
                }}
                styleOverride={{
                    borderRadius: '2rem',
                    width: 'fit-content',
                    padding: '0 2rem',
                    marginRight: '1rem',
                    whiteSpace: 'nowrap',
                    border: 'none',
                    height: '45px',
                    background: '#1CC065',
                    float: "right",
                }}
            />
        </div>
    )
}
