import React, { Component } from 'react';

export class ScoreboardQuestionCardTeam extends Component {
  state = {
    loading: true,
    answered: false,
    correct: false,
  };

  componentDidMount() {
    const user = this.props.user;
    const qid = this.props.qid;

    if (user && typeof user === 'object' && user.answers) {
      const answer = user.answers.filter((a) => a.questionId === qid);
      if (answer.length !== 0) {
        this.setState({
          answered: true,
          correct: answer[0].correct,
          loading: false,
        });
      } else {
        this.setState({
          answered: false,
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return !this.state.loading ? (
      <div
        className={
          'scoreboard-question-card-team-container' +
          (this.state.answered
            ? this.state.correct
              ? ' correct'
              : ' incorrect'
            : '')
        }
      >
        <div className="scoreboard-question-card-team-text">
          {this.props.user.teamName}
        </div>
      </div>
    ) : null;
  }
}

export default ScoreboardQuestionCardTeam;
