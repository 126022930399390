import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { onSnapshot, doc, getFirestore } from 'firebase/firestore';
//import { doc, getDoc } from "firebase/firestore"; 

import {

  ACCOUNT,
} from '../../../../constants/routes';
import SignOutButton from './SignOutButton';
import { FaCaretDown, FaGlobe, FaUser } from 'react-icons/fa';




export default function TopMenu({authUser}) {
  
  /* TODO: move to context/hook */

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    let unsubscribe;
    if (authUser) {
      const ref = doc(getFirestore(), 'admins', authUser.uid);
      unsubscribe = onSnapshot(ref, (doc) => {
        setUserData(doc.data());
      });

    } else {
      setUserData(null);
    }

    return unsubscribe;
  }, [authUser]);

  const [popMenu, setPopmenu] = useState(false);  

  
    
    return(
      <>
         
          <div className='top-navbar'>
            <div className='wrapper'>
            <div className='item'>
                  {userData?.organisation && <><FaGlobe />&nbsp; {userData?.organisation}</>}
              </div>

              <div className='item link' onClick={() => setPopmenu(!popMenu)} onKeyDown={(e) => e.key=="Enter" && setPopmenu(!popMenu)} role="button" tabIndex="-2">
                  <FaUser />&nbsp;{userData?.firstName}
                &nbsp;{userData?.lastName}
                      
                      <FaCaretDown />
              </div>
              
            </div>
          </div>

          <div className={`popmenu ${popMenu ? '' : 'popmenu-hide'}`}>
            <nav>
              <div className='userinfo'>
                <FaUser />
                <div>
                {userData?.firstName}
                &nbsp;{userData?.lastName}
                </div>
                <div className='mail'>
                {authUser.email}
                      &nbsp;</div>
              </div>
            <div className="">
          <Link
            to={ACCOUNT}
            style={{
              minHeight: '70px',
              color: '#59b9eb',
              padding: '2px',
              textDecoration: 'none',
            }}
            onClick={() => setPopmenu(!popMenu)}
            onKeyDown={() => setPopmenu(!popMenu)}
            role="button" tabIndex="-7"
           >
            <div className={'signout-btn'}>
              Minside
            </div>
          </Link>
          <div className="navbar__item">
            <SignOutButton />
          </div>
          <div className='x' onClick={() => setPopmenu(false)} onKeyDown={(e) => e.key=="Enter" && setPopmenu(false)} role="button" tabIndex="-4">x</div>
        </div>
            </nav>
          </div>
      
      </>
  
    );
  
  }