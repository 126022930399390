import React, { useState } from 'react';
import {
  cancelDeleteButtonStyles,
  deleteButtonStyles,
} from '../GamesList/atoms/GamesModalStyles';
import { FaTimesCircle } from 'react-icons/fa';

export const ShareGame = ({
  game,
  onConfirm,
  onCancel,
}) => {
  const [email, setEmail] = useState('');

  return (
    <>
      <div className="create-event-message-container">
        <label className="create-event-section-label">
          <h3>Skriv inn e-postadressen til den du vil dele spillet &laquo;{game ? game.name : '?'}&raquo; med:</h3>
        </label>
        <textarea
          className="create-event-input"
          placeholder="E-postadresse (komma-separert liste om du vil dele med flere)"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          style={{
            resize: 'none',
            borderRadius: '10px',
            borderWidth: '0px',
            padding: '1rem',
            width: '100%',
            marginBottom: '0.5rem',
          }}
         />
      </div>
      <div>
        <button onClick={() => onConfirm(game, email)} style={deleteButtonStyles}>
          Send e-post
        </button>
      </div>
      <button onClick={onCancel} style={cancelDeleteButtonStyles} className="modal-exit-button">
        <FaTimesCircle style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
      </button>
    </>
  );
}

export default ShareGame;
