import React from 'react';

export const Stat = ({ title, data7d, data30d, icon, color }) => {
  return (
    <div className="admin__stat">
      <span className="admin__stat__title">{title}</span>
      <div
        className="admin__stat__body"
        style={{ backgroundColor: color }}
      >
        <span>
          {icon &&
            React.createElement(icon, {
              className: 'admin__stat__icon',
              style: {
                borderColor: color,
              },
            })}
        </span>
        <p className="admin__stat__data">
            {data7d} / {data30d}
            <br/>
            <span style={{fontSize: '1.0rem', fontWeight: '400'}}>7d / 30d</span>
        </p>
      </div>
    </div>
  );
};
