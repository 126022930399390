import React, { useState, useEffect } from 'react';
import { onSnapshot, doc, getDoc, collection, getFirestore } from 'firebase/firestore';
import Loading from '../../atoms/Loading';
import { MY_GAMES } from '../../../constants/routes';

import { FaArrowLeft, FaImages, FaMap, FaMedal, FaQrcode, FaQuestionCircle } from 'react-icons/fa';
import moment from 'moment';
import { Marker } from '@react-google-maps/api';
import MapContainer from '../../molecules/MapContainer/MapContainer';
import QuestionMoreInfo from '../../molecules/Scoreboard/molecules/QuestionMoreInfo';
import { Button } from '../../molecules/NewQuestionForm/atoms/Button';
import Modal from 'react-modal';
import ScoreboardPhotos from '../../molecules/Scoreboard/atoms/ScoreboardPhotos';
import { EventTeam } from './EventTeam';
import DownloadAllButton from '../../molecules/Scoreboard/atoms/DownloadAllButton';
import { useNavigate } from 'react-router';



export default function Results({firebase}) {
    const navigate = useNavigate();
    //const navigate = (path) => { console.log("navigating to: " + path);}
    
    // getting eventID from url
    let params = new URLSearchParams(
        document.location.search.substring(1),
    );
    const eventId = params.get('eventId');

    // initial tab as set in params
    let _tab = params.get('tab') ?? "results";
    const [tab, setTab] = useState(_tab);

    // loading state for beginning, do not display until initial load
    const [loading, setLoading] = useState(true);

    // errormassage
    const [error, setError] = useState('');

    // the current event (contains question array which contains db-refs to real questions)
    const [event, setEvent] = useState(null);
    // subcollection Users under current event (user contains array of own answers)
    const [eventUsers, setEventUsers] = useState([]);

    // the question loaded from database "questions/*"
    const [questions, setQuestions] = useState(null);

    // QR questions loaded from db, if any
    const [QRquestions, setQRQuestions] = useState(null);

    const [sorting, setSorting] = useState("points");


    const [ranking, setRanking] = useState([]);

    const [infoVisible, setInfoVisible] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);

   

    

    // listen to the event - load event object and set initial states
    useEffect(() => {
        let unsubscribe;

        if (eventId) {
            // TODO: try -> catch if no access or error
            const ref = doc(getFirestore(), 'events', eventId);
            unsubscribe = onSnapshot(ref, (doc) => {
                

                const _type = doc.data().type ? doc.data().type : doc.data().outside ? "outside" : "inside";

                setEvent({id: doc.id, type: _type, ...doc.data()});

                loadQuestions(doc.data());

                
                if(doc.data().type === "live") {
                    setRanking(doc.data().ranking);
                    
                }
                else {

                    // set normal results
                }

                setLoading(false);
            });
        
        } else {
            setError('No event');
        }
    
        return unsubscribe;
      }, [eventId]);

        //listen to event users 
        useEffect(() => {
            let unsubscribe;
            if(!eventId) { setError('No event id'); }
            else {
                    // TODO: try -> catch if no access or error
                    const ref = collection(getFirestore(), 'events', eventId, 'users');
                    unsubscribe = onSnapshot(ref, (querySnapshot) => {
                        let _eventUsers = [];
                        let x = 0;
                        querySnapshot.forEach((doc) => {
                            x++;
                            let _user = {id: doc.id, ...doc.data() };
                            if (!_user.teamName) _user.teamName = "[#"+x+"]";
                            //if(doc.data().startTime !== undefined && doc.data().teamName !== undefined && doc.data().timeUsed !== undefined)
                            _eventUsers.push(_user);
                        })
                        if(sorting === "name") 
                            _eventUsers.sort(sortUsersByName);
                        else 
                            _eventUsers.sort(sortUsers);
                        for(let i=0; i<_eventUsers.length;i++) {
                            _eventUsers[i].ranking = i + 1;
                        }
                        
                        setEventUsers(_eventUsers);

                        
                    });
            }
        
            return unsubscribe;
          }, [eventId, sorting]);


    const loadQuestions = async(data) => {

        if(data.questions) {
            let _questions = [];
            for(let i = 0; i < data.questions.length; i++) {
                const ref = doc(getFirestore(), data.questions[i].question.path);
                const docSnap = await getDoc(ref);
                
                _questions.push({id: docSnap.id, ...docSnap.data()});
            }
            setQuestions(_questions);
                    
        }
    }

    useEffect(() => {
        if (event && event.questions && eventUsers) {
            const uniqueQuestionIds = [
                ...new Set(
                  eventUsers
                    .filter(user => user.answers) // Filter out users without answers
                    .flatMap(user => user.answers.map(answer => answer.questionId))
                )
              ];            
            
              const eventQuestionIds = event.questions.map(question => question.question.path);
              const newQuestionIds = uniqueQuestionIds.filter(id => !eventQuestionIds.includes('questions/'+id));
        
            loadQRQuestions(newQuestionIds);
        }
    }, [event, eventUsers]);
      

    const loadQRQuestions = async (newQuestionIds) => {
        const _questions = [];
        for (const questionId of newQuestionIds) {
            const ref = doc(getFirestore(), 'questions', questionId);
            const docSnap = await getDoc(ref);
            
            if (docSnap.exists()) {
                _questions.push({ id: docSnap.id, ...docSnap.data() });
            }
        }
      
        setQRQuestions(_questions);
    };
      
    


    const sortUsers = (a, b) => {
   
            // Initialize variables to 0 if they are undefined, null, or NaN
            const aPoints = a.points !== undefined && a.points !== null && !isNaN(a.points) ? a.points : 0;
            const aExtraPoints = a.extraPoints !== undefined && a.extraPoints !== null && !isNaN(a.extraPoints) ? parseInt(a.extraPoints) : 0;
            const aTotalPoints = aPoints + aExtraPoints;
        
            const bPoints = b.points !== undefined && b.points !== null && !isNaN(b.points) ? b.points : 0;
            const bExtraPoints = b.extraPoints !== undefined && b.extraPoints !== null && !isNaN(b.extraPoints) ? parseInt(b.extraPoints) : 0;
            const bTotalPoints = bPoints + bExtraPoints;
        
            const aTimeUsed = a.timeUsed !== undefined && a.timeUsed !== null && !isNaN(a.timeUsed) ? a.timeUsed : 0;
            const bTimeUsed = b.timeUsed !== undefined && b.timeUsed !== null && !isNaN(b.timeUsed) ? b.timeUsed : 0;
        
            const aDistance = a.distance !== undefined && a.distance !== null && !isNaN(a.distance) ? a.distance : 0;
            const bDistance = b.distance !== undefined && b.distance !== null && !isNaN(b.distance) ? b.distance : 0;
        
            // Perform comparisons
            return (
                bTotalPoints - aTotalPoints || aTimeUsed - bTimeUsed || aDistance - bDistance
            );
        
    };
    const sortUsersByName = (a, b) => {
        
        return a.teamName.replace(/\s+/g, '').localeCompare(b.teamName.replace(/\s+/g, ''));
        
    };

    
          

          // click close event -> go back to my games
    const clickClose = async() => {
        
        typeof window !== "undefined" &&
            navigate(`${MY_GAMES}`);

    }

    const closeQuestionInfo = () => {
    
        setInfoVisible(false);
        setSelectedQuestion(null);
    
    };

    const openQuestionInfo = (question) => {
    
    setInfoVisible(true);
    setSelectedQuestion(question);
    };

    // dev mode shows all variables in a simple setup for debugging
    let dev = false;
    if(true) {
        return(<>
        <div className='headermenu'>
            <div className="menuitem" onClick={() => clickClose()}><FaArrowLeft /> Mine spill</div>
        </div>
        <div className="resultspage">
            <div className='inner'>
            <div className="close-container"><div className="close-button" onClick={() => clickClose()}>Lukk</div></div>
            {loading && <>
                <Loading />
            </> || <>
                
                    <EventPage event={event} 
                        eventUsers={eventUsers} 
                        tab={tab} setTab={setTab}
                        clickClose={clickClose}
                        questions={questions}
                        QRquestions={QRquestions}
                        ranking={ranking}
                        openQuestionInfo={openQuestionInfo}
                        firebase={firebase}
                        sorting={sorting}
                        setSorting={setSorting}
                        />

                        


              
                <div className="stats">
                    
                    <div className="code">Spillere: {eventUsers.length}</div>
                    <div className="code">Spørsmål: *</div>
                    


                </div>
            </>}
            </div>
        </div>

        <Modal
          isOpen={infoVisible}
          onRequestClose={closeQuestionInfo}
          contentLabel="Spørsmålsinformasjon"
          style={{}}
          className="questionModal"
          ariaHideApp={false}
         >
          <QuestionMoreInfo
            question={selectedQuestion}
            users={eventUsers}
            onCloseWindow={closeQuestionInfo}
           />
        </Modal>
        </>);
    }
}




const EventPage = ({event, 
    eventState,
    eventUsers,
    ranking,
    tab, setTab,
    clickClose,
    questions,
    QRquestions,
    openQuestionInfo,
    firebase,
    sorting, setSorting,
    }) => {

        const [participants, setParticipans] = useState(true);
        const [distance, setDistance] = useState(event.type == "outside" ? true : false);
        const [started, setStarted] = useState(false);
        const [time, setTime] = useState(false);
        const [points, setPoints] = useState(true);
        const [colored, setColored] = useState(true);

        const imageQuestion = questions?.filter((q) => q.type === "camera") ?? [];


        if(eventUsers && questions && tab === "questions") {
            questions.map((question) => {
                let _cq = 0;
                let _wq = 0;
                eventUsers.map((user) => {

                    const answer = user?.answers?.filter((a) => a.questionId === question.id);

                    if (answer?.length !== 0) {
                        if(answer && answer[0]?.points > 0) {
                            _cq++;
                        }
                        else
                            _wq++;
                    }
                })
                question.correct_count = _cq;
                question.correct_pst = Math.round(_cq / eventUsers.length * 100);
                question.wrong_count = _wq;
            })
        }

        if(eventUsers && QRquestions && tab === "questions") {
            QRquestions.map((question) => {
                
                let _cq = 0;
                let _wq = 0;
                eventUsers.map((user) => {

                    const answer = user?.answers?.filter((a) => a.questionId === question.id);

                    if (answer?.length !== 0) {
                        console.log("fant qr",question?.question)
                        if(answer && answer[0]?.points > 0) {
                            _cq++;
                        }
                        else
                            _wq++;
                    }
                })
                question.correct_count = _cq;
                question.correct_pst = Math.round(_cq / eventUsers.length * 100);
                question.wrong_count = _wq;
            })
        }


    const generateMarkers = (users) => {
        
        return users.map((user, index) => {
            
            if (user.position && window.google && window.google.maps)
                return (
                <Marker
                    key={'user_'+index}
                    position={{ lat: user.position.latitude, lng: user.position.longitude }}
                    className="map-marker"
                    draggable={false}
                    title={user.lastSeen
                    ? user.teamName + ' var her for ' + moment(user.lastSeen.toDate()).fromNow()
                    : user.teamName
                    }
                    label={Object.assign({
                    text: user.teamName,
                    className: 'markerText',
                    })}
                    />
                );
            return null;
        });
    };

    return(<>
    
        <div className="eventheader">
            
            <div className="title"><h1>{event.name}</h1></div>
            
        </div>




        <div className="eventmain">

        <div className='eventactions-container'>

           
            
            <div className="eventactions">
                
                {event.active && <div className={`button ${tab === "starter" ? "primary" : ""}`} onClick={() => setTab('starter')}><FaQrcode />Spillkode</div>}
                
                    <div className={`button ${tab === "results" ? "primary" : ""}`} onClick={() => setTab('results')}><FaMedal />Resultater</div>
                
                    <div className={`button ${tab === "questions" ? "primary" : ""}`} onClick={() => setTab('questions')}><FaQuestionCircle />Spørsmål</div>

                    {event.outside && <>
                        <div className={`button ${tab === "map" ? "primary" : ""}`} onClick={() => setTab('map')}><FaMap />Kart</div>
                    </>}

                {imageQuestion.length !== 0 &&
                    <div className={`button ${tab === "images" ? "primary" : ""}`} onClick={() => setTab('images')}><FaImages />Bilder</div>}
                
            </div>


        </div>

           {tab === 'results' && 
                <div className={`ranking ${colored ? "finished" : ""}`}>
                    
                    {ranking && ranking.map((user) => (
                        <div key={user.id} className={`pos-${user.position} ${user.position > 10 ? "rest" : ""} ${"user"}`}>
                            <div className="position">{user.position}</div>
                            <div className="name">{user.teamName}</div> 
                            {points && <div className='score'>{(user.totalscore*100).toFixed(0)}</div>}
                        </div>
                    ))}
                    {(event.type === "outside" || event.type === "inside") && <>
                    {eventUsers && eventUsers.map((user) => (
                        <EventTeam key={user.id} user={user} questions={questions} QRquestions={QRquestions} participants={participants} points={points} ranking={ranking} distance={distance} started={started} eventId={event.id} time={time} />
                        
                    ))}
                    
                    

                    </>}

                    <div className="filterbuttons">
                        <div className='top'>
                            <div className={`filterbutton ${participants ? "on" : ""}`} onClick={() => setParticipans(!participants)}><span></span>Vis deltakernavn</div>
                            <div className={`filterbutton ${colored ? "on" : ""}`} onClick={() => setColored(!colored)}><span></span>Vis pallfarger</div>
                            <div className={`filterbutton ${points ? "on" : ""}`} onClick={() => setPoints(!points)}><span></span>Vis Poeng</div>
                        </div>
                        
                        <div className='bottom'>
                            {event.outside && <div className={`filterbutton ${distance ? "on" : ""}`} onClick={() => setDistance(!distance)}><span></span>Vis avstand</div>}
                            
                            <div className={`filterbutton ${started ? "on" : ""}`} onClick={() => setStarted(!started)}><span></span>Vis starttid</div>
                            <div className={`filterbutton ${time ? "on" : ""}`} onClick={() => setTime(!time)}><span></span>Vis tidsbruk</div>
                        </div>
                        <div className='bottom2'>
                            <div>Sortering:</div>
                            
                            <div className={`filterbutton ${sorting === "points" ? "on" : ""}`} onClick={() => setSorting("points")}><span></span>Poeng</div>
                            <div className={`filterbutton ${sorting === "name" ? "on" : ""}`} onClick={() => setSorting("name")}><span></span>Lagnavn</div>
                        </div>

                    </div>


                    

                </div>

                /*
const Userline = ({user}) => {

    const displayName = (user.teamName ? user.teamName : "");
    const usedTime = user.endTime ? formatTime(user.timeUsed) : "Ikke fullført";
    const distance = Number.parseFloat(user.distance).toFixed(0);

}


                {user.participants[0] !== displayName ? (
        <td style={{ width: "20%", textAlign: 'left' }}>{displayName}<br/><span style={{fontSize: '0.75rem', textAlign: 'left'}}>{user.participants.join(", ")}</span></td>
        ) : (
        <td style={{ width: "20%", textAlign: 'left' }}>{displayName}</td>
        )}
        <td style={{paddingLeft: '1rem'}}>{user.points}</td>
        {event.hintsAvailable > 0 ? (
        <td style={{paddingLeft: '1rem'}}>{event.hintsAvailable - user.hintsAvailable ?? 0}</td>
        ) : null}
        {event.timelimit > 0 ? (
          <td style={{paddingLeft: '1rem'}}>{user.startTime ? usedTime : "Ikke startet"}</td>
        ) : null}
        {event.outside ? (
        <td style={{paddingLeft: '1rem'}}>{isNaN(distance) ? "0" : distance} m</td>
        ) : null}
        <td style={{paddingLeft: '1rem'}}>{user.finished ? "Ja" : "Nei"}</td>
        <td>{formatDate(user.startTime)}</td>
                */
            }

            {tab === 'questions' && 
                <div className={`questions`}>
                    <div className='header'>
                        <p style={{ fontSize: '1.5rem' }}>Spørsmål</p>
                        <div className='desc'
                        style={{ backgroundColor: '#EB7179',}}
                        ></div>
                        <p>Feil svar</p>
                        <div className='desc'
                        style={{ backgroundColor: '#6CCFA3',}}
                        ></div>
                        <p>Riktig svar</p>
                        <div className='desc'
                        style={{ backgroundColor: '#aaaaaa',}}
                        ></div>
                        <p>Noe gikk galt / ikke svart</p>
                    </div>
                    {questions && questions.map((question, key) => (
                    <div className="question" key={question.id}>
                        <div className="top">
                            <div className="key">{key + 1 } </div>
                            <div className="text">{question.question}</div>
                            {question.mediaUrl && <div className="media"><img src={question.mediaUrl} alt="media" /></div>}
                            <div className="counts">
                                <div className='correctbar'>
                                <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style={{"--value": question.correct_pst}}></div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="teams">
                                {eventUsers && eventUsers.map((user) => 
                                    <div  key={`${question.id}_${user.id}`} className=''>
                                        <Team question={question} user={user} />
                                    </div>
                                )}
                            </div>
                            <div className="card-see-more">
                                <Button
                                text="Se mer"
                                styleOverride={{
                                    border: 'none',
                                    borderRadius: '1rem',
                                    margin: '1rem',
                                }}
                                handler={() => openQuestionInfo(question)}
                                />
                            </div>
                        </div>
                        
                        
                    </div>
                    ))}

                    {QRquestions && QRquestions.map((question, key) => (
                    <div className="question" key={question.id}>
                        <div className="top">
                            <div className="key">{questions?.length + key + 1 } </div>
                            <div className="text">Fra QR-kode:<br /> {question.question}</div>
                            <div className="counts">
                                <div className='correctbar'>
                                <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style={{"--value": question.correct_pst}}></div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="teams">
                                {eventUsers && eventUsers.map((user) => 
                                    <div  key={`${question.id}_${user.id}`} className=''>
                                        <Team question={question} user={user} />
                                    </div>
                                )}
                            </div>
                            <div className="card-see-more">
                                <Button
                                text="Se mer"
                                styleOverride={{
                                    border: 'none',
                                    borderRadius: '1rem',
                                    margin: '1rem',
                                }}
                                handler={() => openQuestionInfo(question)}
                                />
                            </div>
                        </div>
                        
                        
                    </div>
                    ))}
                </div>
            }

            {tab === 'images' && 
                <div className={`ranking`}>
                    {event.questions.map((q, i) => (
                    <ScoreboardPhotos
                        key={'image_'+i}
                        users={eventUsers}
                        question={q}
                        firebase={firebase}
                    />
                    ))}
                    <DownloadAllButton event={event} users={eventUsers} />

                </div>
            }


            {tab === 'map' && 
                <div className={`ranking`}>

                    <div className={'maps'}>
                    
                        <MapContainer
                        questions={event.questions}
                        onDragEnd={undefined}
                        onDragEndFinalDestination={undefined}
                        onDrop={() => { /* Do nothing */ }}
                        onDropT={() => { /* Do nothing */ }}
                        gameRadius={event.radius}
                        finalDestination={event.finalDestination}
                        generateCustomMarkers={() => generateMarkers(eventUsers)}
                        />
                    </div>
                    
                </div>
            }


            {tab === 'starter' && 
                <div className={`ranking `}>
                    <div className="starters">
                        <div className="code">Spillkode: <span>{event.eventCode}</span></div>
                        <div className="qr">
                            <img src={encodeURI(
                                    'https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=Poplar:' +
                                    event.eventCode,
                                )} alt="QR-kode" />
                            </div>
                    </div>
                </div>
            }


        </div>

        
        <div className="eventbottom"></div>


    </>)
}


const Team = ({question, user, key}) => {
    let answered = false;
    let correct = false;
//console.log("Team",question, user)
    if (user && typeof user === 'object' && user.answers) {
        const answer = user.answers.filter((a) => a.questionId === question.id);
      if (answer.length !== 0) {
        answered = true;
        if(answer[0].points > 0) {
            correct = true;
        }
        
            
        
      }
    }
    return (
        <div className={'team-container' +
            (answered
            ? correct
                ? ' correct'
                : ' incorrect'
            : '')
        }>
        <div className="card-team-text">
            {user.teamName}
        </div>
        </div>
    );
}



