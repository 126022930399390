import React from 'react';
import ScoreboardQuestionCard from '../atoms/ScoreboardQuestionCard';

export default function ScoreboardQuestionList({
  title,
  questions,
  users,
  firebase,
  onSeeMoreClick,
}) {
  return (
    <div className="scoreboard-question-list-container">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '0 2rem',
        }}
      >
        <p style={{ fontSize: '1.5rem' }}>Spørsmål</p>
        <div
          style={{
            width: '1.5rem',
            height: '1.5rem',
            backgroundColor: '#EB7179',
            borderRadius: '5rem',
            marginRight: '0.5rem',
            marginLeft: '1.25rem',
          }}
        ></div>
        <p>Feil svar</p>
        <div
          style={{
            width: '1.5rem',
            height: '1.5rem',
            backgroundColor: '#6CCFA3',
            borderRadius: '5rem',
            marginRight: '0.5rem',
            marginLeft: '1.25rem',
          }}
        ></div>
        <p>Riktig svar</p>
        <div
          style={{
            width: '1.5rem',
            height: '1.5rem',
            backgroundColor: 'black',
            borderRadius: '5rem',
            marginRight: '0.5rem',
            marginLeft: '1.25rem',
          }}
        ></div>
        <p>Noe gikk galt / ikke svart</p>
      </div>
      <div
        className="scoreboard-question-list-content"
        style={{ margin: '0 2rem' }}
      >
        {questions.map((q, index) => (
          <ScoreboardQuestionCard
            key={index}
            index={index}
            onSeeMoreClick={onSeeMoreClick}
            question={q}
            users={users}
            firebase={firebase}
          />
        ))}
      </div>
    </div>
  );
}
