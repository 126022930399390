export const deleteModalStyles = {
  content: {
    top: '30%',
    left: '30%',
    right: '30%',
    bottom: '36%',
    background: 'rgb(255, 255, 255)',
    padding: '20px 20px 40px 20px',
    textAlign: 'center',
    borderRadius: '15px',
    borderStyle: 'none',
    boxShadow: '0px 4px 10px rgba(103, 107, 133, 0.15)',
    height: 'fit-content',
    width: '24rem',
  },
};

export const customStylesModal = {
  content: {
    top: '5%',
    left: '5%',
    right: '5%',
    bottom: '5%',
    background: '#FFF',
    boxShadow: '0px 4px 10px rgba(103, 107, 133, 0.15)',
    borderStyle: 'none',
    color: 'black',
    borderRadius: '1.5rem',
    padding: '0',
  },
};

export const customStyles = {
  content: {
    top: '10%',
    left: '14%',
    right: '14%',
    bottom: '10%',
    background: '#FFF',
    padding: '0px',
    color: 'black',
    borderRadius: '20px',
    borderWidth: '0px',
  },
  overlay: {
    backgroundColor: 'rgba(155, 155, 155, 0.75)',
  },
};

export const gameOverviewStyle = {
  content: {
    top: '5%',
    
    margin: 'auto',
    bottom: '5%',
    background: '#FFF',
    boxShadow: '0px 4px 10px rgba(103, 107, 133, 0.15)',
    borderStyle: 'none',
    color: 'black',
    borderRadius: '1.5rem',
    padding: '0',
    overflow: 'hidden',
  },
  overlay: {
    backgroundColor: 'rgba(155, 155, 155, 0.75)',
  },
};

export const activateGameStyle = {
  content: {
    top: '12%',
    left: '18%',
    right: '18%',
    bottom: '12%',
    background: '#FFF',
    padding: '0px',
    color: 'black',
    borderRadius: '20px',
    borderWidth: '0px',
  },
  overlay: {
    backgroundColor: 'rgba(155, 155, 155, 0.75)',
  },
};

export const deleteButtonStyles = {
  backgroundColor: '#EB7179',
  borderRadius: '100px',
  borderStyle: 'none',
  color: 'white',
  padding: '12px 50px',
  cursor: 'pointer',
};

export const cancelDeleteButtonStyles = {
//  borderRadius: '100px',
  border: 'hidden',
  position: 'absolute',
  top: '0.0rem',
  right: '0.0rem',
//  backgroundColor: '#161B1E',
//  color: '#FFF',
  backgroundColor: '#FFFFFF',
  zIndex: '100',
};

export const qrCodeModalStyles = {
  content: {
    top: '10%',
    left: '20%',
    right: '20%',
    bottom: '17.5%',
    background: '#FFF',
    padding: '0',
    borderStyle: 'none',
    borderRadius: '1rem',
    boxShadow: '0px 4px 10px rgba(103, 107, 133, 0.15)',
    maxWidth: '40rem',
    height: 'fit-content',
  },
};
