import React, { useState } from 'react';
import { doc, getFirestore, updateDoc } from "firebase/firestore";




export const TeamPage = ({ questions, QRquestions, user, eventId }) => {

    const [inputValue, setInputValue] = useState("");

    if (!user || !questions) return null;


    const updateExtraPoints = async () => {
        const ref = doc(getFirestore(), 'events', eventId, 'users', user.id);
        let extraPoints = 0;
        if (user.extraPoints !== undefined && user.extraPoints !== null && !isNaN(user.extraPoints)) {
            extraPoints += parseInt(user.extraPoints);
        }
        extraPoints += parseInt(inputValue)
        try {
          await updateDoc(ref, {
            extraPoints: extraPoints
          });
          console.log("Document successfully updated!");
        } catch (error) {
          console.error("Error updating document: ", error);
        }
      };
    let totalPoints = 0;

    if (user.points !== undefined && user.points !== null && !isNaN(user.points)) {
    totalPoints += user.points;
    }
    
    if (user.extraPoints !== undefined && user.extraPoints !== null && !isNaN(user.extraPoints)) {
    totalPoints += parseInt(user.extraPoints);
    }

    return (
      <div className="modal">
        <div className="modal-content">
            <div className="teampage">
            {/* <div className='header'><h2>{user.teamName}</h2></div> */}
          
          <div className="">
            {questions?.map((question, key) => {
              const answer = user?.answers ? user.answers.find((a) => a.questionId === question.id) : null;
              const answered = Boolean(answer);
              const correct = answered && answer?.points > 0;
              
              
              
              return (
                <div
                  key={question.id}
                  className={`question table-row${answered ? (correct ? ' correct' : ' incorrect') : ''}`}
                >
                    <div className="top">
                        <div className="key">{key + 1 } </div>

                        <div className="text"><div>{question.question}</div></div>

                    </div>
                    <div className="answer-box">
                        {answer && (question.type === 'camera' ? <ImageAnswer answer={answer} /> : <TextAnswer answer={answer} />)}
                    </div>
                    <div className="bottom">
                        
                        
                        <div className={`result-container ${answered ? (correct ? ' correct' : ' incorrect') : ''}`}>{answered ? (correct ? "Riktig" : "Feil") : 'IKke svart'}</div>
                        <div className='points'>Poeng: {answered ? answer.points : 0}</div>
                        
                        
                    </div>
                  
                  
                </div>
              );
            })}
            {QRquestions?.map((question, key) => {
              const answer = user?.answers ? user.answers.find((a) => a.questionId === question.id) : null;
              const answered = Boolean(answer);
              const correct = answered && answer?.points > 0;
              
              
              
              return (
                <div
                  key={question.id}
                  className={`question table-row${answered ? (correct ? ' correct' : ' incorrect') : ''}`}
                >
                    <div className="top">
                        <div className="key">{key + 1 + questions?.length } </div>

                        <div className="text">Fra QR-kode:<div>{question.question}</div></div>

                    </div>
                    <div className="answer-box">
                        {answer && (question.type === 'camera' ? <ImageAnswer answer={answer} /> : <TextAnswer answer={answer} />)}
                    </div>
                    <div className="bottom">
                        
                        
                        <div className={`result-container ${answered ? (correct ? ' correct' : ' incorrect') : ''}`}>{answered ? (correct ? "Riktig" : "Feil") : 'IKke svart'}</div>
                        <div className='points'>Poeng: {answered ? answer.points : 0}</div>
                        
                        
                    </div>
                  
                  
                </div>
              );
            })}
          </div>
          <div className="score-footer">
            <div className="score-text">Poeng: {user.points}</div>

            <div className="score-separator">+</div>

            <div className="score-add">
                Ekstrapoeng: {user.extraPoints}
                
                </div>            
                <div className="score-separator">=</div>
            <div className="score-sum">Totalt: {totalPoints}</div>
          </div>
          <div className="score-footer2">
            <div className="add-text">Gi ekstra poeng: </div>
            <div className="add-inputs">
                <input
                    className='add-field'
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                />
                <input
                    className='add-button'
                    type="button"
                    value="Legg til"
                    onClick={updateExtraPoints}
                />
                </div>            
          </div>
        </div>
        </div>
      </div>
    );
  };


  const TextAnswer = ({ answer }) => {

    return (
        <div>
           Svar:  {answer.answer}
            
        </div>
        );
  };

  const ImageAnswer = ({ answer }) => {

    return (
    <div className='img'>

        <img src={answer.answer} />
        
    </div>
    );
};



