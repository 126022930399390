import React, { Component } from 'react';
import FeideSignIn from './molecules/FeideSignIn';

class FeideLogin extends Component {
  render() {
    return (
      <div>
        <FeideSignIn />
      </div>
    );
  }
}

export default FeideLogin;
