import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { AdminNav } from './molecules/AdminNav';
import useFirebase from '../../../hooks/useFirebase';
import { customStyles } from "../MyQuestions/MyQuestionsModalStyles";
import QuestionCard from '../../molecules/QuestionCategory/molecules/QuestionCard';
import { ContentWrapper } from '../../molecules/ContentWrapper/ContentWrapper';
import Loading from "../../atoms/Loading";
import { createQuestion } from '../../../utils/API/endpoints';
import NewQuestionForm from '../../molecules/NewQuestionForm/NewQuestionForm'

export function AdminLibrary() {
  const firebase = useFirebase();
  const [search, setSearch] = useState('');
  const [questions, setQuestions] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!firebase) return;
    firebase.userQuestions().onSnapshot((snapshots) => {
      const temp = [];
      snapshots.docs.forEach(item => {
        if (item.data().libraryCandidate !== false) {
          temp.push({
            id: item.id,
            ...item.data(),
          });
        }
      });
      setQuestions(temp);
    });
  }, [firebase]);

  function showQuestionInformation(question) {
    setSelectedQuestion(question);
    setModalOpen(true);
  }

  function hideQuestionInformation() {
    setSelectedQuestion(null);
    setModalOpen(false);
  }

  function removeQuestionFromList(question) {
    firebase.question(question.id)
    .update({
        libraryCandidate: false,
    })
    .catch((err) => {
        console.log(err);
    });
    const index = questions.indexOf(question);
    if (index > -1) {
      const copy = questions.slice();
      copy.splice(index, 1);
      setQuestions(copy);
    }
  }

  async function addQuestionToLibrary(question, payload) {
    if (!firebase || !question) {
        return;
    }
    if (payload === undefined) {
        const original = await firebase.question(question.id).get();
        payload = {
            ...original.data(),
        };
        payload.originalOwner = payload.owner;
        payload.owner = 'library';
    }
    createQuestion(firebase, payload)
    .catch((err) => {
        console.log(err);
    });
    removeQuestionFromList(question);
  }

  return (
    <>
        <ContentWrapper>
            <AdminNav />
            <div style={{ margin: '20px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <div style={{ fontSize: '1.5em', color: '#333', padding: '20px'}}>Spørsmål</div>
                <input
                  placeholder="Søk blant spørsmål ..."
                  className="games-list-search-bar"
                  onChange={(e) => setSearch(e.target.value)}
                  style={{
                    margin: '10px',
                    padding: '10px',
                    width: '20%',
                    background: '#F3F3F3',
                    borderRadius: '5px',
                    border: '0px solid black',
                  }} />
              </div>
              <div className='admin__wrapper' style={{flexDirection: 'column'}}>
              {!questions && true ? (
                  <Loading />
              ) : questions.length > 0 ? (
                    questions
                      .filter((value) =>
                        `${value.question}`
                          .toLowerCase().includes(search.toLowerCase()),
                      )
                      .map((q, i) => {
                        return (
                          <QuestionCard
                             key={'qcard_key_' + q.id}
                             selectedQuestion={q}
                             selectedQuestionIndex={i + 1}
                             openQuestionPreview={showQuestionInformation}
                             closeQuestionPreview={hideQuestionInformation}
                             hideFromList={removeQuestionFromList}
                             addQuestionToLibrary={addQuestionToLibrary}
                             isEditable={false}
                             isDeletable={false}
                             isAllQuestionsListMode={true}
                           />
                        );
                      })
                ) : (
                  <p>Ingen treff.</p>
                )}
              </div>
            </div>
        </ContentWrapper>
        {selectedQuestion ? (
        <Modal
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            contentLabel="Spørsmål"
            style={customStyles}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
         >
            <NewQuestionForm
              key={'preview_key_' + selectedQuestion.id}
              edit={true}
              selectedQuestion={selectedQuestion}
              firebase={firebase}
              modalExitButtonHandler={hideQuestionInformation}
              handleModalExit={hideQuestionInformation}
              copyToLibrary={addQuestionToLibrary}
             />
        </Modal>
        ) : null}
    </>
  );
}
