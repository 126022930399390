import React from 'react';
import Modal from 'react-modal';
import { previewStyle } from './GameBuilderStyles';
import { questionsVariables } from '../../variables/variables';

export default function CopyCoordinatesModal({
  isOpen,
  onConfirm,
  onCancel,
}) { 
  return (
    <Modal
        isOpen={isOpen}
        onAfterOpen={() => { /* Do nothing */ }}
        onRequestClose={onCancel}
        contentLabel={questionsVariables.copyTitle}
        style={previewStyle}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
     >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            minHeight: '250px',
          }}
         >
          <h3>{questionsVariables.useCoords}</h3>
          <div style={{ display: 'flex' }}>
            <button
              onClick={() => onConfirm(true)}
              style={{
                backgroundColor: '#1cc065',
                borderRadius: '100px',
                borderStyle: 'none',
                color: 'white',
                padding: '12px 50px',
                marginRight: '1em',
                cursor: 'pointer',
              }}
             >
              {questionsVariables.copyConfirm}
            </button>
            <button
              onClick={() => onConfirm(false)}
              style={{
                backgroundColor: 'rgb(235, 113, 121)',
                borderRadius: '100px',
                borderStyle: 'none',
                color: 'white',
                padding: '12px 50px',
                cursor: 'pointer',
              }}
             >
              {questionsVariables.copyReject}
            </button>
          </div>
        </div>
    </Modal>
  );
}
