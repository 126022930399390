import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { ACCOUNT, EVENT, OVERVIEW, SIGN_IN } from './constants/routes';

import Index from './pages/index';
import Overview from './pages/overview';
import SignIn from './pages/signin';

import Admin from './pages/admin';
import Stats from './pages/admin/stats';
import Users from './pages/admin/users';
import AdminLibrary from './pages/admin/library';
import Account from './pages/account';
import Event from './pages/event';
import GameBuilder from './pages/game-builder';

import Library from './pages/library';
import MyGames from './pages/my-games';
import MyQuestions from './pages/my-questions';

import PwForget from './pages/pw-forget';
import Register from './pages/register';
import Results from './pages/results';

import NotFound from './pages/404'; 
import NavigationProvider from './utils/navigation/context';
import FeideLogin from './components/scenes/Auth/scenes/FeideLogin/FeideLogin';

ReactDOM.render(
  <Router>
    <NavigationProvider>
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path={OVERVIEW} element={<Overview />} />
      <Route path={SIGN_IN} element={<SignIn />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/admin/stats" element={<Stats />} />
      <Route path="/admin/users" element={<Users />} />
      <Route path="/admin/library" element={<AdminLibrary />} />
      <Route path={ACCOUNT} element={<Account />} />
      <Route path={EVENT} element={<Event />} />
      <Route path="/game-builder" element={<GameBuilder />} />
      <Route path="/library" element={<Library />} /> 
      <Route path="/my-games" element={<MyGames />} />
      <Route path="/my-questions" element={<MyQuestions />} />
      <Route path="/pw-forget" element={<PwForget />} />
      <Route path="/register" element={<Register />} />
      <Route path="/results" element={<Results />} />
      <Route path="/feide" element={<FeideLogin />} />
      <Route path="*" element={<NotFound />} /> 
      
    </Routes>
    </NavigationProvider>
  </Router>,
  document.getElementById('root')
);
