import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = () => {
  return (
    <Helmet title="Poplar">
      <meta name="poplar-logo" content="favicon.ico" />
      <meta name="description" content="Poplar er et læringsverktøy som gjør det enkelt å skape mestring, læring og aktivisering" />
      <link rel="icon" type="image/png" href="favicon.ico" />
    </Helmet>
  );
};

export default Seo;
