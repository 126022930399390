import React, { useState, useEffect } from 'react';
import { Input } from '../atoms/Input';
import { Checkbox } from '../atoms/Checkbox';
import { NumberInput } from '../atoms/NumberInput';
import { FaPlus, FaQuestion, FaQuestionCircle, FaTimes, FaTimesCircle } from 'react-icons/fa';

const inputIndexWrapper = (func, index) => (e) => {
	func(e, index);
};

const generateMultipleChoice = (
	amount,
	handleAddAlternative,
	alternatives,
	handleCheckMark,
	checkedAnswers,
	handleRemoveAlternative,
) => {
	let arr = [];
	for (let i = 0; i < amount; i++) {
		arr.push(
			<div className="question-form-set-answer-input-container" key={i}>
				<span
					style={{
						height: '45px',
						width: '50px',
						whiteSpace: 'nowrap',
						marginRight: '15px',
						display: 'flex',
						alignItems: 'center',
					}}>{'Alt ' + (i + 1)}</span>
				<Input
					placeholder={'Svar ' + (i + 1)}
					value={alternatives[i]}
					maxLength={140}
					handler={inputIndexWrapper(handleAddAlternative, i)}
					styleOverride={{
						border: 'none',
						padding: '1rem 2.5rem 1rem 1rem',
						height: '45px',
					}}
				 />

				<Checkbox
					className="multiple-choice-checkbox"
					checked={checkedAnswers.includes(i)}
					handler={() => handleCheckMark(i)}
				 />
				<div
					className="multiple-choice-remove-button"
					onClick={(_) => handleRemoveAlternative(i)}
					onKeyDown={(_) => handleRemoveAlternative(i)}
					role="button"
					tabIndex="0">
					<FaTimesCircle style={{ height: '100%', width: '100%', filter: 'invert(1)' }}/>
				</div>
			</div>,
		);
	}
	return arr;
};

export const MultipleChoice = ({
	amount,
	alternatives,
	handleAddAlternative,
	handleCheckMark,
	checkedAnswers,
	handleRemoveAlternative,
	handleAddMoreAlternatives,
}) => {
	return (
		<>
			{generateMultipleChoice(
				amount,
				handleAddAlternative,
				alternatives,
				handleCheckMark,
				checkedAnswers,
				handleRemoveAlternative,
			)}
			<div className="multiple-choice-add-button-container">
				<div
					className="multiple-choice-add-button"
					onClick={(_) => handleAddMoreAlternatives()}
					onKeyDown={(_) => handleAddMoreAlternatives()}
					role="button"
					tabIndex="0"> + </div>
			</div>
		</>
	);
};


export const NumberAnswer = ({
	handleNumberAnswerChange,
	numberAnswer,
	handleMarginOfErrorChange,
	marginOfError,
}) => {
	return (
		<div className="number-answer-min-max">
			<NumberInput
				placeholder={'Tallsvar'}
				description={'Skriv inn svaret på spørsmålet'}
				value={numberAnswer === 0 ? '0' : numberAnswer}
				handler={handleNumberAnswerChange}
				className="number-question-input"
				styleOverride={{ border: 'none', padding: '1rem' }}
			 />
			<NumberInput
				placeholder={'Feilmargin'}
				description={'± verdi. Null eller tomt felt for nøyaktig svar.'}
				value={marginOfError === 0 ? '0' : marginOfError}
				handler={handleMarginOfErrorChange}
				className="practical-number-input"
				styleOverride={{ border: 'none', padding: '1rem', width: '10rem' }}
			 />
		</div>
	);
};

export const NumberAnswerMinMax = ({
	handleNumberAnswerChange,
	handleNumberAnswerMinChange,
	handleNumberAnswerMaxChange,
	numberAnswer,
	numberAnswerMin,
	numberAnswerMax,
}) => {
	return (
		<div className="number-answer-min-max">
			<NumberInput
				placeholder={'Min'}
				description={'Minste verdi'}
				value={numberAnswerMin === 0 ? '0' : numberAnswerMin}
				handler={handleNumberAnswerMinChange}
				className="practical-number-input"
				styleOverride={{ border: 'none', padding: '1rem' }}
			/>
			<NumberInput
				placeholder={'Maks'}
				description={'Største verdi'}
				value={numberAnswerMax === 0 ? '0' : numberAnswerMax}
				handler={handleNumberAnswerMaxChange}
				className="practical-number-input"
				styleOverride={{ border: 'none', padding: '1rem' }}
			/>
		</div>
	);
};

/* 
Old variant
export const SingleAnswer = ({
	handleSingleAnswerChange,
	answer,
}) => {
	return (
		<>
			<Input
				placeholder={'Skriv inn svaret på spørsmålet'}
				value={answer}
				maxLength={140}
				handler={handleSingleAnswerChange}
				styleOverride={{ border: 'none', padding: '1rem' }}
			 />
		</>
	);
};
*/


export const SingleAnswer = ({ handleSingleAnswerChange, answer }) => {
	const [answers, setAnswers] = useState([{ variants: [''] }]);
	const [answerPreview, setAnswerPreview] = useState('');
	const [desc, setDesc] = useState(false);
	useEffect(() => {
		if (true) {
			const parsedAnswers = answer.split(';').map(ans => ({
				variants: ans.split('|'),
			}));
			setAnswers(parsedAnswers);
		}
	}, [answer]);

	useEffect(() => {
		const filteredAnswers = answers.filter((answer) => answer.variants.some((variant) => variant.trim() !== ''));

		const answerString = filteredAnswers
		  .map((answer) => answer.variants.filter((variant) => variant.trim() !== '').join('|'))
		  .join(';');
	  
		if (answerString) {
		  handleSingleAnswerChange(answerString);
		  
		}
		setAnswerPreview(answerString);

	}, [answers]);

	const handleVariantChange = (e, answerIndex, variantIndex) => {
		const newAnswers = [...answers];
		newAnswers[answerIndex].variants[variantIndex] = e.target.value;
		setAnswers(newAnswers);
	};

	const handleAddVariant = (answerIndex) => {
		const newAnswers = [...answers];
		newAnswers[answerIndex].variants.push('');
		setAnswers(newAnswers);
	};
	const handleRemoveVariant = (answerIndex, variantIndex) => {
		const newAnswers = [...answers];
		newAnswers[answerIndex].variants.splice(variantIndex, 1);
		setAnswers(newAnswers);
	};

	const handleAddAnswer = () => {
		setAnswers([...answers, { variants: [''] }]);
	};
	const handleRemoveAnswer = (answerIndex) => {
		const newAnswers = [...answers];
		newAnswers.splice(answerIndex, 1);
		setAnswers(newAnswers);
	};

	return (
		<>
		
		{answers.map((answer, answerIndex) => (
				<div className='simple-option-container' key={answerIndex}>
					<div className='simple-option'>
					{answer.variants.map((variant, variantIndex) => (
						<div className='simple-variant-container' key={variantIndex}>
							<div className='simple-variant'>
								<input
									placeholder={(answers.length === 1 && answer.variants.length === 1) ? 'Ord eller uttrykk' : ''}
									value={variant}
									onChange={(e) => handleVariantChange(e, answerIndex, variantIndex)}
								/>
								{answer.variants.length > 1 &&
									<div className='simple-variant-remove-button' onClick={() => handleRemoveVariant(answerIndex, variantIndex)}>
										<FaTimes style={{ height: '80%', width: '80%', filter: '' }}/>
									</div>
								}
							</div>
							{(variantIndex < answer.variants.length - 1) && <div className='or-separator'>eller</div>}
						</div>
					))}
						<div className='simple-add-button' onClick={() => handleAddVariant(answerIndex)}>
							<FaPlus /> Legg til variant (eller) </div>
						{answers.length > 1 &&
						<div className="simple-remove-button-container">
							<div className='simple-remove-button' onClick={() => handleRemoveAnswer(answerIndex)}>
								<FaTimes style={{ height: '80%', width: '80%', filter: '' }}/>
							</div>
							</div>
						}
					</div>
					{answerIndex < answers.length - 1 && <div className='and-separator'>og</div>}
				</div>
			))}
			<div className='simple-add-button' onClick={handleAddAnswer}><FaPlus /> Legg til obligatorisk uttrykk (og)</div>
			{!desc && 
				<div className='simple-desc-button' onClick={() => setDesc(true)}>
					<div><FaQuestionCircle /> Forklaring</div>
				</div>
			}
			<div className="simple-desc" style={{display: desc ? 'block': 'none'}}>
				Svaret må inneholde hvert ord/uttrykk nøyaktig slik det skrives inn (og), 
				men en kan legge inn ulike varianter av hvert fasit-ord (eller). 
				Det gjøres ikke forskjell på stor og liten bokstav. 
				Tegn, tall og symboler teller, men mellomrom ignoreres. 
				Tegnene ";" og "|" kan ikke brukes. 
				<br />Eksempel: Bøy "å vere" på nynorsk: 
				<br /> (å vere ELLER å vera) OG (er) OG (var) OG (har vore)
				</div>
			<div className="simple-warning">Bruk av "og"/"eller" krever oppdatert versjon av Poplar-appen (4. sept 2023) for å gi riktig svar.</div>
			<br />{answerPreview}
		</>
	);
};
