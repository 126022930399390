import React from 'react';
import {PASSWORD_FORGET} from '../../../../constants/routes';
import { Link } from 'react-router-dom';


const PasswordForgetLink = () => (
    <p>
        <Link to={PASSWORD_FORGET}>Gl&oslash;mt passord?</Link>
    </p>
);

export default PasswordForgetLink;
