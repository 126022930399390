import React from 'react';

import moment from 'moment';
import { buttonStrings } from '../../../variables/variables';
import {
    FaTrash,
    FaTree,
    FaHome,
    FaQuestion,
    FaPen,
    FaCheckCircle,
    FaCopy,
    FaShareAlt,
    FaLightbulb,
    FaPlayCircle,
    FaClock,
    FaQuestionCircle
} from 'react-icons/fa';
import { GAME_BUILDER } from '../../../../constants/routes';
import { useNavigate } from 'react-router';

const gNameParser = (name) => {
  if (name) {
    return name;
  } else {
    return "Feil: Data ikke funnet";
  }
};

const gCreatedParser = (game) => {
  if (typeof game.created != "undefined") {
    const theDate = game.created.toDate();
    return (
      String(theDate.getDate()).padStart(2, "0") +
      "." +
      String(theDate.getMonth() + 1).padStart(2, "0") +
      "." +
      theDate.getFullYear()
    );
  }
  return "-";
};

export default function GameCard({
  sourceGame,
  isSelected,
  isEditable,
  isDeletable,
  isShareable,
  handleActivateGameAsEvent,
  handleShowGameInfo,
  handleDeleteGame,
  handleEditExistingGame,
  handleShareGame,
}) {
  const navigate = useNavigate();
  //const navigate = (path) => { console.log("navigating to: " + path);}
  
  const handleCopyForNewGame = () => {
    
    console.log("handleCopyForNewGame");
    typeof window !== "undefined" &&
      navigate(`${GAME_BUILDER}?gameId=${sourceGame.id}&outside=${sourceGame.outside}`);
  };

  return (
    <div className={'game-card-container game-card-list ' + (isSelected ? ' ' : '')}>
      
      <div className='top'>
      <div className="game-card-title"
       onClick={() => handleShowGameInfo(sourceGame, navigate)}
       onKeyDown={(e) => e.key=="Enter" && handleShowGameInfo(sourceGame, navigate)}
       role="button"
       tabIndex="0"
      >
          {(sourceGame.name)}
        </div>

     
        
        
        <div className='date'>
           {gCreatedParser(sourceGame)}
        </div>

        </div>

    <div className='bottom'>
        
       
    <div className='card-icons'>
           
           <div className={`icon-container ${sourceGame.outside ? 'outside' : 'inside'}`} >
             <div className='icon'>
               {sourceGame.outside ?  
                 <FaTree /> : sourceGame.live ? 
                 <FaLightbulb /> : 
                 <FaHome /> }
             </div>
             <div className='text'>{sourceGame.outside ? 'UTE' : sourceGame.live ? 'LIVE' : 'INNE'}</div>
           </div>
           
           {sourceGame.active && <>
           <div className={`icon-container play`}>
             <div className='icon'>
               
                   <FaPlayCircle />
                   </div>
               <div className='text'>
                 Aktivt
               
             </div>
           </div>
           </>}



           <div
             className=" icon-container">
             {/* Antall spørsmål pluss ikon */}
             <div className="overview-event-card-questions-icon icon">
               <FaQuestionCircle />
             </div>
             <div className="overview-event-card-questions-num text">
               {sourceGame.questions
                 ? sourceGame.questions.length
                 : 0}
             </div>
           </div>

       </div> 



        <div className='actions'>
          
          <div className='button' 
            onClick={() => handleShowGameInfo(sourceGame)}
            onKeyDown={(e) => e.key=="Enter" && handleShowGameInfo(sourceGame)}
            role="button"
            tabIndex="0" >Vis</div>

            {isEditable && <div className='button'  
              onClick={() => handleEditExistingGame(sourceGame)}
              onKeyDown={(e) => e.key=="Enter" && handleEditExistingGame(sourceGame)}
              role="button"
              tabIndex="0" >Endre</div>}

            {/*
            <div className='button' 
              onClick={() => handleActivateGameAsEvent(sourceGame)}
              onKeyDown={(e) => e.key=="Enter" && handleActivateGameAsEvent(sourceGame)}
              role="button"
              tabIndex="0" >Bruk spill </div>

            sourceGame.owner !== 'library' && */}
            <div className='button' 
              onClick={() => handleCopyForNewGame(sourceGame)}
              onKeyDown={(e) => e.key=="Enter" && handleCopyForNewGame(sourceGame)}
              role="button"
              tabIndex="0" >Bruk spill</div>
               


            {isShareable && <div className='button' 
              onClick={() => handleShareGame(sourceGame)}
              onKeyDown={(e) => e.key=="Enter" && handleShareGame(sourceGame)}
              role="button"
              tabIndex="0" >Del</div>}

            {isDeletable && <div className='button' 
              onClick={() => handleDeleteGame(sourceGame)}
              onKeyDown={(e) => e.key=="Enter" && handleDeleteGame(sourceGame)}
              role="button"
              tabIndex="0">Slett</div> }
            
        </div>


      </div>
      <div className="game-card-right" />
    </div>
  );
}
