export const customStyles = {
  content: {
    top: '10%',
    left: '8%',
    right: '8%',
    bottom: '10%',
    minHeight: '500px',
    background: 'rgba(242, 241, 240, 1)',
    color: 'black',
    padding: '0px',
    borderRadius: '1rem',
    border: 'none',
    boxShadow: '0px 4px 10px rgba(103, 107, 133, 0.15)',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(155, 155, 155, 0.75)',
  },
};

export const previewStyle = {
  content: {
    top: '40%',
    bottom: '60%',
    left: '30%',
    right: '30%',
    minWidth: '500px',
    minHeight: '250px',
    height: 'fit-content',
    background: 'rgba(242, 241, 240, 1)',
    color: 'black',
    padding: '0px',
    borderRadius: '1rem',
    border: 'none',
    boxShadow: '0px 4px 10px rgba(103, 107, 133, 0.15)',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(155, 155, 155, 0.75)',
  },
};

export const languageButtonSelectedStyle = {
  height: '2rem',
  width: '2rem',
  borderStyle: 'none',
  backgroundColor: '#161B1E',
  border: '2px solid #161B1E',
  borderRadius: '99rem',
  marginRight: '1rem',
  outline: 'none',
};

export const languageButtonStyle = {
  height: '2rem',
  width: '2rem',
  borderStyle: 'none',
  backgroundColor: '#FFF',
  border: '2px solid #161B1E',
  borderRadius: '99rem',
  marginRight: '1rem',
  outline: 'none',
};

export const filterClassButtonStyle = {
  width: 'fit-content',
  borderStyle: 'none',
  borderRadius: '1rem',
  color: 'black',
  backgroundColor: '#FFF',
  border: '2px solid black',
  padding: '0.5rem',
  margin: '0.2rem 0.1rem',
  outline: 'none',
};

export const filterClassButtonSelectedStyle = {
  width: 'fit-content',
  borderStyle: 'none',
  borderRadius: '1rem',
  color: 'white',
  border: '2px solid black',
  backgroundColor: '#161B1E',
  padding: '0.5rem',
  margin: '0.2rem 0.1rem',
  outline: 'none',
};

export const tagButtonStyle = {
  borderStyle: 'none',
  borderRadius: '1rem',
  color: 'black',
  backgroundColor: '#FFF',
  border: '2px solid black',
  padding: '0.5rem',
  margin: '0.2rem 0.1rem',
  outline: 'none',
  wordBreak: 'break-word',
};

export const tagButtonSelectedStyle = {
  borderStyle: 'none',
  borderRadius: '1rem',
  color: 'white',
  border: '2px solid black',
  backgroundColor: '#161B1E',
  padding: '0.5rem',
  margin: '0.2rem 0.1rem',
  outline: 'none',
  wordBreak: 'break-word',
};

export const boxShadow = 'rgba(0, 0, 0, 0.12) 0 8px 6px -6px inset';
