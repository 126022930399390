export const customStyles = {
  content: {
    top: '10%',
    left: '8%',
    right: '8%',
    bottom: '10%',
    minHeight: '500px',
    background: 'rgba(242, 241, 240, 1)',
    color: 'black',
    padding: '0px',
    borderRadius: '1rem',
    border: 'none',
    boxShadow: '0px 4px 10px rgba(103, 107, 133, 0.15)',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(155, 155, 155, 0.75)',
  },
};

export const previewStyle = {
  content: {
    
    margin: 'auto 20%',
    minWidth: '500px',
    maxWitdth: '1000px',
    minHeight: '250px',
    height: 'fit-content',
    background: 'rgba(242, 241, 240, 1)',
    color: 'black',
    padding: '0px',
    borderRadius: '1rem',
    border: 'none',
    boxShadow: '0px 4px 10px rgba(103, 107, 133, 0.15)',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(155, 155, 155, 0.75)',
  },
};
