import React from 'react';
import { QuestionList } from '../QuestionCategory/molecules/QuestionList';

const ondragover = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const ondragenter = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export default function InsideQuestionSelection({
  title,
  questions,
  onDrop,
  onCardDeleteClick,
  onCardEditClick,
  onCardPreviewClick,
  onDropT,
  questionIsSelected=false,
}) {
  return (
    <div
      onDrop={(e, _) => onDrop(e)}
      onDragOver={(e) => ondragover(e)}
      onDragEnter={(e) => ondragenter(e)}
      className="selected-questions-container"
      role="link"
      tabIndex="0"
    >
      <div className="questions-list-container">
        <div className="questions-list-title">
          <div className="questions-list-title-text">{title}</div>
        </div>
        <hr className="qustions-list-divider"></hr>
        <QuestionList
          onCardDeleteClick={onCardDeleteClick}
          onCardEditClick={onCardEditClick}
          onCardPreviewClick={onCardPreviewClick}
          open={true}
          outside={false}
          questions={questions}
          onDropT={onDropT}
          withSelector={false}
          questionIsSelected={questionIsSelected}
        />
      </div>
    </div>
  );
}
